<div style="display:flex; flex-direction: column; flex-grow:1">
    <div style="display:flex; flex-wrap:wrap; justify-content:center; padding: 10px 0px 10px 0px">
        <div style="padding-right:2em">Zoom to:</div>
        <div class="link" style="padding-right:2em" title="Zoom to your current location" (click)="PositionToCurrentLocation()">Current Location</div>
        <div *ngIf="!LimitToTicketsWithinXFtOfCurrentLocation" class="link" title="Zoom to the bounds of your Tickets" (click)="ZoomToTicketExtents()">Tickets</div>
        <div *ngIf="LimitToTicketsWithinXFtOfCurrentLocation" class="gray-primary-text" style="font-style:italic;font-size:smaller">* Limiting to Tickets within {{LimitToTicketsWithinXFtOfCurrentLocation}} feet of current location</div>
    </div>
    <div #MapHost id="MapHost" class="map" [ngClass]="{'map-search-visible': MapSearchIsVisible}">
        <iq-map-search-button></iq-map-search-button>
    </div>
    <div id="popup" class="ticket-details-control">
        <!-- Extra div w/margin needed or the parent div GROWS by the padding in it plus the border. -->
        <!-- So when .ticket-details-control has width=400px, it grows to 421px.  Which makes it impossible to constrain it to phone-portrait. -->
        <div style="margin:10px">
            <button id="popup-closer" class="closeBox hasclosebox">x</button>
            <div id="popup-content" style="width:auto">
                <ticket-map-item-pager [TicketMapItemList]="SelectedTicketList" (RefreshMap)="Clear()" (VisibleTicket)="MapItemPagerVisibleTicket($event)"></ticket-map-item-pager>
            </div>
        </div>
    </div>
</div>
