<div style="display: flex; flex-direction: column; flex-grow: 1; height: 100vh; overflow: hidden; background-color: white">
    <div style="display: flex; flex-direction: column">
        <mat-toolbar color="primary" class="iq-display-1">
            <div class="iq-phone-header-left">
                <iq-phone-button icon="Back" (click)="Close()" [disabled]="!child.IsValid && !child.IgnoreRequiredDiscussionResponses">Back</iq-phone-button>
            </div>
            <div class="iq-phone-header-center">{{Data.Ticket.TicketNumber}}</div>
            <div class="iq-phone-header-right">
                <iq-phone-button icon="New" *ngIf="child.IsExcavatorOfTicket && child.CenterUsesExcavatorComments" (click)="child.AddExcavatorComment()">Add Comments</iq-phone-button>
                <div *ngIf="!child.IsExcavatorOfTicket || !child.CenterUsesExcavatorComments" style="min-width: 50px;">&nbsp;</div>
            </div>
        </mat-toolbar>
        <div style="padding: 20px 2vw 20px 2vw; overflow: auto; height: 100%; display: flex; flex-grow: 1; flex-direction: column;" class="FlyoutContent">
            <ticket-response-service-area
                                          [style.flex-grow]="child.ViewOption === 1 ? '1' : null"
                                          [style.height]="child.ViewOption === 1 ? '100%' : '80%'"
                                          class="TicketResponseServiceArea" [ServiceAreaListData]="Data" #child>
            </ticket-response-service-area>
            <div style="display:flex; padding-left: 20px; flex-wrap: wrap;">
                <div style="display:flex; width: 100%; padding-top: 10px;">
                    <div style="height:20px; width:20px; margin-right:10px" class="no-response-background"></div>
                    <div>No Response Posted - Excavator must check job site for marks</div>
                </div>
                <div *ngIf="child.ViewOption === 2" style="display: flex; width: 100%; padding-top: 10px;">
                    <div style="height:20px; width:20px; margin-right:10px;" class="event-background"></div>
                    <div>Ticket Entry Event</div>
                </div>
                <div style="display: flex;">
                    <mat-checkbox *ngIf="child.NeedsExcavationDate || child.NeedsMarkingDelayResponse" color="primary" (change)="child.IgnoreRequired($event)">
                        I choose not to provide required answers right now
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>
