import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DeviceDetectorService } from 'Services/DeviceDetector.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    private _Destroyed: Subject<void> = new Subject();

    constructor(private _Renderer: Renderer2, public DeviceDetectorService: DeviceDetectorService)
    {
        this.DeviceDetectorService.Changed
            .pipe(takeUntil(this._Destroyed))
            .subscribe(() => this.DeviceChanged());
        this.DeviceChanged();
    }

    private DeviceChanged(): void {
        //  Note that desktop/portrait is forced when printing - it is specified in the app.component.html.
        //  It is not possible to prevent the main form from re-rendering during printing because we use a combination of
        //  css classes (below) and the flags in the DeviceDetector.  And the DeviceDetector is a root/global service
        //  so the flags in it need to also be set to Desktop/Portrait during printing.
        if (this.DeviceDetectorService.IsPhone) {
            this._Renderer.addClass(document.body, 'iq-device-size-phone');
            this._Renderer.removeClass(document.body, 'iq-device-size-desktop');

            if (this.DeviceDetectorService.IsLandscape) {
                this._Renderer.addClass(document.body, 'iq-device-landscape');
                this._Renderer.removeClass(document.body, 'iq-device-portrait');
            }
            else {
                this._Renderer.addClass(document.body, 'iq-device-portrait');
                this._Renderer.removeClass(document.body, 'iq-device-landscape');
            }
        }
        else {
            this._Renderer.addClass(document.body, 'iq-device-size-desktop');
            this._Renderer.removeClass(document.body, 'iq-device-size-phone');
            this._Renderer.removeClass(document.body, 'iq-device-portrait');
            this._Renderer.removeClass(document.body, 'iq-device-landscape');
        }
    }
    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();
    }
}
