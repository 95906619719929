
export enum GeometryTypeEnum {
    Point = 1,
    Line = 2,
    Polygon = 3
}

export enum GeometryTypeEnumDescriptions {
    Point = "Point",
    Line = "Line",
    Polygon = "Polygon"
}

//  Geometry Types used by OpenLayers as an enum
//  https://openlayers.org/en/latest/apidoc/module-ol_geom_GeometryType.html
export enum OLGeometryTypeEnum {
    Point = "Point",
    LineString = "LineString",
    LinearRing = "LinearRing",
    Polygon = "Polygon",
    MultiPoint = "MultiPoint",
    MultiLineString = "MultiLineString",
    MultiPolygon = "MultiPolygon",
    GeometryCollection = "GeometryCollection",
    Circle = "Circle"
}
