import { Output, Input, EventEmitter, OnInit, OnDestroy, Directive } from "@angular/core";
import { SearchFilterOperatorEnum } from "Enums/SearchFilterOperator.enum";
import { SearchFilter, SearchFilterValue } from "@iqModels/Searching/SearchFilter.model";
import { Observable, Subscription } from "rxjs";
import { ENTER, COMMA } from "@angular/cdk/keycodes";

@Directive()
export abstract class BaseFilterItem implements OnInit, OnDestroy {
    @Output() valueChange: EventEmitter<SearchFilter> = new EventEmitter();

    @Input() filter: SearchFilter;
    @Input() clearFilter: Observable<void>;//Only need to pass this if the filter can be cleared whil this is shown.  i.e. when filtering form the column popup, etc
    @Input() allowEmpty: boolean = true;

    @Input() set disabled(val: boolean) {
        this.disable(val);
    }
    isDisabled: boolean = false;

    private clearSubscription: Subscription;//Do it this way since we only have one observable to cancel..If we have more than change it to use takeUntil

    readonly separatorKeysCodes: number[] = [ENTER];//, COMMA];

    values: SearchFilterValue[] = [];
    searchOperator: SearchFilterOperatorEnum = SearchFilterOperatorEnum.Contains;

    addTextToFilterHintText = "Press Enter to add text to the filter";

    constructor() { }

    ngOnInit() {
        this.searchOperator = this.filter.Operator;
        this.values = this.filter.Values;
        if (this.clearFilter)
            this.clearSubscription = this.clearFilter.subscribe(() => this.clear());
    }
    ngOnDestroy() {
        if (this.clearSubscription)
            this.clearSubscription.unsubscribe();
    }

    protected fireChangeEvent() {
        this.valueChange.emit(new SearchFilter(this.filter.PropertyName, this.searchOperator, this.values));
    }

    protected addValue(values: SearchFilterValue[]) {
        if (!values)
            return;

        let addedNewValue = false;

        values.forEach(value => {
            if ((value.FilterValue || '').trim()) {
                addedNewValue = true;
                if (!this.values)
                    this.values = [];
                this.values.push(new SearchFilterValue(value.FilterValue.trim().toUpperCase(), value.DisplayValue?.trim()?.toUpperCase()));
            }
        });
            
        //  Only call if new one added
        if (addedNewValue)
            this.fireChangeEvent();
    }

    remove(value: SearchFilterValue): void {
        //  Don't do the check to prevent if !allowEmpty and length == 1 - that's a bad user experience to allow them to add something
        //  and then not allow them to remove it unless they first add something else!
        if (this.isDisabled)// || (!this.allowEmpty && this.values.length === 1))
            return;

        const index = this.values.findIndex(f => f.FilterValue === value.FilterValue);

        if (index >= 0) {
            this.values.splice(index, 1);

            //Only call if removed
            this.fireChangeEvent();
        }
    }

    protected disable(disabled: boolean): void {
        this.isDisabled = disabled;
    }

    protected abstract clear(): void;
}
