
import {debounceTime} from 'rxjs/operators';
/*
This is used as the general page busy indicator.  It will get triggered anytime there is an api call or some other busy component is set to be busy.
*/

import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusyService } from 'Services/BusyService';

@Component({
    selector: 'general-busy-indicator',
    templateUrl: './GeneralBusy.component.html',
    styleUrls: ['./GeneralBusy.component.scss']
})
export class GeneralBusyComponent implements OnInit, OnDestroy {

    private subscription: Subscription = null;

    //This is not used right now, but this is here as a way to import specific values via html
    @Input() color: string = "primary"; // The color to use.  Can be 'primary', 'accent', or 'warn'
    @Input() mode: string = "determinate"; // The mode to use.  Can be 'determinate', 'indeterminate', 'buffer', 'query', defaults to 'determinate'
    @Input() value: number = 100; // The value of the progressbar. Defaults to zero.  Ignored if mode is not 'determinate'  
    @Input() buffer: number = 50; // The buffer value of the progress bar. Defaults to zero.  Ignored if mode is not 'buffer'
    
    constructor(private busyService: BusyService) {
    }

    ngOnInit() {
        setTimeout(()=> this.createServiceSubscription());
    }

    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    private createServiceSubscription() {
        this.subscription = this.busyService.generalObservable.pipe(debounceTime(100))//Wait a tick so that this doesn't flash if it's something fast, and so we don't get errors about this changing from start to stop to fast
            .subscribe(show => {
            if (show) {
                this.start();
            } else {
                this.stop();
            }
        });
    }

    start() {
        this.mode = "indeterminate";
        this.color = "accent";
    }

    stop() {
        this.mode = "determinate";
        this.color = "primary";
    }
}
