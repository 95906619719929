
let dataMemory = {};

/**
 * Stores data in a variable in memory
 */
export class MemoryStorage {
	/**
	 * This is used to set a specific item in storage
	 * @param key - the key for the item
	 * @param value - the value
	 * @returns value that was set
	 */
	static setItem(key, value) {
		dataMemory[key] = value;
		return dataMemory[key];
	}

	/**
	 * This is used to get a specific key from storage
	 * @param key - the key for the item
	 * This is used to clear the storage
	 * @returns the data item
	 */
	static getItem(key) {
		return Object.prototype.hasOwnProperty.call(dataMemory, key)
			? dataMemory[key]
			: undefined;
	}

	/**
	 * This is used to remove an item from storage
	 * @param key - the key being set
	 */
	static removeItem(key) {
		delete dataMemory[key];
	}

	/**
	 * This is used to clear the storage
	 */
	static clear() {
		dataMemory = {};
	}
}