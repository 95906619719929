<div style="width: 100vw; height: 100%; background-color: white">
    <div style="display: flex; flex-direction: column; height: 100%; overflow: hidden">
        <mat-toolbar color="primary" class="iq-display-1" style="flex-shrink:0">
            <div class="iq-phone-header-left">
                <iq-phone-button icon="Cancel" (click)="Close()">Cancel</iq-phone-button>
            </div>
            <div class="iq-phone-header-center">{{HeaderText}}</div>
            <div class="iq-phone-header-right">
                <iq-phone-button icon="Save" (click)="Save()" [disabled]="!addComponent.CanSave">Save</iq-phone-button>
            </div>
        </mat-toolbar>

        <iq-ticket-add-positive-response #addComponent style="padding:10px; overflow: auto" [Data]="Data"></iq-ticket-add-positive-response>
    </div>
</div>
