import { Inject, NgModule, Optional } from '@angular/core';
import { Gtag, GtagConfig } from './google-gtag.models';
import { GoogleGtagService, GTAG, GtagConfigToken } from './google-gtag.service';

export function gtagFactory(config: GtagConfig): Gtag {

  if (!config.targetId || config.targetId === "")
      return null;

  if ((window as any).gtag) { return (window as any).gtag; }

  const script = document.createElement('script');

  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.targetId;
  script.type = 'text/javascript';
  script.async = true;

  document.head.appendChild(script);

  (window as any).dataLayer = (window as any).dataLayer || [];
  function gtag(...args) { (window as any).dataLayer.push(arguments); }

  gtag('js', new Date());

  gtag('config', config.targetId, { send_page_view: false, ...config.configInfo });

  ('setParams' in config) && gtag('set', config.setParams);

  ('moreIds' in config) && config.moreIds.forEach(id => gtag('config', id, { send_page_view: false, ...config.configInfo }));

  return (window as any).gtag = gtag;
}


@NgModule({
  providers: [
    GoogleGtagService,
    {
        provide: GTAG,
        useFactory: gtagFactory,
        deps: [[new Optional(), new Inject(GtagConfigToken)]]
    }
]
})
export class GoogleGtagModule { }
