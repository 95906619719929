import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit } from '@angular/core';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { AddPositiveResponseData } from 'Pages/Tickets/Responses/AddPositiveResponse/AddPositiveResponse.component';
import { AddPositiveResponseFlyoutComponent } from "Pages/Tickets/Responses/AddPositiveResponse/Flyout/AddPositiveResponseFlyout.component";
import { take } from 'rxjs';
import { PermissionsService } from 'Services/PermissionsService';
import { SettingsService } from 'Services/SettingsService';
import { SideSlideoutService } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { TicketResponseRowViewBase } from "../TicketResponseRowViewBase";

@Component({
    selector: 'phone-ticket-response-row',
    templateUrl: './PhoneTicketResponseRow.component.html',
    styleUrls: ['./PhoneTicketResponseRow.component.scss']
})
export class PhoneTicketResponseRowComponent extends TicketResponseRowViewBase implements OnInit {

    public StatusColor: string;
    public StatusDescription: string;

    constructor(public SettingsService: SettingsService, private _SideSlideoutService: SideSlideoutService,
        private _PermissionsService: PermissionsService)
    {
        super();
    }

    public ngOnInit(): void {
        if (!this.ResponseItem.Response)
            return;

        if (!this.ResponseItem.Response.ResponseID) {
            this.StatusDescription = this.ResponseItem.Response.ResponseRequired ? "No Response" : "Not Required";
            this.StatusColor = this.ResponseItem.Response.ResponseRequired ? "red" : null;
            return;
        }

        if (this.ResponseItem.Response.ResponseIsNotComplete) {
            //  Have a response but also missing one
            this.StatusDescription = "Not Complete";
            this.StatusColor = "orange";
            return;
        }

        this.StatusDescription = "Complete";
        this.StatusColor = "green";
    }

    protected override ShowAddResponseDialog(data: AddPositiveResponseData): void {
        if (!this.CanEnterResponse)
            return;

        this._PermissionsService.CurrentUserHasPermission(PermissionsEnum.TicketResponse_Create, [this.ResponseItem.Response.ServiceAreaID]).pipe(take(1))
            .subscribe(val => {
                if (!coerceBooleanProperty(val))
                    return;     //  User does not have permission to create response for this service area (could be allowed on another but view-only on this one)

                this._SideSlideoutService.AttachComponent("Right", AddPositiveResponseFlyoutComponent, data)
                    .OnClose.pipe(take(1))
                    .subscribe((val: TicketAffectedServiceAreaInfo) => {
                        if (val)
                            this.Refresh.next();
                    });
            });
    }
}
