import { Directive, ViewContainerRef, OnInit, OnDestroy, Input, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'Services/PermissionsService';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';

@Directive({
    selector: '[iqHasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
    //By default this will check for the permission not attached to some other item. If you want to check for it on an entity or on anything use the other properties
    @Input('iqHasPermission') appCanAccess: PermissionsEnum;

    //This is kind of a hack so allow more than one permission to determine if the item should be shown.  Should probably have made the main one an array instead of doing this, but that would cause a huge change...
    @Input('iqHasPermissionOr') appCanAccessArray: PermissionsEnum[];

    private _excludeIDs: string[] = null;
    //This will check for the permission anywhere that is not an ID in this collection.
    @Input('iqHasPermissionNot') set excludeIDs(ids: string[]) {
        //May need to do something like this for other properties like entityIDs so that they can change dynamically, but currently it's not needed
        this._excludeIDs = ids;

        //If the subscription exists then unsubscribe because a new one is about to get created for the new ids
        if (this._Subscription) {
            this._Subscription.unsubscribe();
            this.applyPermission();
        }
    }

    //entityIDs will check for permission for the specific IDs passed.  If this is used with hasAnywhere, this will be ignored.
    @Input('iqHasPermissionFor') entityIDs: string[];

    //hasAnywhere will check for the permission in any collection ignoring the item it belongs to.
    //  This is good for showing the main menus when we don't care yet what the parent is, just if they have it.
    //  Or for views of children.  i.e. MemberAdmin seeing Service Areas, the UI can't always know what the SA member is and if the person can see it
    @Input('iqHasPermissionHasAnywhere') hasAnywhere: boolean;

    //Template to show if they don't have permission
    @Input('iqHasPermissionElse') elseTemplate: TemplateRef<any>;


    private _Subscription: Subscription;

    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionsService) {
    }

    ngOnInit(): void {
        this.applyPermission();
    }

    private applyPermission(): void {
        if (this.appCanAccessArray) {
            const permissions = [this.appCanAccess];
            this.appCanAccessArray.forEach(f => permissions.push(f));

            this._Subscription = this.permissionService.CurrentUserHasOneOfPermissionObservable(permissions, this.entityIDs, this.hasAnywhere, this._excludeIDs)
                .subscribe(authorized => {
                    //If this changes we need to clear the view.  That way permisisons change such that the observable
                    //  fires again we don't display the item twice if the permission on this element hasn't changed
                    this.viewContainer.clear();

                    if (authorized)
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    else if (this.elseTemplate)
                        this.viewContainer.createEmbeddedView(this.elseTemplate);
                });
        }
        else {
            this._Subscription = this.permissionService.CurrentUserHasPermissionObservable(this.appCanAccess, this.entityIDs, this.hasAnywhere, this._excludeIDs)
                .subscribe(authorized => {
                    //If this changes we need to clear the view.  That way permisisons change such that the observable
                    //  fires again we don't display the item twice if the permission on this element hasn't changed
                    this.viewContainer.clear();

                    if (authorized)
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    else if (this.elseTemplate)
                        this.viewContainer.createEmbeddedView(this.elseTemplate);
                });
        }
    }

    ngOnDestroy(): void {
        if (this._Subscription)
            this._Subscription.unsubscribe();
    }

}
