import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './PositionErrorDialog.component.html',
    styleUrls: ['./PositionErrorDialog.component.scss']
})
export class PositionErrorDialogComponent {
    public Message: string;
    public ShowPermissionInfo: boolean = false;

    constructor(private dialogRef: MatDialogRef<GeolocationPositionError>, @Inject(MAT_DIALOG_DATA) public Data: GeolocationPositionError) {
        switch (Data?.code) {
            case Data.PERMISSION_DENIED:
                this.Message = "This site does not have permission to access your current location.";
                this.ShowPermissionInfo = true;
                break;
            case Data.POSITION_UNAVAILABLE:
                this.Message = "Location information is currently unavailable.";
                break;
            case Data.TIMEOUT:
                this.Message = "The request to get your current location timed out.";
                break;
            default:
                this.Message = "The request to get your current location failed for an unknown reason.";
                break;
        }
    }
}
