import { NgControl, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Component, forwardRef, Injector, AfterContentInit, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from "rxjs";

//  TODO: This is essentially a duplicate of the iq-entry-field-date control but without any of the ticket dependencies.
//  Should be able to change that control to derive from this one to remove some of the duplication.

//  todo: This is using this component: https://danielykpan.github.io/date-time-picker/
//  It's not necessarily the best...
//  The time picker only uses 24 hour format and will be a pita to use (by clicking).  The author was not interested in making it support 12 hour formats.
//  Really need something that works well with input (keyboard entry) with a nice looking (optional) popup that cleanly supports both dates and times.
//  Something that works something like this one: https://codepen.io/alenaksu/pen/eNzbrZ
//      This is for old angular and there is no port to Angular2+ that I could find.
//      There are multiple forks like this one: https://github.com/dpoetzsch/md-pickers which are discussing porting to Angular2+
//  Another option (for date only and no good <input> integration) is https://kekeh.github.io/ngx-mydatepicker/ or https://github.com/AmolBorse/mydatepicker
//  Angular Material also has a date picker built in.
//  May need to manually handle the <input> part and just pick a date-only calendar control.  (the ngx-mydatepicker or mydatepicker look nicer than the one we're using here).

@Component({
    selector: 'iq-date-with-picker',
    template: `
<mat-form-field>
<input matInput [date-editor]="{ PickerType: PickerType, ForceTime: ForceTime, Min: Min, Max: Max}" #inputElement type="text" [ngStyle]="{'width':Width}" [min]="Min" [max]="Max" [owlDateTime]="dt1" [formControl]="FormControl" />
<span [hidden]="Disabled | async" class="trigger" [owlDateTimeTrigger]="dt1">
    <i class="fas fa-calendar-alt" style="margin-left:5px"></i>
</span>
<owl-date-time #dt1 [pickerType]="PickerType" [stepMinute]="15" [hour12Timer]="true" (afterPickerClosed)="OnAfterPickerClosed()"></owl-date-time>
</mat-form-field>
  `,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateWithPickerComponent),
            multi: true
        }
    ]
})
export class DateWithPickerComponent implements ControlValueAccessor, AfterContentInit {
    public FormControl: UntypedFormControl;

    public readonly Disabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //  'both' | 'calendar' | 'timer'
    @Input("PickerType")
    public PickerType: string = 'both';

    @Input("ForceTime")
    public ForceTime: string = null;

    @Input("Min")
    public Min: Date = null;

    @Input("Max")
    public Max: Date = null;

    public get Width(): string {
        return this.PickerType === "calendar" ? "6em" : "11em";
    }

    @ViewChild("inputElement") _InputElement: ElementRef;

    constructor(private injector: Injector) {
    }

    ngAfterContentInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        this.FormControl = ngControl.control as UntypedFormControl;
    }

    //  Don't need to do anything in these mesages - we're binding directly to the FormControl.
    writeValue(obj: any): void {
    }

    registerOnChange(fn: any): void {
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    OnAfterPickerClosed() {
        //  Set cursor to start and then focus.  This will make the date-editor auto select the first component.
        const targetElement = this._InputElement.nativeElement as HTMLInputElement;
        if (targetElement.setSelectionRange)
            targetElement.setSelectionRange(0, 0);

        this._InputElement.nativeElement.focus();
    }

    public focus(): void {
        this._InputElement.nativeElement.focus();
    }
}
