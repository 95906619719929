<div class="item" style="display:flex; white-space:normal; pointer-events: none; align-items:center">
    <mat-icon style="font-size:24px; width:24px; height:24px" [ngStyle]="{'color': Color}">{{Icon}}</mat-icon>
    <div style="display:flex; flex-direction:column">
        <div>
            <span style="font-weight:bold">{{Model.FeatureName}}</span>
            <span style="color:gray">{{AdditionalText}}</span>
        </div>
        <div style="font-size:small; color:gray">{{Model.FeatureTypeName}}</div>
    </div>
</div>
