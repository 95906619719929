import { Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { IQ_FLYOUT_DATA, IQ_FLYOUT_REF } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { SideSlideoutRef } from 'Shared/PhoneDevice/SideSlideout/SideSlideoutRef';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { AddExcavatorCommentComponent, AddExcavatorCommentData } from '../AddExcavatorComment.component';

@Component({
    templateUrl: './AddExcavatorCommentFlyout.component.html',
    styleUrls: ['./AddExcavatorCommentFlyout.component.scss'],
    animations: [slideInFromRight]
})
export class AddExcavatorCommentFlyoutComponent {

    //  Defining the animation on the HostBinding like this is cleaner and causes it to do the close animation automatically.
    @HostBinding('@slideInFromRight') animation = 'enter';

    @ViewChild(AddExcavatorCommentComponent)
    private _AddExcavatorCommentComponent: AddExcavatorCommentComponent;

    constructor(@Inject(IQ_FLYOUT_DATA) public Data: AddExcavatorCommentData, @Inject(IQ_FLYOUT_REF) private _SideSlideoutRef: SideSlideoutRef) {
    }

    public Close(returnData: TicketDiscussionChildResponse[] = null) {
        this._SideSlideoutRef.close(returnData);
    }

    public Save(): void {
        this._AddExcavatorCommentComponent.Save().subscribe((savedDiscussions) => {
            if (savedDiscussions)
                this.Close(savedDiscussions);
        })
    }
}
