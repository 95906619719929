import { MapTooltipsInteraction } from "../Interactions/MapTooltipsInteraction";
import { MapBaseComponent } from "../MapBase.component";

export class ShowTooltipsTool {

    private _TooltipsInteraction: MapTooltipsInteraction;
    public get Interaction(): MapTooltipsInteraction {
        return this._TooltipsInteraction;
    }

    constructor(mapComponent: MapBaseComponent) {
        this._TooltipsInteraction = new MapTooltipsInteraction(mapComponent);

        this._TooltipsInteraction.setActive(localStorage.getItem("hideMapTooltips") === null);
    }

    public OnDestroy(): any {
        this._TooltipsInteraction.setMap(null);
        return null;
    }

    public IsActive(): boolean {
        return this._TooltipsInteraction.getActive();
    }
}
