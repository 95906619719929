import { Component, OnInit } from '@angular/core';
import { CommonService } from 'Services/CommonService';
import { EmailService } from 'Shared/AddNewForms/Email/Services/Email.service';

@Component({
  selector: 'email-verification-page',
  templateUrl: './EmailVerification.component.html',
    styleUrls: ['./EmailVerification.component.scss'],
    providers: [CommonService]
})
export class EmailVerificationComponent implements OnInit {

    verified: boolean;

    constructor(private commonService: CommonService, private emailService: EmailService) { }

    ngOnInit() {
        let EntityID = this.commonService.ActivatedRoute.snapshot.paramMap.get('id');

        this.emailService.SetVerified(EntityID).subscribe(() => this.verified = true);
    }
}
