import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from '@iqModels/Configuration/SelectOption.model';
import { SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { debounceTime, Subscription } from 'rxjs';
import { BaseFilterItem } from '../BaseFilterItem';

@Component({
    selector: 'iq-list-select-item-filter',
    templateUrl: './ListSelectItemFilter.component.html',
    styleUrls: ['./ListSelectItemFilter.component.scss']
})
export class ListSelectItemFilterComponent extends BaseFilterItem {

    public DisplaySearchValues: SelectOption[];
    public FilteredSearchValues: SelectOption[];
    
    @Input() set SearchValues(vals: SelectOption[]) {
        this.SetDisplayValues(vals);
    };

    /**
     *  For some reason, the Equals operator was built to allow picking multiple options!  If multiple are allowed, the operator
     *  should be "IsOneOf" not "Equals"!  The Report Parameters need to be able to restrict some parameters to only allow picking
     *  one option.  So the only way to accomplish that without breaking existing filters was to add this flag.
     */
    @Input("RestrictListFilterToSingleSelection")
    public RestrictListFilterToSingleSelection: boolean;

    public FilterFormControl: FormControl;

    private _FilterSubscription: Subscription;

    constructor() {
        super();

        this.FilterFormControl = new FormControl();
        this._FilterSubscription = this.FilterFormControl.valueChanges
            .pipe(debounceTime(300))
            .subscribe(filter => this.FilterOptions(filter));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this._FilterSubscription)
            this._FilterSubscription.unsubscribe();
    }

    ngOnInit() {
        super.ngOnInit();
        this.FilterOptions(this.FilterFormControl.value);
    }

    private FilterOptions(filter: string): void {
        if (!filter || filter === "")
            this.FilteredSearchValues = this.DisplaySearchValues;
        else {
            filter = filter.toLowerCase();
            this.FilteredSearchValues = this.DisplaySearchValues.filter(sv => sv.Name.toLowerCase().indexOf(filter) >= 0);
        }
    }

    private SetDisplayValues(vals: SelectOption[]): void {
        if (!vals)
            return;

        //Deep copy it so that the original doesn't get selected property set.  That way when this is loaded it won't carry over anything that isn't selected.  i.e. selecting something, then reseting the filter, then reopening this filter
        this.DisplaySearchValues = vals.map(x => Object.assign({}, x));;

        if (!this.filter.Values)
            this.filter.Values = [];

        if (this.filter.Values.length > 0) {
            let foundCount = 0;
            for (let i = 0; i < this.DisplaySearchValues.length; i++) {
                for (let n = 0; n < this.filter.Values.length; n++) {
                    if (this.DisplaySearchValues[i].Value === this.filter.Values[n].FilterValue) {
                        this.DisplaySearchValues[i].Selected = true;
                        foundCount++;
                    }

                    if (foundCount === this.filter.Values.length)
                        break;
                }

                if (foundCount === this.filter.Values.length)
                    break;
            }
        }

        this.FilterOptions(this.FilterFormControl.value);
    }

    public SelectAll(): void {
        //  Select all that are currently filtered
        this.FilteredSearchValues.forEach(sv => {
            if (!sv.Selected)
                this.ToggleSearchValue(true, sv);
        });
    }

    public SelectNone(): void {
        //  Unselect all - no matter what the filter is
        this.DisplaySearchValues.forEach(sv => {
            if (sv.Selected)
                this.ToggleSearchValue(false, sv);
        })
    }

    public ToggleSearchValue(checked: boolean, val: SelectOption) {
        if (checked) {
            if (this.RestrictListFilterToSingleSelection)
                this.clear();
            this.values.push(new SearchFilterValue(val.Value, val.Name));
        } else
            this.values.splice(this.values.findIndex(f => f.FilterValue === val.Value), 1);

        val.Selected = checked;

        this.fireChangeEvent();
    }

    protected clear(): void {
        //Clear out the values so that if the user does a clear then selected a different value we don't get previous selected values
        this.values = [];

        for (let i = 0; i < this.DisplaySearchValues.length; i++)
            this.DisplaySearchValues[i].Selected = false;
    }
}
