import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Registration } from '@iqModels/Registrations/Registration.model';
import { Observable } from 'rxjs';
import { CommonService } from 'Services/CommonService';

class ConfirmCompleteOrApproveRegistrationDialogData {
    constructor(
        public Action: "Complete" | "Approve",
        public Registration: Registration
    ) { }
}

@Component({
    templateUrl: './ConfirmCompleteOrApproveRegistrationDialog.component.html',
    styleUrls: ['./ConfirmCompleteOrApproveRegistrationDialog.component.scss'],
    providers: [
        CommonService
    ]
})
export class ConfirmCompleteOrApproveRegistrationDialogComponent {
    public Action: "Complete" | "Approve";
    public Registration: Registration;

    public ConfirmedFormControl: FormControl<boolean> = new FormControl<boolean>(false);
    public RequireConfirmation: boolean = false;
    public NoChanges: boolean = false;

    constructor(dialogRef: MatDialogRef<ConfirmCompleteOrApproveRegistrationDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) data: ConfirmCompleteOrApproveRegistrationDialogData)
    {
        this.Action = data.Action;
        this.Registration = data.Registration;

        if (!this.Registration.SqMilesAdded && !this.Registration.SqMilesRemoved) {
            this.RequireConfirmation = true;
            this.NoChanges = true;
        } else {
            //  Only consider it excessive if we have a large amount of changes compared to a parent registration.
            //  A brand new version (no parent) will not have any percentages here since there is nothing to compare against.
            this.RequireConfirmation = (this.Registration.PctAdded > 10) || (this.Registration.PctRemoved > 10);
        }
    }

    public static Show(matDialog: MatDialog, action: "Complete" | "Approve", registration: Registration): Observable<boolean> {
        return matDialog
            .open(ConfirmCompleteOrApproveRegistrationDialogComponent, {
                data: new ConfirmCompleteOrApproveRegistrationDialogData(action, registration),
                disableClose: true,
                autoFocus: false        //  To prevent auto focus on the confirm checkbox (if there is one - just to make it a little more difficult on the user)
            }).afterClosed();
    }
}
