import { SelectOption } from "@iqModels/Configuration/SelectOption.model";

export class EnumHelpers {
    static getIdAndName<T extends number>(e: any, exclude: T[] | null = null) {
        return EnumHelpers.getValues(e)
            .filter(f => exclude ? !exclude.find(s => s === f) : true)
            .map(n => ({ ID: n, Name: e[n] as T }));
    }

    static getAsSelectOptions(valueEnum: any, descriptionEnum?: any, where: (valueEnum) => boolean = null): SelectOption[] {
        if (descriptionEnum) {
            const keys = Object.keys(valueEnum).filter(e => !isNaN(Number(valueEnum[e])) && (!where || where(valueEnum[e])));
            return keys.map(e => (new SelectOption(valueEnum[e], descriptionEnum[e] ?? e)))        //  If no description, default to name of the enum (i.e. EntityTypeEnum does this)
                .sort((a, b) => (a.Name < b.Name) ? -1 : (a.Name > b.Name ? 1 : 0));
        }
        else
            return EnumHelpers.getNames(valueEnum).map(n => (new SelectOption(valueEnum[n], n)));
    }

    //  Same as above but does not order by the name
    static getAsSelectOptionsUnordered(valueEnum: any, descriptionEnum?: any, where: (valueEnum) => boolean = null): SelectOption[] {
        if (descriptionEnum) {
            const keys = Object.keys(valueEnum).filter(e => !isNaN(Number(valueEnum[e])) && (!where || where(valueEnum[e])));
            return keys.map(e => (new SelectOption(valueEnum[e], descriptionEnum[e] ?? e)));        //  If no description, default to name of the enum (i.e. EntityTypeEnum does this)
        }
        else
            return EnumHelpers.getNames(valueEnum).map(n => (new SelectOption(valueEnum[n], n)));
    }

    static getNames(e: any): string[] {
        return EnumHelpers.getObjValues(e).filter(v => typeof v === "string") as string[];
    }

    static getValues<T extends number>(e: any): T[] {
        return EnumHelpers.getObjValues(e).filter(v => typeof v === "number") as T[];
    }

    private static getObjValues(e: any): (number | string)[] {
        return Object.keys(e).map(k => e[k]);
    }

    /**
     * Helper function that maps from a string value defined in a string enum to the cooresponding value in it's matching numeric enum.
     * i.e. Maps the value "Service Area" defined in RoleTypeEnumDescriptions to RoleTypeEnum.ServiceArea.
     * @param value
     * @param stringEnumType
     * @param numericEnumType
     */
    public static ReverseMapStringEnum<Tenum>(value: string, stringEnumType: object, numericEnumType: Tenum): Tenum[keyof Tenum] {
        //  Return type of Tenum[keyof Tenum] is necessary of Typescript thinks we're returning "typeof Tenum".
        //  https://github.com/Microsoft/TypeScript/issues/18869

        //  Reverse map the string enum.  Typescript does not provide reverse mapping of a string enum like it does for a numeric
        //  so have to do it manually using the Object.keys
        const stringEnumValue = Object.keys(stringEnumType).find(k => stringEnumType[k] === value);

        //  If value not found in the string enum, try against the numeric...
        if ((stringEnumValue === null) || (stringEnumValue === undefined))
            return numericEnumType[value];

        return numericEnumType[stringEnumValue];
    }

    //This helper function is the inverse of ReverseMapStringEnum and gets the enum description using the numeric key
    public static MapDescriptionFromKey<Tenum>(value: number, stringEnumType: object, numericEnumType: Tenum) {
        const numberEnumValue = Object.values(numericEnumType).find(k => numericEnumType[k] === value);

        if ((numberEnumValue === null) || (numberEnumValue === undefined))
            return stringEnumType[value];

        return stringEnumType[numberEnumValue];

    }

    public static BitwiseValuesAsSelectOptions(value: any, enumType: any, descriptionEnum: any = null): SelectOption[] {
        if (value === 0)
            return [new SelectOption(value, descriptionEnum ? descriptionEnum[enumType[value]] : null)];

        const values = EnumHelpers.getValues(enumType);
        const retOptions: SelectOption[] = [];
        values.forEach(f => {
            if (f !== 0) {

                if ((value & f) === f)
                    retOptions.push(new SelectOption(f, descriptionEnum ? descriptionEnum[enumType[f]] : null));
            }
        });

        return retOptions;
    }
}
