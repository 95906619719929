import { Subject } from 'rxjs';
import { SectionGroupContainerComponent } from 'Shared/Components/Controls/Section/Container/SectionGroupContainer.component';

/**
 * This service should be added to the constructor of any component where it's needed.
 * The main instance of it is created in the main entry/details page.  So any children within that page
 * (including directives) can add this service into it's constructor and it will get a shared instance of it.
 */
export class EntryPageEventServiceBase {

    constructor() {
    }

    public OnDestroy(): void {
        this._SectionGroupContainerComponent = null;
    }

    private _SectionGroupContainerComponent: SectionGroupContainerComponent;
    public SetSectionGroupContainer(sectionGroupContainerComponent: SectionGroupContainerComponent): void {
        this._SectionGroupContainerComponent = sectionGroupContainerComponent;
    }

    public AllowSectionAutoCloseOnBlur(allow: boolean): void {
        if (this._SectionGroupContainerComponent)
            this._SectionGroupContainerComponent.AutoCloseOnBlur = allow;
        else
            throw new Error("SectionGroupContainerComponent not set in TicketEntryPageEventService!");
    }

    //  Can be used by a section to focus to any field in the form.  Otherwise, if a section calls it's
    //  own FocusFirstControlByName() method, it will only focus if that property is within itself.
    public FocusFirstControlByName: Subject<string[]> = new Subject<string[]>();
}
