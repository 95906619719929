<ng-container *ngIf="Ticket && AllowedActions && OneCallUsesWorkComplete">
    <ng-container *ngIf="(Ticket.IsWorkComplete === true) || (Ticket.IsWorkComplete === 'Yes')">
        <!-- ticket has already been marked as Work Complete -->
        <iq-icon-button *ngIf="AllowedActions.CanMarkWorkNotCompleted" icon="WorkComplete" button-class="Button-Small"
                        title="Mark this ticket as NOT complete" (click)="ToggleWorkCompleted()">Work is Completed (click to toggle)</iq-icon-button>
        <div *ngIf="!AllowedActions.CanMarkWorkNotCompleted && Ticket.IsCanceled" style="color:green">Set to "Complete" due to ticket being Canceled.</div>
        <div *ngIf="!AllowedActions.CanMarkWorkNotCompleted && !Ticket.IsCanceled" style="color:green">Excavator has indicated that Work is Complete.</div>
    </ng-container>
    <ng-container *ngIf="(Ticket.IsWorkComplete === false) || (Ticket.IsWorkComplete === 'No')">
        <iq-icon-button *ngIf="AllowedActions.CanMarkWorkCompleted" icon="WorkNotComplete" button-class="Button-Small"
                        title="Mark this ticket as Completed" (click)="ToggleWorkCompleted()"><span style="color:red">Work <b>not</b> Completed</span> (click to toggle)</iq-icon-button>
        <div *ngIf="!AllowedActions.CanMarkWorkCompleted" style="color:red">Excavator has <b>NOT</b> indicated that Work is Complete.</div>
    </ng-container>
</ng-container>
