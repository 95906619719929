<div style="background-color: white; overflow:hidden; display:flex; flex-grow:1;">
    <div style="display: flex; flex-direction: column; flex-grow:1;">
        <mat-toolbar color="primary" class="iq-display-1">
            <div class="iq-phone-header-left">
                <iq-phone-button icon="Cancel" (click)="Close()">Cancel</iq-phone-button>
            </div>
            <div class="iq-phone-header-center">Split Tickets?</div>
            <div class="iq-phone-header-right">
                <iq-phone-button [matMenuTriggerFor]="actionMenu" icon="Actions">Actions</iq-phone-button>
                <mat-menu #actionMenu="matMenu">
                    <iq-phone-button icon="SaveMultiple" (click)="OnSaveMultipleTickets()" [disabled]="!child.CanCreateMultipleTickets()">Split in to multiple Tickets</iq-phone-button>
                    <iq-phone-button icon="Save" (click)="Close(Data.VerifyInfo)">Create single Ticket</iq-phone-button>
                </mat-menu>
            </div>
        </mat-toolbar>
        <div style="flex-grow:1; overflow:hidden; display:flex; flex-direction:row" class="flex-column ticket-details">
            <mat-tab-group #tabBar [mat-stretch-tabs]="false" style="flex-grow:1;" color="accent" backgroundColor="primary" headerPosition="below"
                           (selectedTabChange)="OnSelectedTabChange($event)">

                <mat-tab bodyClass="no-scroll">
                    <ng-template mat-tab-label>
                        <fa-icon [icon]="TicketIcon" class="icon-label-padding"></fa-icon>
                        Ticket
                    </ng-template>
                    <div style="overflow:auto">
                        <!-- cdkScrollable is needed here to allow Autocomplete results to scroll with this div.  See: https://github.com/angular/material2/issues/4557 -->
                        <div cdkScrollable>
                            <!-- Padding must be here (not on .iq-ticket-entry-left-side) or the scrollbar eats the bottom padding! -->
                            <div style="padding:10px">
                                <iq-create-multiple-tickets (RouteListEvent)="RouteListChanged($event)" [Data]="Data" #child></iq-create-multiple-tickets>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <fa-icon [icon]="MapIcon" class="icon-label-padding"></fa-icon>
                        Map
                    </ng-template>
                    <ng-template class="mapContainer" matTabContent>
                        <ticket-digsite-map [TicketEntryForm]="TicketEntryForm" [ReadOnly]="true"></ticket-digsite-map>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>
        </div>
    </div>
</div>
