import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class SideSlideoutRef {

    public OnClose: Subject<any> = new Subject();

    private _CloseData: any = null;

    constructor(private overlayRef: OverlayRef) { }

    close(data: any = null) {
        //  Do not trigger onClose here - see below.
        this._CloseData = data;

        //  This will trigger the detachments observable on the overlayRef.
        this.overlayRef.detach();
    }

    public FireOnClose(): void {
        //  This is called by SideSlideoutService when the detachments observable fires.
        //  That is needed to guarantee onClose fires in ALL circumstances - not just when we tell it to close.
        //  This fixes onClose not being fired if we use the browser back button!
        this.OnClose.next(this._CloseData);
    }
}
