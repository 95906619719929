import { ScheduledTaskTypeEnum } from "Enums/ScheduledTaskType.enum";
import { ScheduledTaskGroupEnum } from "Enums/ScheduledTaskGroup.enum";

export class CreateOneTimeTaskRequest {

    constructor(
        public TaskType: ScheduledTaskTypeEnum,
        public TaskGroup: ScheduledTaskGroupEnum,
        public Parameters: any,
        public ReferencedEntityID: string,
        public NextRunDate?: Date) {
    }
}

