<div class="dialog-fill-height">
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Responses for Ticket {{Data.Ticket.TicketNumber}}</h1>
    <mat-dialog-content style="display:flex; flex-direction:column;">
        <ticket-response-history-list [AllowedActions]="Data.AllowedActions" [Ticket]="Data.Ticket" [ServiceAreaID]="Data.ServiceAreaID"></ticket-response-history-list>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: space-between">
        <div style="display:flex">
            <div style="display:flex">
                <div style="height:20px; width:20px; margin-right:10px" class="no-response-background"></div>
                <div>No Response</div>
            </div>
            <div style="display:flex; margin-left:40px">
                <div style="height:20px; width:20px; margin-right:10px" class="event-background"></div>
                <div>Event</div>
            </div>
        </div>
        <div style="display:flex">
            <iq-icon-button button-class="Dialog" icon="Close" mat-dialog-close iqAutoFocus>Done</iq-icon-button>
        </div>
    </mat-dialog-actions>
</div>
