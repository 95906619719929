import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SideSlideoutComponent, SideMenuFlyoutContentDirective } from './SideSlideout.component';
import { SideSlideoutService } from './SideSlideout.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SideSlideoutComponent,
        SideMenuFlyoutContentDirective
    ],
    exports: [
        SideSlideoutComponent,
        SideMenuFlyoutContentDirective
    ],
    providers: [SideSlideoutService],
})
export class SideSlideoutModule { }
