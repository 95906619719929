import { SearchFilter } from "@iqModels/Searching/SearchFilter.model";
import { SearchOrderBy } from "@iqModels/Searching/SearchOrderBy.model";
import { EntityEnum } from "Enums/EntityType.enum";

export class SearchRequest {
    // List of columns/properties to return.  Can access child properties like "Child.Name".
    Columns: string[];

    // Optional collection of filters to limit the results.
    // Multiple filters are AND'd together in the query.
    // An "Equals" filter can match on multiple values (i.e. an "OR" condition of all values).
    Filters: SearchFilter[];

    OrderBy: SearchOrderBy[];

    PageNum: number;
    PageSize: number;

    LoadColumnsAndFilters: boolean;
    EntityType: EntityEnum;

    ShowDeleted: boolean;

    constructor() {
    }
}
