<div style="background-color: white; overflow:hidden; display:flex; flex-grow:1;">
    <div style="display: flex; flex-direction: column; flex-grow: 1;">
        <mat-toolbar color="primary" class="iq-display-1">
            <div *ngIf="data.IsConfirm" class="iq-phone-header-left">
                <iq-phone-button icon="Cancel" (click)="Close(false)">{{data.CancelText}}</iq-phone-button>
            </div>
            <div class="iq-phone-header-center">{{data.Title}}</div>
            <div class="iq-phone-header-right">
                <iq-phone-button icon="OK" (click)="Close(true)">{{data.ActionText}}</iq-phone-button>
            </div>
        </mat-toolbar>
        <div style="padding: 20px 2vw 20px 2vw; overflow:auto;">
            <div [innerHTML]="data.Message | trustHtml"></div>
        </div>
    </div>
</div>
