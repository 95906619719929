import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import { Feature } from "ol";
import { Extent } from "ol/extent";
import Map from 'ol/Map';
import { Circle, Fill, Style } from "ol/style";
import { RegistrationService } from "Pages/Registrations/Services/Registration.service";
import { Observable } from "rxjs";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { GeometryUtils } from "../GeometryUtils";
import { ClusteredVectorLayerBase } from "./ClusteredVectorLayerBase";

export class RegistrationVectorLayer extends ClusteredVectorLayerBase {

    public FillColor: string = "red";

    private _RegistrationID: string;

    constructor(map: Map, private _RegistrationService: RegistrationService, mapToolService: MapToolService, layerName: string = 'Registration') {
        super(map, mapToolService, layerName, .2);
    }

    public SetRegistrationID(registrationID: string, onParamsLoaded?: () => void) {
        if (this._RegistrationID === registrationID)
            return;

        this._RegistrationService.GetRegistrationMapLayerParams(registrationID)
            .subscribe(mapParams => {
                this._RegistrationID = registrationID;

                this.SetMapLayerParams(mapParams);

                if (onParamsLoaded)
                    onParamsLoaded();
            });
    }

    protected FetchPolygonsInExtent(latLonExtent: Extent, previousFetchedExtentsGeoJson: object): Observable<Feature<any>[]> {
        return new Observable<Feature<any>[]>(observer => {
            this._RegistrationService.GetPolygonsInBounds(this._RegistrationID, latLonExtent[0], latLonExtent[2], latLonExtent[1], latLonExtent[3], previousFetchedExtentsGeoJson)
                .subscribe(registrationPolygons => {
                    const resultFeatures: Feature<any>[] = [];

                    if (registrationPolygons) {
                        registrationPolygons.forEach(rp => {
                            const features = GeometryUtils.GeoJsonToFeatures(rp.GeometryJson);
                            if (features) {
                                features.forEach(f => {
                                    f.setId(rp.ID);     //  This will replace a feature with the same id - so prevents same features being duplicated if they are re-sent
                                    if (rp.Attribute)
                                        f.set("Attribute", rp.Attribute);
                                    resultFeatures.push(f);
                                });
                            }
                        });
                    }

                    observer.next(resultFeatures);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    //  Only create 1 instance of the Style - do not create one every single time BuildStyleForFeature() is called.
    //  That causes huge performance problems where there are lots of objects on the layer!
    //  If we need to vary it, cache the distinct list of style objects (based on whatever parameter needs to vary)
    //  and then re-use them on additional features.
    private _Style = new Style({
        fill: new Fill({ color: this.FillColor }),
        image: new Circle({
            radius: 30,
            fill: new Fill({ color: this.FillColor }),
            //stroke: new ol.style.Stroke({
            //    color: this.FillColor,
            //    width: 2
            //})
        })
    });

    public BuildStyleForFeature(feature: Feature<any>, isDrawing: boolean, featureStyle: MapFeatureStyleEnum, resolution: number): Style | Style[] {
        return this._Style;
    }

    public FindIntersectingRegistrationFeatures(feature: Feature<any>, attributeValue: string): Feature<any>[] {
        const intersectingFeatures: Feature<any>[] = [];

        if (!feature)
            return intersectingFeatures;

        this.PolygonSource.forEachFeatureInExtent(feature.getGeometry().getExtent(), f => {
            if (attributeValue && (attributeValue !== f.get("Attribute")))
                return;

            intersectingFeatures.push(f);
        });

        return intersectingFeatures;
    }
}
