import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'iq-aws-cognito-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.css']
})
export class PasswordInputComponent {

  @Input() FormControl: AbstractControl;
  @Input() Placeholder: string = "Password";
  
  shownIcon = faEyeSlash;
  showPassword = false;

  constructor() { }

  toggleShow() {
    this.showPassword = !this.showPassword;

    if (this.showPassword)
      this.shownIcon = faEye;
    else
      this.shownIcon = faEyeSlash;
  }
}