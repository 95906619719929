import { Type } from '@angular/core';
import { TicketEntryResponse } from "Models/Tickets/TicketEntryResponse.model";
import { TicketEntryFormGroup } from 'Pages/Tickets/Details/Components/InputControls/TicketEntryFormGroup';
import { ITicketFunctionContentView } from './ITicketFunctionContentView.interface';

export class StartTicketFunctionData {

    constructor(
        public ContentViewComponent: Type<ITicketFunctionContentView>,
        public TicketEntryResponse: TicketEntryResponse,
        public FromTicketDetails: boolean,
        public OnTicketSaved: () => void,                   //  TODO: Don't think this is needed - was only used to tell the dialog to close which is handled by observable now
        public TicketEntryForm: TicketEntryFormGroup
    ) { }
}
