import { Directive, ElementRef, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive({
  selector: '[iq-aws-cognito-validator]'
})
export class iQAwsCognitoValidatorDirective {
  private destroyed$: Subject<void> = new Subject();

  private _control: AbstractControl;
  @Input()
  set control(ctl: AbstractControl) {
    this._control = ctl;
    this.SetErrorMessage();

    this._control.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.SetErrorMessage();
    });
  };
  constructor(private el: ElementRef) {
  }

  SetErrorMessage() {

    //The form control can only have one error at a time.
    this.el.nativeElement.innerHTML = "";
    if (!this._control.invalid || !this._control.errors)
      return;

    //Always show this one if it's empty
    if (this._control.errors.required) {
      this.el.nativeElement.innerHTML = "required";
      return;
    }


    //Static ones let us control what order errors show if they exist.  
    //  If you don't care the order then use the iqCustomError and just set the value to the string you want shown
    if (this._control.errors.pattern)
      this.el.nativeElement.innerHTML = "format error";

    if (this._control.errors.minlength)
      this.el.nativeElement.innerHTML = "minimum length (" + this._control.errors.minlength.actualLength + "/" + this._control.errors.minlength.requiredLength + ")";

    if (this._control.errors.noSpecialChar)
      this.el.nativeElement.innerHTML = "special character required ($ * . ? - ! @ # % &)";
    
    if (this._control.errors.noUppercase)
      this.el.nativeElement.innerHTML = "uppercase character required";
    
    if (this._control.errors.noLowercase)
      this.el.nativeElement.innerHTML = "lowercase character required";
    
    if (this._control.errors.noNumber)
      this.el.nativeElement.innerHTML = "number is required";

    if (this._control.errors.equalValue)
      this.el.nativeElement.innerHTML = this._control.errors.equalValue;

    if (this._control.errors.iqCustomError)
      this.el.nativeElement.innerHTML = this._control.errors.iqCustomError;

    if (this.el.nativeElement.innerHTML === "") {
      this.el.nativeElement.innerHTML = "validation error";
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
