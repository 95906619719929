<!-- class="dialog-fill-height" -->
<!--<div class="iq-ticket-entry-section">-->
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{Title}}</h1>
    <mat-dialog-content style="display:flex; flex-direction: column; overflow:hidden">
        <ng-template dynamic-content></ng-template>
    </mat-dialog-content>
    <mat-dialog-actions class="centered-dialog-buttons">
        <ng-container *ngIf="!IsSaving">
            <iq-icon-button button-class="Button" icon="Cancel" (click)="CloseForm(false)">Cancel</iq-icon-button>
            <iq-icon-button button-class="Button" icon="OK" [disabled]="!IsValid" (click)="OnSaveTicket()">{{SaveButtonText}}</iq-icon-button>
        </ng-container>
        <div *ngIf="IsSaving" style="color:red">Saving...</div>
    </mat-dialog-actions>
<!--</div>-->
