import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IconButtonComponent } from '../../IconButton/IconButton.component';
import { YesNoDialogModel } from "../Models/YesNoDialog.model";

/**
 *  Shows a dialog with a Yes, No, and (optional) Cancel button.
 *  Result of dialog is true (for Yes), false (for No), or null (for Cancel).
 */
@Component({
    templateUrl: './YesNoConfirmationDialog.component.html',
    styleUrls: ['./YesNoConfirmationDialog.component.scss'],
    host: {
        '(keydown)': 'OnKeydownEvent($event)'
    }
})
export class YesNoConfirmationDialogComponent implements AfterViewInit {

    public YesKeyboardShortcut: string = "";
    public YesText: string;

    public NoKeyboardShortcut: string = "";
    public NoText: string;

    public CancelKeyboardShortcut: string = "";
    public CancelText: string;

    @ViewChild("yesButton", { read: IconButtonComponent })
    private _YesButton: IconButtonComponent;

    constructor(public dialogRef: MatDialogRef<YesNoConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: YesNoDialogModel) {
        //  Use the first character as a shortcut key.  And show that key underlined in the dialog button.
        this.YesKeyboardShortcut = data.YesText.charAt(0);
        this.YesText = data.YesText.substring(1);

        this.NoKeyboardShortcut = data.NoText.charAt(0);
        this.NoText = data.NoText.substring(1);

        if (data.CancelText && data.CancelText !== "") {
            this.CancelKeyboardShortcut = data.CancelText.charAt(0);
            this.CancelText = data.CancelText.substring(1);
        } else
            this.CancelText = data.CancelText;
    }

    public ngAfterViewInit(): void {
        //  If this is used, must also set the "autoFocus" option in the dialog open parameters to false or the dialog will do
        //  it's own focus to the first focusable control (which will be the cancel button).
        if (this.data.DefaultToActionButton)
            this._YesButton.focus();
    }

    public OnKeydownEvent(event: KeyboardEvent):void {
        if (event.key === this.YesKeyboardShortcut.toLowerCase()) {
            event.preventDefault();
            event.stopPropagation();
            this.dialogRef.close(true);
        }
        if (event.key === this.NoKeyboardShortcut.toLowerCase()) {
            event.preventDefault();
            event.stopPropagation();
            this.dialogRef.close(false);
        }
        else if (event.key === this.CancelKeyboardShortcut.toLowerCase()) {
            event.preventDefault();
            event.stopPropagation();
            this.dialogRef.close(null);
        }
    }
}
