import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

export function controlsEqualValidator(targetKey: string, toMatchKey: string, errMessage: string = "items must match"): ValidatorFn {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      const target = group.controls[targetKey];
      const toMatch = group.controls[toMatchKey];
      
        const isMatch = target.value === toMatch.value;
        // set equal value error on dirty controls
        if (!isMatch) {
          //const message = targetKey + ' != ' + toMatchKey;
          toMatch.setErrors({equalValue: errMessage});
          return {equalValue: errMessage};
        }
        else
          if (toMatch.hasError('equalValue'))
          toMatch.setErrors(null);
  
      return null;
    };
}

export function hasUppercaseLetter(control: AbstractControl) {
  if (control.value != null && !control.value.toString().match(/[A-Z]/))
    return { noUppercase: true };

  return null;
}
 
export function hasLowercaseLetter(control: AbstractControl) {
  if (control.value != null && !control.value.toString().match(/[a-z]/))
    return { noLowercase: true };

  return null;
}

export function hasNumber(control: AbstractControl) {
  if (control.value != null && !control.value.toString().match(/\d/))
    return { noNumber: true };

  return null;
}

export function hasSpecialCharacter(control: AbstractControl) {
  //^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ `
  
  if (control.value != null && !control.value.toString().match(/[~`@!#$%\^&*\-\[\]\\';,._/{}()|\\":<>\?]/))
    return { noSpecialChar: true };

  return null;
}

export function validateUsername(control: AbstractControl) {
  if (control.value != null){
    let errMsg = IsUsernameValid(control.value.toString())
    if (errMsg != null)
      return { iqCustomError: errMsg };
  }
  return null;
}

/**Checks the format of the username to make sure it's valid */
export function IsUsernameValid(username: string){
  let retMsg = null;
  if (username.match(/\s/))
    return "enter username without spaces";

  if (username.length > 128)
    return "maximum length (" + username.length + "/128)";

  return null;
}

export class IqAwsValidationClass {
    //  Note that the domain suffix can be much longer than just 5 chars we originally had.
    //  AZ has users trying to use "email@something.services".  And this page shows a massive list of other
    //  suffixes: https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains
    //  So allowing up to 20 chars which should hopefully be enough.
    static readonly emailPattern: string = "[&a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+.([a-zA-Z]{2,20})";
}
