
export class SplitGeocodeRequest {
    constructor(
        public LengthFt: number,
        public UnbufferedGeometryJson: object,
        public BufferFt: number,
        public TicketTypeID: string,
        public State: string,
        public County: string,
        public Place: string) {
    }
}
