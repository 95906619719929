<div style="height: 100%; overflow: auto; display: flex; flex-direction: row; place-content:flex-start center; align-items: flex-start;">
    <busy-indicator [busyKey]="BusyKey"></busy-indicator>
    <mat-card appearance="outlined" style="width: 500px; text-align:center;">
        <mat-card-title>Create Login</mat-card-title>
        <mat-card-content>
            <div class="api-error" *ngIf="GotApiError">
                Error on server.
            </div>

            <div *ngIf="Loading; else doneLoading;">
                Verifying....
            </div>

            <ng-template #doneLoading>
                <div *ngIf="PersonHasLogin; else personNotLinked" style="display: flex; flex-direction: column;">
                    <div style="display: flex; flex-direction: column;">
                        <p>
                            You have already completed registration.  Please update any bookmarks you may have to go to the home page.
                        </p>
                    </div>
                    <div style="display: flex; place-content: center flex-end;">
                        <button mat-button color="primary" (click)="Logout(true)">Go to Login</button>
                    </div>
                </div>


                <ng-template #personNotLinked>
                    <!--We can only know the login is linked if they are logged in-->
                    <div *ngIf="LoginLinkedToPerson; else linking" style="display: flex; flex-direction: column;">
                        <ng-container *ngIf="LoginLinkedToEntityID; else notCurrentPerson">
                            <div>
                                <p>
                                    You have already completed registration.  Please update any bookmarks you may have to go to the home page.
                                </p>
                            </div>
                            <div style="display: flex; place-content: center flex-end;">
                                <button mat-button color="primary" (click)="RedirectToHome()">Go to home page</button>
                            </div>
                        </ng-container>
                        <ng-template #notCurrentPerson>
                            <div style="display: flex; flex-direction: column;">
                                <p>
                                    You are currently logged in as '{{Username}}' and that login is already attached to a person for {{OccCode}}.
                                    You can't link a login to multiple people in the same center.
                                </p>
                                <p>To link to a different login please logout</p>
                            </div>
                            <div style="display: flex; place-content: center flex-end;">
                                <button mat-button color="primary" (click)="Logout()">Logout</button>
                            </div>
                        </ng-template>
                    </div>
                    <ng-template #linking>
                        <div *ngIf="DataNotFound; else createLogin">
                            <p style="color: red">
                                Something went wrong.  Please double check you have the correct and complete url and try again.
                            </p>
                        </div>

                        <ng-template #createLogin>
                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <iq-aws-cognito [StartingStep]="6" [PasswordRequirements]="PasswordRequirements" [ShowBrandingImage]="false"
                                                [FederatedLoginReturnState]="FederatedLoginReturnState"
                                                style="width: 100%; overflow: auto; max-width: 375px;">

                                    <ng-template iqAwsResetPasswordTemplate>
                                        <auth-forgot-password [PasswordRequirements]="PasswordRequirements"></auth-forgot-password>
                                    </ng-template>

                                    <ng-template iqAwsCreateNewUserTemplate>
                                        <auth-link-person-sign-up [PasswordRequirements]="PasswordRequirements" [Model]="PersonInfo"></auth-link-person-sign-up>
                                    </ng-template>
                                </iq-aws-cognito>
                            </div>
                        </ng-template>
                    </ng-template>

                </ng-template>
            </ng-template>
        </mat-card-content>
    </mat-card>
</div>
