import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import moment from 'moment';
import { Moment } from 'moment';
import { APIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';

export interface IIN811ResponseCode3Cand3GSharedDataFormGroup {
    AttemptedContactDateTime: FormControl<Moment | string>;
    AttemptedContactMethod: FormControl<string>;
    NameOfPersonAttemptedToContact: FormControl<string>;
    PhoneOrEmailOfPerson: FormControl<string>;
    LocatorName: FormControl<string>;
    PhoneOrEmailOfLocator: FormControl<string>;
    ProposedDateTimeOfCompletion: FormControl<Moment | string>;
}

@Component({
    selector: 'in811_ResponseCode3Cand3GShared',
    templateUrl: './IN811_ResponseCode3Cand3GShared.component.html',
    styleUrls: ['./IN811_ResponseCode3Cand3GShared.component.scss']
})
export class IN811_ResponseCode3Cand3GSharedComponent {

    @Input()
    public FormGroup: FormGroup<IIN811ResponseCode3Cand3GSharedDataFormGroup>;

    public AvailableContactMethods: SelectOption[] = [
        new SelectOption("Email", "Email"),
        new SelectOption("On-Site Meeting", "On-Site Meeting"),
        new SelectOption("Phone", "Phone"),
        new SelectOption("Text", "Text"),
    ];

    constructor()
    {
    }

    public static CreateFormGroup(): IIN811ResponseCode3Cand3GSharedDataFormGroup {
        return {
            AttemptedContactDateTime: new FormControl<Moment | string>(null),
            AttemptedContactMethod: new FormControl<string>(null),
            NameOfPersonAttemptedToContact: new FormControl<string>(null, [Validators.required, Validators.maxLength(100)]),
            PhoneOrEmailOfPerson: new FormControl<string>(null, [Validators.maxLength(100)]),
            LocatorName: new FormControl<string>(null, [Validators.maxLength(100)]),
            PhoneOrEmailOfLocator: new FormControl<string>(null, [Validators.maxLength(100)]),
            ProposedDateTimeOfCompletion: new FormControl<Moment | string>(null)
        };
    }

    /*
     * Format the dates in the data object to the correct format needed by the API.  The mtx-datetime control sends everything back
     * in UTC time.
     */
    public static FormatDates(data: any): void {
        if (data.AttemptedContactDateTime)
            data.AttemptedContactDateTime = moment(data.AttemptedContactDateTime).format(APIDateTimeFormat);
        if (data.ProposedDateTimeOfCompletion)
            data.ProposedDateTimeOfCompletion = moment(data.ProposedDateTimeOfCompletion).format(APIDateTimeFormat);
    }
}
