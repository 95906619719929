import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LatLonBounds } from 'Models/Maps/LatLonBounds.model';
import { AppUser } from 'Models/Security/AppUser.model';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PositionErrorDialogComponent } from 'Shared/Components/Maps/Dialogs/PositionErrorDialog.component';
import { AuthenticationService } from './AuthenticationService';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    private _MapBounds: LatLonBounds;

    constructor(authenticationService: AuthenticationService, private _ToastrService: ToastrService, private _Dialog: MatDialog) {
        authenticationService.CurrentUserObserver().subscribe((user: AppUser) => this._MapBounds = user.MapBounds);
    }

    /**
     *  Queries the browser for the current location.  If the location is outside the bounds of the map, a warning is displayed and outOfBounds is set to true.
     *  Returns the GeolocationPosition which contains the lat/lon coordinate of the browsers current location.
     *  Also handles errors getting the current position by showing the user a dialog with instructions.
     */
    public GetCurrentPosition(): Observable<{ position: GeolocationPosition, outOfBounds: boolean }> {
        //  Can check permission using "navigator.permissions" but it's not supported on Safari.  And not sure what the possible values
        //  are that are returned in the "state" property.  When user denied it, has value of "denied".
        //  https://caniuse.com/permissions-api
        //  https://stackoverflow.com/questions/6092400/is-there-a-way-to-check-if-geolocation-has-been-declined-with-javascript
        //navigator.permissions.query({ name: 'geolocation' }).then(result => console.log("geoLocation permissoins:", result));

        return new Observable<{ position: GeolocationPosition, outOfBounds: boolean }>(observer => {
            if (!navigator.geolocation) {
                observer.error("Geolocation is not supported by this browser.");
                this._ToastrService.error("Geolocation is not supported by this browser.");
                this.ShowPositionErrorDialog(null);
                return;
            }

            const options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };

            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    //  Test coordinates
                    //position = { coords: { /* AZ */ longitude: -111.9618, latitude: 33.3445, accuracy: null, altitude: null, altitudeAccuracy: null, heading: null, speed: null }, timestamp: null };
                    //position = { coords: { /* FL */ longitude: -81.3108, latitude: 28.8755, accuracy: null, altitude: null, altitudeAccuracy: null, heading: null, speed: null }, timestamp: null };
                    //position = { coords: { /* SC */ longitude: -82.4127, latitude: 34.8138, accuracy: null, altitude: null, altitudeAccuracy: null, heading: null, speed: null }, timestamp: null };

                    //console.warn("geolocation.getCurrentPosition", position, this._MapBounds);
                    let outOfBounds = false;
                    if (this._MapBounds) {
                        if ((position.coords.longitude < this._MapBounds.MinX) || (position.coords.longitude > this._MapBounds.MaxX)
                            || (position.coords.latitude < this._MapBounds.MinY) || (position.coords.latitude > this._MapBounds.MaxY))
                        {
                            this._ToastrService.warning("Your current location is not within the bounds of the One Call Center");
                            outOfBounds = true;
                        }
                    }

                    observer.next({ position: position, outOfBounds: outOfBounds });
                    observer.complete();
                },
                (error: GeolocationPositionError) => {
                    observer.error(error);
                    this.ShowPositionErrorDialog(error);
                },
                options);
        });
    }

    private ShowPositionErrorDialog(error: GeolocationPositionError): void {
        this._Dialog.open(PositionErrorDialogComponent, { data: error });
    }
}
