/**********************************
This component does NOT save any data.  It is up to the calling component to actually update the data.  This is because to do Admin features you need to 
create permissions in AWS to do it.  To do that you create a user that is granted access with an accessKeyId and an secretAccessKeyId that shouldn't really
be exposed in a JS control where someone could swipe them.  So the calling app should create server side API calls to do it so that access keys are on the server 
amd more secure.  We could change this to take the URL to call, but then this control would have to know the request object to send which could be different by 
application.  So it's just better to let the calling app that implements the server side code to just make the call and pass whatever it needs.
***********************************/


import { Component, Inject } from '@angular/core';
import { FormComponentBase } from '../../../form-component-base';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IqAwsValidationClass, controlsEqualValidator } from '../../../validation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface iQAdminChangeEmailAcceptedEvent {
  Username: string,
  Email: string
}

export interface iQAdminChangeEmailInputData {
  //Want to pass this in because leaving it up to the user to type it in will be a pain for the user.  
  //  And the parent app should know it an be able to pass it in.
  Username: string;
}

@Component({
  templateUrl: './admin-change-email-dialog.component.html',
  styleUrls: ['./admin-change-email-dialog.component.css']
})
export class iQAdminChangeEmailDailogComponent extends FormComponentBase {
  
  Username: string;

  get newEmail() { return this.group.get("NewEmail"); }
  get confirmEmail() { return this.group.get("ConfirmEmail"); }
  
  constructor(private dialogRef: MatDialogRef<iQAdminChangeEmailDailogComponent>, @Inject(MAT_DIALOG_DATA) dialogData: iQAdminChangeEmailInputData) {
    super();

    if (dialogData == null)
      throw "Need to pass data to the iQAdminChangeEmailDialogComponent";

    this.Username = dialogData.Username;
  }

  ngOnInit() {
    super.ngOnInit();

    this.dialogRef.updateSize("500px");
  }

  BuildForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      NewEmail: new UntypedFormControl(null, [Validators.required, Validators.pattern(IqAwsValidationClass.emailPattern)]),
      ConfirmEmail: new UntypedFormControl(null, [Validators.required, Validators.pattern(IqAwsValidationClass.emailPattern)])
    }, [controlsEqualValidator('NewEmail', 'ConfirmEmail')]);
  }
  
  onChangeEmail() {
    this.dialogRef.close({ Username: this.Username, Email: this.newEmail.value });
  }

}
