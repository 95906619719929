import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {map, zip, mergeMap, take} from 'rxjs/operators';

import { AuthenticationService } from 'Services/AuthenticationService';
import { PermissionsEnum } from '../Enums/RolesAndPermissions/Permissions.enum';
import { AppUser } from '@iqModels/Security/AppUser.model';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    constructor(
        private AuthenticationService: AuthenticationService) {
    }

    //public GetIDsThatHavePermission(permission: PermissionsEnum): Observable<string[]> {
    //    //Need sto be done this way and not by returning a new observable because we need to update references when the user changes (i.e. showing menus when switching centers)
    //    //  May want to make a observable on the permissions and not have it on the whole person...

    //    //If we don't have a current user then we need to use the observer so that this check is delayed until we have fetched one.  Else just check the current user value
    //    if (this.AuthenticationService.CurrentUser === null) {
    //        return this.AuthenticationService.CurrentUserObserver().pipe(mergeMap(val => {

    //            return of<string[]>(this.IDsForPermission(val, permission));
    //        }));
    //    }

    //    return of<string[]>(this.IDsForPermission(this.AuthenticationService.CurrentUser, permission));
    //}

    ////Returning null means that they have it as a global permission and not just for a specific entity
    //private IDsForPermission(user: AppUser, permission: PermissionsEnum): string[] {
    //    if (user.Permissions.includes(permission))
    //        return null;

    //    let ids = [];
    //    let keys = user.EntityPermissions.Keys();
    //    for (let index = 0; index < keys.length; index++) {
    //        let key = keys[index];
    //        if (user.EntityPermissions.Item(key).includes(permission))
    //            ids.push(key);
    //    }

    //    return ids;
    //}


    /**
     * Checks to see if the user has the given permission.  The observable that is returned will autocomplete so this will
     * *NOT* update itself if the current user changes.  If that is needed, subscribe to changes to AuthenticationService.CurrentUserObserver
     * and then can call this method in that handler.  Or make a new permission check method that does not autocomplete.
     * @param permission
     * @param itemIDs
     * @param hasAnywhere: hasAnywhere will check for the permission in any collection ignoring the item it belongs to.
     *  This is good for showing the main menus when we don't care yet what the parent is, just if they have it.
     *  Or for views of children.  i.e. MemberAdmin seeing Service Areas, the UI can't always know what the SA member is and if the person can see it
     * @param excludeIDs
     */
    public CurrentUserHasPermission(permission: PermissionsEnum, itemIDs: string[] = null, hasAnywhere: boolean = false, excludeIDs: string[] = null): Observable<boolean> {
        //  Do *NOT* remove the "take(1)" from here!  There are 100+ references to this method.  Doing so will cause
        //  actions to be executed AGAIN when the user logs out or switches centers.  And the observables will not be
        //  unsubscribed so the subscriptions will be leaked.
        //  i.e.Export the ticket list then log out - it will execute again!  Yes, the callers of this method could
        //  (and probably should) be changed to complete the observable where that is needed but, again, 100+ references
        //  that were built with the expectation that this autocompletes.
        return this.AuthenticationService.CurrentUserObserver(true)
            .pipe(take(1), map(user => this.userHasPermission(user, permission, itemIDs, hasAnywhere, excludeIDs)));
    }

    /**
     * Returns a list of IDs for which the current user has the given permission
     * @param permission
     */
    public EntityIDsCurentUserHasPermissionFor(permission: PermissionsEnum): Observable<string[]> {
        return this.AuthenticationService.CurrentUserObserver(true)
            .pipe(take(1), map(user => {
                const entityIDs: string[] = [];

                if (user.EntityPermissions) {
                    const keys = user.EntityPermissions.Keys();
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        if (user.EntityPermissions.Item(key).includes(permission))
                            entityIDs.push(key)
                    }
                }

                return entityIDs;
            }));
    }

    /**
     * Checks to see if the user has the given permission.  The observable that is returned does *NOT* autocomplet.  The caller is
     * responsible for unsubscribing (or completing it via take(1), etc).
     * @param permission
     * @param itemIDs
     * @param hasAnywhere: hasAnywhere will check for the permission in any collection ignoring the item it belongs to.
     *  This is good for showing the main menus when we don't care yet what the parent is, just if they have it.
     *  Or for views of children.  i.e. MemberAdmin seeing Service Areas, the UI can't always know what the SA member is and if the person can see it
     * @param excludeIDs
     * @param permission
     * @param itemIDs
     * @param hasAnywhere
     * @param excludeIDs
     */
    public CurrentUserHasPermissionObservable(permission: PermissionsEnum, itemIDs: string[] = null, hasAnywhere: boolean = false, excludeIDs: string[] = null): Observable<boolean> {
        return this.AuthenticationService.CurrentUserObserver(true)
            .pipe(map(user => this.userHasPermission(user, permission, itemIDs, hasAnywhere, excludeIDs)));
    }

    /**
     * Checks to see if the user has one of the given permissions.  The observable that is returned does *NOT* autocomplet.  The caller is
     * responsible for unsubscribing (or completing it via take(1), etc).
     * @param permission
     * @param itemIDs
     * @param hasAnywhere: hasAnywhere will check for the permission in any collection ignoring the item it belongs to.
     *  This is good for showing the main menus when we don't care yet what the parent is, just if they have it.
     *  Or for views of children.  i.e. MemberAdmin seeing Service Areas, the UI can't always know what the SA member is and if the person can see it
     * @param excludeIDs
     * @param permission
     * @param itemIDs
     * @param hasAnywhere
     * @param excludeIDs
     */
    public CurrentUserHasOneOfPermissionObservable(permissions: PermissionsEnum[], itemIDs: string[] = null, hasAnywhere: boolean = false, excludeIDs: string[] = null): Observable<boolean> {
        return this.AuthenticationService.CurrentUserObserver(true)
            .pipe(map(user => {
                let allowed = false;
                for (let i = 0; i < permissions.length; i++) {
                    if (this.userHasPermission(user, permissions[1], itemIDs, hasAnywhere, excludeIDs)) {
                        allowed = true;
                        break;//Return allowed for first one we find.
                    }
                }
                return allowed;
            }));
    }

    public CurrentUserHasOneOfPermission(permissions: PermissionsEnum[], itemIDs: string[] = null, hasAnywhere: boolean = false, excludeIDs: string[] = null): Observable<boolean> {

        let checks = this.CurrentUserHasPermission(permissions[0], itemIDs, hasAnywhere, excludeIDs).pipe(map(allowed => allowed));
        permissions.forEach((item, index) => {
            if (index !== 0) {
                checks = checks.pipe(zip(this.CurrentUserHasPermission(item, itemIDs, hasAnywhere, excludeIDs)), map(allowed => allowed[0] || allowed[1]));
            }
        });

        return checks;
    }

    //hasAnywhere will check for the permission in any collection ignoring the item it belongs to.
    //  This is good for showing the main menus when we don't care yet what the parent is, just if they have it.
    //  Or for views of children.  i.e. MemberAdmin seeing Service Areas, the UI can't always know what the SA member is and if the person can see it
    private userHasPermission(user: AppUser, permission: PermissionsEnum, itemIDs: string[], hasAnywhere: boolean, excludeIDs: string[]): boolean {

        if (user.Permissions && user.Permissions.includes(permission))
            return true;

        if (hasAnywhere == true && user.EntityPermissions != null) {
            let keys = user.EntityPermissions.Keys();
            for (let i = 0; i < keys.length; i++) {
                if (excludeIDs != null && excludeIDs.includes(keys[i]))
                    continue;

                if (user.EntityPermissions.ContainsKey(keys[i]) && user.EntityPermissions.Item(keys[i]).includes(permission))
                    return true;
            }
        }

        if (itemIDs !== null && user.EntityPermissions) {
            for (let i = 0; i < itemIDs.length; i++) {
                if (excludeIDs != null && excludeIDs.includes(itemIDs[i]))
                    continue;

                if (user.EntityPermissions.ContainsKey(itemIDs[i]) && user.EntityPermissions.Item(itemIDs[i]).includes(permission))
                    return true;
            }
        }

        return false;
    }
}
