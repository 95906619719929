import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import { Collection, MapBrowserEvent } from "ol";
import { Coordinate } from "ol/coordinate";
import { LineString } from "ol/geom";
import { Draw, Interaction } from "ol/interaction";
import Map from 'ol/Map';
import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";
import { DigSiteSizeControl } from "../Controls/DigSiteSizeControl";

export class DrawLineTool extends DrawingToolBase {

    private _NumPoints: number = 0;

    constructor(map: Map, mapToolService: MapToolService, private _VectorLayer: VectorLayerBase) {
        super(map, mapToolService);

        this.Init();
    }

    public OnDestroy(): any {
        this._VectorLayer = null;
        return super.OnDestroy();
    }

    protected CreateInteraction(): Interaction {
        const sizeControl = DigSiteSizeControl.FindSelfInMap(this.Map);

        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        const interaction = new Draw({
            features: new Collection(),      //  We draw into a collection so that we can pre-process when drawing is finished before adding to the _VectorLayer.
            type: "LineString",
            style: (feature, resolution) => {
                if (sizeControl)
                    sizeControl.ShowSizeOfFeatureBeingDrawn(feature);
                return this._VectorLayer.BuildStyleForFeature(feature, true, MapFeatureStyleEnum.Line, resolution);
            },
            geometryFunction: (coordinates: number[][], geometry) => {
                this._NumPoints = coordinates.length;

                let line = geometry as LineString;
                if (line)
                    line.setCoordinates(coordinates);
                else
                    line = new LineString(coordinates);

                return line;
            },
            condition: (evt: MapBrowserEvent<PointerEvent>) => {
                const coords = (interaction as any).sketchCoords_ as Coordinate[];
                if (!coords)
                    return true;        //  First coord

                //  Do not allow if there are exactly 2 coordinates and they are the same.  That means the user is
                //  slow clicking on the same location as the start.  OpenLayers detects a double click as clicking on the same
                //  location (to end drawing).  So if we don't prevent this, it will allow the user to slow click 3 times at the
                //  same coordinate which will produce a LineString where start=end.  That is an invalid geometry!
                //  There is not an issue if the user clicks, moves, then clicks the same location twice.
                //  *Note: The sketchCoords_ is the line that is currently being drawn and shows the line up to the current mouse
                //  position.  On Phone/Mobile, there is no current mouse position shown but the sketchCoords_ still contains
                //  a coordinate to represent it - WHICH IS ALWAYS EQUAL TO THE LAST COORDINATE!
                //  So we need to test the 1st coordinate in coords against the coordinate in the event.
                if (coords.length === 2) {
                    const evtCoord = evt.coordinate;
                    if ((evtCoord?.length === 2) && (coords[0][0] === evtCoord[0]) && (coords[0][1] === evtCoord[1]))
                        return false;
                }

                return true;
            }
        });

        this.AddListener(interaction.on("drawend", (evt: any /* DrawEvent not exported! */) => {
            this._NumPoints = 0;

            //  Clean the drawing and then add to the source.  This fixes self-intersecting polygons and breaks
            //  them up into multiple features if necessary.  Also applies any extra validation needed (minimum size?).
            this._VectorLayer.CleanAndAddFeature(evt.feature, false, !this.MapToolService.AllowMultipleShapes);

            setTimeout(() => this.MapToolService.ActivateTool.next(MapConstants.TOOL_EDIT_GEOMETRY));
        }));

        return interaction;
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging)
            return;

        let helpMsg = 'Click to start drawing';
        if (this._NumPoints > 0)
            helpMsg = 'Click to add vertex,</br>Double-click to finish drawing';

        this.SetHelpMessage(helpMsg, evt.coordinate);
    }
}
