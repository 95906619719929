export enum DisclaimerTypeEnum {
    TicketSaved = 0,
    Picked = 1,
    ProjectStart = 10,
    ProjectSaved = 11,
    TicketStart = 20,

    /**
     *  If configured, shows the disclaimer when a user first picks the self registration link on the login page.
     */
    SelfRegistration = 30,

    /**
     *  If configured, shows this disclaimer when a user marks a ticket as Work Complete
     */
    MarkedWorkComplete = 40
}

export enum DisclaimerTypeEnumDescription {
    TicketSaved = "Ticket Saved",
    Picked = "Ticket Type Picked",
    ProjectStart = "Project Start",
    ProjectSaved = "Project Saved",
    TicketStart = "Ticket Start",
    SelfRegistration = "Self Registration",
    MarkedWorkComplete = "Ticket marked as Work Complete"
}
