import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { SearchFilter, SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { SearchOrderBy } from '@iqModels/Searching/SearchOrderBy.model';
import { SearchRequest } from '@iqModels/Searching/SearchRequest.model';
import { SearchResponse } from '@iqModels/Searching/SearchResponse.model';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { Observable, of as observableOf } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';

export class AutoCompleteFormControl extends UntypedFormControl {

    Results: Observable<any>;
    PreviousValue: string;
    ExactMatch: any;
    ShowNoResultsMessage: boolean = true;
    filterSelectColumns: string[] = ["Name"];
    filterOrderBy: SearchOrderBy[] = [new SearchOrderBy("Name")];
    filterFiltersColumn: string = "Name";
    filterFiltersOperator: SearchFilterOperatorEnum = SearchFilterOperatorEnum.Contains;
    //Used to override the property name so we can show messages like 'Create New' and 'No Results Found'
    itemDisplayName: string = "Name";

    /**
     *  If true, will search on an empty value (and show an initial search result as soon as displayed).
     */
    public SearchOnEmpty: boolean;

    constructor(searchMethod: (filter: SearchRequest) => Observable<any>, initialValue: any = null,
        createNewOptionPermissionsCheck: Observable<boolean> = observableOf(false),
        validatorOrOpts: ValidatorFn | ValidatorFn[] = null)
    {
        super(initialValue, validatorOrOpts); //Needs to always update on change or the auto complete won't fire.
        
        this.Results = this.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((val) => {
                this.ExactMatch = null;//Reset anytime the value changes

                //  This value could be the selected object or the searched text.
                if (val === null || val === undefined || typeof val !== 'string')
                    return observableOf<SearchResponse>(null);
                if (!this.SearchOnEmpty && (val === ""))
                    return observableOf<SearchResponse>(null);

                this.PreviousValue = val;
                const filterValue = val;
                const filter: SearchRequest = new SearchRequest();
                filter.Columns = this.filterSelectColumns;
                filter.OrderBy = this.filterOrderBy;
                filter.Filters = [new SearchFilter(this.filterFiltersColumn, this.filterFiltersOperator, [new SearchFilterValue(filterValue, filterValue)])];
                return searchMethod(filter);
            }),
            withLatestFrom(createNewOptionPermissionsCheck),
            map(data => {
                if (data && data[0]) {
                    const searchResults: SearchResponse = data[0];

                    //The server should return what the exact match is so that it will be figured out the same way on any UI
                    if (searchResults && searchResults.Items)
                        this.ExactMatch = searchResults.Items.find(val => val.ExactMatch);

                    if (data[1] === true) {
                        const newItem = {};
                        newItem[this.itemDisplayName] = "Create New";
                        //    Name: "Create New"
                        //};
                        const people: any[] = [newItem]
                        if (searchResults && searchResults.Items)//Items will come back null now with the new api
                            return people.concat(searchResults.Items);
                        else
                            return people;
                    }

                    if (!searchResults.Items || (searchResults.Items.length === 0 && this.ShowNoResultsMessage)) {
                        //  To prevent user from being able to pick this, add [disabled]="!item.ID" (or similar) to the mat-option
                        //  It will style it dimmed out, but it's the easiest way to show it and not pick it.
                        const noResultsItem = {};
                        noResultsItem[this.itemDisplayName] = "No Results Found";
                        searchResults.Items = [noResultsItem];
                    }

                    return searchResults.Items;
                }

                return new Array<any>();
            }));
    }
}
