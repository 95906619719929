import { ServiceAreaAssignmentFilterTypeEnum } from "../../Enums/ServiceAreaAssignmentFilterType.enum";

export class ServiceAreaAssignmentFilterInsertOrUpdateRequest {
    //  Must always set both of these properties
    public ServiceAreaID: string;
    public FilterType: ServiceAreaAssignmentFilterTypeEnum;

    //  Set this if the FilterType only needs a single value as a string
    public FilterValue: string;

    //  Set both of these if the FilterType is configured against Configuration.FieldValue records.
    //  The FieldValueIDList must contain the full set of configured values.  The server will delete or insert
    //  records as needed.  If FieldValueIDList is empty, all existing records will be deleted.
    public FieldConfigurationID: string;
    public FieldValueIDList: string[];
}
