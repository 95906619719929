<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Marking delay</h1>
<mat-dialog-content style="display:flex; flex-direction:column;">
    <label>The Service Area has requested a marking delay.  Do you agree or disagree?</label>
    <mat-form-field style="width: 200px;">
        <mat-label>Select Answer</mat-label>
        <mat-select [formControl]="ResponseFormControl">
            <mat-option value="Agree">Agree</mat-option>
            <mat-option value="Disagree">Disagree</mat-option>
        </mat-select>
        <mat-error iqValidationMessage [control]="ResponseFormControl"></mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button button-class="Dialog" icon="Cancel" [disabled]="IsSaving" [mat-dialog-close]="null">Cancel</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" [disabled]="ResponseFormControl.invalid || IsSaving" (click)="OnSave()">Save</iq-icon-button>
</mat-dialog-actions>
