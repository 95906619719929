/*
    This is provide a layout for a list.  You need to provide an iq-list-item tag that will display the content of the individual item in the list
*/



import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'iq-view-list',
    templateUrl: './ViewList.component.html',
    styleUrls: ['./ViewList.component.scss'],
    encapsulation: ViewEncapsulation.None//If changed remove comment from the scss
})
export class ViewListComponent {
}
