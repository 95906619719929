
export class RegistrationGeometryRequest {

    constructor(
        public RegistrationID: string,
        public RegistrationChangeID: string,
        public MinX: number,
        public MaxX: number,
        public MinY: number,
        public MaxY: number,
        public GeometryJson: object)
    {}
}

