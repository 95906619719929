import { Component } from '@angular/core';
import { AuthComponentBase } from '../../auth-component-base';
import { ViewStateEnum } from '../../models';
import { take } from 'rxjs/operators';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';

@Component({
  selector: 'iq-aws-cognito-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
	host: { '[class.iq-aws-cognito-logout]': 'true' }
})
export class iQLogoutComponent extends AuthComponentBase {
  ShouldDisplay(): boolean {
    return this.authState.state == ViewStateEnum.signedIn;
  }
  BuildForm() {
    return null;
  }

  constructor(protected awsCognitoService: IqAwsCognitoService) {
    super(awsCognitoService);
   }

   onSignOut() {
		this.awsCognitoService.SignOut().pipe(take(1)).subscribe();
	}
}
