
/*
 *  Specifies options for filtering on date properties.
 */
export class SearchFilterDateConfig {

    //  "All Dates"/None is shown/hidden based on the SearchColumn IsRequired flag (which is set in to ListDateFilter as allowEmpty)
    public AllowToday: boolean = true;
    public AllowYesterday: boolean = true;
    public AllowLastWeek: boolean = true;
    public AllowLastMonth: boolean = true;
    public AllowPastDaysFromToday: boolean = true;
    public AllowPastHoursFromNow: boolean = true;
    public AllowSingleDate: boolean = true;
    public AllowDateRange: boolean = true;
    public AllowTomorrow: boolean = true;
    public AllowDaysFromToday: boolean = true;
    public AllowHoursFromNow: boolean = true;

    //  These default to false.  They are (currently) only used in the query reports.
    public AllowDaysBeforeAndNotIncludingToday: boolean = false;
    public AllowDaysAfterAndNotIncludingToday: boolean = false;

    public HideFuture(): void {
        this.AllowTomorrow = false;
        this.AllowDaysFromToday = false;
        this.AllowHoursFromNow = false;
        this.AllowDaysAfterAndNotIncludingToday = false;
    }

    public HideHours(): void {
        this.AllowPastHoursFromNow = false;
        this.AllowHoursFromNow = false;
    }

    public HideSpecificDates(): void {
        this.AllowSingleDate = false;
        this.AllowDateRange = false;
    }

    /**
     *  Hide any options that allow picking multiple days
     */
    public HideMultipleDateOptions(): void {
        this.AllowLastWeek = false;
        this.AllowLastMonth = false;
        this.AllowPastDaysFromToday = false;
        this.AllowPastHoursFromNow = false;
        this.AllowDateRange = false;
        this.AllowDaysFromToday = false;
        this.AllowHoursFromNow = false;
    }
}
