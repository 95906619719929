import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';

export class ServiceAreaResponsesDialogData {
    constructor(public AllowedActions: TicketEntryAllowedTicketActions, public Ticket: Ticket | any, public ServiceAreaID: string) { }
}

@Component({
    selector: 'ticket-response-service-area-dialog',
    templateUrl: './ServiceAreaResponsesDialog.component.html',
    styleUrls: ['./ServiceAreaResponsesDialog.component.scss']
})
export class ServiceAreaResponsesDialogComponent {
    constructor(public dialogRef: MatDialogRef<ServiceAreaResponsesDialogData>, @Inject(MAT_DIALOG_DATA) public Data: ServiceAreaResponsesDialogData)
    { }
}
