import { NgModule } from '@angular/core';
import { ServiceAreaResponsesDialogComponent } from 'Pages/Tickets/Responses/Dialogs/ServiceAreaResponsesDialog/ServiceAreaResponsesDialog.component';
import { TicketResponseHistoryListComponent } from 'Pages/Tickets/Responses/Views/TicketResponseHistoryList/TicketResponseHistoryList.component';
import { TicketActionsService } from 'Pages/Tickets/Services/TicketActions.service';
import { CommonService } from 'Services/CommonService';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { SharedPhoneDevice } from 'Shared/PhoneDevice/SharedPhoneDevice.module';
import { AddExcavationDateDialogComponent } from '../AddExcavationDate/AddExcavationDateDialog.component';
import { AddExcavatorCommentComponent } from "../AddExcavatorComment/AddExcavatorComment.component";
import { AddExcavatorCommentDialogComponent } from '../AddExcavatorComment/Dialog/AddExcavatorCommentDialog.component';
import { AddExcavatorCommentFlyoutComponent } from "../AddExcavatorComment/Flyout/AddExcavatorCommentFlyout.component";
import { AddMarkingDelayResponseDialogComponent } from '../AddMarkingDelayResponse/AddMarkingDelayResponseDialog.component';
import { AddPositiveResponseComponent } from '../AddPositiveResponse/AddPositiveResponse.component';
import { AddPositiveResponseDialog } from "../AddPositiveResponse/Dialog/AddPositiveResponseDialog.component";
import { AddPositiveResponseFlyoutComponent } from "../AddPositiveResponse/Flyout/AddPositiveResponseFlyout.component";
import { PositiveResponseCommonComponent } from '../AddPositiveResponse/PositiveResponseCommon.component';
import { IN811_ResponseCode3CComponent } from '../Custom/IN811/IN811_ResponseCode3C.component';
import { IN811_ResponseCode3GComponent } from './../Custom/IN811/IN811_ResponseCode3G.component';
import { IN811_ResponseCode3Cand3GSharedComponent } from '../Custom/IN811/IN811_ResponseCode3Cand3GShared.component';
import { SC811_ResponseCode110_ExtraordinaryCircumstancesComponent } from '../Custom/SC811/SC811_ResponseCode110_ExtraordinaryCircumstances.component';
import { SC811_ResponseCode60_MarkingScheduleComponent } from '../Custom/SC811/SC811_ResponseCode60_MarkingSchedule.component';
import { TicketResponseServiceAreaListDialog } from '../Dialogs/TicketResponseServiceAreaList/Dialog/TicketResponseServiceAreaListDialog.component';
import { TicketResponseServiceAreaListFlyout } from '../Dialogs/TicketResponseServiceAreaList/Flyout/TicketResponseServiceAreaListFlyout.component';
import { TicketResponseServiceAreaComponent } from "../Dialogs/TicketResponseServiceAreaList/TicketResponseServiceArea.component";
import { TicketResponseServiceAreaListComponent } from "../Dialogs/TicketResponseServiceAreaList/TicketResponseServiceAreaList.component";
import { TicketResponseDiscussionListComponent } from '../Views/TicketResponseDiscussionList/TicketResponseDiscussionList.component';
import { DesktopTicketResponseRowComponent } from "../Views/TicketResponseHistoryList/DesktopViews/DesktopTicketResponseRow.component";
import { DesktopTicketResponseRowHeaderComponent } from "../Views/TicketResponseHistoryList/DesktopViews/DesktopTicketResponseRowHeader.component";
import { PhoneTicketResponseRowComponent } from "../Views/TicketResponseHistoryList/PhoneViews/PhoneTicketResponseRow.component";
import { TicketWorkCompleteComponent } from "../WorkComplete/TicketWorkComplete.component";

//  Components dynamically created via the @ComponentLookup() method for custom response prompting
const _DynamicComponents: any[] = [
    SC811_ResponseCode60_MarkingScheduleComponent, SC811_ResponseCode110_ExtraordinaryCircumstancesComponent,
    IN811_ResponseCode3CComponent, IN811_ResponseCode3GComponent
]

const _InternalComponents: any[] = [
    DesktopTicketResponseRowHeaderComponent, DesktopTicketResponseRowComponent, PhoneTicketResponseRowComponent,
    AddPositiveResponseComponent, AddPositiveResponseFlyoutComponent, PositiveResponseCommonComponent,
    AddExcavatorCommentFlyoutComponent, AddExcavatorCommentComponent,
    IN811_ResponseCode3Cand3GSharedComponent
]

const _Components: any[] = [
    TicketResponseDiscussionListComponent,
    ..._DynamicComponents
];

@NgModule({
    imports: [
        GlobalSharedModule,
        SharedPhoneDevice
    ],
    declarations: [
        TicketResponseServiceAreaListComponent, TicketResponseServiceAreaComponent,
        TicketResponseServiceAreaListDialog, TicketResponseServiceAreaListFlyout, ServiceAreaResponsesDialogComponent,
        AddExcavationDateDialogComponent, AddMarkingDelayResponseDialogComponent, AddExcavatorCommentDialogComponent, 
        AddPositiveResponseDialog, TicketResponseHistoryListComponent, TicketWorkCompleteComponent, 
        ..._InternalComponents,
        ..._Components
    ],
    exports: [
        TicketResponseServiceAreaListDialog, TicketResponseServiceAreaListFlyout,
        TicketResponseHistoryListComponent, ServiceAreaResponsesDialogComponent,
        TicketWorkCompleteComponent, 
        ..._Components
    ],
    providers: [
        CommonService,
        TicketActionsService,       //  Needed for viewing responses from inside AZ Political Sign details page!

        //  This is necessary to prevent tree-shaking from not including these dialogs in the production optimized build.
        //  Which will then cause ticket edits for those ticket functions to not work correct/use the default ticket entry form!
        ..._DynamicComponents
    ]
})
export class TicketResponseModule { }
