import { Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { AddPositiveResponseComponent } from "Pages/Tickets/Responses/AddPositiveResponse/AddPositiveResponse.component";
import { IQ_FLYOUT_DATA, IQ_FLYOUT_REF } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { SideSlideoutRef } from 'Shared/PhoneDevice/SideSlideout/SideSlideoutRef';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { AddPositiveResponseData } from "../AddPositiveResponse.component";

@Component({
    templateUrl: './AddPositiveResponseFlyout.component.html',
    styleUrls: ['./AddPositiveResponseFlyout.component.scss'],
    animations: [slideInFromRight]
})
export class AddPositiveResponseFlyoutComponent implements OnInit {
    //  Defining the animation on the HostBinding like this is cleaner and causes it to do the close animation automatically.
    @HostBinding('@slideInFromRight') animation = 'enter';

    @ViewChild(AddPositiveResponseComponent)
    private _AddPositiveResponseComponent: AddPositiveResponseComponent;

    public HeaderText = "Add Response for "; 

    constructor(@Inject(IQ_FLYOUT_DATA) public Data: AddPositiveResponseData, @Inject(IQ_FLYOUT_REF) private _SideSlideoutRef: SideSlideoutRef) {
    }

    public ngOnInit(): void {
        this.HeaderText += this.Data.SingleItem ? ("Ticket " + this.Data.TicketNumbers[0]) : "multiple Tickets";
    }

    public Close(returnData: TicketAffectedServiceAreaInfo = null) {
        this._SideSlideoutRef.close(returnData);
    }

    public Save(): void {
        this._AddPositiveResponseComponent.Save().subscribe((savedServiceArea) => {
            if (this.Data.OnResponseSaved)
                this.Data.OnResponseSaved(savedServiceArea);

            if (savedServiceArea)
                this.Close(savedServiceArea);
        })
    }
}
