import { NgModule } from '@angular/core';
import { MatSelectRemoveAriaOwnsDirective } from './MatSelectRemoveAriaOwns.directive';

@NgModule({
    exports: [
        MatSelectRemoveAriaOwnsDirective
    ],
    declarations: [
        MatSelectRemoveAriaOwnsDirective
    ]
})
export class HacksModule { }
