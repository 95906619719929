import { NgModule } from '@angular/core';
import { DynamicContentDirective } from './DynamicContent.directive';

@NgModule({
    imports: [],
    declarations: [
        DynamicContentDirective
    ],
    exports: [
        DynamicContentDirective
    ]
})
export class DynamicContentModule { }
