import { ListColumnGroup } from '@iqModels/Configuration/ConfiguredListColumnsAndFilters/ListColumnGroup.model';
import { ListFilterGroup } from '@iqModels/Configuration/ConfiguredListColumnsAndFilters/ListFilterGroup.model';

//  This is purposely NOT typed to an entity.  The search is designed to return a flat result set
//  containing only the requested columns.  It is not intended to return full entity graphs!
export class SearchResponse {
    TotalCount: number = 0;

    //Needed for the next/previous buttons so that paging will work.  Because tickets (and other things in rare situations) are always added, possibly changing the page of things.  So we use this to find the right item to show when we fetch a new page so we can keep the correct order and not show things twice
    NextPageFirstID: string;
    //Needed for the next/previous buttons so that paging will work.  They may not start on the first page when they open the details, but may want to go backwards while on the details
    PreviousPageLastID: string;

    Items: any[] = [];

    //Added if it's the first call.  This way we don't ahve to call the server to get these before we call to get the list
    Columns: ListColumnGroup;
    //Added if it's the first call.  This way we don't ahve to call the server to get these before we call to get the list
    Filters: ListFilterGroup;

    constructor() {
    }
}
