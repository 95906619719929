export enum DirectionEnum {
    N = 0,
    S = 1,
    E = 2,
    W = 3,
    NE = 4,
    NW = 5,
    SE = 6,
    SW = 7
}

export enum DirectionEnumDescription {
    N = "N",
    S = "S",
    E = "E",
    W = "W",
    NE = "NE",
    NW = "NW",
    SE = "SE",
    SW = "SW"
}
