import { Component, forwardRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyTicketBeforeSaveResponse } from 'Models/Tickets/VerifyTicketBeforeSaveResponse.model';
import { TicketEntrySectionEventService } from 'Pages/Tickets/Services/TicketEntrySectionEvent.service';
import { CommonService } from 'Services/CommonService';
import { MapRouteSegment } from 'Models/Maps/MapRouteSegment.model';
import { TicketEntryDialogBase } from '../../../Base/TicketEntryDialogBase.component';
import { TicketEntrySectionBase } from '../../../Base/TicketEntrySectionBase.component';
import { DigsiteMapComponent } from '../../DigsiteMap/DigsiteMap.component';
import { CreateMultipleTicketsComponent, CreateMultipleTicketsData } from '../CreateMultipleTickets.component';

/**
 *  Dialog returns the VerifyTicketBeforeSaveResponse.  Which is updated if we are creating multiple tickets
 *  (because the data from this dialog can affect the content shown on the VerifyLocation dialog).
 *  Returns null if the user picks "Back to ticket" which will cause us to abort the save process.
 */
@Component({
    templateUrl: './CreateMultipleTicketsDialog.component.html',
    styleUrls: ['./CreateMultipleTicketsDialog.component.scss'],
    providers: [
        CommonService,
        TicketEntrySectionEventService.Provider,
        { provide: TicketEntrySectionBase, useExisting: forwardRef(() => CreateMultipleTicketsDialogComponent) }      //  so we can use QueryList<EntrySectionBase> to find
    ]
})
export class CreateMultipleTicketsDialogComponent extends TicketEntryDialogBase {
    @ViewChild(DigsiteMapComponent, { static: true })
    private _DigsiteMap: DigsiteMapComponent;

    @ViewChild(CreateMultipleTicketsComponent)
    private _CreateMultipleTicketsComponent: CreateMultipleTicketsComponent;

    constructor(commonService: CommonService, sectionEventService: TicketEntrySectionEventService,
        private _DialogRef: MatDialogRef<CreateMultipleTicketsDialogComponent, VerifyTicketBeforeSaveResponse>,
        @Inject(MAT_DIALOG_DATA) public Data: CreateMultipleTicketsData) {
        super(commonService, sectionEventService, Data.TicketEntryForm);
    }

    public RouteListChanged(routeList: MapRouteSegment[]): void {
        this._DigsiteMap.ShowRoute(routeList);
    }

    public OnSaveMultipleTickets(): void {
        this._CreateMultipleTicketsComponent.OnSaveMultipleTickets();

        this.TicketEntryForm.VerifyTicketBeforeSave().subscribe(verifyResponse => {
            //  Need to manually remove any other Digsite Rules for Length since they have been resolved by this dialog.
            //  This allows configuring limits (errors/warnings) that will only be applied when NOT splitting up the digsite.
            //  TODO: Would be nice if these dig site rules would be calculated based off the segments instead of the
            //  full digsite geometry (since we are re-calling VerifyTicketBeforeSave anyway).  But right now at least,
            //  didn't have any need other than to remove them here.
            if (verifyResponse.InvalidDigSiteRules)
                verifyResponse.InvalidDigSiteRules = verifyResponse.InvalidDigSiteRules.filter(r => !this._CreateMultipleTicketsComponent.IgnoreDigsiteRule(r));

            this._DialogRef.close(verifyResponse);
        }, () => this._CreateMultipleTicketsComponent.IsBusy = false);
    }
}
