import { OnInit, OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class ComponentBase implements OnInit, OnDestroy {
    protected destroyed$: Subject<void> = new Subject();

    _errorMessage: string = null;
    ActionMessage: string;
    isBusy = false;

    constructor() {

    }

    ngOnInit() {
        
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    protected SetActionMessage(data: any) {
        
    }

    protected SetError(err) {
        //Can't be busy if we got an error
        this.isBusy = false;

        if (!err) {
            this._errorMessage = null;
            return;
        }

        let message: string = err.message || err;
        const index = message.indexOf("iQError:");
        if (index > 0)
            message = message.slice(index + 8, message.length - 2);

        this._errorMessage = message;
    }
}
