import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import { Collection } from "ol";
import { Coordinate } from "ol/coordinate";
import { Polygon, SimpleGeometry } from "ol/geom";
import { Draw, Interaction } from "ol/interaction";
import { createBox as Draw_createBox, SketchCoordType } from "ol/interaction/Draw";
import Map from 'ol/Map';
import Projection from "ol/proj/Projection";
import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";
import { DigSiteSizeControl } from "../Controls/DigSiteSizeControl";
import { GeometryTransformer } from "../GeometryTransformer";

export class DrawRectangleTool extends DrawingToolBase {

    private _NumPoints: number = 0;

    constructor(map: Map, mapToolService: MapToolService, private _VectorLayer: VectorLayerBase) {
        super(map, mapToolService);

        this.Init();
    }

    public OnDestroy(): any {
        this._VectorLayer = null;
        return super.OnDestroy();
    }

    protected CreateInteraction(): Interaction {
        const sizeControl = DigSiteSizeControl.FindSelfInMap(this.Map);

        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        const interaction = new Draw({
            features: new Collection(),      //  We draw into a collection so that we can pre-process when drawing is finished before adding to the _VectorLayer.
            type: "Circle",
            style: (feature, resolution) => {
                if (sizeControl && (feature.getGeometry().getType() === "Polygon"))
                    sizeControl.ShowSizeOfFeatureBeingDrawn(feature);
                return this._VectorLayer.BuildStyleForFeature(feature, true, MapFeatureStyleEnum.Polygon, resolution);
            },
            geometryFunction: (coordinates: SketchCoordType, geometry: SimpleGeometry, projection: Projection) => {
                this._NumPoints = coordinates.length;
                if (coordinates.length !== 2)
                    return Draw_createBox()(coordinates, geometry, projection);

                //  transformedRectangle is null, the geometry is not in the bounds of the US!
                //  Fallback to Draw_createBox() because returning null will make everything blow up.
                var transformedRectangle = DrawRectangleTool.MakeBox(coordinates as Coordinate[], geometry);
                return transformedRectangle ?? Draw_createBox()(coordinates, geometry, projection);
            }
        });

        this.AddListener(interaction.on("drawend", (evt: any /* DrawEvent is not exported!*/) => {
            this._NumPoints = 0;

            //  Clean the drawing and then add to the source.  This fixes self-intersecting polygons and breaks
            //  them up into multiple features if necessary.  Also applies any extra validation needed (minimum size?).
            this._VectorLayer.CleanAndAddFeature(evt.feature, false, !this.MapToolService.AllowMultipleShapes);

            setTimeout(() => this.MapToolService.ActivateTool.next(MapConstants.TOOL_EDIT_GEOMETRY));
        }));

        return interaction;
    }

    /*
     *  This is necessary to use (instead of the built-in createBox() method) so that the rectangle we create
     *  is properly aligned with the utm coordinate system we use for measurements.  Otherwise, the extent/envelope
     *  of the rectangle is slightly off vertically and horizontally.  It's not enough to be noticable except
     *  when DigSiteSizeControl is showing the dimensions.  If we don't do this, the dimensions will be noticable
     *  larger and will cause confusion since they won't match what is displayed for the segment distances.
     */
    private static MakeBox(coords: Coordinate[], geometry: SimpleGeometry): SimpleGeometry {
        try {
            const projection = GeometryTransformer.GetProjectionForCoordinate(coords[0]);
            const transformedCoords = GeometryTransformer.TransformCoordinates(coords, null, projection);

            const minX: number = Math.min(transformedCoords[0][0], transformedCoords[1][0]);
            const maxX: number = Math.max(transformedCoords[0][0], transformedCoords[1][0]);
            const minY: number = Math.min(transformedCoords[0][1], transformedCoords[1][1]);
            const maxY: number = Math.max(transformedCoords[0][1], transformedCoords[1][1]);

            const boxCoords: Coordinate[] = [
                [minX, maxY],
                [maxX, maxY],
                [maxX, minY],
                [minX, minY],
                [minX, maxY]
            ];

            const transformedBox = GeometryTransformer.TransformCoordinates(boxCoords, projection, null);

            if (!geometry)
                geometry = new Polygon([transformedBox]);
            else
                geometry.setCoordinates([transformedBox]);

            return geometry;
        } catch {
            //  geoemtry not in bounds of US!
            return null;
        }
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging) {
            return;
        }

        let helpMsg = 'Click to start drawing';
        if (this._NumPoints > 0)
            helpMsg = 'Click to end drawing';

        this.SetHelpMessage(helpMsg, evt.coordinate);
    }
}
