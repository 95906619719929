interface IKeyedCollection<T> {
    Add(key: string, value: T);
    ContainsKey(key: string): boolean;
    Count(): number;
    Item(key: string): T;
    Keys(): string[];
    Remove(key: string): T;
    Values(): T[];
}

//This implementation is not good if you need to get a list of keys or a list of the values (as you can see it loops through the collection and builds them...
//  It's good for when you need to get values by keys and check for keys existing.
//  If you need to get lists of keys, or values you will want to extend the interface and create one that stores stuff differently like in an array
export class Dictionary<T> implements IKeyedCollection<T> {
    constructor(private vals: { [index: string]: T } = {}) {
        this.items = vals;
    }
    private items: { [index: string]: T } = {};

    private count: number = 0;

    public GetItems() {
        //want to send a copy of the items so that if it's changed we don't change the items in the dictionary.  If you want to update the dictionary then, use the methods to do so.
        return { ...this.items };
    }

    public ContainsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }

    public Count(): number {
        return this.count;
    }

    public Add(key: string, value: T) {
        if (!this.items.hasOwnProperty(key))
            this.count++;

        this.items[key] = value;
    }

    public Remove(key: string): T {
        var val = this.items[key];

        if (!val)//Nothing to remove
            return null;

        delete this.items[key];
        this.count--;
        return val;
    }

    public Item(key: string): T {
        return this.items[key];
    }

    public Keys(): string[] {
        var keySet: string[] = [];

        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }

        return keySet;
    }

    public Values(): T[] {
        var values: T[] = [];

        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                values.push(this.items[prop]);
            }
        }

        return values;
    }
}
