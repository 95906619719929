import * as _ from 'lodash';

export class ObjectUtils {

    /**
     * Returns true if the object is completely empty.  Which means it's null, undefined, an empty string, an empty array/object.
     * i.e. A number with a value of 0 or 1 will still return false in both cases.
     * The lodash _.isEmpty method only tests if a collection object (which includes string) is empty so does not work as you
     * might expect on numeric or boolean values.  This method handles all of those different types.
     * @param val
     */
    public static IsEmpty(val: any): boolean {
        if ((val === null) || (val === undefined))
            return true;
        if (val === "")
            return true;

        if (_.isArray(val) || _.isPlainObject(val))        //  true for [] and {} values: https://lodash.com/docs/4.17.15#isObjectLike
            return _.isEmpty(val);

        return false;
    }

    //  Use this to test the IsEmpty method if changes are made
    //public static Test(): void {
    //    console.assert(ObjectUtils.IsEmpty(null));
    //    console.assert(ObjectUtils.IsEmpty(undefined));
    //    console.assert(!ObjectUtils.IsEmpty(0));
    //    console.assert(!ObjectUtils.IsEmpty(1));
    //    console.assert(!ObjectUtils.IsEmpty(true));
    //    console.assert(!ObjectUtils.IsEmpty(false));
    //    console.assert(ObjectUtils.IsEmpty(''));
    //    console.assert(!ObjectUtils.IsEmpty('a'));
    //    console.assert(!ObjectUtils.IsEmpty(new Date()));
    //    console.assert(ObjectUtils.IsEmpty({}));
    //    console.assert(!ObjectUtils.IsEmpty({ val: true}));
    //    console.assert(ObjectUtils.IsEmpty([]));
    //    console.assert(!ObjectUtils.IsEmpty([1, 2]));
    //    console.warn("If no assertions, all tests of ObjectUtils passed");
    //}


    public static Copy(obj) {
        let copy;

        // Handle the 3 simple types, and null or undefined
        if (!obj || "object" !== typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.Copy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.Copy(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }
}
