
export class ExcavatorOfficesForCompanyRequest {

    /**
     * Set OneCallCenterCode if calling from Self Registration.  User is logged in to Cognito (so passes the auth check) but 
     * has not fully logged in to Exactix (so not cookie w/OneCallCenter set).
     */
    constructor(public ExcavatorCompanyID: string, public OneCallCenterCode: string = undefined) {
    }
}
