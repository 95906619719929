import { IEntity } from "Models/Interfaces/IEntity.interface";

export class EntityBase implements IEntity {
    Selected: boolean;

    xmin: number;
    constructor(public ID: string) {

    }
}
