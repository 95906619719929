import { OverlayContainer } from '@angular/cdk/overlay';
import { Directive, HostListener } from '@angular/core';
import screenfull from 'screenfull';

/**
 *  Use this directive to toggle the browser to/from full screen via the screenfull node package.
 *  See: https://www.npmjs.com/package/screenfull
 *  There is also a package called "angular-screenful" which wraps screenfull but it doesn't work
 *  with Angular 5 and hasn't been updated in a while.
 */
@Directive({
    selector: '[toggleFullscreen]'
})
export class ToggleFullscreenDirective {
    constructor(private _OverlayContainer: OverlayContainer) {
    }

    @HostListener('click') onClick() {
        if (!screenfull.isEnabled)
            return;

        screenfull.toggle().then(() => {
            //  The overlay container uses the FullscreenOverlayContainer (configured in app.module).
            //  That creates a new <div> element that is OUTSIDE the <body>!  Which means that our main theme class
            //  names will not be set.  This will add those class names to the overlay container element so that our themes
            //  display correctly.  To see where this applies - comment this out, enter full screen mode, then save a ticket.
            //  The dialogs that are displayed do not use our themes!
            if (this._OverlayContainer) {
                const elem = this._OverlayContainer.getContainerElement();
                if (elem && (elem.className.indexOf(document.body.className) < 0))
                    elem.className += (" " + document.body.className);
            }
        });
    }
}
