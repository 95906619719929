
export class MapConstants {
    //  Layer names:
    public static readonly LAYERNAME_DIGSITE: string = "DigSite";
    public static readonly LAYERNAME_UNBUFFERED_DIGSITE: string = "UnbufferedDigSite";
    public static readonly LAYERNAME_DIGSITE_EDITOR: string = "DigSiteEditor";
    public static readonly LAYERNAME_MAPFEATURE: string = "MapFeature";
    public static readonly LAYERNAME_UNBUFFERED_MAPFEATURE: string = "UnbufferedMapFeature";
    public static readonly LAYERNAME_MAPFEATURE_EDITOR: string = "MapFeatureEditor";
    public static readonly LAYERNAME_NEARSTREET: string = "NearStreets";
    public static readonly LAYERNAME_REGISTRATION_CHANGES: string = "RegistrationChanges";
    public static readonly LAYERNAME_REGISTRATION_EDITOR: string = "RegistrationEditor";
    public static readonly LAYERNAME_SEARCH: string = "Search";

    public static readonly LEGENDNAME_REGISTRATION_CHANGES: string = "Registration Changes";

    //  OpenLayers Css classes:
    public static readonly CLASS_UNSELECTABLE: string = 'ol-unselectable';
    public static readonly CLASS_CONTROL: string = 'ol-control';
    public static readonly CLASS_UNSUPPORTED: string = 'ol-unsupported';

    public static readonly TOOL_EDIT_GEOMETRY: string = "EditGeometryTool";

    public static readonly METERS_PER_FOOT = 0.3048;
    public static readonly FEET_PER_METER = 3.28084;

    public static readonly FEET_PER_MILE = 5280;

    public static readonly SQFEET_PER_SQMETER = 10.764;
    public static readonly SQFEET_PER_SQMILE = 2.788e+7;            //  Divide sq ft by this constant to get sq mi

    public static readonly MAP_PROJECTION = "EPSG:3857";
    public static readonly LATLON_PROJECTION = "EPSG:4326";

    //  The name of the Font Awesome Free package.  Used where we reference this in an OpenLayers Style.
    //  This needs to match the version of the "@fortawesome/fontawesome-free" package referenced in package.json.
    //  ** It is also stored in the Configuration.MapFeatureTypes records.  There is a script at the top of
    //  the MigrationSql.plpgsql script to handle that but the version must be changed there too!
    public static readonly FONT_AWESOME_FREE_NAME = "Font Awesome 6 Free";
}
