import { Person } from "../People/Person.model";
import { DiscussionTypeEnum } from "../../Enums/DiscussionType.enum";
import { ServiceArea } from "../ServiceAreas/ServiceArea.model";
import { TicketResponse } from "./TicketResponse.model";
import { IEntity } from "../Interfaces/IEntity.interface";

export class TicketDiscussion implements IEntity {
    ID: string;
    Selected: boolean;

    TicketNumber: string;

    //  This should really always be set, but if it's created due to a response being entered and that was entered
    //  by an api/ivr or something else that doesn't necessarily authenticate a user, this will be null.
    CreatePersonID: string;
    CreatePerson: Person;

    CreateDate: string;

    /// <summary>
    /// If not null: The service area that this discussion/comment applies to.
    /// If null: Applies to the Excavator on the ticket.
    /// </summary>
    ServiceAreaID: string;
    ServiceArea: ServiceArea;        //  Don't use this property to get service area info - use ServiceAreaInfo (loaded via stored proc) - it has everything we need when displaying service areas

    /// <summary>
    /// If true, visible to anyone (depending on permissions).  If false, only visible to the Excavator or Service Area depending on the ServiceAreaID property.
    /// </summary>
    IsPublic: boolean;

    //  TODO: If these "types" get more complicated, we may need to create a Configuration.DiscussionTypes table to hold those
    //  configurations (which would then need this enum defined in that record).  But at least for now, it's not needed.
    //  Everyone is going to get ExcavatorComments.  Only FL uses the other 2 and has custom handling for those types.
    DiscussionType: DiscussionTypeEnum;

    Message: string;

    Response: string;

    ResponsePersonID: string;
    ResponsePerson: Person;

    ResponseDate: string;

    /// <summary>
    /// Set if this discussion was created due to a response being entered.  i.e. Response.TriggerDiscussionType is set.
    /// </summary>
    TicketResponseID: string;
    TicketResponse: TicketResponse;
}
