export enum ResponseEntryMethodEnum {
    AutoResponse = 0,       //  Response automatically added when ticket was saved based on Service Area configuration
    Operator = 1,           //  Operator/local user via web app
    ServiceAreaUser = 2,    //  Service Area user via web app
    WebService = 3,         //  via web api call from external system
    IVR = 4,
    Telnet = 5
}

export enum ResponseEntryMethodEnumDescriptions {
    AutoResponse = "Auto Response",
    Operator = "Operator",
    ServiceAreaUser = "Web",
    WebService = "Web Service",
    IVR = "IVR",
    Telnet = "Telnet"
}
