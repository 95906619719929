
export enum ScheduledTaskFrequencyEnum {
    /// <summary>
    /// Task runs once, at a specified time.
    /// </summary>
    OneTime = 0,

    /// <summary>
    /// Task runs at a specified interval.
    /// </summary>
    Interval = 1,

    /// <summary>
    /// Task runs at a scheduled time every day.  The task IntervalSeconds value is used to determine the run-time.
    /// </summary>
    Daily = 2,

    Weekly = 3,

    Monthly = 4
}


export enum ScheduledTaskFrequencyEnumDescriptions {
    OneTime = "One Time",
    Interval = "Interval",
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly"
}
