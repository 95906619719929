import { Directive, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

/**
 *  Use this as a base class on a Component that needs a "Destroyed" subject in order to release subscriptions.
 */
@Directive()
export abstract class ComponentWithDestroySubscription implements OnDestroy {
    protected Destroyed: Subject<void> = new Subject();

    public ngOnDestroy(): void {
        this.Destroyed.next();
        this.Destroyed.complete();
    }
}
