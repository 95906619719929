import { Directive, OnDestroy, Renderer2 } from '@angular/core';
import { LinkedServerTypeEnum } from 'Enums/LinkedServerType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { AppUser } from 'Models/Security/AppUser.model';
import { LinkedServerSetting } from 'Models/Security/LinkedServerSetting.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { SettingsService } from 'Services/SettingsService';
import {
    faBellExclamation, faBroadcastTower, faBuilding, faChartLine, faCog, faEnvelopeOpenText, faFlagAlt, faHomeAlt,
    faInboxIn, faReceipt, faShieldAlt, faShovel,
    faTools, faUser, faUsers
} from '@fortawesome/pro-light-svg-icons';
import { faBuilding as solidBuilding, faTrafficCone } from '@fortawesome/pro-solid-svg-icons';

@Directive()
export abstract class BasePrimaryView implements OnDestroy {
    public OccLogoImage: string = null;
    public OccLogoHeight: string;
    public OccMobileLogoHeight: string;

    public CanSwitchServer: boolean;
    public LinkedServers: LinkedServerSetting[] = null;
    public CurrentServerCode: string;
    public OtherServer: LinkedServerSetting;        //  Only set if there is exactly 1 other One Call Center

    public ProjectsName: string = null;

    public SignedIn: Observable<boolean> = this.AuthenticationService.UserIsSignedIn;

    protected Destroyed: Subject<void> = new Subject();

    private _FontClass: string;
    private _PaletteClass: string;
    public FontSize: string;

    public HomeIcon = faHomeAlt;
    public TicketIcon = faReceipt;
    public ProjectIcon = faTrafficCone;

    public TicketViewPermission: PermissionsEnum = PermissionsEnum.Ticket_View;
    public ProjectViewPermission: PermissionsEnum = PermissionsEnum.Projects_View;

    constructor(protected AuthenticationService: AuthenticationService, private _Renderer: Renderer2,
        private _SettingsService: SettingsService)
    {
        this.SetPaletteAndFontFromLocalStorage();

        //  Need to always get when it changes.  It will be an empty user if logged out
        this.AuthenticationService.CurrentUserObserver(true).pipe(takeUntil(this.Destroyed))
            .subscribe((appUser) => {
                this.LinkedServers = appUser.LinkedServers;
                this.CurrentServerCode = appUser.CurrentOneCallCenterCode;
                this.CanSwitchServer = this.LinkedServers && (this.LinkedServers.length > 1);
                if (this.LinkedServers && (this.LinkedServers.length === 2))
                    this.OtherServer = appUser.LinkedServers.find(server => server.Code !== this.CurrentServerCode);
                else
                    this.OtherServer = null;

                if (appUser.OneCallCenterSettings) {
                    this.OccLogoImage = appUser.OneCallCenterSettings.LogoImage;
                    this.OccLogoHeight = appUser.OneCallCenterSettings.LogoHeight ?? "60px";
                    this.OccMobileLogoHeight = appUser.OneCallCenterSettings.MobileLogoHeight ?? "45px";
                }

                //  setTimeout needed here because this is executed in the constructor.  If we already have the appUser (i.e. user refreshes page),
                //  the derived classes constructor has not executed yet.  In the case of the PhoneMainMenu, that means _EnumService will not be set
                //  which is then referenced by the PhoneMainMenu.OnAppUserChanged() method.
                setTimeout(() => this.OnAppUserChanged(appUser));
            });
    }

    ngOnDestroy(): void {
        this.Destroyed.next();
        this.Destroyed.complete();
    }

    protected OnAppUserChanged(appUser: AppUser): void {
        this.ProjectsName = appUser.ProjectsName;
    }

    public SwitchServer(server: LinkedServerSetting): void {
        if (!server)
            return;     //  ???

        if (server.ServerType === LinkedServerTypeEnum.Exactix)
            this.AuthenticationService.SwitchServer(server);
        else {
            //  Everything else is an external system (Coursettra, DigTix, URL).
            //  Open all of these in a new tab.  Also important to not send these through the above SwitchServer()
            //  method because that appends the /home to the URL which may not be valid for the configured URL (like Coursettra)!
            window.open(server.URL, '_blank').focus();
        }
    }

    private SetPaletteAndFontFromLocalStorage(): void {
        this._FontClass = "iq-typography-default";
        this._PaletteClass = this._SettingsService.DefaultColorPalette;

        //  Wrap all access to localStorage in case it is full or disabled by browser/security settings
        try {
            let paletteColor = localStorage.getItem('colorPalette');

            //  Changed the theme names to be more meaningful so migrate any that have been saved.  Can remove this eventually.
            switch (paletteColor) {
                case "a":
                    paletteColor = "green";
                    localStorage.setItem('colorPalette', paletteColor);
                    break;
                case "b":
                    paletteColor = "blue";
                    localStorage.setItem('colorPalette', paletteColor);
                    break;
                case "c":
                    paletteColor = "gray";
                    localStorage.setItem('colorPalette', paletteColor);
                    break;
            }

            this._PaletteClass = 'color-palette-' + (!paletteColor ? this._SettingsService.DefaultColorPalette : paletteColor);

            this.FontSize = localStorage.getItem('fontSize');
            this._FontClass = this.FontSize === 'small' ? 'iq-typography-small' : this.FontSize === 'large' ? 'iq-typography-large' : 'iq-typography-default';
        } catch { /* empty */ }     //  Ignore error if local storage is full!

        this._Renderer.addClass(document.body, 'client-fouriq');
        this._Renderer.addClass(document.body, this._FontClass);
        this._Renderer.addClass(document.body, this._PaletteClass);
    }

    public SetFontSize(size: string | null) {
        try {
            if (!size)
                localStorage.removeItem('fontSize');
            else
                localStorage.setItem('fontSize', size);
        } catch { /* empty */ }     //  Ignore error if local storage is full!

        this._Renderer.removeClass(document.body, this._FontClass);
        this._FontClass = size === 'small' ? 'iq-typography-small' : size === 'large' ? 'iq-typography-large' : 'iq-typography-default';
        this.FontSize = size;
        this._Renderer.addClass(document.body, this._FontClass);
    }

    public SetColorPalette(palette: string) {
        try {
            localStorage.setItem('colorPalette', palette);
        } catch { /* empty */ }     //  Ignore error if local storage is full!

        this._Renderer.removeClass(document.body, this._PaletteClass);
        this._PaletteClass = 'color-palette-' + palette;
        this._Renderer.addClass(document.body, this._PaletteClass);
    }
}
