
//  The format of the TRSQ Grids that can be searched from the Map Search.
//  This is NOT the same thing as the setting for OneCallCenterSettingTypeEnum.MapGridType.
//  That determines the grids that are saved in TicketGrids.  FL saves USGS grids there but also
//  has TRS grids available for searching on the map.
export enum MapSearchTRSQGridTypeEnum {
    None = 0,

    // i.e. AZ - formatted as: "T24.0SR25.0E18NW"
    TRSQ_Long = 1,

    // i.e. FL - formatted as: "67S26E28" or "02N13E188" (have > 800 that have a 3 digit section #!)
    //  And no Quarter Sections at all
    //  They also have 6 (exactly 6!) non-standard like this:
    //      04N24E3747
    //      06S18E3334
    //      56S42E1019
    //      595S32E9999
    //      59S355E100
    //      9999N9999E9999
    TRS_Short = 2
}
