import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { AddPositiveResponseData } from 'Pages/Tickets/Responses/AddPositiveResponse/AddPositiveResponse.component';
import { AddPositiveResponseDialog } from 'Pages/Tickets/Responses/AddPositiveResponse/Dialog/AddPositiveResponseDialog.component';
import { SettingsService } from 'Services/SettingsService';
import { TicketResponseRowViewBase } from "../TicketResponseRowViewBase";

@Component({
    selector: 'desktop-ticket-response-row',
    templateUrl: './DesktopTicketResponseRow.component.html',
    styleUrls: ['./DesktopTicketResponseRow.component.scss']
})
export class DesktopTicketResponseRowComponent extends TicketResponseRowViewBase {
    public TicketResponseCreatePermission = PermissionsEnum.TicketResponse_Create;

    constructor(public SettingsService: SettingsService, private _Dialog: MatDialog) {
        super();
    }

    protected override ShowAddResponseDialog(data: AddPositiveResponseData): void {
        this._Dialog.open(AddPositiveResponseDialog, {
            data: data,
            minWidth: '45%',
            width: '550px',
            maxWidth: '550px'
        }).afterClosed().subscribe(savedResponse => {
            if (savedResponse)
                this.Refresh.next();
        });
    }
}
