import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ViewStateEnum } from 'iqCognito/models';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { UserRegistrationService } from 'Pages/UserRegistration/Services/UserRegistration.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService, ExactixFederatedLoginState } from 'Services/AuthenticationService';
import { BusyService } from 'Services/BusyService';
import { passwordRequirements } from 'Services/CognitoAdminService';
import { MainMenuService } from 'Services/MainMenuService';

@Component({
  selector: 'load-user-info',
    templateUrl: './Login.component.html',
    styleUrls: ['./Login.component.scss']
})
export class LoginComponent implements OnDestroy {

    //Only set this if the user is deep linking (the AuthenticationService has a RedirectUrl value)
    federatedLoginReturnState: ExactixFederatedLoginState = null;

    passwordRequirements = passwordRequirements;

    private destroyed$: Subject<void> = new Subject();

    //Setup observable in the service and get the key to that observable back to start and end the indicator
    busyKey: string = this.busyService.createNew();

    public CanSelfRegister: boolean = false;
    public ShowEmergencyContactsPage: boolean = false;

    constructor(private mainMenuService: MainMenuService, private authService: AuthenticationService, private busyService: BusyService,
        private userRegService: UserRegistrationService, cogService: IqAwsCognitoService, private router: Router)
    {
        //If there is a return url (deep linking) then set the federated login return state in case they use a federated login provider. i.e. Google, Facebook, etc.
        //  Tell set it to redirect after getting the user info because unlike the link login page we don't handle the login event from cognito in this component
        if (this.authService.RedirectUrl)
            this.federatedLoginReturnState = { redirectBeforeFetchingUser: false, returnUrl: this.authService.RedirectUrl };

        cogService.viewStateInfoChange$.subscribe(val => {
            if (val.state === ViewStateEnum.createNewUser)
                this.router.navigateByUrl('/newUser');
        });

        mainMenuService.forceClose = true;

        this.userRegService.LoginPageOptions()
            .subscribe(options => {
                this.CanSelfRegister = options?.CanSelfRegister ?? false;
                this.ShowEmergencyContactsPage = options?.ShowEmergencyContactsPageOccCodes && Object.keys(options.ShowEmergencyContactsPageOccCodes).length > 0;
            });

        combineLatest(
            this.authService.UserIsSignedIn.pipe(takeUntil(this.destroyed$)),
            this.authService.FetchedUserInfo.pipe(takeUntil(this.destroyed$))
        ).subscribe(val => {
            if (!val)
                return;

            if (val[0] && !val[1])
                this.busyService.showByKey(this.busyKey);
            else if (!val[0] && !val[1])//If we never leave the page but these values change, i.e. when checking for userInfo and not finding any when we are embedded in Coursettra
                this.busyService.hideByKey(this.busyKey);
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.mainMenuService.forceClose = false;
        this.busyService.removeByKey(this.busyKey);
    }
}
