<div *ngIf="Model.ActionName" class="action" >
    <div>{{Model.ActionName}}</div>
</div>
<div *ngIf="!Model.ActionName" class="item" style="display:flex; white-space:normal">
    <!-- todo: if One Call doesn't use ExcavatorID, need to hide it.  Can't do it based on Model.ExcavatorID because results may be rolled up.  Will need one call setting -->
    <div class="gray-primary-text" style="min-width:6ch">{{Model.ExcavatorID}}</div>
    <div style="display: flex; flex-direction: column; flex-grow: 1; padding-left: 10px">
        <div style="display:flex">
            <div style="padding-right:10px; min-width:25%">
                <div>{{Model.ContactName}}</div>
                <div>{{Model.PrimaryPhoneNumber | phone}}</div>
            </div>
            <div *ngIf="!this.Model.ExcavatorContactID" style="flex-grow:1; color:red">
                * Create New Contact
            </div>
            <div *ngIf="this.Model.ExcavatorContactID" class="gray-primary-text" style="flex-grow:1">
                <div *ngIf="Description">{{Description}}</div>
                <div *ngIf="Model.CompanyName">
                    <span [ngClass]="{'red':Model.ExcavatorCompanyIDMatches===false}">{{Model.CompanyName}}</span>
                    <span *ngIf="Model.CompanyID" [ngClass]="{'red':Model.ExcavatorCompanyIDMatches===false}">&nbsp;({{Model.CompanyID}})</span>
                    <span *ngIf="Model.OfficeName" [ngClass]="{'red':Model.ExcavatorOfficeIDMatches===false}">&nbsp;-&nbsp;{{Model.OfficeName}}</span>
                </div>
                <div *ngIf="Model.Address" [ngClass]="{'red':Model.ExcavatorOfficeIDMatches===false}">{{FormattedAddress}}</div>
                <div *ngIf="ShowOfficePhone">{{Model.OfficePhone | phone}}</div>
                <div *ngIf="Model.Login" style="color:green">Login: {{Model.Login}}</div>
                <div *ngIf="Model.RoleNames" style="display: flex; color: green">
                    <div style="padding-right: 5px">Role(s):</div>
                    <div style="display:flex; flex-direction: column">
                        <div *ngFor="let role of Model.RoleNames">{{role}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="Model.WarningMessage" style="color:red">{{Model.WarningMessage}}</div>
    </div>
</div>
