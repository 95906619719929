export enum TicketStatusEnum {
    Incomplete = 0,
    Suspended = 1,
    Released = 2,
    Void = 4
}

export enum TicketStatusEnumDescription {
    Incomplete = "Incomplete",
    Suspended = "Suspended",
    Released = "Released",
    Void = "Void"
}
