import { NgModule } from '@angular/core';
import { TicketResponseModule } from 'Pages/Tickets/Responses/Modules/TicketResponse.module';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { PrintTicketTextAndServiceAreasComponent } from './PrintTicketTextAndServiceAreas.component';

@NgModule({
    imports: [
        GlobalSharedModule,
        TicketResponseModule
    ],
    declarations: [
        PrintTicketTextAndServiceAreasComponent
    ],
    exports: [
        PrintTicketTextAndServiceAreasComponent
    ]
})
export class PrintTicketTextAndServiceAreasModule { }
