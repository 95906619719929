import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { GeometryTransformer } from "Shared/Components/Maps/GeometryTransformer";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { GeometryUtils } from "Shared/Components/Maps/GeometryUtils";
import Map from 'ol/Map';
import { Pointer, Interaction } from "ol/interaction";
import { MapBrowserEvent } from "ol";
import { Coordinate } from "ol/coordinate";

export class BufferFeatureTool extends DrawingToolBase {

    constructor(map: Map, mapToolService: MapToolService, private _VectorLayer: VectorLayerBase) {
        super(map, mapToolService);

        this.Init();
    }

    public OnDestroy(): any {
        this._VectorLayer = null;
        return super.OnDestroy();
    }

    protected CreateInteraction(): Interaction {
        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        return new Pointer({
            handleEvent: (evt: MapBrowserEvent<any>) => {
                if (evt.type !== "dblclick")
                    return true;

                this.OnDoubleClick(evt);
                return false;
            }
        });
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging)
            return;

        const helpMsg = 'Double-click to buffer feature';
        this.SetHelpMessage(helpMsg, evt.coordinate);
    }

    private OnDoubleClick(evt: MapBrowserEvent<any>): void {

        //  15 pixel tolerance (radius).  Multiply by resolution gives the tolerance in map coordinates.
        //  This changes the size of the select box based on the current resolution.
        const offset = 15 * evt.frameState.viewState.resolution;

        const nwCornerMapProj: Coordinate = [evt.coordinate[0] - offset, evt.coordinate[1] + offset];
        const seCornerMapProj: Coordinate = [evt.coordinate[0] + offset, evt.coordinate[1] - offset];

        const nwCornerLL = GeometryTransformer.TransformCoordinate(nwCornerMapProj, null, MapConstants.LATLON_PROJECTION);
        const seCornerLL = GeometryTransformer.TransformCoordinate(seCornerMapProj, null, MapConstants.LATLON_PROJECTION);

        this.MapToolService.MapSearchService.FeaturesForBuffering(nwCornerLL[0], seCornerLL[0], seCornerLL[1], nwCornerLL[1], this.Map.getView().getZoom())
            .subscribe(response => {
                if (response && response.Items) {
                    if (!this.MapToolService.AllowMultipleShapes)
                        this._VectorLayer.VectorSource.clear();
                    response.Items.forEach(item => {
                        const featureList = GeometryUtils.GeoJsonToFeatures(item.GeometryJson);
                        if (featureList) {
                            featureList.forEach(feature => {
                                //  Features are always added unbuffered.  The buffer is shown visually in the style and
                                //  the buffered and unbuffered geometries are then stored as the manual dig site.
                                //  That buffering is done in DigsiteEditor.Save().
                                this._VectorLayer.CleanAndAddFeature(feature, false);
                            });
                        }
                    });
                }
            });
    }
}
