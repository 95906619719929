import { LatLonBounds } from "Models/Maps/LatLonBounds.model";

export class SearchAutocompleteRequest {

    public SearchValue?: string;

    public State?: string;
    public County?: string;

    public Bounds?: LatLonBounds;
}
