import { PhoneTypeEnum } from "Enums/PhoneType.enum";
import { AdditionalContactTypeEnum } from "Enums/AdditionalContactType.enum";
import { ExcavatorSaveActionEnum } from "Enums/ExcavatorSaveAction.enum";

export class TicketExcavator {
    ExcavatorCompanyID?: string;        //  ExcavatorCompany.ID
    ExcavatorOfficeID?: string;         //  ExcavatorOffice.ID
    ExcavatorContactID?: string;        //  ExcavatorContact.ID

    CompanyID?: number;                 //  Generated (displayed) company ID (from ExcavatorCompany.CompanyID)
    OfficeID?: number;                  //  Generated (displayed) company ID (from ExcavatorCompany.CompanyID)
    ExcavatorID?: number;               //  Generated (displayed) excavator/caller ID (from ExcavatorContact.ExcavatorID)

    //  Excavator Company fields:
    CompanyName?: string;

    //CompanyTypeName?: string;
    CompanyTypeID?: string;         //  ID to ExcavatorCompanyType record

    //CompanyIndustryName?: string;
    CompanyIndustryID?: string;         //  ID to IndustryType record

    OfficeName?: string;
    OfficeAddress1?: string;
    OfficeAddress2?: string;
    OfficeCity?: string;
    OfficeState?: string;
    OfficeZip?: string;
    OfficeCounty?: string;
    OfficePhone?: string;

    //  Excavator Contact fields:
    ContactName?: string;
    ContactMainPhone?: string;
    ContactBestTime?: string;
    ContactEmail?: string;
    ContactEmailNA?: boolean;

    ContactAdditionalPhone1Type?: AdditionalContactTypeEnum;
    ContactAdditionalPhone1Value?: string;
    ContactAdditionalPhone2Type?: AdditionalContactTypeEnum;
    ContactAdditionalPhone2Value?: string;
    ContactAdditionalPhone3Value?: string;

    AltContactName?: string;
    ContactAdditionalContactType1?: AdditionalContactTypeEnum;
    ContactAdditionalContactValue1?: string;
    ContactAdditionalContactType2?: AdditionalContactTypeEnum;
    ContactAdditionalContactValue2?: string;

    //  These flags are used to determine if new excavator records should be inserted or updated when
    //  saving a ticket.  These flags are set by the client during ticket entry - they are not stored.
    CompanySaveAction: ExcavatorSaveActionEnum;
    OfficeSaveAction: ExcavatorSaveActionEnum;
    ContactSaveAction: ExcavatorSaveActionEnum;
}
