import { NgModule } from '@angular/core';
import { PhoneButtonsModule } from "./Buttons/PhoneButtons.module";
import { PhoneHeaderModule } from './Header/PhoneHeader.module';
import { SideSlideoutModule } from './SideSlideout/SideSlideout.module';

const _PhoneModules: any[] = [
    PhoneButtonsModule, PhoneHeaderModule, SideSlideoutModule
];

@NgModule({
    imports: [
        ..._PhoneModules
    ],
    exports: [
        ..._PhoneModules
    ]
})
export class SharedPhoneDevice { }
