<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div [formGroup]="group" style="width: 100%; display: flex; flex-direction: column;">
    <div style="flex: 1 1 0%;">
        Confirm Login
    </div>
    <div *ngIf="ActionMessage != null" class="mat-body-2" style="padding: 15px 0;">
        {{ActionMessage}}
    </div>
    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>Username</mat-label>
        <input matInput type="search" autocomplete="off" formControlName="Username" />
        <mat-error *ngIf="username.hasError('required')">required</mat-error>
    </mat-form-field>
    <mat-form-field style="flex: 1 1 0%;">
        <mat-label>Code</mat-label>
        <input matInput type="search" autocomplete="off" formControlName="Code" (keyup.enter)="onConfirm()" />
        <mat-error *ngIf="code.hasError('required')">required</mat-error>
    </mat-form-field>

    <label class="mat-caption mat-error" style="padding-bottom: 10px;" *ngIf="_errorMessage">{{_errorMessage}}</label>

    <button mat-flat-button (click)="onConfirm()" color="primary" [disabled]="!group.valid || isBusy">
        Confirm
    </button>

    <div style="padding: 20px 0; display:flex;">
        <span style="text-align: left; flex: 1 1 0%; margin-right: 15px;">
            <a class="link" style="text-align: right;" (click)="onResendCode()">Resend Code</a>
        </span>

        <span style="text-align: right; flex: 1 1 0%;">
            <a class="link" (keyup.enter)="onReturnToLogin()" (click)="onReturnToLogin()" tabindex="0">Return to Login</a>
        </span>
    </div>
</div>
