import { Pipe, PipeTransform } from '@angular/core';
import { EnumHelpers } from 'Shared/Utils/Enums';

@Pipe({
    name: 'BitwiseEnumDescription'
})
export class BitwiseEnumDescriptionPipe implements PipeTransform {
    transform(value: any, enumType: any, descriptionEnum: any): string {
        if (value === null || value === undefined) {
            return "";
        }

        if (value === 0)
            return descriptionEnum[enumType[value]];

        const values = EnumHelpers.getValues(enumType);
        const retStrings = [];
        values.forEach(f => {
            if (f !== 0) {

                if ((value & f) === f)
                    retStrings.push(descriptionEnum[enumType[f]]);
            }
        });

        return retStrings.join(", ");
    }
}

@Pipe({
    name: 'EnumDescription'
})
export class EnumDescriptionPipe implements PipeTransform {
    transform(value: any, enumType: any, descriptionEnum: any): string {
        if (value === null || value === undefined) {
            return "";
        }

        return descriptionEnum[enumType[value]];
    }
}
