import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { matDialogAnimations } from '@angular/material/dialog';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchFilter, SearchFilterValue } from 'Models/Searching/SearchFilter.model';
import { of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ListFilterService } from '../Filters/Services/ListFilter.service';

export class SortFilterEvent {
    SortAscending: boolean;
    Filter: SearchFilter;
    constructor(public Sort: boolean, public FilterValues: SearchFilterValue[]) { }
}

@Component({
    selector: 'iq-column-header',
    templateUrl: './ListColumnHeader.component.html',
    styleUrls: ['./ListColumnHeader.component.scss'],
    animations: [matDialogAnimations.dialogContainer],
    //encapsulation: ViewEncapsulation.None
})
export class ListColumnHeaderComponent implements OnInit, OnDestroy {
    private destroyed$: Subject<void> = new Subject();

    private template: TemplatePortal<any>;

    private overlayRef: OverlayRef;

    aToZChecked: boolean;
    zToAChecked: boolean;
    filtered: boolean;

    clearFilterEvent: Subject<void> = new Subject();

    isOpen: boolean;

    @Input() Title: string;

    //Needs to be the PropertyNames of the SearchColumns
    @Input() requiredColumnPropertyNames: string[] = [];
    @Input() IsRequiredPropertyCheck: (requiredColumns: string[], columnFilter: SearchFilter, column: SearchColumn, currentFilters: SearchFilter[]) => boolean = (requiredColumns: string[], columnFilter: SearchFilter, column: SearchColumn, currentFilters: SearchFilter[]) => {
        if (!requiredColumns || requiredColumns.length === 0)
            return false;

        return requiredColumns.includes(columnFilter.PropertyName) &&
            (currentFilters
                ? !currentFilters.find(fil => requiredColumns.includes(fil.PropertyName) && (fil.PropertyName !== column.filterColumn && !column.OtherFilterColumnNames.includes(fil.PropertyName)))
                : true);
    }

    @ViewChild('actions') public _template: TemplateRef<any>;

    @Input() StoredFiltersKey: number;
    @Input() UseStoredFilters: boolean = true;
    @Input() SearchColumn: SearchColumn;
    filter: SearchFilter;
    filterChanged(newFilter: SearchFilter) {
        this.filter = newFilter;
        this.values = newFilter.Values;
        this.fireChangeEvent();
    }

    isValueRequired: boolean;

    //  Still needed here because it affects the sorting
    SelectItemSearch: boolean;

    @Input() ClearSortAndFilters: Subject<boolean>;
    @Input() isDisabled: boolean;
    
    @Output() change: EventEmitter<SortFilterEvent> = new EventEmitter();

    constructor(private _viewContainerRef: ViewContainerRef, private overlay: Overlay, private parentElem: ElementRef,
        private listFilterService: ListFilterService)
    {
    }

    ngOnInit() {
        if (this.SearchColumn)
            this.SelectItemSearch = coerceBooleanProperty(this.SearchColumn.filterOptions);
        else {
            //  All pages must provide a SearchColumn!
            this.SearchColumn = new SearchColumn(null, this.Title, null, null);
        }

        //Need this to be hot, so we can tell if this is filtered or not.  Has to be here so that if they change the filter on the builder then it will update here properly
        this.listFilterService.GetUserCurrentDisplayedFilters(this.StoredFiltersKey).pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                if (this.UseStoredFilters && this.StoredFiltersKey && val) {
                    if (val.OrderBy) {
                        //  Find the orderBy column using this.SearchColumn.column (not the filter properties!)
                        //  because that's always the property we sort on).  The filter properties may be something like
                        //  TicketType.Name so we would never match on the orderBy property for the "TicketTypeID" column.
                        const orderBy = val.OrderBy.find(f => (f.PropertyName === this.SearchColumn.column));

                        //  Always update these (not sure timeout is needed though) because of the orderBy is not found,
                        //  we need to unset the flags.  Happens if you try to sort on more than 3 columns - we remove
                        //  the oldest so that we don't build up a huge list of orderBy columns.
                        setTimeout(() => {
                            this.aToZChecked = orderBy && !orderBy.Descending;
                            this.zToAChecked = orderBy && orderBy.Descending;
                        });
                    }

                    if (val.Filters) {
                        //Keep the quick search items out of the column search values.
                        //  This is because if we don't do this they can open the column header filter, click clear and clear it for the column,
                        //  but not clear the quick search.And they have to be differnt for ticket searching becuase if you do a quick search for
                        //  a ticket number it needs to disable all other filtering(if that changes then we can possibly change this).
                        const filter = val.Filters.find(f => (f.PropertyName === this.SearchColumn.filterColumn || this.SearchColumn.OtherFilterColumnNames.includes(f.PropertyName))
                            && !f.QuickTextSearch);

                        if (filter) {
                            this.setIsValueRequired(filter, val.Filters);

                            this.filter = filter;
                            this.values = filter.Values;
                            this.filtered = true;
                            return;
                        }
                    }
                }

                this.values = [];
                this.filtered = false;
                this.filter = new SearchFilter(this.SearchColumn.filterColumn, this.SearchColumn.filterOperator, this.values);
                this.setIsValueRequired(this.filter, val ? val.Filters : null);
            });

        if (this.ClearSortAndFilters)
            this.ClearSortAndFilters.pipe(takeUntil(this.destroyed$)).subscribe(val => {
                if (val) {
                    this.ClearSortAndFiltersInternal(false);
                }
            });
    }
    
    ngOnDestroy() {
        if (this.overlayRef)
            this.overlayRef.dispose();

        this.clearFilterEvent.complete();

        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private setIsValueRequired(columnFilter: SearchFilter, currentFilters: SearchFilter[]) {
        this.isValueRequired = this.IsRequiredPropertyCheck(this.requiredColumnPropertyNames, columnFilter, this.SearchColumn, currentFilters);
    }

    private fireChangeEvent() {
        this.filtered = this.values && this.values.length > 0;
        const evt = new SortFilterEvent(this.aToZChecked || this.zToAChecked, this.values);
        evt.SortAscending = this.aToZChecked;
        evt.Filter = this.filter;
        this.change.emit(evt);
    }

    public ClearSortAndFiltersInternal(emitEvent: boolean = true): void {
        if (!this.filtered && !this.aToZChecked && !this.zToAChecked)
            emitEvent = false;

        this.aToZChecked = false;
        this.zToAChecked = false;
        this.filtered = false;

        //  Do not clear items with HideInUI - they are required filter values that the user cannot configure
        if (this.values)
            this.values = this.values.filter(v => v.HideInUI);
        this.filter.Values = this.values;
        this.clearFilterEvent.next();//If this dialog is open then this needs to fire to tell the filter controls to clear
        
        if (emitEvent)
            this.fireChangeEvent();
    }

    @HostListener('click') click() {
        if (this.SearchColumn.filterOptions) {
            //  If we have filterOptions, it's an observable that might require a server call.  We need to resolve this BEFORE we
            //  show the popup or the popup content will be initially empty and cause it to not size correctly.
            //  If filterOptions is an "of", this will just immediately resolve the items and we'll just re-wrap them in another of.
            //  This also has the benefit of caching the filter items the first time and avoiding calling the server again.
            this.SearchColumn.filterOptions.pipe(take(1)).subscribe(items => {
                this.SearchColumn.filterOptions = of(items);
                this.ShowFilterPopup();
            });
        } else
            this.ShowFilterPopup();
    }

    ToggleSortAToZ($event: MatCheckboxChange) {
        this.zToAChecked = false;

        this.fireChangeEvent();
    }
    ToggleSortZToA($event: MatCheckboxChange) {
        this.aToZChecked = false;

        this.fireChangeEvent();
    }

    //  What is the purpose of this?  The SearchFilter we are working with stores this!
    values: SearchFilterValue[] = [];

    private ShowFilterPopup() {
        if (this.isOpen)
            return;     //  User double-clicked the link and we had to make a server call to get the filter items?
        this.isOpen = true;

        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(this.parentElem)
            .withViewportMargin(10)     //  Forces some margin between the top/bottom - otherwise, it may sit right on the top/bottom
            .withPositions([
                {
                    overlayX: 'start',
                    overlayY: 'bottom',
                    originX: 'start',
                    originY: 'bottom'
                },
                {
                    overlayX: 'start',
                    overlayY: 'center',
                    originX: 'start',
                    originY: 'bottom'
                },
                {//Allow the end positions so that large width items will move left
                    overlayX: 'end',//the end (right edge) of overlay
                    overlayY: 'center',
                    originX: 'end',//the end (right edge) of the element linking to
                    originY: 'bottom'
                },
                {
                    overlayX: 'end',
                    overlayY: 'bottom',
                    originX: 'end',
                    originY: 'bottom'
                }
            ]);
        //If we want to offset them then add these back in with the values we want
        //.withDefaultOffsetX(-20)
        //.withDefaultOffsetY(-20)

        const config = new OverlayConfig({
            positionStrategy: positionStrategy,
            hasBackdrop: true
        });

        config.maxHeight = "90%";

        this.overlayRef = this.overlay.create(config);

        this.template = new TemplatePortal(this._template, this._viewContainerRef);
        this.overlayRef.attach(this.template);
        this.overlayRef.backdropClick().subscribe(val => this.detach());
        this.overlayRef.keydownEvents().subscribe(val => {
            if (val.code === 'Escape')
                this.detach();
        });
    }

    /**
     * Detaches the content.
     * @docs-private
     */
    detach() {
        this.isOpen = false;

        this.overlayRef.detach();
        this.overlayRef.dispose();
    }
}
