import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketCancelComponent, TicketCancelData } from '../TicketCancel.component';


@Component({
    templateUrl: './TicketCancelDialog.component.html',
    styleUrls: ['./TicketCancelDialog.component.scss']
})
export class TicketCancelDialogComponent {
    @ViewChild(TicketCancelComponent)
    private _TicketCancelComponent: TicketCancelComponent;

    constructor(private _DialogRef: MatDialogRef<TicketCancelDialogComponent>, @Inject(MAT_DIALOG_DATA) public Data: TicketCancelData) {
    }

    public OnSave(): void {
        var response = this._TicketCancelComponent.OnSave();
        this._DialogRef.close({ TicketNumber: response.TicketNumber, Reason: response.Reason });
    }
}
