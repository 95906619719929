import { Observable } from 'rxjs';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export const REDIRECT_KEY = "authRedirect";

export class PasswordRequirements{
	MinCharacters: number = 8;
	RequireNumbers: boolean = true;
	RequireASpecialCharacter: boolean = true;
	RequireAnUppercaseCharacter: boolean = true;
	RequireALowercaseCharacter: boolean = true;
}

export interface SignInState{
	SignedIn: boolean;
    User: any;

    /** Only applies when the SignedIn value is true.
     *
     *  Will be true if the login returned is fetched from SSO and false if the user provides a Username/Password or uses an external login like Google
     * */
    UsedSso: boolean;

	FederatedLoginReturnState: FederatedLoginState;
}

export interface FederatedLoginState {
	CustomState: any;
	Provider: string;
}

export enum ViewStateEnum{
	signedIn,//User is signed in
	signedOut,//User is signed out.  Should show the login component
	newPasswordRequired,//User requires new password.  Should show new password component
	setupMFA,//Allow setup of Multi-Factor Auth.  May use in the future
	confirmWithCode,//User has been sent an code to confirm (like when creating a new user).  Should show the confirm code component
	resetPassword,//User wants to reset thier password.  Should show the reset password component
	createNewUser//User doesn't exist.  Should should show the create user component
}

export interface ViewState {
	state: ViewStateEnum;
	user: any;
	MessageData: any;//Any message data that needs to be sent to the step.  i.e. After they create a new user we can send the response to inform them to check where the code was sent
}

export interface NewUser {
	username: string;
	password: string;
	email: string;
	email_verified: boolean;
	phone: string;//??Maybe make a number? May not be able to because it needs the country code.
	phone_verified: boolean;
	given_name: string;
	family_name: string;
	preferred_username: string;

	/** These are any custom attributes we want to save. */
	custom: { [key: string]: string };
}

/**
 * Configuration for this control
 */
export class IqAwsCognitoConfig {

	/** Name of the application the default config is used for.  This will be used when alternate configs are available to allow switching back to the default config */
	DefaultAppName: string;

	/** Image to be used at the top of the sign in view when picked to sign in with  */
	DefaultSignInImage: string;

	/** Configuration for the Cognito instace for the site the control is on */
	DefaultCognitoSettings: IqAwsCognitoAmplifyConfig;

	/** Any alternate Cognito configurations to use.  Use this to show a Google sign in as well */
	AlternateCognitoSettings: IqAwsCognitoAllowedCognitoConfiguration[];

	/** Turns on debug messages for sign in */
    Debug: boolean;

    /**
     * Set to a url in the host system that can be called by the UserMigration lambda to check to see if the user is known
     * to the host system.  If so, the user can be migrated in to the user pool (with forgot password initiated).
     */
    FindExistingUserUrl?: string;
}

/** Cognito config for Amplify */
export class IqAwsCognitoAmplifyConfig {
	// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
	//identityPoolId: 'us-east-1:f440b637-0156-4335-89a7-78bc466b416f',

	/** REQUIRED - Amazon Cognito Region */
	region: string;

	// OPTIONAL - Amazon Cognito Federated Identity Pool Region
	// Required only if it's different from Amazon Cognito Region
	//identityPoolRegion: 'XX-XXXX-X',

	/** Amazon Cognito User Pool ID */
	userPoolId: string;

	/** Amazon Cognito Web Client ID (26-char alphanumeric string) */
    userPoolWebClientId: string;

	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	//mandatorySignIn: false,

	//// OPTIONAL - Configuration for cookie storage
	//// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
	//cookieStorage: {
	//    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
	//    domain: '.yourdomain.com',
	//    // OPTIONAL - Cookie path
	//    path: '/',
	//    // OPTIONAL - Cookie expiration in days
	//    expires: 365,
	//    // OPTIONAL - Cookie secure flag
	//    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
	//    secure: true
	//},

	/** OPTIONAL - customized storage object */
	storage: any;

	/**
	 * OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
	 *
	 * For migrations use 'USER_PASSWORD_AUTH'
	 */
	authenticationFlowType: string;//'USER_PASSWORD_AUTH',

	//// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
	//clientMetadata: { myCustomKey: 'myCustomValue' }

	/** Optional - used for external oAuth clients like Google */
	oauth: {
		client_id: string;
		domain: string;
		scope: string[];
		redirectSignIn: string;
		redirectSignOut: string;
		responseType: string;
	};

	/**
	 * Optional - Will make the person re-login when the page is loaded if the refresh token has been stored for this number of days.
	 * This is checked on app load when IqAwsCognitoService.IsSessionValid, and when getting values from any configured single sign on options.
	 * This is a preventative feature to try an not have the user get logged out in the middle of something.  This should be less than the configured
	 * days in a AWS Cognito user pool app client
	 */
	RefreshTokenExiprationMinutes: number;
}

//Need these to completely flip the Auth.configuration to use which one they picked or are logged in with
/** Alternate Cognito configuration values that this control can use to login */
export class IqAwsCognitoAllowedCognitoConfiguration {

	/** Name of the application the config is used for */
	Name: string;

	/** Image to be used at the top of the sign in view when picked to sign in with  */
	SignInImage: string;

	/**
	 * The full configuration for the app pool
	 */
	Configuration: IqAwsCognitoAmplifyConfig;

	/**
	 * Use this if the configuration is for a Federated Login provider in Cognito for Google.  A sign in image is not needed and will be ignored.
	 */
	IsGoogle: boolean;//Maybe make this more generic and pass in the provider instead of flags...May not be needed but depends on what all we allow.

    /**
     *  True if this is an alternate login provider (app client) to the main app client.
     */
    IsAlternate: boolean;

	/**
	 * If we should show a button on the login screen or not.
	 * This is helpful so we can allow the user to connect an alternate config, but not login with it yet.
	*/
	ShowOnLogin: boolean;

	RedirectUrl: string;
}

/** Use this interface to create a service to store the info for single sign on. */
export abstract class SingleSignOnService {
	/**Write data to the Single Sign On storage */
	abstract StoreData(data:SingleSignOnStoreData): Observable<boolean>;
	/**Read data from the Single Sign On storage */
	abstract ReadData(clientId: string): Observable<SingleSignOnReadData>;
	/**Clear data in the Single Sign On storage */
	abstract ClearData(clientId: string): Observable<boolean>;
}
export interface SingleSignOnStoreData{
	/** userPoolWebClientId used to find the data for reads and clears  */
	clientId: string;
	/** username used to build the user */
	username: string;
	/** refresh token used to get user data */
	token: string;
	/** OPTIONAL - If the config is set to store the time the refresh token was created (RefreshTokenExiprationMinutes != null) this should have a value so
	 * that other apps that pull this data will know when to get a new refresh token
	 */
	tokenExpirationDate: string;
}
export interface SingleSignOnReadData {
	/** username used to build the user */
	username: string;
	/** refresh token used to get user data */
	token: string;
	/** OPTIONAL - If the config is set to store the time the refresh token was created (RefreshTokenExiprationMinutes != null) this should have a value so
	 * that other apps that pull this data will know when to get a new refresh token
	 */
	tokenExpirationDate: string;
}

