import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GeneralBusyComponent } from 'Shared/Components/Indicators/GeneralBusy/GeneralBusy.component';
import { BusyComponent } from 'Shared/Components/Indicators/Busy/Busy.component';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule
    ],
    exports: [
        GeneralBusyComponent,
        BusyComponent,
    ],
    declarations: [
        GeneralBusyComponent,
        BusyComponent,
    ]
})
export class BusyIndicatorModule { }
