import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentLookup } from 'Services/ComponentLookup.service';
import { ICustomResponseDataComponent } from "../ICustomResponseDataComponent";
import { IIN811ResponseCode3Cand3GSharedDataFormGroup, IN811_ResponseCode3Cand3GSharedComponent } from './IN811_ResponseCode3Cand3GShared.component';

interface IIN811ResponseCode3GDataFormGroup extends IIN811ResponseCode3Cand3GSharedDataFormGroup {
    DescriptionOfMarkedArea: FormControl<string>;
}

@ComponentLookup('IN811-Response-Code-3G')
@Component({
    templateUrl: './IN811_ResponseCode3G.component.html',
    styleUrls: ['./IN811_ResponseCode3G.component.scss']
})
export class IN811_ResponseCode3GComponent implements ICustomResponseDataComponent {

    public FormGroup: FormGroup<IIN811ResponseCode3GDataFormGroup>;

    constructor() {
        this.BuildFormGroup();
    }

    private BuildFormGroup(): void {
        this.FormGroup = new FormGroup<IIN811ResponseCode3GDataFormGroup>({
            ...IN811_ResponseCode3Cand3GSharedComponent.CreateFormGroup(),
            DescriptionOfMarkedArea: new FormControl<string>(null, [Validators.required, Validators.maxLength(500)]),
        });
    }

    public IsValid(): boolean {
        return this.FormGroup?.valid ?? false;
    }

    public GetData(): object {
        const data = this.FormGroup.getRawValue();
        IN811_ResponseCode3Cand3GSharedComponent.FormatDates(data);     //  Needed to fix the date field format so it's not in UTC time
        return data;
    }
}
