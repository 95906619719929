import { Component, Input } from '@angular/core';
import { ExcavatorContactAutocompleteOptionBase } from "./ExcavatorContactAutocompleteOptionBase.component";

@Component({
    selector: 'iq-excavator-contact-autocomplete-option',
    templateUrl: './ExcavatorContactAutocompleteOption.component.html',
    styleUrls: ['./ExcavatorContactAutocompleteOption.component.scss']
})
export class ExcavatorContactAutocompleteOptionComponent extends ExcavatorContactAutocompleteOptionBase {
    @Input("ShowOfficePhone")
    public ShowOfficePhone: boolean = false;
}
