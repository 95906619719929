import { GeocodeTypeEnum } from "Enums/GeocodeType.enum";
import { LocationValidationError } from "./LocationValidationError.model";

export class GeocodeResponse {
    public WarningMessage?: string;

    /// <summary>
    /// If true, the warning message must be confirmed by the user - the dismiss timeout is disabled.
    /// </summary>
    public WarningMustBeAcknowledged: boolean;

    public ClippedByCounty?: boolean;
    public ClippedByPlace?: boolean;

    //  Indicates that this GeocodeResponse is a previous/saved response
    public IsPreviousResult: boolean = false;

    //  These are only returned for a lat/lon geocode if the place was not given.  Allows the UI to populate them
    //  based on the coordinate.
    public State: string;
    public County: string;
    public Place: string;

    /**
     *  True if the buffered geometry intersects a railroad.
     *  Only set if ICenterBiz.CalculateNearRailroad is set to true.
     */
    public NearRailroad?: boolean;

    public ValidationErrors: LocationValidationError[];

    //  Set to true if we set the last response to the values on a ticket.  Then when this is true, if we skip geocoding the dig site
    //  (in order to use the last response), this flag will force refreshing the ValidationErrors.  This allows us to keep the dig site on
    //  the ticket but still get fresh validation errors.
    public RefreshValidationErrors: boolean;

    constructor(public GeocodeType: GeocodeTypeEnum, public GeometryJson: object, public BufferFt: number, public UnbufferedGeometryJson: object) {
    }

    public Clone(): GeocodeResponse {
        const response = new GeocodeResponse(this.GeocodeType, this.GeometryJson, this.BufferFt, this.UnbufferedGeometryJson);

        //  Also copy any values that are NOT returned by /Maps/Geocoder/ValidateDigSite
        response.WarningMessage = this.WarningMessage;
        response.ClippedByCounty = this.ClippedByCounty;
        response.ClippedByPlace = this.ClippedByPlace;
        response.State = this.State;
        response.County = this.County;
        response.Place = this.Place;
        response.NearRailroad = this.NearRailroad;
        response.ValidationErrors = this.ValidationErrors;

        return response;
    }
}
