import { EntityEnum } from 'Enums/EntityType.enum';

export class EntityPropertyUpdateTaskParams {

    //  This tells Json.Net how to deserialize this object in to the correct type.
    //  It *MUST* be the very first property in the json so it must be listed first and we cannot define
    //  the rest of the properties as public inside the constructor arguments (or they will preceed the $type property)
    public $type: string = "Tix.ApiModels.ScheduledTasks.TaskParams.EntityPropertyUpdateTaskParams, Tix.ApiModels";

    public EntityType: EntityEnum;
    public PropertyName: string;
    public PropertyValue: any;
    public DisplayValue: string;

    constructor(entityType: EntityEnum, propertyName: string, propertyValue: any, displayValue: string) {
        this.EntityType = entityType;
        this.PropertyName = propertyName;
        this.PropertyValue = propertyValue;
        this.DisplayValue = displayValue;
    }
}
