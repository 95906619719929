import { Injectable, OnDestroy } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

//  Add this to the providers configuration for a derived class:
//        { provide: EntryFieldComponentBase, useExisting: forwardRef(() => EntryInputFieldValueAutocompleteComponent) }
@Injectable()
export abstract class EntryFieldComponentBase implements ControlValueAccessor, OnDestroy {

    protected Destroyed: Subject<void> = new Subject();

    constructor() {
    }

    ngOnDestroy() {
        this.Destroyed.next();
        this.Destroyed.complete();
    }

    public abstract Focus(): void;

    writeValue(value: any) {
    }

    registerOnChange(fn) {
    }

    registerOnTouched() {
    }

    setDisabledState?(isDisabled: boolean): void {
    }
}
