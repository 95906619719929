import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PhonePipe } from '../Phone/phone.pipe';
import { YesNoFormatPipe } from '../yesNo.pipe';
import { EntityTypeEnumPipe } from 'Shared/Pipes/Enum/EntityTypeEnum.pipe';

@Pipe({
    name: 'dynamicFormat',
    
})
export class DynamicFormatPipe implements PipeTransform {

    public transform(value: any, formatType: string, format: string): any {
        if (!Array.isArray(value))
            return this.TransformSingleValue(value, formatType, format);

        //  value is an array.  Format each item in the array and return a comma (and newline) separated list of all non-empty values.
        let items = value
            .map(v => this.TransformSingleValue(v, formatType, format))
            .filter(v => v)
            .sort();
        return items.join(',\r\n');     //  for \r\n to actually add the new line, element also needs: white-space: pre-line
    }

    private TransformSingleValue(value: any, formatType: string, format: string): any {
        //Have to check for null on value because the 'yesNo' format will be booleans
        if (value === null || value === '' || value === undefined || !formatType || formatType === '')
            return value;

        switch (formatType) {
            case 'date':
                var datePipe = new DatePipe("en-US");
                return datePipe.transform(value, format);
            case 'phone':
                const phonePipe = new PhonePipe();
                return phonePipe.transform(value);
            case 'yesNo':
                const yesNoPipe = new YesNoFormatPipe();
                return yesNoPipe.transform(value);
            case 'entityType':
                const entityTypeEnumPipe = new EntityTypeEnumPipe();
                return entityTypeEnumPipe.transform(value);
            default:
                return value;
        }
    }
}
