<div class="iq-list-item">
    <div class="iq-item-row iq-body-2 iq-item-details" [ngClass]="{'pl10': !hasMenu}">
        <ng-container *ngIf="hasMenu === true; then withMenu;">
            <!--Outlet for the templates.  This way seems to make it less jumpy than doing inline html here-->
        </ng-container>
        <ng-content></ng-content>
    </div>
    <div class="menu-container iq-body-2" *ngIf="showMenu">
        <div style="flex-grow:1">
            <div *ngIf="hasMenu" class="show-hide-menu"></div>
            <ng-content select="iq-list-menu"></ng-content>
        </div>
    </div>

</div>

<ng-template #withMenu>
    <div class="show-hide-menu">
        <span *ngIf="!showMenu" class="link" (click)="ToggleShowMore()">More</span>
        <span *ngIf="showMenu" class="link" (click)="ToggleShowMore()">Less</span>
    </div>
</ng-template>

