<!-- Don't use the section group stuff here when all we need is the header and border styles!
     Using that makes it impossible to allow the content to grow to the parent height.  And none of
     the edit/view stuff that it provides is needed here!  -->
<div style="display:flex; flex-direction:column; height:100%; overflow:hidden">
    <div class="iq-display-2 primary-color iq-primary-bottom-border" style="">Announcements</div>
    <iq-carousel class="view" [autoMoveNext]="15000" style="overflow-x: auto; cursor: auto">
        <ng-container *ngFor="let item of items">
            <ng-template carouselItem>
                <div [innerHtml]="item.Html | trustHtml"></div>
            </ng-template>
        </ng-container>
    </iq-carousel>
</div>
