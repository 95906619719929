import { NgModule } from '@angular/core';
import { IqAwsCognitoModule } from 'iqCognito/iq-aws-cognito.module';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { ExactixAdminCreateLoginDialogComponent } from './AdminCreateLogin/AdminCreateLoginDialog.component';
import { AdminSetPasswordDialogComponent } from "./AdminSetPassword/AdminSetPasswordDialog.component";
import { PasswordPromptComponent } from "./PasswordPrompt/PasswordPrompt.component";

const _Dialogs: any[] = [
    ExactixAdminCreateLoginDialogComponent,
    AdminSetPasswordDialogComponent
];

const _Components: any[] = [
    PasswordPromptComponent
];

@NgModule({
    imports: [
        GlobalSharedModule,
        IqAwsCognitoModule //  TODO: Remove this dependency
    ],
    declarations: [
        ..._Dialogs,
        ..._Components
    ],
    exports: []
})
export class AdminLoginControls { }
