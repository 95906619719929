import { Registration } from "./Registration.model";

export class RegistrationActionRequest {

    public RegistrationID: string;
    public xmin: number;                //  Registration.xmin used to check to see if record was changed by another user

    constructor(registration: Registration)
    {
        this.RegistrationID = registration.ID;
        this.xmin = registration.xmin;
    }
}

