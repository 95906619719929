//These are used to set the title for the browser properly.  If you choose name or code or firstlastname it will use those properties of the object in the title.
//  If you do static you must also supply a 'title' property of the title you want
export enum NavigationTypes {
    Name,
    Code,
    FirstLastName,
    Static,

    //  Also set "title" to text value to display and "titleProperty" to the name of a property in the entity to display.
    //  If need a value from a nested object, set titleProperty to an array of property names like this: ["Ticket", "TicketNumber"]
    //  which will return the value of: event.item.Ticket.TicketNumber
    //  Formatted as [title] [prop]
    Property,

    //  Special handling for showing the TicketNumber of a ticket.  See handling in RoutingService for details.
    TicketNumber
}
