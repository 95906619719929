import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { MainMenuService } from 'Services/MainMenuService';

@Component({
  selector: 'google-response',
    templateUrl: './GoogleResponsePage.component.html',
    styleUrls: ['./GoogleResponsePage.component.scss']
})
export class GoogleResponseComponent implements OnDestroy {

    public ShowPleaseWait: boolean = true;

    public Error: string = null;
    public ErrorDescription: string = null;

    constructor(private mainMenuService: MainMenuService, activatedRoute: ActivatedRoute) {
        mainMenuService.forceClose = true;

        activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
            if (params.error) {
                //  Our Cognito user pool is configured to require the first and last name.  And there is no way to undo that
                //  without creating an entirely new user pool.  Also, Cognito does not fire any lambda triggers during a Google/Federated
                //  login so there is no way for us to provide default values!
                //  The only thing we can do is show the user why it failed and give them instructions on how to fix it.
                //  Issues have been created about this which have not been resolved (and these seem to be in an archived github repository so probably never will):
                //      https://github.com/amazon-archives/amazon-cognito-auth-js/issues/54
                //      https://github.com/amazon-archives/amazon-cognito-auth-js/issues/222
                //  Could not find an issue in the active github repository: https://github.com/aws-amplify/amplify-js/issues
                //  ** Also, when an error is returned, it's going to trigger several javascript errors in Amplify that I could not find a way to suppress.
                //     They do not handle errors in there at all - it blows up because of the error and then twice
                //     with "Cannot read property 'accessToken' of undefined" errors.
                //     Those will get logged in our system error log so there will be info about this there too.
                this.ShowPleaseWait = false;
                this.Error = params.error;
                this.ErrorDescription = params.error_description;
            }
        });
    }

    ngOnDestroy() {
        this.mainMenuService.forceClose = false;
    }
}
