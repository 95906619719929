export enum MessageStatusEnum {
    Queued = 0,
    Delivered = 1,
    Failed = 2,
    Canceled = 3,
    Redirected = 4,
    Blacklisted = 5,
    PendingConfirmation = 6,    //  Message was successfully sent to an external system and we are now waiting for delivery confirmation/status.
}

export enum MessageStatusEnumDescriptions {
    Queued = "Queued",
    Delivered = "Delivered",
    Failed = "Failed",
    Canceled = "Canceled",
    Redirected = "Redirected",
    Blacklisted = "Blacklisted",
    PendingConfirmation = "Pending Confirmation"
}
