import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesNoFormat',
    
})
export class YesNoFormatPipe implements PipeTransform {

    transform(value: any): any {
       if (value == null || value == undefined) {
            value = false;
        }

        return value === true ? 'Yes' : 'No';
    }

}
