import { SideOfStreetTypeEnum } from "Enums/SideOfStreetType.enum";

//  Some centers enter the near street as text (which we then need to parse into components in order
//  to show it on the map and calculate the distance) and some enter it as the street components.
export class TicketNearStreet {
    public Order: number;

    public NearStreetText?: string;

    public GeometryJson?: object;
    public UnbufferedGeometryJson?: object;
    public DistanceFt?: number;

    public Prefix?: string;
    public Name?: string;
    public StreetType?: string;
    public Suffix?: string;

    public SideOfStreet?: SideOfStreetTypeEnum;

    public WithinQuarterMile?: boolean;
    public DistanceFromNearStreet?: string;
}
