<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Create new Login
</h2>
<mat-dialog-content style="display: flex; flex-direction: column; overflow: auto;" class="mat-typography">
    <div *ngIf="SettingsService.AllowApiAccessOnlyLogins" style="padding-bottom:20px; display:flex">
        <mat-checkbox color="primary" [checked]="ApiAccessOnly" labelPosition="after" (change)="ApiAccessOnlyChanged()">API Access Only</mat-checkbox>
        <div *ngIf="ApiAccessOnly" style="color:red; padding-left:4em; font-size:smaller"><i>* This login will only be usable by API methods that require a username/password.</i></div>
    </div>

    <ng-container *ngIf="!ApiAccessOnly">
        <mat-form-field>
            <mat-label>Email Address</mat-label>
            <input matInput LowerCase [formControl]="EmailFormControl" autocomplete="off" type="email"
                   inputmode="email" iqFieldLength="Email_EmailAddress" />
            <mat-error iqValidationMessage [control]="EmailFormControl"></mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="ShowVerifyEmail" style="bottom: 10px">
            <mat-label>Confirm Email Address</mat-label>
            <input matInput LowerCase [formControl]="ConfirmEmailFormControl" autocomplete="off" type="email"
                   inputmode="email" iqFieldLength="Email_EmailAddress" />
            <mat-error iqValidationMessage [control]="ConfirmEmailFormControl"></mat-error>
        </mat-form-field>
    </ng-container>

    <div style="display:flex; flex-direction:column; padding-bottom:20px">
        <mat-checkbox *ngIf="!ApiAccessOnly" color="primary" [checked]="UsernameSameAsEmail" (change)="UsernameSameAsEmailChanged()">My email will be my username</mat-checkbox>
        <mat-form-field *ngIf="ApiAccessOnly || !UsernameSameAsEmail" style="padding-top:10px">
            <mat-label>Username</mat-label>
            <input iqDisableAutofill matInput type="text" [formControl]="UsernameFormControl" />
            <mat-error iqValidationMessage [control]="UsernameFormControl"></mat-error>
        </mat-form-field>
    </div>

    <div style="display:flex">
        <mat-form-field style="flex-basis:50%; padding-right:1em">
            <mat-label>First Name</mat-label>
            <input iqDisableAutofill matInput type="text" [formControl]="FirstNameFormControl" />
            <mat-error iqValidationMessage [control]="FirstNameFormControl"></mat-error>
        </mat-form-field>
        <mat-form-field style="flex-basis:50%; padding-left:1em">
            <mat-label>Last Name</mat-label>
            <input iqDisableAutofill matInput type="text" [formControl]="LastNameFormControl" />
            <mat-error iqValidationMessage [control]="LastNameFormControl"></mat-error>
        </mat-form-field>
    </div>

    <iq-password-prompt [PasswordFormControl]="PasswordFormControl" [PasswordRequirements]="PasswordRequirements"></iq-password-prompt>
    <div *ngIf="!ApiAccessOnly" style="color:red; font-size:smaller">
        <i>
            * This will set a temporary password which will be emailed to the user.  The user will need to use this password to login and will then
            be prompted to change their password.
        </i>
    </div>

</mat-dialog-content>

<mat-dialog-actions style="flex-direction:column; align-items:initial">
    <div class="mat-caption" style="color: red; align-self:center; padding-bottom:10px">{{ErrorMessage}}</div>
    <div style="display:flex; justify-content:space-evenly">
        <iq-icon-button button-class="Dialog" icon="Cancel" mat-dialog-close>Cancel</iq-icon-button>
        <iq-icon-button button-class="Dialog" icon="Save" [disabled]="!FormGroup.valid || Saving" (click)="CreateLogin()">Create Login</iq-icon-button>
    </div>
</mat-dialog-actions>
