import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { IEntity } from '../../../../Models/Interfaces/IEntity.interface';
import { TicketListItemViewEnum } from "../Models/TicketListItemViewEnum";
import { Router } from '@angular/router';

@Component({
    selector: 'ticket-list-item',
    templateUrl: './TicketListItemView.component.html',
    styleUrls: ['./TicketListItemView.component.scss']
})
export class TicketListItemViewComponent {

    @Input()
    public View: TicketListItemViewEnum;

    @Input()
    public Item: any;

    @Input()
    public SearchColumns: SearchColumn[];

    @Input()
    public MultiSelectMode: boolean;

    @Output()
    public ToggleMultiSelectMode = new EventEmitter<boolean>();

    @Output()
    public ToggleSelectedItem = new EventEmitter<IEntity>();

    constructor(protected Router: Router) {

    }

    public TicketListItemViewEnum = TicketListItemViewEnum;

    public ToggleSelected(selected: boolean) {
        this.Item.Selected = selected;
        this.ToggleSelectedItem.emit(this.Item);
    }

    public ToggleMultiSelect(enabled: boolean): void {

        setTimeout(() =>
        {
            this.ToggleMultiSelectMode.emit(enabled);
            this.ToggleSelected(!this.Item.Selected);
        });
    }

    public TicketClicked() {
        if (this.MultiSelectMode) 
            this.ToggleSelected(!this.Item.Selected);
        else 
            this.Router.navigate(['/tickets/view/' + this.Item.ID]);
    }
}
