
export class StreetVerifyRequest {

    public State?: string;

    public CountyName?: string;

    public PlaceName?: string;

    public EnteredStreet: string;
}
