<div *ngIf="Visible" class="iq-section-group" role="region">
    <div #focusDiv tabindex="{{tabIndex}}" (focus)="OnFocus()" (blur)="OnBlur()" (keydown.shift.tab)="HeaderShiftTab()" (keyup.enter)="OnClicked(null)" style="height:0px; width:0px;"></div>
    <div *ngIf="HeaderText" class="header" [ngClass]="{'edit': _edit === true, 'focused':headerFocused}" (click)="OnClicked($event)">
        <span class="header-text iq-display-2 primary-color">{{HeaderText}}</span>
        <div *ngIf="CommandLink && CommandLinkAllowed" class="link" (click)="OnCommandLinkClicked($event)">{{CommandLink}}</div>
        <div *ngIf="HelpLink && ShowHelp" class="link" (click)="OnShowHelpLinkClicked($event)" style="padding-left:2em">{{HelpLink}}</div>
        <div *ngIf="!HideOpenClose && EditAllowed && !_edit && (ViewAllowed !== false)" class="link" style="padding-left:2em">change</div>
        <div *ngIf="!HideOpenClose && EditAllowed && _edit && (ViewAllowed !== false)" class="link" style="padding-left:2em">close</div>
        <!--<div *ngIf="(mouseIn || headerFocused) && EditAllowed" class="header-action-link">
        <span *ngIf="!_edit">change</span>
        <span *ngIf="_edit">close</span>
    </div>-->
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
</div>
