import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from 'Shared/Utils/StringUtils';

@Pipe({
    name: 'nameAddressFormat',
    
})
export class NameAndAddressPipe implements PipeTransform {

    transform(value: any): any {
        if (value == null) {
            return value;
        }

        let text = '';

        text = StringUtils.AppendToString(text, value.FirstName, null);
        text = StringUtils.AppendToString(text, value.LastName, ' ');

        text = StringUtils.AppendToString(text, value.Address1, ', ');
        text = StringUtils.AppendToString(text, value.City, ', ');
        text = StringUtils.AppendToString(text, value.State, ', ');
        text = StringUtils.AppendToString(text, value.Zip, ' ');

        return text;
    }

}
