import { EntityBase } from "Models/Base/EntityBase.model";
import { StringUtils } from "Shared/Utils/StringUtils";
import { AddressTypeEnum } from "Enums/AddressType.enum";
import { SelectOption } from "@iqModels/Configuration/SelectOption.model";

export class Address extends EntityBase {

    //  For one-to-many (an entity has multiple addresses), specify the join here.
    //  It is only valid for *ONE* of these to be set because an address belongs to 1 and only 1 entity.
    //  ** It is also possible to directly reference an AddressID as a property on an entity if that
    //  entity only supports 1 and only 1 address.  In that case, all of these properties will be null.
    ExcavatorCompanyID: string;
    MemberID: string;
    PersonID: string;


    public AddressType: SelectOption = new SelectOption(AddressTypeEnum.Corporate, AddressTypeEnum.Corporate.toString());
    public County: string = null;
    public Reference: string = null;
    public Attention: string = null;

    Editing: boolean;

    constructor(public ID: string = null, public Address1: string = null,
        public Address2: string = null,
        public City: string = null,
        public State: string = null,
        public Zip: string = null) {

        super(ID);
    }

    //  Returns a new Address object initialized with the values from the given address.
    //  This is used when we receive an "address" from the api and need to store it into a full Address object
    //  (with all defined functions - which will not happen for a plan api response).
    public static Copy(a: Address): Address {
        let newAddr = new Address(a.ID, a.Address1, a.Address2, a.City, a.State, a.Zip);
        newAddr.County = a.County;

        return newAddr;
    }

    /**
     * Formats the address into a single string (for display as a single line).
     * Static because the object doesn't seem to be created correctly when received from api
     * (even when it's typed to this object).
     */
    public static FormatAddress(a: Address): string {
        if (!a)
            return "";

        let s: string = "";

        s = StringUtils.AppendToString(s, a.Address1, null);
        s = StringUtils.AppendToString(s, a.Address2, ", ");
        s = StringUtils.AppendToString(s, a.City, ", ");
        s = StringUtils.AppendToString(s, a.State, ", ");
        s = StringUtils.AppendToString(s, a.Zip, ", ");
        s = StringUtils.AppendToString(s, a.County, ", ");

        return s;
    }

    public IsEqual(address1: string, address2: string, city: string, state: string, zip: string, county: string): boolean {
        return StringUtils.IsEqual(this.Address1, address1) &&
            StringUtils.IsEqual(this.Address2, address2) &&
            StringUtils.IsEqual(this.City, city) &&
            StringUtils.IsEqual(this.State, state) &&
            StringUtils.IsEqual(this.Zip, zip) &&
            StringUtils.IsEqual(this.County, county);
    }
}

