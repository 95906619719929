import { AfterViewInit, ContentChild, Output, EventEmitter, Directive } from '@angular/core';
import { ListMenuComponent } from '../../Menu/ListMenu.component';

@Directive()
export class BaseListItem implements AfterViewInit {

    @Output() ShowMore: EventEmitter<boolean> = new EventEmitter()

    @ContentChild(ListMenuComponent) private menu: ListMenuComponent;
    hasMenu: boolean;

    showMenu: boolean;
    ToggleShowMore() {
        this.showMenu = !this.showMenu;

        //Emit either way incase ther eis logic needed for both showing and hiding
        this.ShowMore.emit(this.showMenu);
    }

    ngAfterViewInit(): void {
        //This seems to keep the screen form jumping, where as the setTimeout will display stuff, then do the logic to determine if the 'more' should be shown and makes it look 'jumpy'
        Promise.resolve(null).then(() => this.hasMenu = this.menu ? true : false);
    }
}
