import { Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { IQ_FLYOUT_DATA, IQ_FLYOUT_REF } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { SideSlideoutRef } from 'Shared/PhoneDevice/SideSlideout/SideSlideoutRef';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { ManuallyAddServiceAreaData } from '../Dialog/ManuallyAddServiceAreaDialog.component';
import { ManuallyAddServiceAreaComponent } from '../ManuallyAddServiceArea.component';

@Component({
    templateUrl: './ManuallyAddServiceAreaFlyout.component.html',
    styleUrls: ['./ManuallyAddServiceAreaFlyout.component.scss'],
    animations: [slideInFromRight]
})

export class ManuallyAddServiceAreaFlyoutComponent {
    //  Defining the animation on the HostBinding like this is cleaner and causes it to do the close animation automatically.
    @HostBinding('@slideInFromRight') animation = 'enter';

    @ViewChild(ManuallyAddServiceAreaComponent)
    private _ManuallyAddServiceAreaComponent: ManuallyAddServiceAreaComponent;

    public AddButtonText: string;

    constructor(@Inject(IQ_FLYOUT_DATA) public ComponentData: ManuallyAddServiceAreaData, @Inject(IQ_FLYOUT_REF) private _SideSlideoutRef: SideSlideoutRef) {
        this.AddButtonText = ComponentData.AddButtonText;
    }

    public Close() {
        this._SideSlideoutRef.close();
    }

    public AddToTicket(): void {
        this._SideSlideoutRef.close(this._ManuallyAddServiceAreaComponent.SelectedServiceAreas);
    }

}
