
export class DateUtils {

    public static IsValidDate(date: Date): boolean {
        if (!date)
            return false;

        const year = date.getFullYear();
        return (!isNaN(year) && (year > 2000));     //  2000 is sanity check - because new Date(null) returns 12/31/1969!
    }

    public static ConvertToString(date: Date, nullIfInvalid: boolean = false): string {
        //  Want format like this: 2018-01-19T07:00:00
        //  date.getMonth() is 0 based!
        if (nullIfInvalid && !this.IsValidDate(date))
            return null;

        return date.getFullYear().toString().padStart(4, "0").toString() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0") + 'T' +
            date.getHours().toString().padStart(2, "0") + ':' + date.getMinutes().toString().padStart(2, "0") + ':00';
    }

    //  Using the browser to do this conversion from String -> Date may or may not work correctly.
    //  Some browsers (Chrome) will translate a date/time without an offset in to local time.  Other browsers (Safari)
    //  will translate in to UTC time if the offset is not provided.
    //  This method will translate our string that is formatted as 2018-01-19T07:00:00 to a
    //  Date in the local timezone.  If the string is not in that format (not 19 characters), it will default to using
    //  the browser (and as long as that includes the utc offset, it will translate correctly).
    //  https://medium.com/@toastui/handling-time-zone-in-javascript-547e67aa842d
    public static ConvertFromString(dateAsString: string): Date {
        //  Requires a string formatted as 2018-01-19T07:00:00.
        //  If not the right number of characters, will just use Date.parse() and hope for the best...
        if (!dateAsString)
            return new Date();
        if (dateAsString.length !== 19) {
            //  new Date() is *ONLY* safe to use if it's formatted using ISO 8601 date format.
            //  which is like this: yyyy-mm-ddThh:mm:ss-04:00
            //  Safari is *VERY* strict about that.  If the utc offset is not provided, it assumes utc which
            //  will jack up the date.  Chrome parses a string without the offset as local time.
            return new Date(dateAsString);  //  Uses Date.parse() internally
        }

        const year = parseInt(dateAsString.substr(0, 4));
        const month = parseInt(dateAsString.substr(5, 2))-1;      //  month is zero based!
        const date = parseInt(dateAsString.substr(8, 2));
        const hours = parseInt(dateAsString.substr(11, 2));
        const minutes = parseInt(dateAsString.substr(14, 2));
        const seconds = parseInt(dateAsString.substr(17, 2));

        return new Date(year, month, date, hours, minutes, seconds);
    }
}
