import { Injectable } from '@angular/core';
import { CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { Observable, of } from 'rxjs';
import { SelfRegistrationConfigurationResponse } from 'Models/SelfRegistration/SelfRegistrationConfigurationResponse.model';
import { LoginPageOptions } from "Models/Configuration/LoginPageOptions.model";

@Injectable({
    providedIn: 'root'
})
export class UserRegistrationService {
    private _LoginPageOptions: LoginPageOptions = null;
    private _SelfRegistrationConfigs: SelfRegistrationConfigurationResponse[];

    constructor(private _Services: CRUDServices) {
    }

    public LoginPageOptions(): Observable<LoginPageOptions> {
        if (this._LoginPageOptions!== null)
            return of(this._LoginPageOptions);

        return new Observable<LoginPageOptions>(observer => {
            this._Services.http.get<LoginPageOptions>(this._Services.settingsService.ApiBaseUrl + "/Administration/OneCallCenter/LoginPageOptions")
                .subscribe(options => {
                    this._LoginPageOptions = options;

                    observer.next(options);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public SelfRegistrationConfiguration(): Observable<SelfRegistrationConfigurationResponse[]> {
        if (this._SelfRegistrationConfigs)
            return of(this._SelfRegistrationConfigs);

        //  Don't have data cached yet - fetch it now
        return new Observable<SelfRegistrationConfigurationResponse[]>(observer => {
            this._Services.http.get<SelfRegistrationConfigurationResponse[]>(this._Services.settingsService.ApiBaseUrl + "/Administration/OneCallCenter/SelfRegistrationConfiguration")
                .subscribe(data => {
                    this._SelfRegistrationConfigs = data;

                    //  Don't need to make a copy - these can't be modified and if we refresh, we set the cached property to null to force a new download
                    observer.next(data);
                    observer.complete();
                }, err => {
                    observer.error(err);
                    observer.complete();
                });
            });
    }
}
