export enum GeocodeTypeEnum {
    //  Ordered by largest/least defined to smallest/most defined
    NA = 0,
    County = 1,
    Place = 2,

    Street = 10,
    Address = 11,
    Parcel = 12,
    AddressPoint = 13,

    Intersection = 20,

    BetweenIntersection = 30,

    BoundedBy = 40,

    LatLonCoordinate = 50,

    Manual = 100
}

export enum GeocodeTypeEnumDescriptions {
    NA = "N/A",
    County = "County",
    Place = "Place",

    Street = "Street",
    Address = "Address",
    Parcel = "Parcel",
    AddressPoint = "Address Point",

    Intersection = "Intersection",

    BetweenIntersection = "Between Intersection",

    BoundedBy = "Bounded By",

    LatLonCoordinate = "Lat/Lon Coordinate",

    Manual = "Manual"
}
