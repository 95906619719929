import { Injectable } from '@angular/core';
import { DisclaimerTypeEnum } from 'Enums/DisclaimerType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { Disclaimer } from 'Models/Configuration/Disclaimer.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { SearchResponse } from 'Models/Searching/SearchResponse.model';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';

@Injectable({
    providedIn: 'root'
})
export class DisclaimerService extends CRUDBaseService<Disclaimer> {

    ViewPermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_View;
    EditPermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_Edit;
    CreatePermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_Create;
    DeletePermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_Delete;

    protected apiPath: string = 'Config/Disclaimers';

    constructor(protected services: CRUDServices) {
        super(services)
    }

    public GetList(searchRequest: SearchRequest, keepListOfIDsForNextPreviousFunctionality: boolean = true): Observable<SearchResponse> {
        throw "Not Used";
    }

    public GetAll(): Observable<Disclaimer[]> {
        return this.CanPerformAction('View').pipe(mergeMap(allowed => {
            if (!allowed)
                return of();
            return this.services.http.get<Disclaimer[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath);
        }));
    }

    public DisclaimerForType(type: DisclaimerTypeEnum): Observable<string> {
        const url = this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/DisclaimerForType/" + type;

        return this.services.http.get<string>(url);
    }
}
