<busy-overlay [show]="isBusy" setParentPosition="false"></busy-overlay>
<div [formGroup]="group" style="width: 100%; display: flex; flex-direction: column;" [@simpleFadeAnimation] *ngIf="!switchingViews">
    <mat-form-field style="padding-bottom:10px; flex: 1 1 0%;">
        <mat-label>Username</mat-label>
        <input matInput #usernameInput type="text" formControlName="Username" (keyup.enter)="trySignIn()" />
        <mat-error iq-aws-cognito-validator [control]="username"></mat-error>
        <mat-hint align="end">* typically, your email address</mat-hint>
    </mat-form-field>

    <iq-aws-cognito-password-input style="flex: 1 1 0%;" #passwordInput [FormControl]="password" (keyup.enter)="trySignIn()"></iq-aws-cognito-password-input>

    <label class="mat-caption mat-error" style="padding-bottom: 10px; flex: 1 1 0%;" *ngIf="_errorMessage">{{_errorMessage}}</label>

    <button mat-flat-button (click)="trySignIn()" color="primary" [disabled]="(!group.valid && !isAutoFilled) || isBusy">
        Sign In
    </button>

    <div style="flex: 1 1 0%;" class="account-help-actions">
        <div class="create-account" *ngIf="AllowCreateLogin && usingAlternateConfig == null">
            No account?
            <a class="link" (click)="onSignUp()">
                Sign up
            </a>
        </div>

        <div class="forgot-password">
            <a class="link" (click)="onForgotPassword()">Reset/Forgot password</a>
        </div>        
    </div>


    <div *ngIf="AlternateCognitoConfigs && AlternateCognitoConfigs.length > 0" style="padding-top: 40px; display: flex; align-items: center; flex-direction: column; place-content: center;">
        <div class="google-width" style="padding-bottom: 10px; flex: 1 1 0%; display: flex; align-items: center; place-content: center;">
            <div style="height: 0; border-top: solid 1px gray; position: relative; top: 1px; flex: 1 0 auto;"></div>
            <div style="flex: 0 1 auto;">or sign in with</div>
            <div style="height: 0; border-top: solid 1px gray; position: relative; top: 1px; flex: 1 0 auto;"></div>
        </div>

        <div style="display: flex; flex-flow: row wrap; place-content: center; align-items: center;">
            <button *ngIf="usingAlternateConfig != null" mat-button class="alt-sign-in-container" name="defaultAppName" title="Sign in with {{defaultAppName}}" (click)="returnToDefaultConfig()">
                <img class="alt-sign-in-img" [src]="defaultAppImage">
            </button>

            <ng-container *ngFor="let alt of AlternateCognitoConfigs">
                <ng-container *ngIf="alt.IsGoogle; else notFederatedProvider">
                    <button *ngIf="alt.IsGoogle" type="submit" class="google-width google-button" name="Google" title="Sign in with Google" (click)="signInWithAltConfig(alt)"></button>
                </ng-container>

                <ng-template #notFederatedProvider>
                    <button *ngIf="usingAlternateConfig == null || usingAlternateConfig.Name != alt.Name" mat-button class="alt-sign-in-container" name="alt.Name" title="Sign in with {{alt.Name}}" (click)="signInWithAltConfig(alt)">
                        <ng-container *ngIf="alt.SignInImage; else noImgTemplate">
                            <img class="alt-sign-in-img" [src]="alt.SignInImage">
                        </ng-container>
                        <ng-template #noImgTemplate>
                            Sign in with {{alt.Name}}
                        </ng-template>
                    </button>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
