<div class="dialog-fill-height">
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Split in to multiple Tickets?</h1>
    <mat-dialog-content style="display:flex; flex-direction:column">
        <div style="display:flex; flex-grow:1; overflow: hidden">
            <iq-create-multiple-tickets (RouteListEvent)="RouteListChanged($event)" [Data]="Data" #child></iq-create-multiple-tickets>
            <div class="iq-ticket-entry-right-side">
                <ticket-digsite-map [TicketEntryForm]="TicketEntryForm" [ReadOnly]="true" style="height:100%"></ticket-digsite-map>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="centered-dialog-buttons">
        <iq-icon-button button-class="Dialog" icon="Cancel" [mat-dialog-close]="null">Back to Ticket</iq-icon-button>
        <iq-icon-button button-class="Dialog" icon="SaveMultiple" [disabled]="!child.CanCreateMultipleTickets()" (click)="OnSaveMultipleTickets()">Split in to multiple Tickets</iq-icon-button>
        <iq-icon-button button-class="Dialog" icon="OK" [mat-dialog-close]="Data.VerifyInfo">Create single Ticket</iq-icon-button>
    </mat-dialog-actions>
</div>
