import { MapConstants } from 'Shared/Components/Maps/MapConstants';
import proj4 from 'proj4';      //  Needs "allowSyntheticDefaultImports" in compiler options in tsconfig.json: https://stackoverflow.com/questions/44179559/typescriptangularjswebpack-modules-without-default-export
import * as jsts from "jsts";      //  JavaScript Topology Suite: 2.7.2 changes how to import, see https://github.com/bjornharrtell/jsts/issues/442
import { LineString, LinearRing } from 'ol/geom';
import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import * as Proj from 'ol/proj';
import { register as ol_proj_proj4_register } from 'ol/proj/proj4';

class Projections {
    constructor(public UtMZone: number,     //  UTM Zone number: See https://en.wikipedia.org/wiki/Universal_Transverse_Mercator_coordinate_system#UTM_zone
        public Name: string,                //  EPSG:xxxxx
        public Definition: string) { }      //  Proj4 definition, from: http://spatialreference.org/ref/epsg/nad83-utm-zone-17n/
}

export class GeometryTransformer {
    public static ToGeography(olGeom: LineString): LineString {
        const coords = olGeom.getCoordinates();

        try {
            const projection = GeometryTransformer.GetProjectionForCoordinate(coords[0]);
            if (!projection)
                return olGeom;

            const transformedCoords = GeometryTransformer.TransformCoordinates(olGeom.getCoordinates(), null, projection);
            return new LineString(transformedCoords);
        } catch {
            return null;        //  geometry not in bounds of US!
        }
    }

    public static TransformExtent(extent: Extent, fromProjection: string, toProjection: string): Extent {
        const coords = this.TransformCoordinates([[extent[0], extent[1]], [extent[2], extent[3]]], fromProjection, toProjection);
        return [coords[0][0], coords[0][1], coords[1][0], coords[1][1]];
    }

    public static TransformCoordinates(coordinates: Coordinate[], fromProjection: string, toProjection: string): Coordinate[] {
        //  If either is null, default to the map projection
        if (!fromProjection)
            fromProjection = MapConstants.MAP_PROJECTION;
        if (!toProjection)
            toProjection = MapConstants.MAP_PROJECTION;

        return coordinates.map(c => Proj.transform(c, fromProjection, toProjection));
    }

    public static TransformCoordinate(coordinate: Coordinate, fromProjection: string, toProjection: string): Coordinate {
        //  If either is null, default to the map projection
        if (!fromProjection)
            fromProjection = MapConstants.MAP_PROJECTION;
        if (!toProjection)
            toProjection = MapConstants.MAP_PROJECTION;

        return Proj.transform(coordinate, fromProjection, toProjection);
    }

    public static TransformJstsGeom(geom: jsts.geom.Geometry, fromProjection: string, toProjection: string): jsts.geom.Geometry {
        switch (geom.getGeometryType()) {
            case "Polygon":
                return this.TransformJstsPolygon(geom, fromProjection, toProjection);
            case "MultiPolygon":
                return this.TransformJstsMultiPolygon(geom, fromProjection, toProjection);

            //  TODO: Add support for these when needed
            case "Point":
            case "MultiPoint":
            case "LineString":
            case "MultiLineString":
            default:
                console.error("TransformJstsGeom: Unhandled geometry type", geom.getGeometryType());
                return null;
        }
    }

    public static TransformJstsCoordsToOlCoords(jstsCoords: jsts.geom.Coordinate[], fromProjection: string, toProjection: string): Coordinate[] {
        return jstsCoords.map(c => GeometryTransformer.TransformCoordinate([c.x, c.y] as Coordinate, fromProjection, toProjection));
    }

    public static TransformJstsCoords(jstsCoords: jsts.geom.Coordinate[], fromProjection: string, toProjection: string): jsts.geom.Coordinate[] {
        return jstsCoords.map(c => {
            const olCoord = GeometryTransformer.TransformCoordinate([c.x, c.y] as Coordinate, fromProjection, toProjection);
            return new jsts.geom.Coordinate(olCoord[0], olCoord[1]);
        });
    }

    public static TransformJstsMultiPolygon(multiPoly: jsts.geom.MultiPolygon, fromProjection: string, toProjection: string): jsts.geom.MultiPolygon {
        const polyList: jsts.geom.Polygon[] = [];
        for (let i = 0; i < multiPoly.getNumGeometries(); i++)
            polyList.push(this.TransformJstsPolygon(multiPoly.getGeometryN(i), fromProjection, toProjection));

        const geometryFactory = new jsts.geom.GeometryFactory();
        return geometryFactory.createMultiPolygon(polyList);
    }

    public static TransformJstsPolygon(poly: jsts.geom.Polygon, fromProjection: string, toProjection: string): jsts.geom.Polygon {
        const exteriorRing = GeometryTransformer.TransformJstsRing(poly.getExteriorRing(), fromProjection, toProjection);

        const interiorRings: jsts.geom.LinearRing[] = [];
        for (let i = 0; i < poly.getNumInteriorRing(); i++)
            interiorRings.push(GeometryTransformer.TransformJstsRing(poly.getInteriorRingN(i), fromProjection, toProjection));

        const geometryFactory = new jsts.geom.GeometryFactory();
        return geometryFactory.createPolygon(exteriorRing, interiorRings);
    }

    public static TransformJstsRing(jstsRing: jsts.geom.LinearRing, fromProjection: string, toProjection: string): LinearRing {
        const transformedCoords = GeometryTransformer.TransformJstsCoords(jstsRing.getCoordinates(), fromProjection, toProjection);

        const geometryFactory = new jsts.geom.GeometryFactory();
        return geometryFactory.createLinearRing(transformedCoords)
    }

    /**
     * Returns the name of the projection to use to translate the coordinate into a geographic coordinate system.
     * This is necessary to do accurate measurements.
     * @param coord
     * @param coordIsLatLon
     */
    public static GetProjectionForCoordinate(coord: Coordinate, coordIsLatLon?: boolean): string {
        GeometryTransformer.CreateTransformers();

        //  Translate coordinate into lat/lon so we can determine the correct UTM zone
        let latLonCoord: Coordinate = coord;
        if (!coordIsLatLon)
            latLonCoord = Proj.transform(coord, MapConstants.MAP_PROJECTION, MapConstants.LATLON_PROJECTION) as Coordinate;

        //  How to calculate UTM Zone from longitude: https://stackoverflow.com/questions/9186496/determining-utm-zone-to-convert-from-longitude-latitude/9188972#9188972
        //  See also: https://en.wikipedia.org/wiki/Universal_Transverse_Mercator_coordinate_system
        const utmZone = (Math.floor((latLonCoord[0] + 180) / 6) % 60) + 1;

        let i: number = 0;
        for (i = 0; i < GeometryTransformer.RegisteredProjections.length; i++) {
            const p = GeometryTransformer.RegisteredProjections[i];
            if (p.UtMZone === utmZone)
                return p.Name;
        }

        //  If this throws, make sure you passed in an OpenLayers coordinate and not a jsts coordinate!
        throw new Error("Could not determine UTM zone for coord " + coord.toString());
    }

    private static RegisteredProjections: Projections[] = [
        new Projections(10, "EPSG:26910", "+proj=utm +zone=10 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(11, "EPSG:26911", "+proj=utm +zone=11 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(12, "EPSG:26912", "+proj=utm +zone=12 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(13, "EPSG:26913", "+proj=utm +zone=13 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(14, "EPSG:26914", "+proj=utm +zone=14 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(15, "EPSG:26915", "+proj=utm +zone=15 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(16, "EPSG:26916", "+proj=utm +zone=16 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(17, "EPSG:26917", "+proj=utm +zone=17 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(18, "EPSG:26918", "+proj=utm +zone=18 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
        new Projections(19, "EPSG:26919", "+proj=utm +zone=19 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"),
    ];

    private static CreateTransformers(): void {
        if (Proj.get(GeometryTransformer.RegisteredProjections[0].Name))
            return;     //  Already created

        //  utm zones: https://en.wikipedia.org/wiki/Universal_Transverse_Mercator_coordinate_system#UTM_zone
        //  EPSG definitions: http://spatialreference.org/ref/epsg/nad83-utm-zone-17n/
        GeometryTransformer.RegisteredProjections.forEach(p => proj4.defs(p.Name, p.Definition));

        ol_proj_proj4_register(proj4);
    }
}
