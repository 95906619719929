import { EntityBase } from "@iqModels/Base/EntityBase.model";
import { SelectOption } from "@iqModels/Configuration/SelectOption.model";
import { ServiceAreaResponseEntryTypeEnum } from "Enums/ServiceAreaResponseEntryType.enum";
import { SuppressedReasonEnum } from "Enums/SuppressedReason.enum";
import { TicketDiscussionChildResponse } from "./TicketDiscussionChildResponse.model";
import { TicketResponseItem } from "./TicketResponseItem.model";

export class TicketAffectedServiceAreaInfo extends EntityBase {
    public Code: string;
    public Name: string;
    public IsActive: boolean;
    public CurrentRegistrationID?: string;
    
    public ServiceAreaType: SelectOption;

    public ManuallyAdded: boolean = false;

    //  Same flags exists on the TicketServiceArea record and are set when the ticket is saved.
    //  Duplicated here because this class is returned by VerifyTicketBeforeSave
    public NotOnOriginalVersion: boolean = false;
    public NotOnPreviousVersion: boolean = false;

    public Suppressed: boolean = false;
    public CanToggleSuppression: boolean = false;
    public SuppressedUntilDate: Date;

    public SuppressedReason: SuppressedReasonEnum;

    public CustomBufferFt: number;

    public MarkingColor: string;
    public UtilityTypes: string;

    public ExtraordinaryCircumstances: boolean;
    public ParticipatesInMeetScheduling: boolean;

    public PrimaryContact: TicketAffectedServiceAreaContact;
    public AlternateContact: TicketAffectedServiceAreaContact;
    public EmergencyContact: TicketAffectedServiceAreaContact;

    public ResponseEntryType: ServiceAreaResponseEntryTypeEnum;
    
    public CurrentResponses: TicketResponseItem[];

    public ExcavatorComments: TicketDiscussionChildResponse[];
    public Discussions?: TicketDiscussionChildResponse[];

    // Only set when searching for a service area to add to the ticket or show on the map
    public DistanceToGeometryFt?: number;

    /**
     * If true, the Affected Service Area dialog will require the user to verify the service area before saving.
     * Currently only used by AZ to verify LBPs, Landlords, and all Service Areas when doing an after hours Emergency.
     */
    public RequiresVerification: boolean;

    /**
     * Not sent from server.  Used to track if the user has verified the Service Area
     */
    public Verified: boolean = false;

    constructor(public ID: string = null) {
        super(ID);
    }
}

export class TicketAffectedServiceAreaContact {
    public FirstName: string;
    public LastName: string;
    public PhoneNumber: string;

    //  These are currently only provided on a main contact when the service area is a Landlord.
    public FaxNumber?: string;
    public Address1?: string;
    public City?: string;
    public State?: string;
    public Zip?: string;

    public EmailAddress?: string;
}

