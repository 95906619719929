import { Directive, HostListener, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

/**
 *  Quick way to prevent a user from double-clicking on an element.  If possible, always better
 *  to disable the button/link while the task is being executed.  But sometimes that's not easy to do.
 *  This will throttle the click event so that it fires immediately but then not again for a short duration.
 *  Also fires DebounceClick for keyup.enter and keyup.space events.
 *  Use like this:<button iqPreventDoubleClick (DebounceClick)="log()" [DebounceTime]="700">Debounced Click</button>
 *  https://stackoverflow.com/a/51390698/916949
 */
@Directive({
    selector: '[iqPreventDoubleClick]'
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
    @Input()
    DebounceTime = 700;

    @Output()
    DebounceClick = new EventEmitter();

    private _Clicks = new Subject();
    private _Subscription: Subscription;

    constructor() { }

    ngOnInit() {
        //  throttleTime will emit the first click and then ignore any more for debounceTime duration
        this._Subscription = this._Clicks.pipe(
            throttleTime(this.DebounceTime)
        ).subscribe(e => this.DebounceClick.emit(e));
    }

    ngOnDestroy() {
        //  Had a situation where _Subscription was undefined - happened when switching one calls so something must
        //  have been in the process of initializing and then the page changed.
        if (this._Subscription)
            this._Subscription.unsubscribe();
        this.DebounceClick.complete();
        this.DebounceClick.unsubscribe();
        this.DebounceClick = null;
        this._Clicks.complete();
        this._Clicks.unsubscribe();
        this._Clicks = null;
    }

    @HostListener('click', ['$event'])
    ClickEvent(event) {
        this.OnClick(event);
    }

    //  Don't catch these events - unless it's used on something that is not a button...
    //  On a button element that has type="button" specified, Angular automatically fires the MouseClick
    //  event for Enter and Space!  So if we also catch them here, it can result in the event being fired twice.
    //  Which *SHOULD* be handled by the throttle, but in some cases it's not!  The ENTER event (seems to only be a problem
    //  with enter too, btw) can get fired several seconds AFTER! (that happens if you ENTER on the Discard button of the
    //  project form, wait for a couple seconds, then enter the cancel button on that dialog - it triggers it again!).
    //  Also caused problems (on the project form) if you Enter the Complete button - the 2nd event immediately triggers
    //  the button on the dialog that comes up!  That makes it seem like something is screwy where the 2nd event is not
    //  being sent to the same control as the 1st.
    //  The only solution I could find was to just not catch these.  I think it was initially needed because we did not
    //  have type="button" specified on the IconButton (which had to be added to keep enter from doing a submit on the form...).
    //@HostListener('keyup.enter', ['$event'])
    //EnterEvent(event) {
    //    this.OnClick(event);
    //}
    //@HostListener('keyup.space', ['$event'])
    //SpaceEvent(event) {
    //    this.OnClick(event);
    //}

    private OnClick(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this._Clicks.next(event);
    }
}
