<mat-toolbar color="primary" class="iq-display-1">
    <div class="iq-phone-header-left">
        <ng-container *ngTemplateOutlet="leftTemplate ? leftTemplate : defaultLeftTemplate"></ng-container>
    </div>
    <div class="iq-phone-header-center">
        <ng-container *ngTemplateOutlet="centerTemplate ? centerTemplate : defaultCenterTemplate"></ng-container>
    </div>
    <div class="iq-phone-header-right">
        <ng-container *ngTemplateOutlet="rightTemplate ? rightTemplate : defaultRightTemplate"></ng-container>
    </div>
</mat-toolbar>

<ng-template #defaultLeftTemplate>
    <iq-phone-main-menu></iq-phone-main-menu>
</ng-template>

<ng-template #defaultCenterTemplate>
    <img *ngIf="OccLogoImage" src="/assets/Images/Logos/{{OccLogoImage}}" [ngStyle]="{'height': OccMobileLogoHeight}" style="padding: 5px 0 0 5px; z-index: 10" alt="{{OccLogoImage}}">
</ng-template>

<ng-template #defaultRightTemplate>
    &nbsp;
</ng-template>

