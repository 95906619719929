import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddExcavatorCommentComponent, AddExcavatorCommentData } from '../AddExcavatorComment.component';

@Component({
    templateUrl: './AddExcavatorCommentDialog.component.html',
    styleUrls: ['./AddExcavatorCommentDialog.component.scss']
})
export class AddExcavatorCommentDialogComponent {

    @ViewChild(AddExcavatorCommentComponent)
    private _AddExcavatorCommentComponent: AddExcavatorCommentComponent;

    constructor(private _DialogRef: MatDialogRef<AddExcavatorCommentDialogComponent>, @Inject(MAT_DIALOG_DATA) public Data: AddExcavatorCommentData) {
    }

    public Save(): void {
        this._AddExcavatorCommentComponent.Save().subscribe((savedDiscussions) => {
            if (savedDiscussions)
                this._DialogRef.close(savedDiscussions);
        })
    }
}
