export enum RoleTypeEnum {
    //  These are local user role types
    Admin = 0,
    Operator = 1,

    //  Remote user role types
    Member = 2,
    Excavator = 3,       //  Can tell if homeowner b/c user not linked to an excavator
    ServiceArea = 4,

    /**
     * State/Government PUC user.  Has access to view all tickets like a local user but with
     * some extra restrictions.
     * */
    Regulator = 5
}

export enum RoleTypeEnumDescriptions {
    Admin = "Admin",
    Operator = "Operator",
    Member = "Member",
    Excavator = "Excavator",
    ServiceArea = "Service Area",
    Regulator = "Regulator"
}
