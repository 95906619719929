import { Component, Input, AfterContentInit } from '@angular/core';
import { ExcavatorAutocompleteResponse } from '@iqModels/Excavators/ExcavatorAutocompleteResponse.model';
import { Address } from "@iqModels/Common/Address.model";

@Component({
    selector: 'iq-excavator-company-autocomplete-option',
    templateUrl: './ExcavatorCompanyAutocompleteOption.component.html',
    styleUrls: ['./ExcavatorCompanyAutocompleteOption.component.scss']
})
export class ExcavatorCompanyAutocompleteOptionComponent implements AfterContentInit {

    @Input("model")
    public Model: ExcavatorAutocompleteResponse;

    @Input("ShowOfficePhone")
    public ShowOfficePhone: boolean = false;

    public Description: string;
    public FormattedAddress: string;

    ngAfterContentInit(): void {
        this.Description = "";

        //  These 3 properties exist on all responses.  But not on the "View all" action items.
        if ((this.Model.NumCompanies !== null) && (this.Model.NumCompanies !== undefined)) {
            if (this.Model.NumOffices != 1)
                this.Description = this.Model.NumOffices.toString().concat(" offices...");
            else if (this.Model.NumContacts > 1)
                this.Description = this.Model.NumContacts.toString().concat(" contacts...");    //  Don't show 0 contacts, just leave it blank

            this.FormattedAddress = this.Model.Address ? Address.FormatAddress(this.Model.Address) : '';
        }
    }
}
