import { Directive } from "@angular/core";
import { MatSelect } from '@angular/material/select';

@Directive({
    selector: "mat-select[iq-material-select-clear]",
    host: {
        '(keydown.backspace)': 'onKeydownBackspaceEvent()',
        '(keydown.delete)': 'onKeydownBackspaceEvent()'
    }
})
export class MaterialSelectClearDirective {

    constructor(private select: MatSelect) {

    }

    onKeydownBackspaceEvent() {
        this.select.ngControl.reset(undefined);
        this.select._keyManager.setActiveItem(null);//Have to set this because the material select keeps track of the last item set 'active' when you use keys.  Resetting the control value currently doesn't reset this value
    }
}
