import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { SearchFilter } from 'Models/Searching/SearchFilter.model';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { Observable, Subject } from 'rxjs';

/**
 *  Contains the UI for editing an existing SearchFilter
 */
@Component({
    selector: 'iq-list-filter-container',
    templateUrl: './ListFilterContainer.component.html',
    styleUrls: ['./ListFilterContainer.component.scss'],
})
export class ListFilterContainerComponent implements OnInit {

    @Input()
    public SearchFilter: SearchFilter;

    @Input()
    public SearchColumn: SearchColumn

    @Input()
    public IsDisabled: boolean = false;

    @Input()
    public IsValueRequired: boolean = false;

    @Input("ClearFilter")
    public ClearFilterEvent: Subject<void> = new Subject();

    @Output("FilterChanged")
    private _FilterChanged: EventEmitter<SearchFilter> = new EventEmitter(); 

    public NumberSearch: boolean;
    public PersonSearch: boolean;
    public ExcavatorCompanySearch: boolean;
    public ExcavatorContactSearch: boolean;
    public SelectItemSearch: boolean;
    public DateSearch: boolean;
    public FreeTextSearch: boolean;
    public AutoComplete: boolean;
    public AutocompleteResultFilterValue: string;
    public AutocompleteResultDisplayValue: string;
    public AutoCompleteSearchFunction: (filter: SearchRequest) => Observable<any> = null;

    constructor() {
    }

    public SelectItemValues: SelectOption[];

    public ngOnInit(): void {
        this.SelectItemSearch = coerceBooleanProperty(this.SearchColumn.filterOptions);
        this.DateSearch = this.SearchColumn.useDateSearch;
        this.PersonSearch = this.SearchColumn.usePersonSearch;
        this.NumberSearch = this.SearchColumn.useNumberSearch;
        this.ExcavatorCompanySearch = this.SearchColumn.useExcavatorCompanySearch;
        this.ExcavatorContactSearch = this.SearchColumn.useExcavatorContactSearch;

        this.FreeTextSearch = !this.SelectItemSearch && !this.DateSearch && !this.PersonSearch
            && !this.ExcavatorCompanySearch && !this.ExcavatorContactSearch
            && !this.NumberSearch && !this.SearchColumn.autoComplete;
        this.AutoComplete = this.SearchColumn.autoComplete;
        this.AutocompleteResultDisplayValue = this.SearchColumn.autocompleteResultDisplayValue;
        this.AutocompleteResultFilterValue = this.SearchColumn.autocompleteResultFilterValue;
        this.AutoCompleteSearchFunction = this.SearchColumn.autoCompleteSearchFunction;
    }

    public FilterChanged(newFilter: SearchFilter): void {
        this.SearchFilter = newFilter;
        this._FilterChanged.emit(this.SearchFilter);
    }
}
