import {share, takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, Output, EventEmitter, HostListener, ElementRef, AfterContentInit, OnInit, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SectionGroupService, FirstOrLastItemFocusChange } from '@iqSharedComponentControls/Section/SectionGroup.service';
import { Subject } from 'rxjs';
import { SectionItemService } from '@iqSharedComponentControls/Section/SectionItem.service';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'iq-section-item',
    templateUrl: './SectionItem.component.html',
    styleUrls: ['./SectionItem.component.scss'],
    providers: [SectionItemService],
    encapsulation: ViewEncapsulation.None
})
export class SectionItemComponent implements OnDestroy, AfterContentInit, OnInit {

    private destroyed$: Subject<void> = new Subject();
    
    @Output() FocusEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    //When there is a control marked with the iqSectionItemLastControl diective this will be fired.
    @Output() FocusLastControlEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() EditAllowed: boolean = null;

    //  Use this (instead of *ngIf) to conditionally show/hide the section.
    //  Using *ngIf will change the ordering of the event handlers and mess up the tab ordering.
    private _Visible: boolean = true;
    @Input()
    set Visible(visible: boolean) {
        if (this._Visible !== visible) {
            this._Visible = visible;
            this.sectionGroupService.SectionItemVisibilityChanged.next(this);

            //  This is necessary when we show/hide a section item that is inside a div that uses justify-content:space-between.
            //  If we don't do this, the section item will still "display" and cause it to take up space.
            //  Setting to null when visible will remove a 'none' override and cause it to use whatever is configured in the html.
            this.el.nativeElement.style.display = visible ? null : 'none';
        }
    }
    get Visible(): boolean {
        return this._Visible;
    }

    /**
     * Name assigned to the section - used to find it (in ticket entry) so we can set focus to a field with a validation error.
     * Ticket Entry does an "indexOf" check of this value against a property name.  So can specify multiple property names
     * as a comma separated list.
     */
    @Input() Name: string;

    _edit: boolean = false;
    @Input() set Edit(val: boolean)
    {
        this.SetEdit(coerceBooleanProperty(val));
        //let edit = coerceBooleanProperty(val);

        if (this._edit)//If true then call the focus method??
            this.Focus();
    }
    get Edit() {
        return this._edit;
    }

    private _focusOnGroupFocusNext: boolean = false;
    @Input('GroupAutoFocusNext') set FocusOnGroupFocusNext(val: boolean) {
        this._focusOnGroupFocusNext = coerceBooleanProperty(val);
    }
    private _focusOnGroupFocusPrevious: boolean = false;
    @Input('GroupAutoFocusPrevious') set FocusOnGroupFocusPrevious(val: boolean) {
        this._focusOnGroupFocusPrevious = coerceBooleanProperty(val);
    }
    
    private focusOnTab: boolean = false;
    //When the item loses focus should it change to be non edit mode
    @Input() set FocusOnTab(val: boolean) {
        this.focusOnTab = coerceBooleanProperty(val) && !this.sectionGroupService.id;   //Only use this property if it's not in a group.
    }
    get FocusOnTab() {
        return this.focusOnTab;
    }

    private closeonBlur: boolean = false;
    //When the item loses focus should it change to be non edit mode
    @Input() set AutoCloseOnBlur(val: boolean) {
        this.closeonBlur = coerceBooleanProperty(val);
    }

    //Only set this if the last control in the container is a component we created because angular can't auto find it that deep!
    public _LastControlInContainer: boolean = false;
    @Input() set IsLastControlInContainer(val: boolean) {
        this._LastControlInContainer = coerceBooleanProperty(val);
    }

    //Only set this if the first control in the container is a component we created because angular can't auto find it that deep!
    public _FirstControlInContainer: boolean = false;
    @Input() set IsFirstControlInContainer(val: boolean) {
        this._FirstControlInContainer = coerceBooleanProperty(val);
    }

    ngAfterContentInit() {
        this.focusMonitor.monitor(this.el.nativeElement, true).pipe(share()).subscribe(method => {
            if (method) {
                this.sectionGroupService.sectionItemFocusedInEdit.next(true);
            }
            else if (this.closeonBlur) {
                this.SetEdit(false); //Don't inform the group, may not want to close the whole group when tabbing through.  This is mainly used when the SectionItem is used without a group to have it auto switch between edit and view
            }
        });

        this.sectionItemService.lastControlTabForward.pipe(takeUntil(this.destroyed$)).subscribe(s => {
            if (s === null)//happens on the first subscription.  Nothing to do there.
                return;

            //Only care if it's the last control in the container so we can tell the group to close if need be
            if (this._LastControlInContainer)
                this.sectionGroupService.sectionContainerFocusLost.next(new FirstOrLastItemFocusChange(false));
        });
        
        this.sectionGroupService.groupEditChangeObserver.pipe(takeUntil(this.destroyed$)).subscribe(s => {
            if (s.edit === null)//happens on the first subscription.  Nothing to do there.
                return;

            this.SetEdit(s.edit);
            if (this._edit && this._focusOnGroupFocusNext && s.focusFirst && this.Visible) {
                this.FocusEvent.emit(true);
                this.sectionGroupService.groupChildFocusedOnAutoOpenObserver.next(true);

                //  Set this to false once we focus to a control.  This causes us to focus to the first one we find
                //  (all of these events seem to get fired in top-down order...hopefully that continues...).
                //  This is necessary if we have multiple SectionItems and we need to dynamically show/hide them.
                //  We can put the "GroupAutoFocusNext" tag on all of them and we'll always focus to the first one
                //  that is currently available.
                s.focusFirst = false;
            }
            if (this._edit && this._focusOnGroupFocusPrevious && s.focusLast && this.Visible) {
                if (this.sectionItemService.hasLastControl)
                    this.FocusLastControlEvent.emit(true);
                else
                    this.FocusEvent.emit(true);

                //  Note: Do not change focusLast like we do above to focusFirst.
                //  Since the events for all the sections in a group are fired in top-down order, we want to focus
                //  to the LAST SectionItem we find.  So this allows us to dynamically show/hide controls and
                //  always focus to the last one that is currently available.

                this.sectionGroupService.groupChildFocusedOnAutoOpenObserver.next(true);
            }
        });
    }
    ngOnInit() {
        if (this.EditAllowed === false && this.Edit)
            this._edit = false;
        else if (this.EditAllowed !== true && this.sectionGroupService.EditAllowed === false && this.Edit)
            this._edit = false;
    }
    constructor(private sectionGroupService: SectionGroupService, private sectionItemService: SectionItemService, private focusMonitor: FocusMonitor, private el: ElementRef) {
        
    }

    @HostListener('keydown.tab') onTab() {
        if (this._LastControlInContainer && !this.sectionItemService.hasLastControl)
            this.sectionGroupService.sectionContainerFocusLost.next(new FirstOrLastItemFocusChange(false));
    }

    @HostListener('keydown.shift.tab') onShiftTab() {
        if (this._FirstControlInContainer)
            this.sectionGroupService.sectionContainerFocusLost.next(new FirstOrLastItemFocusChange(true));
    }

    private SetEdit(val: boolean) {
        if (val && this.EditAllowed === false)
            return;
        else if (val && this.sectionGroupService.EditAllowed === false)
            return;

        this._edit = val;
    }
    public Focus() {
        this.SetEdit(true);
        this.sectionGroupService.groupChildEditChangeObserver.next(true);
        this.FocusEvent.emit(true);
    }
    public Clicked($event: MouseEvent): void {
        $event.stopPropagation();//Stop propagation so that the group doesn't get the event to change back
        
        //  Do *NOT* call $event.preventDefault()!  We need those events
        //  to propogate into the child controls (checkboxes, dropdowns, etc) so that they function correctly.

        //  If already in edit mode, do nothing.  In edit mode, just need to eat the event to keep the group from closing.
        if (!this._edit) {
            this.Focus();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.focusMonitor.stopMonitoring(this.el.nativeElement);
    }
}
