import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { FieldLengths } from 'Models/Configuration/FieldLengths.model';
import { AuthenticationService } from 'Services/AuthenticationService';
import { take } from 'rxjs/operators';

/**
 * Put this directive on a control and set it equal to a property name from FieldLengths.
 * It will add the maxLength attribute with the value from the FieldLengths for that property.
 * This limits the number of characters that can be entered in to the input.
 */
@Directive({
    selector: 'input[iqFieldLength]'
})
export class FieldLengthDirective implements OnInit {

    @Input('iqFieldLength') _PropertyName: string;

    public FieldLengths: FieldLengths = new FieldLengths();

    constructor(private el: ElementRef, private renderer: Renderer2, authenticationService: AuthenticationService) {
        authenticationService.CurrentUserObserver().pipe(take(1)).subscribe(user => {
            if (user && user.FieldLengths) {
                this.FieldLengths = user.FieldLengths;
                this.SetMaxLength();
            }
        });
    }

    ngOnInit() {
        this.SetMaxLength();
    }

    private SetMaxLength(): void {
        if (this._PropertyName) {
            const maxLength = this.FieldLengths[this._PropertyName] as number;
            if (maxLength)
                this.renderer.setAttribute(this.el.nativeElement, 'maxLength', maxLength.toString());
        }
    }
}
