<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Excavation Date</h1>
<mat-dialog-content style="display:flex; flex-direction:column;">
    <div>The Service Area has requested the actual date and time when the excavation will take place so that they can be present while the work is being done.</div>

    <iq-date-with-picker style="padding-left:2em" [formControl]="DateTimeFormControl" [Min]="MinDate" [Max]="MaxDate"></iq-date-with-picker>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button button-class="Dialog" icon="Cancel" [disabled]="IsSaving" [mat-dialog-close]="null">Cancel</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" [disabled]="IsSaving" (click)="OnSave()">Save</iq-icon-button>
</mat-dialog-actions>
