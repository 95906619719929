import { AfterViewInit, ContentChild, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioGroup } from '@angular/material/radio';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MtxSelect } from '@ng-matero/extensions/select';
import { Observable, Subscription } from 'rxjs';
import { EntryFieldComponentBase } from "Shared/EntryFields/Components/EntryFieldComponentBase.component";
import { EntryFieldDateComponent } from "Shared/EntryFields/Components/EntryFieldDate.component";

// ** The issue with not finding the directive when inside some element (like mat-form-field)
// seems to be an issue with Angular not handling content embedded inside ng-content.
// It's a known issue and it looks like it may get fixed some time after the 6.0 release.
// https://github.com/angular/angular/issues/16299

/**
 * Put this directive on a control to allow the SectionItem component to set focus when
 * Focus is set to it in edit mode.
 * ** If the input control is within a mat-form-field tag, put this directive on the mat-form-field
 * and it will focus correctly.  Otherwise, the @ContentChildren does not find it!
 */
@Directive({
    selector: '[iqFocus]'
})
export class FocusDirective implements AfterViewInit, OnDestroy {

    @Input('iqFocus') _focusEvent: Observable<boolean>;

    @ContentChild(MatFormFieldControl)
    private _MatInput: MatInput;

    @ContentChild(MatSlideToggle)
    private _MatSlideToggle: MatSlideToggle;

    @ContentChild(MatCheckbox)
    private _MatCheckbox: MatCheckbox;

    @ContentChild(EntryFieldDateComponent)
    private _TicketEntryDate: EntryFieldDateComponent;

    @ContentChild(EntryFieldComponentBase)
    private _TicketEntryComponent: EntryFieldComponentBase;

    @ContentChild(MtxSelect)
    private _MtxSelect: MtxSelect;

    @ContentChild(MatRadioGroup)
    private _RadioGroup: MatRadioGroup;

    private _Subscription: Subscription;

    constructor(private el: ElementRef) {
    }

    public ngOnDestroy(): void {
        if (this._Subscription) {
            this._Subscription.unsubscribe();
            this._Subscription = null;
        }

        this.el = null;
        this._MatInput = null;
        this._MatSlideToggle = null;
        this._MatCheckbox = null;
        this._TicketEntryDate = null;
        this._TicketEntryComponent = null;
        this._MtxSelect = null;
    }

    ngAfterViewInit() {
        if (this._focusEvent)
            this._Subscription = this._focusEvent.subscribe(s => this.FocusEvent(s));
    }

    private FocusEvent(focus: boolean) {
        if (!focus)
            return;
        
        setTimeout(() => {
            if (this._MatInput)
                this._MatInput.focus();
            else if (this._MatSlideToggle)
                this._MatSlideToggle.focus();
            else if (this._MatCheckbox)
                this._MatCheckbox.focus();
            else if (this._TicketEntryDate)
                this._TicketEntryDate.focus();
            else if (this._TicketEntryComponent)
                this._TicketEntryComponent.Focus();
            else if (this._MtxSelect)
                this._MtxSelect.focus();
            else if (this._RadioGroup) {
                //  For a radio, if one is selected, focus that one.  Otherwise, the _RadioButton we have here will be the first one in the group
                if (this._RadioGroup.selected)
                    this._RadioGroup.selected.focus();
                else {
                    //  _radios is probably supposed to be private/internal.  But saves us having to find it.
                    //  If we do need to remove access to this property, can get it like this: @ContentChild(MatRadioButton) _RadioButton: MatRadioButton
                    const firstRadio = this._RadioGroup._radios?.first;
                    if (firstRadio)
                        firstRadio.focus();
                }
            }
            else if (this.el)
                this.el.nativeElement.focus();
        });
    }
}
