import { Component, HostBinding, Inject } from '@angular/core';
import { TicketEntryOptionsService } from 'Pages/Tickets/Services/TicketEntryOptions.service';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { CommonService } from 'Services/CommonService';
import { IQ_FLYOUT_DATA, IQ_FLYOUT_REF } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { SideSlideoutRef } from 'Shared/PhoneDevice/SideSlideout/SideSlideoutRef';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { StartTicketFunctionData } from "../StartTicketFunctionData.model";
import { TicketFunctionBase } from '../TicketFunctionBase.component';

@Component({
    templateUrl: './TicketFunctionFlyout.component.html',
    styleUrls: ['./TicketFunctionFlyout.component.scss'],
    animations: [slideInFromRight],
    providers: [
        CommonService,
        //  Scoped to this component.  All children (and directives) can add to their constructor to get THIS instance.
        //  *BUT* That does not apply to any other dialogs that may be opened because the MatDialog is scoped to the application root!
        //  Any child dialog that needs this instance of TicketService, will need to have it specifically passed to it instead
        //  of getting it from it's own constructor!
        //  https://stackoverflow.com/questions/58083775/propagating-service-instance-to-dialog-component-not-working-as-expected
        TicketService, TicketEntryOptionsService //  Once we move all of the state storage out of TicketService, should only need TicketEntryOptionsService
    ]
})
export class TicketFunctionFlyoutComponent extends TicketFunctionBase {
    //  Defining the animation on the HostBinding like this is cleaner and causes it to do the close animation automatically.
    @HostBinding('@slideInFromRight') animation = 'enter';

    constructor(ticketService: TicketService, @Inject(IQ_FLYOUT_DATA) data: StartTicketFunctionData,
        @Inject(IQ_FLYOUT_REF) private _SideSlideoutRef: SideSlideoutRef)
    {
        super(ticketService, data);
    }

    protected override CloseForm(saved: boolean): void {
        this._SideSlideoutRef.close(saved);
    }
}
