import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SignInState } from 'iqCognito/models';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { PersonIsLoginLinkedResponse } from "Models/People/PersonIsLoginLinkedResponse.model";
import { PersonLinkLoginRequest } from 'Models/People/PersonLinkLoginRequest.model';
import { PersonLinkLoginResponse } from 'Models/People/PersonLinkLoginResponse.model';
import { AppUser } from 'Models/Security/AppUser.model';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { ExactixFederatedLoginState } from 'Services/AuthenticationService';
import { BusyService } from 'Services/BusyService';
import { passwordRequirements } from 'Services/CognitoAdminService';
import { CommonService } from 'Services/CommonService';
import { MainMenuService } from 'Services/MainMenuService';

/*
 *  This component is used in the "link-login" url.
 *  The ID in the url is either a Person.ID or an ExcavatorContact.ID.
 *  It is used to link or create a login in Cognito to an existing Person or ExcavatorContact.  In the case of an ExcavatorContact,
 *  this will create a new Person (if necessary), put that Person in the Self Registration role, and link it to the ExcavatorContact.
 *  That is essentially a short-cut to self registering directly in to a specific ExcavatorContact.
 */
@Component({
    templateUrl: './LinkUserToPerson.component.html',
    styleUrls: ['./LinkUserToPerson.component.scss'],
    providers: [CommonService]
})
export class LinkUserToPersonComponent implements OnDestroy {

    public FederatedLoginReturnState: ExactixFederatedLoginState = { redirectBeforeFetchingUser: true, returnUrl: this._Router.url };
    public PasswordRequirements = passwordRequirements;

    private _Destroyed: Subject<void> = new Subject();

    public OccCode: string;
    private _EntityID: string;       //  Person.ID or ExcavatorContact.ID

    public GotApiError: boolean = false;

    //Setup observable in the service and get the key to that observable back to start and end the indicator
    public BusyKey: string = this._BusyService.createNew();

    public DataNotFound: boolean = false;

    public LoginLinkedToPerson: boolean = false;        //  If the browser is currently logged in and linked to any Person in the system
    public LoginLinkedToEntityID: boolean = false;      //  If the browser is currently logged in and linked to the EntityID (which is either a Person.ID or ExcavatorContact.ID)
    public Username: string;
    public PersonHasLogin: boolean = false;             //  If the Person we are trying to link to already has a login
    public Loading = true;
    public PersonInfo: PersonLinkLoginResponse;

    private _CognitoUser;

    constructor(
        private _CommonService: CommonService,
        private _BusyService: BusyService,
        private _MainMenuService: MainMenuService,
        private _CognitoService: IqAwsCognitoService,
        private _Router: Router
    ) {
        this._MainMenuService.forceClose = true;

        this._BusyService.showByKey(this.BusyKey);
        this.OccCode = this._CommonService.ActivatedRoute.snapshot.paramMap.get('occ');
        this._EntityID = this._CommonService.ActivatedRoute.snapshot.paramMap.get('id');

        this._CognitoService.signInEventChange$.pipe(distinctUntilChanged((prev: SignInState, cur: SignInState) => prev.SignedIn === cur.SignedIn),
            mergeMap((state: SignInState) => {
                if (state.SignedIn === true) {
                    //  The browser is already logged in.  Check to see if it is logged in as the Person/ExcavatorContact specified by EntityID or
                    //  if it's logged in as a different user
                    this._CognitoUser = state.User;
                    this.Username = state.User.getUsername();

                    //  Check if the login is linked to a person in this client.  Have to check this anytime they login because they
                    //  could use an existing login and not be creating a new one.
                    return this._CommonService.HttpClient.get<PersonIsLoginLinkedResponse>(this._CommonService.SettingsService.ApiBaseUrl + "/Administration/Person/IsLoginLinked/" + this.OccCode + "/" + this._EntityID)
                        .pipe(take(1), map(val => {
                            return { state: state, loginLinked: val.Linked, linkedToID: val.LinkedToID };
                        }));
                }
                else
                    return of({ state: state, loginLinked: false, linkedToID: false });
            }),
            mergeMap(val => {
                this.LoginLinkedToPerson = val.loginLinked;     //  If the browser is currently logged in and linked to any Person in the system
                this.LoginLinkedToEntityID = val.linkedToID;    //  If the browser is currently logged in and linked to the EntityID (which is either a Person.ID or ExcavatorContact.ID)

                if (this.LoginLinkedToPerson === true)
                    return of({ state: val.state, personData: null });
                else if (this.PersonInfo)   //  Don't reload this data if we have it.
                    return of({ state: val.state, personData: this.PersonInfo });
                else
                    return this._CommonService.HttpClient.get<PersonLinkLoginResponse>(this._CommonService.SettingsService.ApiBaseUrl + "/Administration/Person/IsPersonLinked/" + this.OccCode + "/" + this._EntityID)
                        .pipe(take(1), map(personInfo => { return { state: val.state, personData: personInfo }; }));
            }),
            takeUntil(this._Destroyed))
            .subscribe(data => {
                this.Loading = false;

                //  Can't link a login to a person if the login is already linked to a person
                if (this.LoginLinkedToPerson) {
                    this._BusyService.hideByKey(this.BusyKey);
                    return;
                }

                if (!data?.personData) {
                    this._BusyService.hideByKey(this.BusyKey);
                    this.DataNotFound = true;
                    return;
                }

                //  TODO: Need to pass back anything if EntityID is an ExcavatorContact?
                this.PersonInfo = data.personData;

                this.PersonHasLogin = this.PersonInfo ? this.PersonInfo.HasLogin : false;

                const isSignedIn = data.state.SignedIn;

                //Can't link a login to a person if the person already has a login
                if (this.PersonInfo.HasLogin) {
                    this._BusyService.hideByKey(this.BusyKey);
                    return;
                }

                if (isSignedIn)
                    this.LinkPerson();

                this._BusyService.hideByKey(this.BusyKey);
            });
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();

        this._MainMenuService.forceClose = false;
        this._BusyService.hideByKey(this.BusyKey);
        this._BusyService.removeByKey(this.BusyKey);
    }

    private _Linking = false;
    private LinkPerson(): void {
        if (this._Linking)
            return;

        this._Linking = true;

        this._BusyService.showByKey(this.BusyKey);

        //Make sure we have the latest incase they changed anything.
        this._CognitoService.GetCurrentAuthenticatedUser().pipe(take(1)).subscribe(val => {

            //If we don't get a valid user then we probably need to log them out..
            val.getUserAttributes((err, atts) => {

                const request = new PersonLinkLoginRequest(this.OccCode, this._EntityID, atts);

                this._CommonService.HttpClient.post<AppUser>(this._CommonService.SettingsService.ApiBaseUrl + "/Administration/Person/LinkLogin", request)
                    .subscribe(appUser => {
                        this._CommonService.AuthenticationService.redirectAfterUserRegistration(appUser, val, atts);
                        this._BusyService.hideByKey(this.BusyKey);
                    }, (err: HttpErrorResponse) => {
                        this.GotApiError = true;
                        this._BusyService.hideByKey(this.BusyKey);
                    });
            });
        });
    }

    public Logout(redirect = false): void {
        this.LoginLinkedToPerson = false;
        this.Username = null;
        this.PersonHasLogin = false;
        this.Loading = true;

        //  This will trigger a change to the signInEventChange$ handler up above and we will re-process everything
        this._CommonService.AuthenticationService.logout(redirect);
    }

    public RedirectToHome(): void {
        this._CommonService.AuthenticationService.SetUserLoggedIn(this._CognitoUser, true);
    }
}
