<iq-slideout #slideout (Open)="OpenToggle($event)">
    <iq-phone-button *ngIf="SignedIn | async" icon="Menu" (click)="slideout.attach()">Menu</iq-phone-button>

    <ng-template iqSideMenuFlyoutContent>
        <div class="mat-elevation-z7 iq-body-2 iq-phone-main-menu" style="width: 80vw; background-color: white;" [@slideInFromLeft]="IsOpen ? 'enter' : 'exit'">
            <div style="display: flex; flex-direction: column; height: 100%;" *ngIf="ShowNav" [@slideInFromLeft]="ShowNav ? 'enter' : 'exit'">
                <mat-toolbar color="primary" class="iq-display-1">

                    <div class="iq-phone-header-center">
                        <img *ngIf="OccLogoImage" src="/assets/Images/Logos/{{OccLogoImage}}" [ngStyle]="{'height': OccMobileLogoHeight}" style="padding: 5px 0 0 5px; z-index: 10" alt="{{OccLogoImage}}">
                    </div>

                    <div class="iq-phone-header-right">
                        <iq-phone-button icon="Settings" (click)="ShowNav = false">Settings</iq-phone-button>
                    </div>
                </mat-toolbar>

                <mat-divider></mat-divider>

                <div class="iq-phone-navigation" style="display: flex; flex-direction: column; flex-grow: 1; overflow:hidden">
                    <div class="iq-display-2" style="align-self:center; padding: 10px 10px 5px; font-weight: bold; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; flex-shrink: 0">
                        {{PersonName}}
                    </div>
                    <div class="iq-display-1" style="align-self: center; padding: 5px 10px 10px; font-weight: bold; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; flex-shrink: 0">
                        {{Email}}
                    </div>

                    <mat-nav-list style="flex-grow:1; overflow: auto">
                        <mat-divider></mat-divider>

                        <a routerLinkActive="active-link" mat-list-item routerLink="/home" (click)="close()">
                            <fa-icon [icon]="HomeIcon" class="menu-icon"></fa-icon>
                            <span> Home</span>
                        </a>
                        <mat-divider></mat-divider>

                        <a routerLinkActive="active-link" mat-list-item routerLink="/tickets/dashboard" *iqHasPermission="TicketViewPermission hasAnywhere true" (click)="close()">
                            <fa-icon [icon]="TicketIcon" class="menu-icon"></fa-icon>
                            <span> Ticket Dashboard</span>
                        </a>
                        <mat-divider></mat-divider>

                        <a routerLinkActive="active-link" mat-list-item routerLink="/tickets/new" *iqHasPermission="TicketCreatePermission hasAnywhere true" (click)="close()">
                            <fa-icon [icon]="TicketIcon" class="menu-icon"></fa-icon>
                            <span> New Ticket</span>
                        </a>
                        <mat-divider></mat-divider>

                        <ng-container *ngIf="ProjectsName">
                            <a routerLinkActive="active-link" mat-list-item routerLink="/projects" *iqHasPermission="ProjectViewPermission hasAnywhere true" (click)="close()">
                                <fa-icon [icon]="ProjectIcon" class="fas menu-icon"></fa-icon>
                                <span> {{ProjectsName}}</span>
                            </a>
                            <mat-divider></mat-divider>
                            <ng-container *ngFor="let pt of ProjectTypes">
                                <a routerLinkActive="active-link" mat-list-item routerLink="/projects/new/{{pt.ID}}" *iqHasPermission="ProjectCreatePermission hasAnywhere true" (click)="close()">
                                    <fa-icon [icon]="ProjectIcon" class="menu-icon"></fa-icon>
                                    <span> New {{pt.SingularName}}</span>
                                </a>
                                <mat-divider></mat-divider>
                            </ng-container>
                        </ng-container>

                        <a mat-list-item (click)="SignOut()">
                            <fa-icon [icon]="SignoutIcon" class="menu-icon"></fa-icon>
                            <span> Sign out</span>
                        </a>
                        <mat-divider></mat-divider>
                    </mat-nav-list>

                    <div style="padding-bottom:10px; padding-left:10px; font-size:small; flex-shrink: 0">
                        <div>Exactix v {{AppUpdateService.CurrentVersion}}</div>
                        <div style="padding-left:2em; font-size:x-small">by Irth Solutions</div>
                        <div *ngIf="AppUpdateService.UpdateIsAvailable" style="color:red" class="link" (click)="AppUpdateService.ActivateUpdateNow()">Update Available!</div>
                    </div>
                </div>
            </div>

            <div style="display: flex; flex-direction: column; height: 100%;" *ngIf="!ShowNav" [@slideInFromRight]="!ShowNav ? 'enter' : 'exit'">
                <mat-toolbar color="primary" class="iq-display-1">
                    <div class="iq-phone-header-left">
                        <iq-phone-button icon="Back" (click)="ShowNav = true">Back</iq-phone-button>
                    </div>
                </mat-toolbar>
                <mat-divider></mat-divider>
                <div style="display: flex; flex-direction: column; align-items: flex-start; flex-grow: 1;">

                    <button mat-button *ngIf="CanSwitchServer" [matMenuTriggerFor]="serverMenu">Switch to...<fa-icon [icon]="DownIcon" style="margin-left: 5px;"></fa-icon></button>
                    <mat-menu #serverMenu="matMenu">
                        <ng-template matMenuContent>
                            <button mat-menu-item *ngFor="let server of LinkedServers" [disabled]="CurrentServerCode === server.Code" (click)="SwitchServer(server)" style="display:flex">
                                <span *ngIf="CurrentServerCode === server.Code" style="flex: 0 1 auto">
                                    <fa-icon [icon]="CheckIcon" style="margin-right: 10px; vertical-align: middle;"></fa-icon>
                                </span>
                                <span style="flex:0 0 auto">
                                    {{server.Name}}
                                </span>
                            </button>
                        </ng-template>
                    </mat-menu>

                    <button mat-button [matMenuTriggerFor]="colorMenu" aria-describedby="Change Color" title="Change Color">
                        Color Scheme <mat-icon style="margin-right: unset">format_color_fill</mat-icon><fa-icon [icon]="DownIcon" style="margin-left: 5px;"></fa-icon>
                    </button>
                    <mat-menu #colorMenu="matMenu" class="iq-palette-picker">
                        <ng-template matMenuContent>
                            <mat-grid-list cols="2">
                                <mat-grid-tile class="color-palette-green" (click)="SetColorPalette('green')">
                                    <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                                </mat-grid-tile>
                                <mat-grid-tile class="color-palette-blue" (click)="SetColorPalette('blue')">
                                    <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                                </mat-grid-tile>
                                <mat-grid-tile class="color-palette-gray" (click)="SetColorPalette('gray')">
                                    <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                                </mat-grid-tile>
                                <mat-grid-tile class="color-palette-irth" (click)="SetColorPalette('irth')">
                                    <div class="primary-background" style="width:100%; height:100%; border-radius: 30px"></div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </ng-template>
                    </mat-menu>

                    <button mat-button [matMenuTriggerFor]="fontSizeMenu" aria-describedby="Change Font Size" title="Change Font Size">
                        Font Size <fa-icon [icon]="TextSizeIcon" style="margin-left: 5px;"></fa-icon><fa-icon [icon]="DownIcon" style="margin-left: 5px;"></fa-icon>
                    </button>
                    <mat-menu #fontSizeMenu="matMenu" class="iq-typography-picker">
                        <ng-template matMenuContent>
                            <button mat-menu-item (click)="SetFontSize('small')" class="iq-typography-small">
                                <div [ngClass]="{'iq-display-1': FontSize!=='small', 'iq-subheading-2':FontSize==='small'}">
                                    <mat-icon>format_size</mat-icon>
                                    <span>Small</span>
                                    <mat-icon *ngIf="FontSize==='small'">check</mat-icon>
                                </div>
                            </button>
                            <button mat-menu-item (click)="SetFontSize(null)" class="iq-typography-default">
                                <div [ngClass]="{'iq-display-1': FontSize!=null, 'iq-subheading-2':FontSize==null}">
                                    <mat-icon>format_size</mat-icon>
                                    <span>Medium</span>
                                    <mat-icon *ngIf="FontSize==null">check</mat-icon>
                                </div>
                            </button>
                            <button mat-menu-item (click)="SetFontSize('large')" class="iq-typography-large">
                                <div [ngClass]="{'iq-display-1': FontSize!=='large', 'iq-subheading-2':FontSize==='large'}">
                                    <mat-icon>format_size</mat-icon>
                                    <span>Large</span>
                                    <mat-icon *ngIf="FontSize==='large'">check</mat-icon>
                                </div>
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
        </div>
    </ng-template>
</iq-slideout>
