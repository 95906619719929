import { GeocodeTypeEnum } from "Enums/GeocodeType.enum";
import { DigSite } from "Models/DigSites/DigSite.model";

export class GeocodeRequest {
    constructor(
        public Digsite: DigSite,

        public RequestedGeocodeType: GeocodeTypeEnum,

        /**
         * The current ticket type.  Needed to check settings such as ClipToCounty/ClipToPlace.
         * If null, those settings will be defaulted/not performed.
         */
        public TicketTypeID: string,

        /**
         * Set to the ProjectType.ID if the geocode is for a Project.  This is needed when checking the allowed geocode types to
         * make sure we only allow geocode tickets for Tickets vs Projects - which can be very different.
         */
        public ForProjectTypeID?: string,

        /**
         * True to allow geocode type to fallback if can't be geocoded to the requested type.
         * Defaults to true if not set.
         */
        public AllowFallback?: boolean,

        /**
         * True to allow matching on adjacent places in addition to the specified place.
         */
        public IncludeAdjacentPlaces?: boolean,

        /*
         * True if the geocode is for a map search
         */
        public ForMapSearch?: boolean,

        /*
         * True if the geocode is for a near street
         */
        public ForNearStreet?: boolean) {
    }
}
