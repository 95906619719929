import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IQPipesModule } from '../../../Pipes/Pipes.module';
import { CarouselModule } from '../Carousel/Carousel.module';
import { AnnouncementsCarouselComponent } from './AnnouncementsCarousel.component';
import { IQSectionModule } from '../Section/Modules/Section.module';

@NgModule({
    imports: [
        CommonModule,
        IQPipesModule,
        FontAwesomeModule,
        IQSectionModule,
        CarouselModule
    ],
    declarations: [
        AnnouncementsCarouselComponent
    ],
    exports: [
        AnnouncementsCarouselComponent
    ]

})
export class AnnouncementsCarouselModule { }
