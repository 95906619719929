import { AfterViewInit, Component, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { from, Observable, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';

export class ObservableProgressDialogModel<T> {
    constructor(public ObservableList: Observable<T[]>[], public Title: string, public Message: string) {}
}

/**
 *  A dialog that shows the progess of executing an array of observables.
 * */
@Component({
    templateUrl: './ObservableProgressDialog.component.html',
    styleUrls: ['./ObservableProgressDialog.component.scss']
})
export class ObservableProgressDialogComponent<T> implements AfterViewInit, OnDestroy {

    public PctComplete: number = 0;
    public NumLoaded: number = 0;

    private _Items: T[] = [];

    private _Destroyed: Subject<void> = new Subject();

    constructor(private _DialogRef: MatDialogRef<ObservableProgressDialogComponent<T>>, @Inject(MAT_DIALOG_DATA) public data: ObservableProgressDialogModel<T>) {
    }

    ngAfterViewInit(): void {
        this.ExecuteObservables();
    }

    ngOnDestroy() {
        this._Destroyed.next();
        this._Destroyed.complete();
    }

    private ExecuteObservables(): void {

        let count = 0;
        const numItems = this.data.ObservableList.length;

        //  This executes each observable sequentially and appends each list of results to the final list in items
        from(this.data.ObservableList)                          //  Executes each observable in the array (causes .subscribe to be called for each)
            .pipe(concatMap(apiCallObs => apiCallObs), takeUntil(this._Destroyed))          //  Concats each obserable sequentially
            .subscribe(
                results => {                                    //  This fires for each apiCallObs.  Append the results as we go.
                    this._Items.push(...results);
                    count++;
                    this.PctComplete = Math.round((count / numItems) * 100);
                    this.NumLoaded = this._Items.length;
                },
                () => this._DialogRef.close(null),              //  Error
                () => this.OnComplete()                         //  Complete (or dialog closed), return all items we have loaded.
            );
    }

    public OnComplete(): void {
        this._DialogRef.close(this._Items);
    }
}
