import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconButtonComponent } from './IconButton.component';
import { UtilsModule } from 'Shared/Modules/Utils.module';

@NgModule({
    imports: [
        CommonModule, UtilsModule,
        FontAwesomeModule
    ],
    exports: [
        IconButtonComponent
    ],
    declarations: [
        IconButtonComponent
    ]
})

export class IconButtonModule { }
