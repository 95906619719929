import { Component, OnDestroy, ViewChild, ViewContainerRef, Input, 
        OnInit, TemplateRef, ContentChild, Directive, Renderer2, ElementRef } from '@angular/core';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViewStateEnum, PasswordRequirements, IqAwsCognitoConfig } from '../../models';
import { iQSignInComponent } from '../sign-in/sign-in.component';
import { iQResetPasswordComponent } from '../reset-password/reset-password.component';
import { iQLogoutComponent } from '../logout/logout.component';
import { iQSignUpComponent } from '../sign-up/sign-up.component';
import { iQConfirmUserComponent } from '../confirm-user/confirm-user.component';
import { iQNewPasswordComponent } from '../new-password/new-password.component';
import { AuthComponentBase } from '../../auth-component-base';

@Directive({
  selector: '[iqAwsSignInTemplate]'
})
export class iqAwsSignInTemplate {

}

@Directive({
  selector: '[iqAwsSignOutTemplate]'
})
export class iqAwsSignOutTemplate {
  
}

@Directive({
  selector: '[iqAwsResetPasswordTemplate]'
})
export class iqAwsResetPasswordTemplate {
  
}

@Directive({
  selector: '[iqAwsCreateNewUserTemplate]'
})
export class iqAwsCreateNewUserTemplate {
  
}

@Directive({
  selector: '[iqAwsConfirmUserTemplate]'
})
export class iqAwsConfirmUserTemplate {
  
}

@Directive({
  selector: '[iqAwsNewPasswordTemplate]'
})
export class iqAwsNewPasswordTemplate {
  
}


@Component({
  selector: 'iq-aws-cognito',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.css']
})
export class iQAuthContainerComponent implements OnDestroy, OnInit {
  private destroyed$: Subject<void> = new Subject();

  @Input() ShowBrandingImage = true;
  @Input() AllowCreateLogin = true;
  @Input() HideLogout = true;//By default in all our apps that use this we don't want to show the logout screen

  @Input() FederatedLoginReturnState: any = null;

  private _startingStep: ViewStateEnum;
  //Maybe add this to do two way binding, but I don't see why we would need it
  @Input() set StartingStep(val: ViewStateEnum){
    this._startingStep = val;

    if (!this._firstStateChangeCall)
      this.awsCognitoService.setViewState({ state: val, user: null, MessageData: null});
  };
  get StartingStep(){
    return this._startingStep;
  }

  private _passwordRequirements: PasswordRequirements;
  @Input() set PasswordRequirements(val: PasswordRequirements) {
    let defaultRequirements = new PasswordRequirements();
    this._passwordRequirements = { ...defaultRequirements, ...val };
  }
  get PasswordRequirements(){
    return this._passwordRequirements;
  }
  
  /** ClientMetadata to pass to the default controls */
  @Input() ClientMetadata: { [key: string]: string };


  /**
   * Pass in false if you want to have the busy overlay cover more than just the area of this control
   */
  @Input() LimitBusySymbolToControl: boolean = true;

  @ContentChild(iqAwsSignInTemplate, {read: TemplateRef, static: true}) signinTemplate: TemplateRef<AuthComponentBase>;
  @ContentChild(iqAwsSignOutTemplate, {read: TemplateRef, static: true}) signoutTemplate: TemplateRef<AuthComponentBase>;
  @ContentChild(iqAwsResetPasswordTemplate, {read: TemplateRef, static: true}) resetPasswordTemplate: TemplateRef<AuthComponentBase>;
  @ContentChild(iqAwsCreateNewUserTemplate, {read: TemplateRef, static: true}) createNewUserTemplate: TemplateRef<AuthComponentBase>;
  @ContentChild(iqAwsConfirmUserTemplate, {read: TemplateRef, static: true}) confirmUserTemplate: TemplateRef<AuthComponentBase>;
  @ContentChild(iqAwsNewPasswordTemplate, {read: TemplateRef, static: true}) newPasswordTemplate: TemplateRef<AuthComponentBase>;
  
  @ViewChild('defaultSigninTemplate', { read: TemplateRef, static: true }) defaultSigninTemplate: TemplateRef<iQSignInComponent>;
  @ViewChild('defaultSignoutTemplate', { read: TemplateRef, static: true }) defaultSignoutTemplate: TemplateRef<iQLogoutComponent>;
  @ViewChild('defaultResetPasswordTemplate', { read: TemplateRef, static: true }) defaultResetPasswordTemplate: TemplateRef<iQResetPasswordComponent>;
  @ViewChild('defaultCreateNewUserTemplate', { read: TemplateRef, static: true }) defaultCreateNewUserTemplate: TemplateRef<iQSignUpComponent>;
  @ViewChild('defaultConfirmUserTemplate', { read: TemplateRef, static: true }) defaultConfirmUserTemplate: TemplateRef<iQConfirmUserComponent>;
  @ViewChild('defaultNewPasswordTemplate', { read: TemplateRef, static: true }) defaultNewPasswordTemplate: TemplateRef<iQNewPasswordComponent>;
  
  @ViewChild('viewContainer', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;


  siginImage: string;

  private _firstStateChangeCall = true;

  constructor(public awsCognitoService: IqAwsCognitoService,
    private elemRef: ElementRef, private renderer2: Renderer2, private config: IqAwsCognitoConfig) {
    
      this.siginImage = config.DefaultSignInImage;
  }

  ngOnInit(){
    if (this.LimitBusySymbolToControl == true){
      this.renderer2.setStyle(this.elemRef.nativeElement, "position", "relative");
    }

    if (this._passwordRequirements == null)
      this.PasswordRequirements = new PasswordRequirements();

    this.awsCognitoService.viewStateInfoChange$.pipe(takeUntil(this.destroyed$))
        .subscribe(val => this.ShowView(val.state));
  };

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private ShowView(authState: ViewStateEnum) {
    
    //If this is thie first call and the starting step is different than the step the default flow is 
    //  then we need to change it so stuff matches up.  We can't jsut set this in the OnInit because it will get updated after
    //  the awsCognitoService is initiated because that automatically calls to check if the user is valid and sets the state.
    if (this._firstStateChangeCall){
      this._firstStateChangeCall = false;

      //Reset the state and return, this will be called again.
      if(this.StartingStep != null && this.StartingStep != authState && authState !== ViewStateEnum.signedIn) {
        this.ShowView(this.StartingStep);
        return;
      }
    }
    
    
    switch (authState) {
      case ViewStateEnum.signedOut:
        this.createComponentTemplate(this.signinTemplate || this.defaultSigninTemplate);
        break;
      case ViewStateEnum.resetPassword:
        this.createComponentTemplate(this.resetPasswordTemplate || this.defaultResetPasswordTemplate);
        break;
      case ViewStateEnum.signedIn:
        if (!this.HideLogout)//By default we hide this because we don't want it on any of our apps because we signout other ways
          this.createComponentTemplate(this.signoutTemplate || this.defaultSignoutTemplate);
        else
          this.viewContainer.clear();
        break;
      case ViewStateEnum.createNewUser:
        this.createComponentTemplate(this.createNewUserTemplate || this.defaultCreateNewUserTemplate);
        break;
      case ViewStateEnum.confirmWithCode:
        this.createComponentTemplate(this.confirmUserTemplate || this.defaultConfirmUserTemplate);
        break;
      case ViewStateEnum.newPasswordRequired:
        this.createComponentTemplate(this.newPasswordTemplate || this.defaultNewPasswordTemplate);
        break;
      default://Not 100% sure we want this, but for now if the sate isn't found then show the login
        this.createComponentTemplate(this.signoutTemplate || this.defaultSignoutTemplate);
    }
  }

  private createComponentTemplate(template: TemplateRef<AuthComponentBase>) {
    this.viewContainer.clear();

    return this.viewContainer.createEmbeddedView(template);
  }
}
