import { ScheduledTask } from "@iqModels/ScheduledTasks/ScheduledTask.model";
import { EventEmitter } from '@angular/core';

export class ScheduledTaskRefreshInfo {
    public CompletedCallbackList: ((task: ScheduledTask) => void)[];

    public CompletedEventHandlers: EventEmitter<ScheduledTask>[];

    constructor(public ScheduledTask: ScheduledTask, firstEventHandler: EventEmitter<ScheduledTask>, firstCallback: (task: ScheduledTask) => void) {
        this.CompletedEventHandlers = [firstEventHandler];
        this.CompletedCallbackList = [firstCallback];
    }
}

