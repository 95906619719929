<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Unable to position map</h1>
<mat-dialog-content style="display:flex; flex-direction:column">
    <div>{{Message}}</div>
    <div *ngIf="ShowPermissionInfo" style="padding-top:1em">
        <div>Please adjust your browser settings:</div>
        <div>
            <ul>
                <li>Chrome &amp; Edge: Click the lock icon with the red "x" to the right of the browser url at the top of the page.</li>
                <li>FireFox: Click the location icon to the left of the browser url at the top of the page.</li>
            </ul>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="display:flex; justify-content:center">
    <iq-icon-button button-class="Dialog" icon="OK" mat-dialog-close>OK</iq-icon-button>
</mat-dialog-actions>
