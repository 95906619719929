import { Component, Input } from '@angular/core';
import { EntityEnum } from 'Enums/EntityType.enum';
import { TicketResponseService } from 'Pages/Tickets/Services/TicketResponse.service';
import { SettingsService } from 'Services/SettingsService';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { AuthenticationService } from '../../../../../Services/AuthenticationService';
import { TicketActionsService } from '../../../Services/TicketActions.service';
import { PhoneTicketListBase } from '../PhoneTicketListBase';

@Component({
    templateUrl: './PhoneTicketResponseSearch.component.html',
    styleUrls: ['./PhoneTicketResponseSearch.component.scss'],
    animations: [slideInFromRight]
})
export class PhoneTicketResponseSearchComponent extends PhoneTicketListBase {

    @Input() CanViewResponses: boolean = false;
    @Input() ShowPositiveResponse: boolean = false;

    EntityEnum = EntityEnum.TicketResponse;

    constructor(protected ticketService: TicketResponseService, protected settingsService: SettingsService,
        protected ticketActionsService: TicketActionsService, authenticationService: AuthenticationService) {
        super(ticketService, settingsService, ticketActionsService, authenticationService);
    }
}
