<div class="iq-list-select-item iq-body-2" aria-haspopup="true">
    <div class="iq-item-row">
        <ng-container *ngIf="actions == null || ShowSelect; else showActions">
            <div class="check-box" [ngClass]="{'actionable-background': checked }" (click)="ToggleCheck()">
                <mat-checkbox [(ngModel)]="checked" color="primary"></mat-checkbox>
            </div>
        </ng-container>
        <div class="iq-item-details" [ngClass]="{'pl10': !hasMenu}">
            <ng-container *ngIf="hasMenu === true; then withMenu;">
                <!--Outlet for the templates.  This way seems to make it less jumpy than doing inline html here-->
            </ng-container>
            <ng-content></ng-content>
        </div>
    </div>
    <div class="menu-container iq-body-2" *ngIf="showMenu">
        <div class="menu-left-pad"></div>
        <div style="flex-grow:1">
            <div *ngIf="hasMenu" class="show-hide-menu"></div>
            <ng-content select="iq-list-menu"></ng-content>
        </div>
    </div>
</div>

<ng-template #withMenu>
    <div class="show-hide-menu">
        <span *ngIf="!showMenu" class="link" (click)="ToggleShowMore()">More</span>
        <span *ngIf="showMenu" class="link" (click)="ToggleShowMore()">Less</span>
    </div>
</ng-template>

<ng-template #showActions>
    <ng-container *ngIf="actions && actions.length > 0">
        <button mat-icon-button class="iq-body-1" matTooltip="Actions" [matMenuTriggerFor]="filter">
            <mat-icon aria-label="Actions">more_vert</mat-icon>
        </button>
        <mat-menu #filter="matMenu">
            <ng-template matMenuContent>
                <div style="display: flex; flex-direction: column;">
                    <button matMenuItem mat-button (click)="ChangeToSelect()">
                        <mat-icon>check_box_outline_blank</mat-icon>
                        <span>Select</span>
                    </button>

                    <ng-container *ngFor="let action of actions">
                        <button *ngIf="action" matMenuItem mat-button (click)="ActionClicked(action)" [disabled]="action.Disabled">
                            <mat-icon>{{action.iconName}}</mat-icon>
                            <span>{{action.Name}}</span>
                        </button>
                        <mat-divider *ngIf="!action"></mat-divider>
                    </ng-container>
                </div>
            </ng-template>
        </mat-menu>
    </ng-container>
</ng-template>
