import { ViewState } from './models';
import { IqAwsCognitoService } from './Services/iq-aws-cognito.service';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from './form-component-base';
import { Input, Directive } from '@angular/core';

///Base class used for the Login controls
@Directive()
export abstract class AuthComponentBase extends FormComponentBase {

    /**Configured data used to pass data to Cognito for Lambdas when allowed.  Not stored with a login*/
    @Input() ClientMetadata: { [key: string]: string };

    private _authState: ViewState;
    set authState(authState: ViewState) {
        this._authState = authState;
        this._show = this.ShouldDisplay();
    }
    get authState() {
        return this._authState;
    }

    _show: boolean = true;


    abstract ShouldDisplay(): boolean;
    //abstract BuildForm(): FormGroup;

    //group: FormGroup;// = this.BuildForm();

    constructor(protected awsCognitoService: IqAwsCognitoService) {
        super();

        this.awsCognitoService.viewStateInfoChange$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                this.authState = val;
            });
    }

    protected SetActionMessage(data: any) {
        const details = data.codeDeliveryDetails || data.CodeDeliveryDetails;
        if (details) {
            //  When sent from CognitoAdmin api, deliveryMedium has a .Value property.
            //  If using the stock amplify ForgotPassword, it doesn't...
            const deliveryMedium = details.DeliveryMedium.Value || details.DeliveryMedium;
            switch (deliveryMedium) {
                case "EMAIL":
                    this.ActionMessage = "An email has been sent to " + details.Destination + ".  Please check that email for the code.";
                    break;

                default:
                    this.ActionMessage = null;
            }
        }
    }
}
