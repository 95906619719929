import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { BaseAddDialogDataModel } from 'Shared/BaseClasses/BaseAddDialogDataModel';
import { TicketDiscussionService } from '../../Services/TicketDiscussion.service';

export class AddMarkingDelayResponseDialogData extends BaseAddDialogDataModel {
    TicketDiscussion: TicketDiscussionChildResponse;
}

@Component({
    templateUrl: './AddMarkingDelayResponseDialog.component.html',
    styleUrls: ['./AddMarkingDelayResponseDialog.component.scss']
})
export class AddMarkingDelayResponseDialogComponent {
    public ResponseFormControl: UntypedFormControl;

    public IsValid: boolean = false;
    public IsSaving: boolean = false;

    constructor(private _DialogRef: MatDialogRef<AddMarkingDelayResponseDialogComponent>, @Inject(MAT_DIALOG_DATA) private _Data: AddMarkingDelayResponseDialogData,
        private _DiscussionService: TicketDiscussionService) {

        this.ResponseFormControl = new UntypedFormControl(null, [Validators.required]);
    }

    public OnSave(): void {
        this.IsSaving = true;

        this._DiscussionService.SaveResponse(this._Data.TicketDiscussion.ID, this.ResponseFormControl.value)
            .subscribe(updatedDiscussionList => {
                //  Should only ever be 1 for this type of discussion
                if (updatedDiscussionList && updatedDiscussionList.length > 0)
                    this._DialogRef.close(updatedDiscussionList);
                else
                    this.IsSaving = false;      //  Nothing updated?  Should not happen...
            }, err => {
                this.IsSaving = false;
            });
    }
}
