import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import '@iqSharedUtils/StringUtils';//Need to import this here so that the string utils will work in prod builds. If this is not here then the Comms list page will hit a point where it's trying to use 'iqToUppercase' before it's been loaded
import Amplify from 'aws-amplify';//For some reason this is needed or the build throws an error...I'm trying to figure out how to get it out of here.
import { LocalEncodedStorage } from 'iqCognito/Storage/local-encoded-storage';
import { IqAwsCognitoConfig, SingleSignOnService } from 'iqCognito/models';
import { iFrameSingleSignOnServiceConfig, iFrameSingleSignOnService } from 'iqCognito/Services/i-frame-single-sign-on.service';
import { GtagConfigToken } from "Services/GoogleAnalytics/google-gtag.service";


//User this code to not even load the Angular app if we don't support the browser.  Same code that is in the index.html but this is incase we build
//  scripts for a browser that we don't support (like if we decide not to support opera or some browser that angular works with).
function GetBrowser() {
    let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/Edge\/\d./i.test(ua)) {
        tem = /(edge\/|rv:|msie\s)([\d.]+)/i.exec(ua) || [];
        return { name: 'Edge', version: tem[2] };
    }
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/)
        if (tem !== null) {
            return { name: 'Opera', version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) { M.splice(1, 1, tem[1]); }
    return {
        name: M[0],
        version: M[1]
    };
};

const browser = GetBrowser();

if ((browser.name === "IE" || browser.name === "MSIE") ||//IE
    (browser.name === "Safari" && parseInt(browser.version) < 9))//Safari
{
    console.log('Browser not supported.  App not loaded');

}
else {    
    //Have to load the Auth settings befroe we even try to bootstrap or we get an error about it not being configured.
    //fetch('./assets/amplifyConfig-4iQInternal-us-west-2.json?ngsw-bypass=true')
    fetch('./assets/amplifyConfig.json?ngsw-bypass=true')
        .then(response => response.json())
        .then(config => {

            if (environment.production) {
                enableProdMode()
            }

            //let isEmbedded = true;
            //let isEmbeddedInCoursettra = true;
            const isEmbedded = window.self !== window.top;

            let isEmbeddedInCoursettra = false;
            const coursettraAllowedUrls = environment.AllowedEmbedCoursettraUrls;//config.AllowedEmbedCoursettraUrls
            if (isEmbedded) {
                for (let i = 0; i < coursettraAllowedUrls.length; i++) {
                    if (document.referrer.startsWith(coursettraAllowedUrls[i])) {
                        isEmbeddedInCoursettra = true;
                        break;
                    }
                }
            }

            //If this is embedded in a iframe we don't control then don't let it show anything.  Have to do this so that we can show the ticket screen in Coursettra, but I would prefer the
            //  ticket controls to be a shared library so we don't have to embed sites in each other.
            if (isEmbedded && !isEmbeddedInCoursettra) {
                console.log('embeded');
                return;
            }

            const iframeSSOSettings: iFrameSingleSignOnServiceConfig = config.iframeSingleSignOnSettings;            
            
            if (iframeSSOSettings && iframeSSOSettings.iFrameUrl && iframeSSOSettings.iFrameUrl !== "") {
                let iframe = document.getElementById(iframeSSOSettings.iFrameElementId);
                if (!iframe) {
                    //  Dynamically create the iframe if we don't find it.  This allows us to easily change the iFrameElementId.
                    //  Otherwise, if we rely on the iframe being in the index.html and change the configuration, the current
                    //  version of the index.html will be downloaded (with the new name) but the cached app may be referencing
                    //  the OLD name.  When that happened, it caused the app to blow up during initialization and just show a white page!
                    iframe = document.createElement('iframe');
                    iframe.id = iframeSSOSettings.iFrameElementId;
                    iframe.style.display = "none";
                    iframe.style.border = "none";
                    document.body.appendChild(iframe);
                }
                iframe.setAttribute("src", iframeSSOSettings.iFrameUrl);
            }

            //Set Coursettra config to use encoded local storage
            const coursettraConfig = config.AllowedAlternateCognitoConfigurations
                && config.AllowedAlternateCognitoConfigurations.length !== 0 ? config.AllowedAlternateCognitoConfigurations.find(f => f.Name === "Coursettra") : null;
            if (coursettraConfig) {
                coursettraConfig.Configuration.storage = LocalEncodedStorage;
            }
            //Set Google config to use encoded local storage
            const googleConfig = config.AllowedAlternateCognitoConfigurations
                && config.AllowedAlternateCognitoConfigurations.length !== 0 ? config.AllowedAlternateCognitoConfigurations.find(f => f.Name === "Google") : null;
            if (googleConfig) {
                googleConfig.Configuration.storage = LocalEncodedStorage;
            }

            //  This is the same thing done inside SettingsService.ApiBaseUrl() - but can't create the SettingsService here.
            let apiUrl: string;
            if (environment.apiBaseUrl)
                apiUrl = environment.apiBaseUrl;
            else
                apiUrl = window.location.origin + "/api";
            //  For testing locally using ngrok with this command: ngrok http 80
            //  ** If testing forgot password, also need to set this in CognitoAdminBiz.ForgotPassword()
            //apiUrl = "http://0a13-2603-6011-2400-2e92-10e1-746a-60c4-836f.ngrok.io/TixApi";

            //Use the encoded localstorage to encode the refresh token and not have it stored raw
            config.Auth.storage = LocalEncodedStorage;
            const iqCogConfig: IqAwsCognitoConfig = {
                DefaultAppName: "Exactix",
                DefaultSignInImage: "./assets/Images/Logos/Exactix-logo.png",
                DefaultCognitoSettings: config.Auth,
                AlternateCognitoSettings: config.AllowedAlternateCognitoConfigurations,
                Debug: false,
                FindExistingUserUrl: apiUrl + "/CognitoAdmin/FindExistingUser",
            };

            //Set this to know if we are embedded in Coursettra (or any other app we control that need to try and single sign on with)
            environment.isEmbedded = isEmbedded;
            if (isEmbedded) {//If embeded in Coursettra set the config so we know to try and login with it's token if we don't have one of our own.
                if (isEmbeddedInCoursettra && iframeSSOSettings && coursettraConfig)
                    environment.embeddedAuthConfig = coursettraConfig.Configuration;
            }


            const gtagConfig: any = {
                targetId: config?.GoogleAnalytics?.trackingID,
                capturePageView: config?.GoogleAnalytics?.capturePageView,
                ignoreRoutes: config?.GoogleAnalytics?.ignoreRoutes
            }

            //Only add in the property if something is set in the config
            if (config?.GoogleAnalytics && config.GoogleAnalytics.moreIds && config.GoogleAnalytics.moreIds.length > 0)
                gtagConfig.moreIds = config.GoogleAnalytics.moreIds;

            platformBrowserDynamic(
                [
                    //iqAwsCognito services needed to read configs
                    { provide: IqAwsCognitoConfig, useValue: iqCogConfig },// Cognito settings
                    { provide: iFrameSingleSignOnServiceConfig, useValue: iframeSSOSettings },// Settings to use for the iframe single sign on service
                    { provide: SingleSignOnService, useClass: iFrameSingleSignOnService, deps: [iFrameSingleSignOnServiceConfig] },// How to handle single sign on.  Currently using the iFrameSingleSignOnService in iqAwsCognito package
                    {
                        provide: GtagConfigToken,
                        useValue: gtagConfig
                    }                    
                ]
            ).bootstrapModule(AppModule)
                .catch(err => console.log(err));
        });

    
}
