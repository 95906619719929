import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'Shared/Utils/Dictionary';
import { Guid } from 'Shared/Utils/Guid';


@Injectable({
    providedIn: 'root'
})
export class BusyService {

    //This is the page level observable.  If you subscribe to this it will get triggered when an api call happens.
    //  Should never need to subscribe to this, but if for some reason you do, make sure that if you call this and subscribe to it the you unsubscribe when your component is disposed of
    public generalObservable: BehaviorSubject<boolean>;//Use BehaviorSubjects for these because it needs an initial value right away.  And when subscribed to it will return the last value and not have to wait until "next" is called on an observer

    //Use BehaviorSubjects for these instead of Observeable/Observer because it needs an initial value right away.
    //  Behaviorsubjects are types of observables that need a default value and when subscribed to it will return the last value and not have to wait until "next" is called on an observer
    private behaviorDictionary = new Dictionary<BehaviorSubject<boolean>>();

    private busySections = new Dictionary<Guid>();//Used to keep a count of the sections that are busy, so we don't remove the general indicator if there is a section still busy
    
    constructor()
    {
        //Create the observable so that they are available at startup.
        this.generalObservable = new BehaviorSubject<boolean>(false);
    }

    //Create the new observable and add it to a collection.  Return the key to used to toggle the busy state
    //  If showInitial = true, it will start out as shown (needed so that subscribers can start showing immediately).  If you want to override it to not start shown pass in false
    createNew(): string {
        let key = Guid.newGuid();

        //Just incase we get a duplicate
        while (this.behaviorDictionary.ContainsKey(key))
            key = Guid.newGuid();

        this.behaviorDictionary.Add(key, new BehaviorSubject(false));

        return key;
    }

    //Set the observable to busy for the given key.  If the key doesn't exist it does nothing.
    //  Also shows the general busy indicator
    showByKey(key: string) {

        if (this.behaviorDictionary.ContainsKey(key))
            this.behaviorDictionary.Item(key).next(true);
        
        if (this.busySections.Count() === 0)
            this.showGeneral();

        this.busySections.Add(key, key);
    }

    //Set the observable to not busy for the given key.  If the key doesn't exist it does nothing.
    //  Also hides the general busy indicator
    hideByKey(key: string) {

        if (this.behaviorDictionary.ContainsKey(key))
            this.behaviorDictionary.Item(key).next(false);

        this.busySections.Remove(key);

        if (this.busySections.Count() === 0)
            this.hideGeneral();
    }

    //Can happen if the container that the busy indicator is in is shown and hidden
    addByKey(key: string) {
        this.behaviorDictionary.Add(key, new BehaviorSubject(false));
    }

    //Remove the observable and observer for the give key.
    removeByKey(key: string) {
        this.hideByKey(key);//Hide any active instances
        this.behaviorDictionary.Remove(key);
    }

    //Get the observable for the given key.
    //  Should never need to subscribe to these (it's only needed for the busy components), but if for some reason you do, make sure that if you call this and subscribe to it the you unsubscribe when your component is disposed of
    getByKey(key: string): BehaviorSubject<boolean> {
        return this.behaviorDictionary.Item(key);
    }
    //This is used for the general indicator at the top of the screen.
    showGeneral() {
        if (this.generalObservable) {
            this.generalObservable.next(true);
        }
    }
    //This is used for the general indicator at the top of the screen.
    hideGeneral() {
        //Don't hide it if there is a busy section
        if (this.generalObservable && this.busySections.Count() === 0) {
            this.generalObservable.next(false);
        }
    }
}
