import { Observable } from "rxjs";
import { SearchResponse } from "@iqModels/Searching/SearchResponse.model";
import { EntityEnum } from "Enums/EntityType.enum";
import { EntityBase } from "@iqModels/Base/EntityBase.model";
import { SelectOption } from "../SelectOption.model";
import { SearchFilter } from "../../Searching/SearchFilter.model";
import { SearchOrderBy } from "../../Searching/SearchOrderBy.model";


export class ListFilterGroup extends EntityBase {
    Entity: EntityEnum;
    Name: string = "";
    IsDefault: boolean;
    Filters: SearchFilter[];
    OrderBy: SearchOrderBy[];

    //Used to signify the default if a user doesn't have one selected.  Can not be saved
    IsSystemDefault: boolean;

    IsSystemWide: boolean;
    RoleTypes: SelectOption[];
}
