import { Pipe, PipeTransform } from '@angular/core';
import { ResponseEntryMethodEnum, ResponseEntryMethodEnumDescriptions } from 'Enums/ResponseEntryMethod.enum';

@Pipe({
    name: 'ResponseEntryMethodEnum'
})
export class ResponseEntryMethodEnumPipe implements PipeTransform {
    transform(value: ResponseEntryMethodEnum): string {
        if (value == null) {
            return "";
        }

        return ResponseEntryMethodEnumDescriptions[ResponseEntryMethodEnum[value]];
    }
}
