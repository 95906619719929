import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { MainMenuService } from 'Services/MainMenuService';

@Directive()
export abstract class AnonymousPageBase implements OnInit, OnDestroy {
    protected Destroyed = new Subject<void>();

    //  Allows the page to also be used when the user is logged in
    public IsAnonymous: boolean = true;

    constructor(private _MainMenuService: MainMenuService, authenticationService: AuthenticationService) {
        //  Initial check is so that the menu doesn't flash closed if we're already logged in
        this.IsAnonymous = !authenticationService.CurrentUser?.CurrentOneCallCenterCode;

        _MainMenuService.forceClose = this.IsAnonymous;

        //  This check is necessary in case the user refreshes the page
        authenticationService.CurrentUserObserver()
            .pipe(takeUntil(this.Destroyed))
            .subscribe(userInfo => {
                this.IsAnonymous = !userInfo.CurrentOneCallCenterCode;
                _MainMenuService.forceClose = this.IsAnonymous;
            });

        _MainMenuService.hideMenu.pipe(takeUntil(this.Destroyed)).subscribe(val => {
            //For some reason after printing it will sometimes (not always) show the menu area.
            //Don't let this ever show the menu on the side.  If it ever tries change it back.
            if (!val.hide)
                _MainMenuService.forceClose = this.IsAnonymous;
        });
    }

    ngOnDestroy() {
        this.Destroyed.next();
        this.Destroyed.complete();
        this._MainMenuService.forceClose = false;
    }

    ngOnInit() {
        setTimeout(() => {
            this._MainMenuService.forceClose = this.IsAnonymous;
        });
    }
}
