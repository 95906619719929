import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { EntryFieldDropdownItem } from 'Models/EntryFields/EntryFieldDropdownItem.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { EntryFormControl } from 'Shared/EntryFields/Forms/EntryFormControl';
import { EntryFormGroupBase } from "./EntryFormGroupBase";

/*
 *  Base class for a component that contains an EntryFormGroupBase (i.e. a section or a form).
 */
@Directive()
export abstract class EntryFormGroupContainer<TFormGroup extends EntryFormGroupBase> implements OnDestroy
{
    private _EntryFormGroup: TFormGroup;
    @Input()
    get EntryFormGroup(): TFormGroup { return this._EntryFormGroup; }
    set EntryFormGroup(val: TFormGroup) {
        this._EntryFormGroup = val;

        //  This should never be called with a null!  But it happened by a SC user who managed to trigger
        //  the VerifyLocation dialog after the form had been destroyed!  Added a check there to prevent that
        //  but added this check to prevent the js error if it happens some other way.
        if (val) {
            this._IsEditing = val.IsEditing;
            this.OnEntryFormGroupSet();
        }
    }

    private _IsEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _NotEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public get IsEditing(): BehaviorSubject<boolean> {
        if (this.ForceReadOnly)
            return this._NotEditing;
        return this._IsEditing;
    }

    /**
     *  Can set this to true to force the section/form to be readonly - overrides the "IsEditing" flag.
     *  This can be used if we need to re-use the component to show a readonly view as a confirmation during entry.
     *  i.e. in a confirmation dialog before saving.
     */
    @Input()
    public ForceReadOnly: boolean;

    protected Destroyed: Subject<void> = new Subject();

    constructor() {
    }

    public ngOnDestroy(): void {
        this.Destroyed.next();
        this.Destroyed.complete();

        this._IsEditing = null;
        this._EntryFormGroup = null;
    }

    //  Derived classes can override this to detect when the TicketEntryForm has been reset.
    //  Needed if the view does not get re-created (which, for some reason, happens to the side panel).
    protected OnEntryFormGroupSet(): void {
    }

    public GetValueFromForm(propertyName: string): any {
        if (!this.EntryFormGroup)
            return null;
        return this.EntryFormGroup.GetValueFromForm(propertyName);
    }

    public GetValueFromFormControl(control: AbstractControl): any {
        return EntryFormGroupBase.GetValueFromFormControl(control);
    }

    public GetDisplayValueFromForm(propertyName: string): string {
        const v = this.GetValueFromForm(propertyName);
        if ((v !== null) && (v !== undefined))
            return v.toString();
        return '';
    }

    public GetDropdownDisplayValueFromForm(propertyName: string): string {
        if (!this.EntryFormGroup)
            return '';

        return this.EntryFormGroup.GetDropdownDisplayValueFromForm(propertyName);
    }

    public GetDropdownDisplayValueForFormControl(formControl: EntryFormControl): string {
        return this.EntryFormGroup.GetDropdownDisplayValueForFormControl(formControl);
    }

    public GetDropdownItemsForControl(propertyName: string): EntryFieldDropdownItem[] {
        return this.EntryFormGroup.GetDropdownItemsForControl(propertyName);
    }

    public GetDropdownItemsForFormControl(control: AbstractControl): EntryFieldDropdownItem[] {
        return this.EntryFormGroup.GetDropdownItemsForFormControl(control);
    }

    public GetDropdownDisplayValueForControlAndValue(propertyName: string, selectedValue: any): string {
        if (!this.EntryFormGroup)
            return '';

        return this.EntryFormGroup.GetDropdownDisplayValueForControlAndValue(propertyName, selectedValue);
    }

    /**
     * Sets a property value for a field without emitting a change event.
     * @param propertyName
     * @param propertyValue
     */
    protected UpdateSinglePropertyValue(propertyName: string, propertyValue: any): void {
        //  If null, page has been destroyed already
        if (this.EntryFormGroup)
            this.EntryFormGroup.UpdateSinglePropertyValue(propertyName, propertyValue);
    }

    /**
     * Set the value in the formControl if the value is different.  Doing this avoids triggering all kinds
     * of change & status event notifications that get fired even if the value is the same!
     * @param formControl
     * @param newValue
     */
    protected SetFormValueIfDifferent(formControl: UntypedFormControl, newValue: any): void {
        if (formControl.value !== newValue)
            formControl.setValue(newValue);
    }

    protected SetFormValueForControlNameIfDifferent(formControlName: string, newValue: any): void {
        const formControl = this.EntryFormGroup.get(formControlName) as UntypedFormControl;
        if (formControl !== null)
            this.SetFormValueIfDifferent(formControl, newValue);
    }
}
