import { Injectable } from '@angular/core';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { Announcement } from 'Models/Announcements/Announcement.model';

//Update this to use the base CRUDService if we need to.  This is a child item so there isn't any real permissions for it, they have to come from the parent it belongs to.
//  So with that and only doing add, and delete I didn't want to extend CRUDService and make the permissions confusing and possibly call one of the other methods that aren't implemented
//  to handle permissions correctly.
@Injectable({
    providedIn: 'root'
})
export class AnnouncementService extends CRUDBaseService<Announcement> {
    ViewPermission: PermissionsEnum = PermissionsEnum.OneCallCenter_View;
    EditPermission: PermissionsEnum = PermissionsEnum.OneCallCenter_Edit;
    CreatePermission: PermissionsEnum = PermissionsEnum.OneCallCenter_Edit;
    DeletePermission: PermissionsEnum = PermissionsEnum.OneCallCenter_Edit;

    protected apiPath: string = 'Administration/Announcements';

    constructor(//public http: HttpClient, public settingsService: SettingsService, public permissionService: PermissionsService,
        protected services: CRUDServices) {
        super(services)
    }

    GetActive(forLogin = false) {
        return this.services.http.get<Announcement[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/GetActive/" + forLogin);
    }


    //CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', item: Announcement): Observable<boolean> {

    //    return this.permissionService.CurrentUserHasPermission(PermissionsEnum.OneCallCenter_Edit);
    //}

    //Delete(item: Announcement): Observable<Announcement> {
    //    return this.CanPerformAction('Delete', item).pipe(mergeMap(allowed => {
    //        if (!allowed)
    //            return new BehaviorSubject<Announcement>({} as Announcement)
    //                .pipe((data) => {//Do something to inform the user??
    //                    console.log('invalid permission');
    //                    return data;
    //                }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

    //        return this.http.delete<Announcement>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/" + item.ID);
    //    }));
    //}

    ////The caller needs to supply the subscribe so that it can do any logic that's needed
    //InsertOrUpdate(model: Announcement): Observable<Announcement> {
    //    //Always an Edit since it's a child property
    //    return this.CanPerformAction('Edit', model).pipe(mergeMap(allowed => {
    //        if (!allowed)
    //            return new BehaviorSubject<Announcement>({} as Announcement)
    //                .pipe((data) => {//Do something to inform the user??
    //                    console.log('invalid permission');
    //                    return data;
    //                }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

    //        return this.http.post<Announcement>(this.settingsService.ApiBaseUrl + "/" + this.apiPath, model);
    //    }));
    //}
}
