export enum TicketFunctionActionEnum {
    New = 0,
    Edit = 1,
    Cancel = 2,
    Late = 3,

    /**
     * Function used to add to the internal comments.  Does not create a new ticket record - updates the existing record.
     * Will never be set to Ticket.TicketFunction - just used to trigger the UI and so the server knows how to handle it
     * when saving (to not create a new version, etc).
     */
    AddComments = 4
}
