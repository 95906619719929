import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketResponseServiceAreaListData } from '../TicketResponseServiceArea.component';

@Component({
    templateUrl: './TicketResponseServiceAreaListDialog.component.html',
    styleUrls: ['./TicketResponseServiceAreaListDialog.component.scss']
})
export class TicketResponseServiceAreaListDialog {

    constructor(public DialogRef: MatDialogRef<TicketResponseServiceAreaListData>,
        @Inject(MAT_DIALOG_DATA) public Data: TicketResponseServiceAreaListData)
    {
    }
}
