<div style="overflow:hidden; display:flex; flex-grow:1; flex-direction:column; background-color: white; flex-basis:100%;">
    <mat-toolbar color="primary" class="iq-display-1">
        <div class="iq-phone-header-left">
            <iq-phone-button style="padding-right: 20px;" icon="Cancel" (click)="Close()">Close</iq-phone-button>
        </div>
        <div class="iq-phone-header-center">Add Affected Service Area</div>
        <div class="iq-phone-header-right">
            <iq-phone-button icon="OK" [disabled]="!child.HaveSelections" (click)="AddToTicket()">{{AddButtonText}}</iq-phone-button>
        </div>
    </mat-toolbar>
    <div style="padding: 20px 2vw 20px 2vw; display:flex; flex-grow:1;">
        <manually-add-service-area [ComponentData]="ComponentData" #child></manually-add-service-area>
    </div>
</div>
