import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SectionItemComponent } from './Item/SectionItem.component';

export class GroupEditChange {
    constructor(public edit: boolean, public focusFirst: boolean = false, public focusLast: boolean = false) {
    }
}

export class FirstOrLastItemFocusChange {
    constructor(public first: boolean) {
    }
}

@Injectable()       //  Included in the providers section of IQSectionModule
export class SectionGroupService {

    public id: number = null;

    public EditAllowed: boolean = null;

    //Fired when a child needs to change the group edit value
    public groupChildEditChangeObserver: BehaviorSubject<boolean> = new BehaviorSubject(null);

    //Fired when a child has been focused automatically by the group changing to edit
    public groupChildFocusedOnAutoOpenObserver: BehaviorSubject<boolean> = new BehaviorSubject(null);

    //fired when the group needs to change it's children edit value 
    public groupEditChangeObserver: BehaviorSubject<GroupEditChange> = new BehaviorSubject(new GroupEditChange(null));

    //fired then the last control in the section container is tabbed from.  ie the first control in the first group or the last control in the last group (these can also be designated by the html markup)
    public sectionContainerFocusLost: BehaviorSubject<FirstOrLastItemFocusChange> = new BehaviorSubject(null);

    //fired when the section item is in edit mode and it gets focus.  Don't need it when it's in view mode because the changing to edit mode will handle what we need this for (to fire the group focused event)
    public sectionItemFocusedInEdit: BehaviorSubject<boolean> = new BehaviorSubject(null);

    public SectionItemVisibilityChanged: Subject<SectionItemComponent> = new Subject();

    constructor() {
    }
}
