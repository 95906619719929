import { ReceiverTypeEnum } from "Enums/ReceiverType.enum";

export class SendTicketToExcavatorRequest {

    constructor(
        //  Can be multiple if we created multiple tickets by splitting up the dig site.
        public TicketIDList: string[],
        public ExcavatorContactID: string,
        public EmailAddressList: string[],
        public FaxNumber: string) { }
}


export class SendTicketToExcavatorItem {
    constructor(
        public Send: boolean,
        public ReceiverType: ReceiverTypeEnum,
        public Name: string,
        public Address: string) { }
}
