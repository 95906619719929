import { AbstractControl } from '@angular/forms';


export function EntityHasIDValidator(control: AbstractControl) {
    if (control.value !== null && control.value !== undefined && (control.value.ID === null || control.value.ID === undefined))
        return { noID: true };

    return null;
}

export function SelectOptionSelectedValidator(control: AbstractControl) {
    if (control.value !== null && control.value !== undefined && (control.value.Value === null || control.value.Value === undefined))
        return { noID: true };

    return null;
}

export const EntityHasProperty = (property: string) => {
    return (control: AbstractControl) => {
        if (control.value !== null && control.value !== undefined && (control.value[property] === null || control.value[property] === undefined))
            return {
                missingProp: { valid: false, propertyName: property }
            };

        return null
    };
};
