<div>Why is ticket {{TicketNumber}} being canceled?</div>
<mat-form-field class="ReasonFormControl" style="width: 100%;">
    <mat-label>Reason</mat-label>
    <textarea matInput [formControl]="ReasonFormControl" UpperCase cdkTextareaAutosize
              iqInputLimiter [MaxColumns]="MaxColumns" [MaxRows]="MaxRows" (InputHint)="InputHint=$event"></textarea>
    <mat-hint align="end" class="accent-color">{{InputHint}}</mat-hint>
</mat-form-field>
<div *ngIf="PromptForReplacedByTicketNumber" class="flex-row ReplacedPrompt">
    <div>
        <label>Replaced by Ticket Number:</label>
        <mat-form-field class="ReplacedByTicketNumberFormField">
            <mat-label>Ticket Number</mat-label>
            <input matInput [formControl]="ReplacedByTicketNumberFormControl" type="search" autocomplete="off"
                   [textMask]="TicketNumberMaskOptions" style="width:10em" />
        </mat-form-field>
    </div>
    <mat-checkbox *ngIf="LastTicketNumber" [formControl]="UseLastTicketCreatedFormControl" (change)="UseLastTicketCreatedChanged()"
                  color="primary" class="LastTicketNumberCheckBox">
        The last Ticket I created: {{LastTicketNumber}}
    </mat-checkbox>
</div>
