export enum QAStatusEnum {
    NotRequired = 0,
    Queued = 1,
    Pending = 2,
    Completed = 3,
    ManuallyQueued = 4      // Manually queued by a user.  Sends with a Priority of at least 2 so it's bumped up higher than regular tickets.
}

export enum QAStatusEnumDescription {
    NotRequired = "Not Required",
    Queued = "Queued",
    Pending = "Pending",
    Completed = "Completed",
    ManuallyQueued = "Manually Queued"
}
