import { TicketAffectedServiceAreaInfo } from "@iqModels/Tickets/TicketAffectedServiceAreaInfo.model";
import { SelectOption } from "@iqModels/Configuration/SelectOption.model";
import { SuppressedReasonEnum } from "Enums/SuppressedReason.enum";

export class TicketServiceArea {
    public ServiceAreaInfo: TicketAffectedServiceAreaInfo;

    public Late: boolean;

    //  Not from server.  Stored here so that we can determine it ONCE and not have to do it inside the html
    //  which recalculates it during each change event!
    public CssClass: string;

    //  Not from server.  Just used to track service areas that have been added right now and not yet saved to the ticket.
    //  Allows a user to remove them even if the options do not allow removing service areas (that were already saved to a
    //  previous version of the ticket).
    public NotSaved: boolean;

    //  This IsLBP flag gets persisted in TicketServiceArea so should be used instead of ServiceAreaInfo.IsLBP (in case it changed)
    constructor(
        public ServiceAreaID: string,
        public ManuallyAdded: boolean,
        public ServiceAreaType: SelectOption,
        public Suppressed: boolean,
        public SuppressedReason: SuppressedReasonEnum,
        public CustomBufferFt: number,
        public ScheduledInMeeting: boolean) {
    }
}
