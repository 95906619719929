import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import { GeometryUtils } from "Shared/Components/Maps/GeometryUtils";
import { OLGeometryTypeEnum } from "Enums/GeometryType.enum";
import { Feature } from "ol";
import { Style, Text, Fill, Stroke } from "ol/style";

export class SearchVectorLayer extends VectorLayerBase {

    constructor(mapToolService: MapToolService) {
        super(mapToolService, MapConstants.LAYERNAME_SEARCH, 1);
    }

    public BuildStyleForFeature(feature: Feature<any>, isDrawing: boolean, featureStyle: MapFeatureStyleEnum, resolution: number): Style | Style[] {
        //  Want white fill with opacity but red outline with no opacity.
        //  So need to build the fill color like this and set the layer to no opacity (1) in the constructor
        const white = [255, 255, 255, 0.3];

        const geomType = GeometryUtils.GeometryTypeOfFeature(feature);
        if (geomType === OLGeometryTypeEnum.Point) {
            return new Style({
                text: new Text({
                    text: '\uf3c5',     //  fa-map-marker-alt
                    font: '900 24px "' + MapConstants.FONT_AWESOME_FREE_NAME + '"',     //  If doesn't work, see comments on this constant - package version probably changed!
                    textBaseline: 'bottom',
                    fill: new Fill({
                        color: 'red'
                    })
                })
            });
        } else {
            return new Style({
                fill: new Fill({
                    color: white
                }),
                stroke: new Stroke({
                    color: "red",
                    width: 1
                }),
                text: new Text({
                    textAlign: "center",
                    textBaseline: "middle",
                    font: "bold 16px 'Courier New'",
                    text: feature.get('name'),          //  this attribute can be set by VectorLayerBase.LoadGeoJSON
                    fill: new Fill({ color: 'red' }),
                    stroke: new Stroke({ color: 'white', width: 5 }),
                    placement: "point",
                    overflow: true        //  Allow overlap on the total label so that it ALWAYS shows.
                })
            });
        }
    }
}
