import { DigSiteStreet } from "./DigSiteStreet.model";
import { DigSiteOffset } from "./DigSiteOffset.model";
import { DigSiteIntersectionItemTypeEnum } from "Enums/DigSiteIntersectionItemType.enum";
import { DirectionEnum } from "Enums/Direction.enum";

export class DigSiteIntersection {
    ItemType: DigSiteIntersectionItemTypeEnum;

    State?: string;

    CountyName?: string;

    PlaceName?: string;

    CallerPlace?: string;

    Corner?: DirectionEnum;

    Streets?: { [key: string]: DigSiteStreet; };

    Offsets?: { [key: string]: DigSiteOffset; };

    //  ** If anything is added here that affects geocoding, need to add it to GeocodeService.DigSiteIntersectionsAreEqual()!
}
