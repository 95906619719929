import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';
import { take } from 'rxjs/operators';
import { PermissionsService } from 'Services/PermissionsService';
import { SettingsService } from 'Services/SettingsService';
import { CommonService } from '../../../../../Services/CommonService';
import { TicketResponseService } from '../../../Services/TicketResponse.service';
import { AddExcavatorCommentData } from '../../AddExcavatorComment/AddExcavatorComment.component';
import { AddExcavatorCommentDialogComponent } from '../../AddExcavatorComment/Dialog/AddExcavatorCommentDialog.component';
import { AddPositiveResponseData } from '../../AddPositiveResponse/AddPositiveResponse.component';
import { AddPositiveResponseDialog } from '../../AddPositiveResponse/Dialog/AddPositiveResponseDialog.component';
import { DiscussionEvent } from './TicketResponseServiceAreaList.component';

export class TicketResponseServiceAreaListData {
    constructor(public AllowedActions: TicketEntryAllowedTicketActions, public Ticket: Ticket | any) { }
}

@Component({
    selector: 'ticket-response-service-area',
    templateUrl: './TicketResponseServiceArea.component.html',
    styleUrls: ['./TicketResponseServiceArea.component.scss'],
    providers: [CommonService]
})
export class TicketResponseServiceAreaComponent implements OnInit {
    @Input()
    public ServiceAreaListData: TicketResponseServiceAreaListData;

    public ServiceAreas: TicketAffectedServiceAreaInfo[] = [];
    public IsValid: boolean;
    public IgnoreRequiredDiscussionResponses: boolean;
    public NeedsExcavationDate: boolean;
    public NeedsMarkingDelayResponse: boolean;
    public IsExcavatorOfTicket: boolean;
    public CenterUsesExcavatorComments: boolean = this._SettingsService.CenterUsesExcavatorComments;

    public ViewOption: number = 1;        //  1 = Grouped by Service Area, 2 = Chronological history of all

    public CanEnterResponse: boolean = false;

    public MostRecentLabel: string = "Current/Most Recent Response for each Service Area";

    constructor(private _TicketResponseService: TicketResponseService,
        private _SettingsService: SettingsService, private _PermissionsService: PermissionsService, private _Dialog: MatDialog,
        private _CommonService: CommonService) {
    }

    public ngOnInit(): void {
        this.FetchServiceAreas();
        this.MostRecentLabel = this._CommonService.DeviceDetectorService.IsPhone ? "Most Recent Responses for Service Areas" : this.MostRecentLabel;
    }

    private FetchServiceAreas(): void {
        //  TODO: This api call is extremely wasteful.  It's returning all kinds of stuff that we don't need here.
        //  Should change what it returns to not send so  much extra stuff!  And to make it more efficient on the server
        //  to only query what we actually need.
        this._TicketResponseService.
            GetServiceAreasForView(this.ServiceAreaListData.Ticket.TicketNumber)
            .subscribe(val => {
                this.IsExcavatorOfTicket = val.IsExcavatorOfTicket;
                this.ServiceAreas = val.ServiceAreas.map(m => m.ServiceAreaInfo).sort((a, b) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()));

                //  If AllowedActions is not null, need to use that to see if CanAddResponses is true or not.  It is forced to false if we are VIEWING
                //  responses - so we should not enable adding responses in that case.
                if (!this.ServiceAreaListData.AllowedActions || this.ServiceAreaListData.AllowedActions.CanAddResponses) {
                    if (this.ServiceAreaListData.Ticket.TicketTypeID && this._SettingsService.UsesPositiveResponse) {
                        const serviceAreaIDs = this.ServiceAreas.map(sa => sa.ID);
                        this._PermissionsService.CurrentUserHasPermission(PermissionsEnum.TicketResponse_Create, serviceAreaIDs).pipe(take(1))
                            .subscribe(val => this.CanEnterResponse = coerceBooleanProperty(val));
                    }
                }
            });
    }

    public AddNewResponse(): void {
        this._Dialog.open(AddPositiveResponseDialog, {
            data: new AddPositiveResponseData([this.ServiceAreaListData.Ticket.TicketNumber], this.ServiceAreaListData.Ticket.TicketTypeID, true, null, this.ServiceAreas),
            minWidth: '45%',
            width: '550px',
            maxWidth: '550px'
        });
    }

    public NeedsDiscussionValue(needsDiscussionInfo: DiscussionEvent) {
        setTimeout(() => {
            this.NeedsExcavationDate = needsDiscussionInfo.NeedsExcavationDate;
            this.NeedsMarkingDelayResponse = needsDiscussionInfo.NeedsMarkingDelay;
            this.IsValid = !needsDiscussionInfo.NeedsExcavationDate && !needsDiscussionInfo.NeedsMarkingDelay;
        });
    }

    public IgnoreRequired($event: MatCheckboxChange) {
        this.IgnoreRequiredDiscussionResponses = $event.checked;
    }

    public AddExcavatorComment() {
        const dialogData = new AddExcavatorCommentData(this.ServiceAreaListData.Ticket.TicketNumber, this.ServiceAreas);

        this._Dialog.open(AddExcavatorCommentDialogComponent, {
            data: dialogData,
            minWidth: '45%',
            width: '550px',
            maxWidth: '550px'
        }).afterClosed()
            .subscribe((result: TicketDiscussionChildResponse[]) => {
                if (result) {
                    for (let i = 0; i < result.length; i++) {
                        const saID = result[i].ServiceAreaID;

                        this.ServiceAreas.forEach(f => {
                            if (f.ID === saID) {
                                if (!f.ExcavatorComments)
                                    f.ExcavatorComments = [result[i]];
                                else
                                    f.ExcavatorComments.push(result[i]);
                            }
                        });
                    }
                }
            });
    }
}
