import { OverlayRef } from '@angular/cdk/overlay';
import { Component, ContentChild, Directive, EventEmitter, HostListener, Input, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { slideInFromLeft } from 'Shared/Utils/Animations';
import { SideSlideoutService } from './SideSlideout.service';

@Directive({
    selector: '[iqSideMenuFlyoutContent]'
})
export class SideMenuFlyoutContentDirective {
}

@Component({
    selector: 'iq-slideout',
    templateUrl: './SideSlideout.component.html',
    styleUrls: ['./SideSlideout.component.scss'],
    providers: [SideSlideoutService],
    animations: [slideInFromLeft]
})
export class SideSlideoutComponent {

    @Input() Side: 'Left' | 'Right' = "Left";

    @Output() Open: EventEmitter<boolean> = new EventEmitter()

    private overlayRef: OverlayRef;
    @ContentChild(SideMenuFlyoutContentDirective, { read: TemplateRef, static: false }) public _template: TemplateRef<any>;


    @HostListener('click', ['$event'])
    onClick(e) {
        this.attach();
    }

    constructor(private _viewContainerRef: ViewContainerRef, private slideoutService: SideSlideoutService) {
       
    }

    ngOnDestroy() {
        this.slideoutService.Detach(this.overlayRef);
    }

    /**
     * Attaches the content with a particular context.
     * @docs-private
     */
    attach(context: any = {}) {

        this.Open.emit(true);

        this.overlayRef = this.slideoutService.Attach(this.Side, this._template, this._viewContainerRef);

        //Emit that the item is closed anytime it's closed
        this.overlayRef.detachments().pipe(take(1)).subscribe(() => this.Open.emit(false));
    }

    /**
     * Detaches the content.
     * @docs-private
     */
    detach() {
        this.slideoutService.Detach(this.overlayRef);
    }
}
