import { Inject, Injectable, InjectionToken, NgZone, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { CustomParams, EventParams, Gtag, GtagConfig } from './google-gtag.models';

export const GTAG = new InjectionToken<Gtag>('exactix.gtag.instance');

export const GtagConfigToken = new InjectionToken<GtagConfig>('exactix.gtag.config');

//  TODO: Why can't we just use this: https://www.npmjs.com/package/ngx-google-analytics
//  It looks like this code was copy/pasted from somewhere else anyway.

@Injectable()
export class GoogleGtagService {

    constructor(@Inject(GTAG) @Optional() private gtag: Gtag, private zone: NgZone, private router: Router,
        @Inject(GtagConfigToken) @Optional() private config: GtagConfig) {

        if (config && config.capturePageView && gtag) {

            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {

                    let shouldRecord = true;
                    if (this.config.ignoreRoutes) {
                        const ignoreRoutes = this.config.ignoreRoutes;
                        for (let i = 0; i < ignoreRoutes.length; i++) {

                            //Currently only allow startsWith or equals.  SO if not starts with then it's an equals.
                            if (ignoreRoutes[i].type === 'startsWith') {
                                if (event.urlAfterRedirects.toLocaleLowerCase().startsWith(ignoreRoutes[i].url.toLocaleLowerCase())) {
                                    shouldRecord = false;
                                    break;
                                }
                            }
                            else {
                                if (event.urlAfterRedirects.toLocaleLowerCase() === ignoreRoutes[i].url.toLocaleLowerCase()) {
                                    shouldRecord = false;
                                    break;
                                }
                            }
                        }
                    }

                    if (shouldRecord) {

                        const routeData = this.getRouteData();

                        //Maybe make this more generic so the using app can configure it??
                        const pageTitle = routeData.googleAnalyticsTitle ?? routeData.title ?? event.urlAfterRedirects;
                        this.pageView(pageTitle, event.urlAfterRedirects, location.href)
                            .pipe(catchError(err => {
                                //Only log the error msg if it's something other than the timeout
                                if (err.message !== 'gtag call timed-out.  Check if the gtag.js was blocked from download')
                                    console.warn(err.message);

                                return EMPTY;
                            }))
                            .subscribe();
                    }
                });
        }
    }

    private getRouteData(): any {
        const root = this.router.routerState.snapshot.root;

        return this.lastChild(root).data;

    }

    private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
        if (route.firstChild) {
            return this.lastChild(route.firstChild);
        } else {
            return route;
        }
    }


    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/setting-values */
    public set(params: CustomParams): void {
        if (this.gtag)
            return this.gtag('set', params);
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/events */
    public event(action: string, params?: EventParams): Observable<void> {
        if (this.gtag) {
            return this.zone.runOutsideAngular(() => new Observable<any>(obs => {
                try {
                    //Triggers a 3s time-out timer 
                    //  Maybe just let it complete instead of throwing the error?  The error is so the developer knows what to handle.
                    const tmr = setTimeout(() => obs.error(new Error('gtag call timed-out.  Check if the gtag.js was blocked from download')), 10);

                    //Performs the event call resolving with the event callback
                    this.gtag('event', action, {
                        ...params, event_callback: () => {
                            clearTimeout(tmr);
                            obs.next();
                            obs.complete();
                        }
                    });
                }
                //send error
                catch (e) { obs.error(e); }
            }));
        }
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
    public pageView(page_title?: string, page_path?: string, page_location?: string) {
        return this.event('page_view', { page_title, page_location, page_path });
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions */
    public exception(description?: string, fatal?: boolean) {
        return this.event('exception', { description, fatal });
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings */
    public timingComplete(name: string, value: number, event_category?: string, event_label?: string) {
        return this.event('timing_complete', { name, value, event_category, event_label });
    }

    /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/screens */
    public screenView(app_name: string, screen_name: string, app_id?: string, app_version?: string, app_installer_id?: string) {
        return this.event('screen_view', { app_name, screen_name, app_id, app_version, app_installer_id });
    }

    public login(method?: string) {
        return this.event('login', { method });
    }

    public signUp(method?: string) {
        return this.event('sign_up', { method });
    }

    public search(search_term?: string) {
        return this.event('search', { search_term });
    }

    //public selectContent(content?: Content) {
    //    return this.event('select_content', content);
    //}

    //public share(method?: string, content?: Content) {
    //    return this.event('share', { method, ...content });
    //}

    //public generateLead(action?: Action) {
    //    return this.event('generate_lead', action);
    //}

    //public viewItem(items?: Product[]) {
    //    return this.event('view_item', { items });
    //}

    //public viewItemList(items?: Product[]) {
    //    return this.event('view_item_list', { items });
    //}

    //public viewPromotion(promotions?: Promotion[]) {
    //    return this.event('view_promotion', { promotions });
    //}

    //public viewSearchResults(search_term?: string) {
    //    return this.event('view_search_results', { search_term });
    //}

    //public addPaymentInfo() {
    //    return this.event('add_payment_info');
    //}

    //public addToCart(action?: Action) {
    //    return this.event('add_to_cart', action);
    //}

    //public addToWishlist(action?: Action) {
    //    return this.event('add_to_wishlist', action);
    //}

    //public beginCheckout(action?: Action) {
    //    return this.event('begin_checkout', action);
    //}

    //public checkoutProgress(action?: Action) {
    //    return this.event('checkout_progress', action);
    //}

    //public purchase(action?: Action) {
    //    return this.event('purchase', action);
    //}

    //public refund(action?: Action) {
    //    return this.event('refund', action);
    //}

    //public removeFromCart(action?: Action) {
    //    return this.event('remove_from_cart', action);
    //}

    //public setCheckoutOption(checkout_step?: number, checkout_option?: string) {
    //    return this.event('set_checkout_option', { checkout_step, checkout_option });
    //}
}
