
export enum DigSiteRuleActionEnum {
    ShowError = 1,
    ShowMessage = 2,
    CreateMultipleTickets = 3
}

export enum DigSiteRuleActionEnumDescriptions {
    ShowError = "Show Error",
    ShowMessage = "Show Message",
    CreateMultipleTickets = "Create Multiple Tickets"
}
