import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'Services/AuthenticationService';
import { take } from 'rxjs/operators';

/**
 *  Navigate to the /logout route to do a logout.  It's necessary to do this as a navigate in case
 *  the user logs out when on a page that has a route guard (like ticket entry which has a CanDeactivateGuard
 *  to prompt about discarding changes).  Otherwise, we would log the user out before that guard has
 *  a chance to activate!
 * */
@Component({
    templateUrl: './Logout.component.html',
    styleUrls: ['./Logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(private _AuthenticationService: AuthenticationService) {
    }

    //  This component does nothing other than do the actual log out.  
    ngOnInit(): void {
        this._AuthenticationService.logoutObservable().pipe(take(1)).subscribe();
    }
}
