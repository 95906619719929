import { UntypedFormGroup, AbstractControl, Validators } from '@angular/forms';
import { PasswordRequirements } from './models';
import { hasLowercaseLetter, hasUppercaseLetter, hasNumber, hasSpecialCharacter } from './validation';
import { ComponentBase } from './component-base';
import { Directive } from "@angular/core";

@Directive()
export abstract class FormComponentBase extends ComponentBase {

    abstract BuildForm(): UntypedFormGroup;

    group: UntypedFormGroup;// = this.BuildForm();

    constructor() {
        super();
    }

    ngOnInit() {
        this.group = this.BuildForm();
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    protected SetPasswordRequirements(passwordControl: AbstractControl, passwordRequirements: PasswordRequirements) {
        let passwordValidator = [Validators.required];
        if (passwordRequirements != null) {
            if (passwordRequirements.MinCharacters != null) {
                passwordValidator.push(Validators.minLength(passwordRequirements.MinCharacters));
            }

            if (passwordRequirements.RequireALowercaseCharacter === true) {
                passwordValidator.push(hasLowercaseLetter);
            }

            if (passwordRequirements.RequireAnUppercaseCharacter === true) {
                passwordValidator.push(hasUppercaseLetter);
            }

            if (passwordRequirements.RequireNumbers === true) {
                passwordValidator.push(hasNumber);
            }

            if (passwordRequirements.RequireASpecialCharacter === true) {
                passwordValidator.push(hasSpecialCharacter);
            }
        }

        passwordControl.setValidators(passwordValidator);
    }
}