<mat-chip-grid #chipList>
    <ng-container *ngFor="let value of values">
        <!-- changed this to allow removing even if it's required and removing would leave no items. -->
        <!-- originally had this condition: [removable]="!isDisabled && (allowEmpty || values.length > 1)" -->
        <!-- same condition was also on the mat-icon -->
        <!-- ...and same check is also in BaseFilterItem.remove()... -->
        <!-- That's just confusing to the user if you start with an empty list, add something, and then can't remove it unless you add something else first. -->
        <!-- The proper way to handle that is to just check to see if it's empty when saving -->
        <mat-chip-row *ngIf="!value.HideInUI" [selectable]="true" [removable]="!isDisabled" (removed)="remove(value)" [disabled]="isDisabled">
            {{value.DisplayValue}}
            <mat-icon matChipRemove *ngIf="!isDisabled">cancel</mat-icon>
        </mat-chip-row>
    </ng-container>
</mat-chip-grid>

<mat-form-field style="padding-top: 10px; width: 100%">
    <mat-label>Search Text...</mat-label>
    <!-- class="no-margin" needed or placeholder get cut-off due to default margin set by angular that does not work if the mat-chip-grid is not in the same mat-form-fiel.  -->
    <input class="no-margin" autocomplete="off" type="search" UpperCase [formControl]="autoCompleteControl" [matAutocomplete]="auto" matInput
           [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false"
           (matChipInputTokenEnd)="Add($event)" iqAutoFocus />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="AutoCompleteResultSelected($event)" autoActiveFirstOption panelWidth="auto">
        <mat-option *ngFor="let option of autoCompleteControl.Results | async" [value]="option" style="line-height: 15px;">
            <div class="flex-column">
                <div>{{ option.Code || option.Name }}<span *ngIf="option.IsDeleted === 'Yes'" style="color:red; padding-left:1em">(deleted)</span></div>
                <div *ngIf="option.Code" class="iq-caption">Name: {{ option.Name }}</div>
            </div>
        </mat-option>
    </mat-autocomplete>
    <mat-hint>
        <ng-container *ngIf="minChars && minChars > 0; else addToFilterHintText">
            Min {{minChars}} characters required
        </ng-container>
    </mat-hint>
</mat-form-field>

<div *ngIf="minChars && minChars > 0" class="iq-caption gray-primary-text">
    <ng-content *ngTemplateOutlet="addToFilterHintText"></ng-content>
</div>

<ng-template #addToFilterHintText>
    {{addTextToFilterHintText}}
</ng-template>
