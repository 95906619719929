import { AbstractControl, ValidatorFn } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';

export function IsEqualToFormControl(otherFormControl: UntypedFormControl, mismatchMessage: string): ValidatorFn {

    //  Note: Also need to add a subscription to changes on otherFormControl and make it validate "this" control.
    //  Otherwise changes to otherFormControl that may change this validation, are not re-checked.
    //  Can't put that here because we have no way to unsubscribe when destroyed.

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value !== otherFormControl.value) {
            //console.warn("IsEqualToFormControl", control.value, otherFormControl.value);
            return { customMessage: mismatchMessage };
        }
        return null;
    };
}
