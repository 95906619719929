import { TicketCreateSourceEnum } from "Enums/TicketCreateSource.enum";

export class RefreshDateConfigurationRequest {
    constructor(
        public TicketFunctionID: string,
        public TicketTypeID: string,
        public LocateTypeID: string,
        public CreateSource: TicketCreateSourceEnum,
        public DateCalcSeedDate: string,
        public State: string
    ) { }
}
