import { Injectable, Provider } from '@angular/core';
import { TicketEntryPageEventService } from 'Pages/Tickets/Services/TicketEntryPageEvent.service';
import { EntrySectionEventServiceBase } from "Shared/EntryFields/Services/EntrySectionEventBase.service";

/**
 * This service should be added *IN THE PROVIDER SECTION* of each EntrySectionBase derived ticket entry section.
 * Then any children (including directives) of that section can add this service into it's constructor and it will
 * get a shared instance of it.  Therefore each section will have it's own instance.
 * And anything inside it will have a reference to that instance.
 */
@Injectable({
    providedIn: 'root'
})
export class TicketEntrySectionEventService extends EntrySectionEventServiceBase<TicketEntryPageEventService> {

    //  Helper to use when registering this event service in a component.  It can then be fetched via
    //  DI by referencing the base class EntrySectionEventServiceBase.
    //  And by registering it in a component, it will be scoped to that component - all child components and directives
    //  will get the instance associated with the component where it was "provided".
    //  ** Not doing this anymore but left it here in case we need to see how to "provide" and then QueryList by a base class.
    //public static Provider: Provider = {
    //    provide: EntrySectionEventServiceBase,
    //    useClass: TicketEntrySectionEventService,
    //    deps: [ TicketEntryPageEventService ]
    //}
    public static Provider: Provider = {
        provide: TicketEntrySectionEventService
    }

    constructor(pageEventService: TicketEntryPageEventService) {
        super(pageEventService);
    }
}
