import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IconButtonComponent } from '../../IconButton/IconButton.component';
import { DialogModel } from "../Models/Dialog.model";

@Component({
    templateUrl: './ConfirmationDialog.component.html',
    styleUrls: ['./ConfirmationDialog.component.scss'],
    host: {
        '(keydown)': 'OnKeydownEvent($event)'
    }
})
export class ConfirmationDialogComponent implements AfterViewInit {

    public ActionKeyboardShortcut: string = "";
    public ActionText: string;

    public CancelKeyboardShortcut: string = "";
    public CancelText: string;

    @ViewChild("actionButton", { read: IconButtonComponent })
    private _ActionButton: IconButtonComponent;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogModel) {
        //  If possible use the first character as a shortcut key.  And show that key underlined in the dialog button.
        if ((data.ActionText.length > 0) && (data.CancelText.length > 0) && (data.ActionText.charAt(0) !== data.CancelText.charAt(0))) {
            this.ActionKeyboardShortcut = data.ActionText.charAt(0);
            this.ActionText = data.ActionText.substring(1);
            this.CancelKeyboardShortcut = data.CancelText.charAt(0);
            this.CancelText = data.CancelText.substring(1);
        }
        else {
            this.ActionText = data.ActionText;
            this.CancelText = data.CancelText;
        }
    }

    public ngAfterViewInit(): void {
        //  If this is used, must also set the "autoFocus" option in the dialog open parameters to false or the dialog will do
        //  it's own focus to the first focusable control (which will be the cancel button).
        if (this.data.DefaultToActionButton)
            this._ActionButton.focus();
    }

    private OnKeydownEvent(event: KeyboardEvent):void {
        if (event.key === this.ActionKeyboardShortcut.toLowerCase()) {
            event.preventDefault();
            event.stopPropagation();
            this.dialogRef.close(true);
        }
        else if (event.key === this.CancelKeyboardShortcut.toLowerCase()) {
            event.preventDefault();
            event.stopPropagation();
            this.dialogRef.close(false);
        }
    }
}
