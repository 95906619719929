//  Contains components that should be included everywhere
//  See: https://angular.io/guide/ngmodule-faq#sharedmodule

import { NgModule } from '@angular/core';
import { CommaSeparatedFormatPipe } from 'Shared/Pipes/CommaSeparatedFormat.pipe';
import { AuditActionEnumPipe } from 'Shared/Pipes/Enum/AuditActionEnum.pipe';
import { EntityTypeEnumPipe } from 'Shared/Pipes/Enum/EntityTypeEnum.pipe';
import { BitwiseEnumDescriptionPipe, EnumDescriptionPipe } from 'Shared/Pipes/Enum/EnumDescription.pipe';
import { LogLevelEnumPipe } from 'Shared/Pipes/Enum/LogLevelEnum.pipe';
import { MessageSourceEnumPipe } from 'Shared/Pipes/Enum/MessageSourceEnum.pipe';
import { MessageStatusEnumPipe } from 'Shared/Pipes/Enum/MessageStatusEnum.pipe';
import { MessageTypeEnumPipe } from 'Shared/Pipes/Enum/MessageTypeEnum.pipe';
import { NotificationTypeEnumPipe } from "Shared/Pipes/Enum/NotificationTypeEnum.pipe";
import { ProjectStatusEnumPipe } from 'Shared/Pipes/Enum/ProjectStatusEnum.pipe';
import { GeometryTypeEnumPipe } from 'Shared/Pipes/Enum/GeometryTypeEnum.pipe';
import { QAStatusEnumPipe } from 'Shared/Pipes/Enum/QAStatusEnum.pipe';
import { ReceiverTypeEnumEnumPipe } from 'Shared/Pipes/Enum/ReceiverTypeEnum.pipe';
import { ResponseEntryMethodEnumPipe } from 'Shared/Pipes/Enum/ResponseEntryMethodEnum.pipe';
import { ServiceAreaTypeEnumPipe } from 'Shared/Pipes/Enum/serviceAreaTypeEnum.pipe';
import { TicketEventTypeEnumPipe } from 'Shared/Pipes/Enum/TicketEventTypeEnum.pipe';
import { TicketStatusEnumPipe } from 'Shared/Pipes/Enum/TicketStatusEnum.pipe';
import { ItemFilterPipe } from 'Shared/Pipes/ItemFilter.pipe';
import { DynamicFormatPipe } from 'Shared/Pipes/Lists/dynamicFormat.pipe';
import { PersonNameFormatPipe } from 'Shared/Pipes/Person/nameFormat.pipe';
import { PhonePipe } from 'Shared/Pipes/Phone/phone.pipe';
import { OpenTimePipe } from 'Shared/Pipes/Time/openTime.pipe';
import { TrustHtmlPipe } from 'Shared/Pipes/TrustHtml.pipe';
import { YesNoFormatPipe } from 'Shared/Pipes/yesNo.pipe';
import { AddressPipe } from './Address/address.pipe';
import { NameAndAddressPipe } from './nameAndAddress.pipe';
import { PartialDatePipe } from './PartialDate.pipe';
import { ServiceAreaCssPipe } from './ServiceArea/ServiceAreaCss.pipe';
import { HighlightSearchTermPipe } from "./HighlightSearchTerm.pipe";

@NgModule({
    imports: [

    ],
    exports: [
        PhonePipe,
        PersonNameFormatPipe,
        OpenTimePipe,
        DynamicFormatPipe,
        ServiceAreaTypeEnumPipe,
        YesNoFormatPipe,
        MessageStatusEnumPipe, ReceiverTypeEnumEnumPipe, MessageTypeEnumPipe, MessageSourceEnumPipe, NotificationTypeEnumPipe, TicketEventTypeEnumPipe,
        TicketStatusEnumPipe, ResponseEntryMethodEnumPipe, QAStatusEnumPipe, AuditActionEnumPipe, ProjectStatusEnumPipe, GeometryTypeEnumPipe,
        CommaSeparatedFormatPipe,
        LogLevelEnumPipe,
        TrustHtmlPipe,
        AddressPipe,
        PartialDatePipe,
        NameAndAddressPipe,
        ServiceAreaCssPipe,
        BitwiseEnumDescriptionPipe,
        EnumDescriptionPipe,
        ItemFilterPipe,
        HighlightSearchTermPipe
    ],
    declarations: [
        PhonePipe,
        PersonNameFormatPipe,
        OpenTimePipe,
        DynamicFormatPipe,
        ServiceAreaTypeEnumPipe,
        YesNoFormatPipe,
        MessageStatusEnumPipe, ReceiverTypeEnumEnumPipe, MessageTypeEnumPipe, MessageSourceEnumPipe, NotificationTypeEnumPipe, TicketEventTypeEnumPipe,
        TicketStatusEnumPipe, ResponseEntryMethodEnumPipe, QAStatusEnumPipe, AuditActionEnumPipe, ProjectStatusEnumPipe, GeometryTypeEnumPipe,
        CommaSeparatedFormatPipe,
        LogLevelEnumPipe,
        TrustHtmlPipe,
        EntityTypeEnumPipe,
        AddressPipe,
        PartialDatePipe,
        NameAndAddressPipe,
        ServiceAreaCssPipe,
        BitwiseEnumDescriptionPipe,
        EnumDescriptionPipe,
        ItemFilterPipe,
        HighlightSearchTermPipe
    ]
})

export class IQPipesModule { }
