import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, pipe, Subject } from 'rxjs';
import { CommonService } from 'Services/CommonService';
import { SettingsService } from 'Services/SettingsService';
import { AuthenticationService } from 'Services/AuthenticationService';
import { AppUser } from 'Models/Security/AppUser.model';
import { BusyService } from 'Services/BusyService';
import { Router } from '@angular/router';
import { PersonInsertRequest } from '@iqModels/People/Person.model';
import { PersonService } from 'Pages/People/Services/Person.service';
import { take, finalize, filter, mergeMap } from 'rxjs/operators';
import { MainMenuService } from 'Services/MainMenuService';
import { Guid } from 'Shared/Utils/Guid';
import { UserRegistrationService } from 'Pages/UserRegistration/Services/UserRegistration.service';

@Component({
  selector: 'auth-no-person-for-login',
    templateUrl: './NoPersonForLogin.component.html',
    styleUrls: ['./NoPersonForLogin.component.scss'],
  providers: [CommonService]
})
export class NoPersonForLoginComponent implements OnInit, OnDestroy {

    currentUser: AppUser = new AppUser();
    private OccCode: string;
    //EntityID: string;
    apiError: boolean = false;

    firstName: string;
    lastName: string;

    public OptionPicked: string;
    public CanSelfRegister: boolean;
    public OneCallCenterName: string;
    public EmailFrom: string;

    userPersonID = null;

    //Setup observable in the service and get the key to that observable back to start and end the indicator
    busyKey: string = this.busyService.createNew();

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private busyService: BusyService,
        private personService: PersonService,
        private mainMenuService: MainMenuService, private userRegService: UserRegistrationService
    ) {
        this.mainMenuService.forceClose = true;
    }

    ngOnInit() {
        this.busyService.showByKey(this.busyKey);

        //Need to wait until we have the user info fetched so we can determine what to show the user.
        //  Need to mergeMap the CurrentUserObservable because cognito calls to get the atrributes and we have to wait for that before we get the currect user
        this.authService.FetchedUserInfo.pipe(filter(val => val === true), mergeMap(val => this.authService.CurrentUserObserver().pipe(take(1))), take(1))
            .subscribe(val => {
                this.currentUser = val;

                this.SetupUser();
            });
    }

    ngOnDestroy() {
        this.busyService.hideByKey(this.busyKey);
        this.busyService.removeByKey(this.busyKey);

        this.mainMenuService.forceClose = false;
    }

    private SetupUser() {
        if (this.currentUser.ID && this.currentUser.ID !== Guid.empty)
            this.userPersonID = this.currentUser.ID;

        if (this.userPersonID && !this.currentUser.LoginDisabled && this.currentUser.ConfiguredLandingPage)
            this.router.navigateByUrl(this.currentUser.ConfiguredLandingPage);
        else {
            this.userRegService.SelfRegistrationConfiguration()
                .pipe(take(1), finalize(() => this.busyService.hideByKey(this.busyKey)))
                .subscribe(data => {
                    if (data && data.length > 0) {
                        this.CanSelfRegister = true;
                        //  Since we're looking for this info using this api, won't have it unless self registration is enabled.
                        //  But it always is except for pre-launch.  If we need it then, we're going to need a different api.
                        //  This also won't handle IN/KY - will just show one of them...
                        this.OneCallCenterName = data[0].OneCallCenter.Name;
                        this.EmailFrom = data[0].EmailFrom;
                    }
                }, err => {
                    this.apiError = true;
                });
        }
    }

    OnCreateForAdmin() {
        const request = new PersonInsertRequest();
        request.FirstName = this.firstName;
        request.LastName = this.lastName;
        request.OneCallCenterCode = this.currentUser.CurrentOneCallCenterCode;

        this.personService.Add4iQAdminPersonAndLinkLogin(request)
            .subscribe(appUser => {
                this.authService.setAppUserAndRedirect(appUser);
            });
    }
}
