import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponentLookup } from 'Services/ComponentLookup.service';
import { DateWithPickerComponent } from 'Shared/Components/Forms/FormControls/DateWithPickerComponent';
import { ICustomResponseDataComponent } from "../ICustomResponseDataComponent";

@ComponentLookup('SC811-Response-Code-60')
@Component({
    templateUrl: './SC811_ResponseCode60_MarkingSchedule.component.html',
    styleUrls: ['./SC811_ResponseCode60_MarkingSchedule.component.scss']
})
export class SC811_ResponseCode60_MarkingScheduleComponent implements OnInit, ICustomResponseDataComponent {

    @ViewChild('meetingDatePicker', { read: DateWithPickerComponent, static: true })
    private _MeetingDatePicker: DateWithPickerComponent;

    private _FormGroup: UntypedFormGroup;
    public MeetingDateFormControl: UntypedFormControl;
    public PersonSpokeToFormControl: UntypedFormControl;
    public PhoneNumberFormControl: UntypedFormControl;
    public LocatorNameFormControl: UntypedFormControl;

    public MinDate: Date;
    public MaxDate: Date;

    constructor()
    { }

    public ngOnInit(): void {
        //  Default is tomorrow at 8am.
        const defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() + 1);
        defaultDate.setHours(8, 0, 0, 0);

        //  Min is today @ midnight, Max is 10 days
        this.MinDate = new Date();
        this.MinDate.setHours(0, 0, 0);

        this.MaxDate = new Date();
        this.MaxDate.setDate(this.MaxDate.getDate() + 10);
        this.MaxDate.setHours(0, 0, 0);

        this.MeetingDateFormControl = new UntypedFormControl(defaultDate, [Validators.required]);
        this.PersonSpokeToFormControl = new UntypedFormControl(null, [Validators.required]);
        this.PhoneNumberFormControl = new UntypedFormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]);
        this.LocatorNameFormControl = new UntypedFormControl(null, [Validators.required]);

        this._FormGroup = new UntypedFormGroup({
            MeetingDate: this.MeetingDateFormControl,
            PersonSpokeTo: this.PersonSpokeToFormControl,
            PhoneNumber: this.PhoneNumberFormControl,
            LocatorName: this.LocatorNameFormControl
        });

        setTimeout(() => this._MeetingDatePicker.focus());
    }

    public IsValid(): boolean {
        return this._FormGroup?.valid ?? false;
    }

    public GetData(): object {
        const data = this._FormGroup.getRawValue();

        data.PersonSpokeTo = data.PersonSpokeTo?.toUpperCase();
        data.LocatorName = data.LocatorName?.toUpperCase();

        return data;
    }
}
