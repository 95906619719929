import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from './DeviceDetector.service';

export class HideMenuEvent {
    constructor(public hide: boolean, public isMobile: boolean, public fromPage: boolean, public HideTopMenuBar: boolean) {}
}

@Injectable({
    providedIn: 'root'
})
export class MainMenuService {

    public hideMenu: BehaviorSubject<HideMenuEvent> = new BehaviorSubject(new HideMenuEvent(true, false, false, false));

    //Need to make sure to clear this out on the destroy action of any controller that sets it!!!
    private _forceClose: boolean = false;
    get forceClose() {
        return this._forceClose;
    }
    set forceClose(val: boolean) {
        if (this._forceClose !== val) {
            //  Only do this if the value is different or if it's called multiple times, can cause stack overflow issues!
            this._forceClose = val;
            this.UpdateMenuStatus();
        }
    }

    private _HideTopMenuBar: boolean = false;
    get HideTopMenuBar() {
        return this._HideTopMenuBar;
    }
    set HideTopMenuBar(val: boolean) {
        if (this._HideTopMenuBar !== val) {
            this._HideTopMenuBar = val;
            this.UpdateMenuStatus();
        }
    }

    constructor(private _DeviceDetectorService: DeviceDetectorService) {
        this._DeviceDetectorService.Changed.pipe().subscribe(() => this.UpdateMenuStatus());

        //  Need to do initial check since the Changed event will only fire it actually changes
        this.UpdateMenuStatus();
    }

    private UpdateMenuStatus() {
        const isMobile = !this._DeviceDetectorService.IsDesktopSize;
        this.hideMenu.next(new HideMenuEvent(isMobile || this.forceClose, isMobile, this.forceClose, this.HideTopMenuBar));
    }
}
