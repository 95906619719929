import { AbstractControl, ValidatorFn } from '@angular/forms';

export function TextMaskIsValid(textMask: any): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
        //  typeof check is needed in case this is also used on an autocomplete control.  Initially, the value gets set
        //  to the object that was picked...
        if (control.value && (typeof control.value === 'string') && textMask && textMask.mask) {
            //  textMask.mask can be either a function (that returns an array) or an array.  See https://github.com/text-mask/text-mask
            const mask = (typeof textMask.mask === "function") ? textMask.mask(control.value) : textMask.mask;
            if (control.value.length !== mask.length)
                return { pattern: "Invalid value" };
        }
        return null;
    };
}
