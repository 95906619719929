import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { finalize, take } from 'rxjs';
import { SendCopyToComponent } from '../SendCopyTo/SendCopyTo.component';

@Component({
    templateUrl: './ConfirmReleaseSuspendedTicketDialog.component.html',
    styleUrls: ['./ConfirmReleaseSuspendedTicketDialog.component.scss']
})
export class ConfirmReleaseSuspendedTicketDialogComponent {
    public Ticket: Ticket;

    @ViewChild('sendCopyTo', { static: true })
    public _SendCopyTo: SendCopyToComponent;

    public IsSaving: boolean = false;

    constructor(private dialogRef: MatDialogRef<ConfirmReleaseSuspendedTicketDialogComponent>, @Inject(MAT_DIALOG_DATA) data,
        private _TicketService: TicketService)
    {
        this.Ticket = data.Ticket;
    }

    public OnReleaseTicket(): void {
        if (this.IsSaving)
            return;     //  ??
        this.IsSaving = true;

        this._TicketService.ReleaseSuspendedTicket(this.Ticket.ID)
            .pipe(finalize(() => this.IsSaving = false))
            .subscribe({
                next: response => {
                    if (response) {
                        this._SendCopyTo.SendToExcavator();
                        this.dialogRef.close(true);
                    }
                }
            });
    }

    public CanRelease(): boolean {
        return this._SendCopyTo.IsValid;
    }
}
