import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import { Feature } from "ol";
import { Style, Fill, Stroke } from "ol/style";

export class DigSiteVectorLayer extends VectorLayerBase {

    constructor(layerName: string, mapToolService: MapToolService) {
        super(mapToolService, layerName, .2);
    }

    public BuildStyleForFeature(feature: Feature<any>, isDrawing: boolean, featureStyle: MapFeatureStyleEnum, resolution: number): Style | Style[] {
        return new Style({
            fill: new Fill({
                color: "#0D72EF"
            }),
            stroke: new Stroke({
                color: "#0951A7",
                width: 2
            })
        });
    }
}
