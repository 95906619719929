<div class="home-card-container" style="display:flex; flex-direction:column; align-items:center;">
    <mat-card appearance="outlined" class="card-full-width-lt-sm"  style="margin-bottom: 10px;">
        <busy-indicator [busyKey]="busyKey"></busy-indicator>
        <mat-card-content class="loginContent" style="display:flex;">
            <div style="display:flex; flex: 1 1 50%; flex-direction:column; place-content: center; align-items: center;">
                <p>
                    Searching for a ticket?
                    <button mat-button color="primary" routerLink="/findTicketByNumberAndPhone">Search here</button>
                </p>
                <p *ngIf="ShowEmergencyContactsPage">
                    <button mat-button color="primary" routerLink="/emergencyContacts">View Emergency Contacts</button>
                </p>
            </div>
            <mat-divider style="margin-top:10px;"></mat-divider>
            <div style="display: flex; flex: 1 1 50%; flex-direction: column; place-content: center; align-items: center;">
                <iq-aws-cognito [StartingStep]="1" style="width: 100%; max-width: 375px;"
                                [AllowCreateLogin]="CanSelfRegister"
                                [PasswordRequirements]="passwordRequirements"
                                [HideLogout]="false"
                                [FederatedLoginReturnState]="federatedLoginReturnState">
                    <ng-template iqAwsResetPasswordTemplate>
                        <auth-forgot-password [PasswordRequirements]="passwordRequirements"></auth-forgot-password>
                    </ng-template>
                </iq-aws-cognito>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="card-full-width-lt-sm" style="display:flex; box-sizing:border-box;">
        <iq-announcements-carousel forLogin></iq-announcements-carousel>
    </mat-card>
</div>
