import { Injectable } from '@angular/core';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { ExcavatorAutocompleteRequest } from 'Models/Excavators/ExcavatorAutocompleteRequest.model';
import { ExcavatorAutocompleteResponse } from 'Models/Excavators/ExcavatorAutocompleteResponse.model';
import { ExcavatorContactToExcavatorOffice } from 'Models/Excavators/ExcavatorContactToExcavatorOffice.model';
import { ExcavatorOffice } from 'Models/Excavators/ExcavatorOffice.model';
import { ExcavatorOfficesForCompanyRequest } from 'Models/Excavators/ExcavatorOfficesForCompanyRequest.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';

@Injectable({
    providedIn: 'root'
})
export class ExcavatorOfficeService extends CRUDBaseService<ExcavatorOffice> {

    protected apiPath: string = "Administration/ExcavatorOffice";

    ViewPermission: PermissionsEnum = PermissionsEnum.ExcavatorOffice_View;//Needs to be overriden and set to the proper permission
    EditPermission: PermissionsEnum = PermissionsEnum.ExcavatorOffice_Edit;//Needs to be overriden and set to the proper permission
    CreatePermission: PermissionsEnum = PermissionsEnum.ExcavatorOffice_Create;//Needs to be overriden and set to the proper permission
    DeletePermission: PermissionsEnum = PermissionsEnum.ExcavatorOffice_Delete;//Needs to be overriden and set to the proper permission
    //CopyPermission: PermissionsEnum = PermissionsEnum.ExcavatorOffice_Copy;//Needs to be overriden and set to the proper permission

    constructor(protected services: CRUDServices) {
        super(services);
    }

    public CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', itemID: string = null): Observable<boolean> {
        //Just let the server do the checks. If we want to do it here we can for the Create, Edit, and Delete by getting the whole item and checking
        //  the ExcavatorCompanyID.  But these should only be used for checking to make the call to the server, so it doens't hurt to let it call and
        //  figure it out there for this object
        switch (action) {
            case 'View':
                //Always need to look for it anywhere.  This is because we need to check permissions after it's fetched for
                //  the case like a MemberAdmin. We don't know if they can view this until it's been fetched and we know the service area's Member.
                //Also, for searching the server needs to figure out permissions
                return this.services.permissionService.CurrentUserHasPermission(this.ViewPermission, null, true);
            case 'Create':
                return this.services.permissionService.CurrentUserHasPermission(this.CreatePermission, null, true);
            case 'Edit':
                return this.services.permissionService.CurrentUserHasPermission(this.EditPermission, null, true);
            case 'Delete':
                return this.services.permissionService.CurrentUserHasPermission(this.DeletePermission, null, true);
        }

        return super.CanPerformAction(action, itemID);
    }

    public AddContact(item: ExcavatorContactToExcavatorOffice): Observable<any> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<any>({})
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.post<any>(this.services.settingsService.ApiBaseUrl + "/ExcavatorOfficeToExcavatorContact", item);
        }));
    }

    public RemoveContact(item: ExcavatorContactToExcavatorOffice): Observable<any> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<any>({})
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.services.http.put<boolean>(this.services.settingsService.ApiBaseUrl + "/ExcavatorOfficeToExcavatorContact/Delete", item);
        }));
    }

    public TicketAutoComplete(value: string, column: string, companyID: string = null): Observable<ExcavatorAutocompleteResponse[]> {
        const searchRequest = new ExcavatorAutocompleteRequest(column, value);
        if (companyID !== null) {
            searchRequest.ExcavatorCompanyID = companyID;
            searchRequest.RestrictToCompany = true;
        }

        return this.services.http.post<ExcavatorAutocompleteResponse[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/TicketAutoComplete", searchRequest);
    }

    public OfficesForCompany(excavatorCompanyID: string, oneCallCenterCode: string = undefined): Observable<ExcavatorOffice[]> {
        const searchRequest = new ExcavatorOfficesForCompanyRequest(excavatorCompanyID, oneCallCenterCode);
        return this.services.http.post<ExcavatorOffice[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/OfficesForCompany", searchRequest);
    }

    public CanDelete(excavatorOfficeIDs: string[]): Observable<ExcavatorOffice[]> {//Use an array incase we ever allow deleteing multiple.  We do for Companies and Contacts.
        return this.services.http.post<ExcavatorOffice[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/CanDelete", excavatorOfficeIDs);
    }
}
