//  Check out this url to build this out a little more so it's more like the material modal (animation events, etc):
//  https://blog.thoughtram.io/angular/2017/11/27/custom-overlays-with-angulars-cdk-part-two.html

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, TemplateRef, ViewContainerRef } from '@angular/core';
import { take, takeUntil } from 'rxjs';
import { SideSlideoutRef } from './SideSlideoutRef';

export const IQ_FLYOUT_DATA = new InjectionToken<{}>('IQ_FLYOUT_DATA');
export const IQ_FLYOUT_REF = new InjectionToken<SideSlideoutRef>('IQ_FLYOUT_REF');

@Injectable()       //  Included in the providers section of SideSlideoutModule
export class SideSlideoutService {

    constructor(private _Overlay: Overlay, private _Injector: Injector) { }

    private GetOverlayRef(Side: "Left" | "Right", width: string | number): OverlayRef {
        const positionStrategy = Side === "Left" ? this._Overlay.position().global().left() : this._Overlay.position().global().right();
        //.flexibleConnectedTo(this.parentElem)
        //.withPositions([
        //    {
        //        overlayX: 'start',
        //        overlayY: 'bottom',
        //        originX: 'start',
        //        originY: 'bottom'
        //    },
        //    {
        //        overlayX: 'start',
        //        overlayY: 'center',
        //        originX: 'start',
        //        originY: 'bottom'
        //    }
        //]);
        //If we want to offset them then add these back in with the values we want
        //.withDefaultOffsetX(-20)
        //.withDefaultOffsetY(-20)

        const config = new OverlayConfig({
            positionStrategy: positionStrategy,
            //backdropClass: 'cdk-overlay-transparent-backdrop',
            hasBackdrop: true,
            disposeOnNavigation: true
        });

        if (width)
            config.width = width;

        return this._Overlay.create(config);
    }

    //  TODO: Remove the viewContainerRef?  It is not needed - see notes in method.
    /**
     * Slide out a component
     * @param side Which side the slidout should be on
     * @param component Component to use in the slideout
     * @param componentData Data to pass to the the component.  To access the data in the Component add this to the contructor: @Inject(IQ_CONTAINER_DATA) public componentData: any
     * @param allowCloseOnBackdropClick If true, allow closing by clicking outside of flyout (or hitting ESC key, if that's even an option).
     * @param overlayWidth Width to make the slideout
     */
    public AttachComponent(side: "Left" | "Right", component: ComponentType<any>, componentData: any,
        allowCloseOnBackdropClick: boolean = true, overlayWidth: string | number = "100vw"): SideSlideoutRef
    {
        const overlayRef = this.GetOverlayRef(side, overlayWidth);

        //  This is necessary for the animation to work correctly.  Can also specify this in the animation itself in the style for the "* => enter"
        //  transition, but doing that causes issues in some places.  Animating a template needs position:static for some reason.  And then there are
        //  some desktop dialogs that also need it (like the main filter editor dialog that slides out from the right).
        //  Another option is to specify this in the components .scss in the ":host" tag, but that does not work if view encapsulation is turned off.
        overlayRef.hostElement.style.position = "fixed";

        const ref = new SideSlideoutRef(overlayRef);

        const injectorTokens = new WeakMap();
        injectorTokens.set(IQ_FLYOUT_DATA, componentData);
        injectorTokens.set(IQ_FLYOUT_REF, ref);
        const injector = new PortalInjector(this._Injector, injectorTokens);

        //  Info about the viewContainerRef parameter (from the Angular docs for ComponentPortal() ):
        //      [Optional] Where the attached component should live in Angular's *logical* component tree.
        //      This is different from where the component * renders *, which is determined by the PortalOutlet.
        //      The origin is necessary when the host is outside of the Angular application context.
        //  We should NEVER need to set this!  And setting it can actually cause issues initializing the component if the page
        //  (where the viewContainerRef came from) navigates.  Had that issue with the TicketSavedFlyout.  Setting this to null
        //  fixed that issue.
        //  If we ever do need to add it back, it can be obtained through DI on a component constructor: private _ViewContainerRef: ViewContainerRef
        const template = new ComponentPortal(component, null, injector);
        overlayRef.attach(template);

        const detachedObs = overlayRef.detachments();

        //  This catches absolutely every case of the overlay closing - for whatever reason.  Including the browser back button!
        //  If we don't handle it this way, using the browser back will cause the flyout to close but anything waiting on the
        //  SideSlideoutRef.onClose subscription will be stuck!
        detachedObs.pipe(take(1)).subscribe(() => ref.FireOnClose());

        if (allowCloseOnBackdropClick) {
            //  ref.close() also detaches.  Calling .close is necessary in case something is subscribed to it (like the Verify Location flyout).
            overlayRef.backdropClick().pipe(takeUntil(detachedObs)).subscribe(() => ref.close());
            overlayRef.keydownEvents().pipe(takeUntil(detachedObs)).subscribe(val => {
                if (val.code === 'Escape')
                    ref.close();
            });
        }

        return ref;
    }

    /**
     * Side out a template item.  Can define the template using ng-template and then attach to it.  i.e. The PhoneFilterList.component.
     * @param Side Which side the slidout should be on
     * @param _template Template to use in the slideout
     * @param _viewContainerRef
     * @param overlayWidth Width to make the slideout
     */
    public Attach(Side: "Left" | "Right", _template: TemplateRef<any>, viewContainerRef: ViewContainerRef, overlayWidth: string | number = null): OverlayRef {
        const overlayRef = this.GetOverlayRef(Side, overlayWidth);

        const template = new TemplatePortal(_template, viewContainerRef);
        overlayRef.attach(template);

        const detachedObs = overlayRef.detachments();
        overlayRef.backdropClick().pipe(takeUntil(detachedObs)).subscribe(() => this.Detach(overlayRef));
        overlayRef.keydownEvents().pipe(takeUntil(detachedObs)).subscribe(val => {
            if (val.code === 'Escape')
                this.Detach(overlayRef);
        });

        return overlayRef;
    }

    public Detach(overlayRef: OverlayRef): void {
        if (overlayRef)
            overlayRef.detach();
    }
}
