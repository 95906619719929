import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { iQAdminChangeEmailDailogComponent, iQAdminChangeEmailInputData } from 'iqCognito/ManageAccount/Admin/admin-change-email-dialog/admin-change-email-dialog.component';
import { iQAdminForcePasswordResetDialogComponent, iQAdminForcePasswordResetDialogComponentData } from 'iqCognito/ManageAccount/Admin/admin-force-password-reset-dialog/admin-force-password-reset-dialog.component';
import { iQChangeAlternateUsernameDialogComponent } from 'iqCognito/ManageAccount/Self/change-alternate-username-dialog/change-alternate-username-dialog.component';
import { iQChangePasswordDialogComponent, iQChangePasswordDialogComponentData } from 'iqCognito/ManageAccount/Self/change-password-dialog/change-password-dialog.component';
import { PasswordRequirements } from 'iqCognito/models';
import { Person } from 'Models/People/Person.model';
import { PersonLogin } from 'Models/People/PersonLogin.model';
import { ToastrService } from 'ngx-toastr';
import { ExactixAdminCreateLoginDialogComponent, ExactixAdminCreateLoginDialogComponentData } from 'Pages/Authentication/LoginControls/AdminCreateLogin/AdminCreateLoginDialog.component';
import { AdminSetPasswordDialogComponent, AdminSetPasswordDialogComponentData } from 'Pages/Authentication/LoginControls/AdminSetPassword/AdminSetPasswordDialog.component';
import { ExactixChangeVerifyEmailDialogComponent } from 'Pages/Authentication/LoginControls/ChangeVerifyEmailDialog/ChangeVerifyEmailDialog.component';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { SettingsService } from './SettingsService';

export const passwordRequirements: PasswordRequirements = {
    MinCharacters: 6,
    RequireALowercaseCharacter: false,
    RequireAnUppercaseCharacter: false,
    RequireASpecialCharacter: false,
    RequireNumbers: false
};

/**
* This service is for Cognito functions that Exactix needs, and is maintained in Exactix.
*/
@Injectable({
    providedIn: 'root'
})
export class CognitoAdminService {

    private cognitoAdminUrlPath = this.settingsService.ApiBaseUrl + "/CognitoAdmin";

    constructor(private settingsService: SettingsService, private http: HttpClient, private toastrService: ToastrService, private matDialog: MatDialog) {

    }

    ForgotPasswordByEmail(email: string) {
        return this.http.post<boolean>(this.cognitoAdminUrlPath + "/ForgotPasswordByEmail", '"' + email + '"');
    }

    ForgotPasswordByUsername(username: string): Observable<any> {
        return this.http.post<any>(this.cognitoAdminUrlPath + "/ForgotPasswordByUsername", '"' + username + '"');
    }

    AddAlternateExternalLoginToPerson(personID: string, providerType: number, cognitoAttributes: CognitoUserAttribute[]): Observable<Person> {
        const request = { PersonID: personID, ProviderType: providerType, CognitoAttributes: cognitoAttributes };

        return this.http.post<Person>(this.settingsService.ApiBaseUrl + "/Administration/Person/PersonAddExternalLogin", request);
    }

    AdminAddLoginToPerson(personID: string, email: string, firstName: string, lastName: string): Observable<PersonLogin> {
        const addLoginDialogData: ExactixAdminCreateLoginDialogComponentData = {
            PasswordRequirements: passwordRequirements,
            Email: email,
            FirstName: firstName,
            LastName: lastName,
            Username: email,
            ShowVerifyEmail: !email,
            PersonID: personID
        };
        return this.matDialog.open(ExactixAdminCreateLoginDialogComponent, {
            data: addLoginDialogData,
            width: "40em"
        }).afterClosed().pipe(take(1), mergeMap((val: any) => {
            if (val) {
                this.toastrService.success("Login created");
            }
            return of<PersonLogin>(val);
        }));
    }

    AdminForcePasswordReset(username: string) {

        const adminForcePasswordChangeData: iQAdminForcePasswordResetDialogComponentData = { Username: username };
        this.matDialog.open(iQAdminForcePasswordResetDialogComponent, { data: adminForcePasswordChangeData }).afterClosed().pipe(take(1),
            mergeMap((username: string) => {
                if (username)
                    return this.http.post<boolean>(this.cognitoAdminUrlPath + "/InitiatePasswordReset", '"' + username + '"');
                else
                    return of<boolean>(false);
            }))
            .subscribe(val => {
                if (val)
                    this.toastrService.success("Force password reset complete");
            });
    }

    public AdminSetPassword(username: string, permanent: boolean): void {
        const data: AdminSetPasswordDialogComponentData = {
            PasswordRequirements: passwordRequirements,
            Permanent: permanent
        };

        this.matDialog.open(AdminSetPasswordDialogComponent, {
            data: data,
            width: "500px",
        }).afterClosed().pipe(take(1),
            mergeMap((password: string) => {
                if (password) {
                    const request = {
                        Username: username,
                        Password: password,
                        Permanent: permanent
                    };
                    return this.http.post(this.cognitoAdminUrlPath + "/SetPassword", request);
                }
                else
                    return of<boolean>(false);
            }))
            .subscribe(val => {
                if (val)
                    this.toastrService.success("Password set");
            });
    }

    AdminChangeEmail(username: string, personID: string): Observable<string> {
        const adminChangeEmailData: iQAdminChangeEmailInputData = { Username: username };
        return this.matDialog.open(iQAdminChangeEmailDailogComponent, { data: adminChangeEmailData }).afterClosed().pipe(take(1),
            mergeMap(val => {
                if (val) {
                    val.PersonID = personID;
                    return this.http.post<string>(this.settingsService.ApiBaseUrl + "/Administration/Person/AdminChangeCognitoEmail", val)
                        .pipe(take(1), tap(response => {
                            if (response)
                                this.toastrService.success("Email Updated");
                        }));
                }
                else
                    return of<string>(null);
            }));
    }

    CurrentLoginChangeEmail(username: string, showVerify: boolean = false): Observable<[string, boolean]> {
        
        const dialogRef = this.matDialog.open(ExactixChangeVerifyEmailDialogComponent, { width: "500px", data: { ShowVerify: showVerify } });
        //let dialogRef = this.matDialog.open(iQChangeVerifyEmailDialogComponent, { width: "500px", data: { ShowVerify: showVerify } });
        const dialogOpen$ = new Subject<void>();
        let changedEmail = null;

        //In the dialog we can change the email multiple times without closing it, so get all the changes so that we have the latest.
        dialogRef.componentInstance.onEmailChanged.pipe(takeUntil(dialogOpen$), mergeMap(val => {
            if (val) {
                const request = {
                    Username: username,
                    Email: val
                };

                return this.http.post<string>(this.settingsService.ApiBaseUrl + "/Administration/Person/UpdateCurrentUserCognitoEmail", request)
                    .pipe(take(1), tap(val => changedEmail = val));
            }
            else
                return of(null);

        }), catchError(err => { throw err })).subscribe(null, err => console.log(err));

        return dialogRef
            .afterClosed()
            .pipe(take(1), mergeMap(val => {
                //Clean up the listener on if the email is changed
                dialogOpen$.next();
                dialogOpen$.complete();

                if ((val !== null) && (val !== undefined))      //  Will be undefined if user hits esc to close dialog!
                    return this.CurrentLoginChangeEmailVerified(username, val);
                return of(null);
            }), map(val => [changedEmail, val]));
    }

    CurrentLoginChangeEmailVerified(username: string, emailVerified: boolean) {
        return this.http.post<boolean>(this.settingsService.ApiBaseUrl + "/Administration/Person/UpdateCurrentUserCognitoEmailVerified",
            { Username: username, EmailVerified: emailVerified });
    }

    CurrentUserChangePassword() {
        const dialogData: iQChangePasswordDialogComponentData = { PasswordRequirements: passwordRequirements };
        this.matDialog.open(iQChangePasswordDialogComponent, { width: "500px", data: dialogData });
    }

    CurrentUserChangePreferredUsername(username: string): Observable<string> {
        return this.matDialog.open(iQChangeAlternateUsernameDialogComponent, { width: "500px" })
                .afterClosed()
                .pipe(take(1), mergeMap(val => {
                    //If no value then it didn't change
                    if (val) {
                        const request = {
                            Username: username,
                            NewUsername: val
                        };

                        return this.http.post<string>(this.settingsService.ApiBaseUrl + "/Administration/Person/UpdateCurrentUserCognitoUsername", request)
                            .pipe(take(1), tap(response => {
                                if (response)
                                    this.toastrService.success("Alternate Username Updated");
                            }, err => this.toastrService.error("Alternate Username changed, but Exactix record not updated!")));
                    }
                    else
                        return of(null);
                }));
    }
}
