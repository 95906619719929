import { Injectable } from '@angular/core';
import { SettingsService } from './SettingsService';

@Injectable({
    providedIn: 'root'
})
export class LatLonCoordinateTextMaskService {

    constructor(private _SettingsService: SettingsService) {
    }

    /**
     * Parses a comma separated lat/lon coordinate.  Can be formatted as either decimal or degree-minute-second
     * format.  Ordering of lat vs lon is determined by which one starts with a negative (which is longitude/x).
     * @param latLon
     */
    public ParseLatLonString(latLon: string): { x: number, y: number } {
        const parts = latLon.split(',');
        if (parts.length !== 2)
            return null;

        let x: number;
        let y: number;

        if (this.IsDegreeMinuteSecond(parts[0])) {
            x = this.DegreesMinutesSecondsToDecimal(parts[0]);
            y = this.DegreesMinutesSecondsToDecimal(parts[1]);
        } else {
            x = parseFloat(parts[0]);
            y = parseFloat(parts[1]);
        }

        if (!x || !y)
            return null;

        if ((x < 0) && (y < 0))
            return null;        //  both negative???

        if (y < 0) {
            const a = x;
            x = y;
            y = a;
        }

        return { x, y };
    }

    private DegreesMinutesSecondsToDecimal(degreesMinutesSeconds: string): number {
        const parts = degreesMinutesSeconds.trim().split(" ");
        if (parts.length === 0)
            return 0;

        let decimal = parseFloat(parts[0]);
        const neg = (decimal < 0) ? -1 : 1;     //  To properly add/subtract depending on integer portion
        if (parts.length > 0)
            decimal += (parseFloat(parts[1]) / 60) * neg;
        if (parts.length > 1)
            decimal += (parseFloat(parts[2]) / 3600) * neg;

        return decimal;
    }

    public DefaultPlaceholder(): string {
        return this.DecimalPlaceholder();
    }

    public DecimalLatitudePlaceholder(): string {
        const decimalPart = "x".repeat(this._SettingsService.LatLonCoordinateMaxDecimalDigits);
        return "xx." + decimalPart;
    }

    public DecimalLongitudePlaceholder(): string {
        const decimalPart = "x".repeat(this._SettingsService.LatLonCoordinateMaxDecimalDigits);
        const wholeNumberPart = "x".repeat(this._SettingsService.LonCoordinateMaxWholeNumberDigits);
        return "-" + wholeNumberPart + "." + decimalPart;
    }

    private DecimalPlaceholder(): string {
        const latitudePlaceholder = this.DecimalLatitudePlaceholder();
        const longitudePlaceholder = this.DecimalLongitudePlaceholder();

        if (this._SettingsService.LatLonCoordinateEnteredLatFirst)
            return latitudePlaceholder + ", " + longitudePlaceholder;
        else
            return longitudePlaceholder + ", " + latitudePlaceholder;
    }

    public DegreeMinuteSecondLatitudePlaceholder(): string {
        return "xx xx xx.x";
    }

    public DegreeMinuteSecondLongitudePlaceholder(): string {
        const wholeNumberPart = "x".repeat(this._SettingsService.LonCoordinateMaxWholeNumberDigits);
        return "-" + wholeNumberPart + " xx xx.x";
    }

    private DegreeMinuteSecondPlaceholder(): string {
        const latitudePlaceholder = this.DegreeMinuteSecondLatitudePlaceholder();
        const longitudePlaceholder = this.DegreeMinuteSecondLongitudePlaceholder();

        if (this._SettingsService.LatLonCoordinateEnteredLatFirst)
            return latitudePlaceholder + ", " + longitudePlaceholder;
        else
            return longitudePlaceholder + ", " + latitudePlaceholder;
    }

    //  mask: https://github.com/text-mask/text-mask/tree/master/angular2/#readme
    //  mask issues on a mat-input control:
    //      https://github.com/angular/angular/issues/16755

    //  rawValue = the current value.  This function is called BEFORE a mask is applied to that value.
    //  So the returned mask needs to cover only the contents of rawValue.
    public BuildMaskAndPlaceholder(rawValue: string): { mask: any[], placeholder: string } {
        //  Dynamically builds the mask as the user types based on what has been entered so far.
        //  Ultimately, allows entering the lat/lon coordinate in this (maximum) form: -xxx.xxxx, xx.xxxx
        //  But allows the number of decimal places to vary all the way down to this (minimum) form: -xxx, xx

        //  If prompting for Latitude first, we are expecting a positive number.  Otherwise, we expect a negative.
        const latitudeIsFirst = this._SettingsService.LatLonCoordinateEnteredLatFirst;

        if (!rawValue || rawValue.length <= 1) {
            //  Return a starting mask that will show the - and allow another digit to be entered.  If we return
            //  ONLY the '-' here, the text-mask component won't allow any input at all for some reason...
            //  So this mask allow the user to type the '-' if they want or they can just start typing the first digit
            //  and the '-' will be added automatically.
            return { mask: [/\d/], placeholder: this.DefaultPlaceholder() };
        }

        const mask: any[] = [];        
        const have2ndCoord = rawValue.indexOf(',') > 0;
        const parts = rawValue.split(',');
        const coord1 = parts[0];
        const coord2 = have2ndCoord ? parts[1].trimLeft() : '';

        const isDMS = this.IsDegreeMinuteSecond(coord1);
        let placeholder: string;

        if (isDMS) {
            placeholder = this.DegreeMinuteSecondPlaceholder();
            this.AddMaskForDegreeMinuteSecond(coord1, latitudeIsFirst, mask);
        }
        else {
            placeholder = this.DecimalPlaceholder();
            this.AddMaskForDecimal(coord1, latitudeIsFirst, mask);
        }

        if (have2ndCoord) {
            mask.push(',');        //  Don't use literal - it messes everything up when you type

            //  Note: Handling the space differently here depending on if we have coord2.  It handles typing better
            //  this way.  The difference is that a "character" is a fixed character.  A regular expression is
            //  a placeholder that accepts input.  So if we don't have the space entered yet, using the regex
            //  of a space allow it to be typed by the user.
            //  https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#mask
            if (coord2.length > 0) {
                mask.push(' ');
                if (isDMS)
                    this.AddMaskForDegreeMinuteSecond(coord2, !latitudeIsFirst, mask);
                else
                    this.AddMaskForDecimal(coord2, !latitudeIsFirst, mask);
            } else
                mask.push(/\ /);        //  Don't use literal - it messes everything up when you type
        }

        return { mask, placeholder };
    }

    public BuildMaskAndPlaceholderForSingleCoordinate(coord: string, isLatitude: boolean): { mask: any[], placeholder: string } {
        if (!coord || coord.length <= 1) {
            //  Return a starting mask that will show the - and allow another digit to be entered.  If we return
            //  ONLY the '-' here, the text-mask component won't allow any input at all for some reason...
            //  So this mask allow the user to type the '-' if they want or they can just start typing the first digit
            //  and the '-' will be added automatically.
            const placeholder = isLatitude ? this.DecimalLatitudePlaceholder() : this.DecimalLongitudePlaceholder();
            return { mask: [/\d/], placeholder: placeholder };
        }

        const mask: any[] = [];        
        let placeholder: string;

        const isDMS = this.IsDegreeMinuteSecond(coord);

        if (isDMS) {
            placeholder = isLatitude ? this.DegreeMinuteSecondLatitudePlaceholder() : this.DegreeMinuteSecondLongitudePlaceholder();
            this.AddMaskForDegreeMinuteSecond(coord, isLatitude, mask);
        }
        else {
            placeholder = isLatitude ? this.DecimalLatitudePlaceholder() : this.DecimalLongitudePlaceholder();
            this.AddMaskForDecimal(coord, isLatitude, mask);
        }

        return { mask, placeholder };
    }

    private IsDegreeMinuteSecond(enteredValue: string): boolean {
        let foundDigits = 0;

        const len = enteredValue.length;
        for (let i = 0; i < len; i++) {
            const c = enteredValue[i];
            if (c === '-')
                continue;       //  Skip the leading -
            else if ((c >= '0') && (c <= '9'))
                foundDigits++;
            else if (c === ' ') {
                //  Space is the separator between degrees and minutes.  If we found at least 2 leading digits,
                //  this is degree-minute-second format
                return (foundDigits >= 2);
            } else
                return false;       //  . or , at this point is definitely not DMS
        }

        return false;
    }

    private AddMaskForDegreeMinuteSecond(enteredValue: string, isLatitude: boolean, mask: any[]): void {
        //  Degree-minute-second formatted like xx xx xx.x
        //  With the leading component being 2-3 digits if longitude (always 2 digits if latitude).

        if (!isLatitude)
            mask.push('-');

        //  Ignore leading '-' in the enteredValue
        if (enteredValue.startsWith('-'))
            enteredValue = enteredValue.substr(1);

        let foundPeriod = false;
        let component = 1;              //  1 = degree, 2 = minutes, 3 = seconds, 4 = the decimal portion of seconds
        let digitsInComponent = 0;
        let requiredDigitsInComponent = this.RequiredLeadingDigits(isLatitude, enteredValue);

        const len = enteredValue.length;
        for (let i = 0; i < len; i++) {
            const c = enteredValue[i];

            if (c === ' ') {
                if ((component < 3) && (digitsInComponent >= requiredDigitsInComponent)) {
                    mask.push(' ');
                    component++;
                    digitsInComponent = 0;
                    requiredDigitsInComponent = 2;
                }
            } else if (c === '.') {
                if (!foundPeriod && (component === 3)) {
                    mask.push('.');
                    foundPeriod = true;
                    component++;
                    digitsInComponent = 0;
                    requiredDigitsInComponent = 1;
                }
            } else if ((c >= '0') && (c <= '9')) {
                if (digitsInComponent >= requiredDigitsInComponent) {
                    //  Max digits already collected.  Auto add the separator and advance in to the next component.
                    //  If there is one...
                    switch (component) {
                        case 1:
                        case 2:
                            mask.push(' ');
                            requiredDigitsInComponent = 2;
                            break;
                        case 3:
                            mask.push('.');
                            requiredDigitsInComponent = 1;      //  1 decimal digit
                            break;
                        case 4:
                            return;     //  Nothing more can be entered!
                    }
                    component++;
                    digitsInComponent = 0;
                }

                mask.push(/\d/);
                digitsInComponent++;
            }
        }
    }

    private AddMaskForDecimal(enteredValue: string, isLatitude: boolean, mask: any[]): void {
        //  Decimal formatted like xx.xxxxxx
        //  With the leading component being 2-3 digits if longitude (always 2 digits if latitude).
        //  And the number of decimal digits configurable (currently either 5 or 6).

        if (!isLatitude)
            mask.push('-');

        //  Ignore leading '-' in the enteredValue
        if (enteredValue.startsWith('-'))
            enteredValue = enteredValue.substr(1);

        let component = 1;              //  1 = integer portion, 2 = decimal portion
        let digitsInComponent = 0;
        let requiredDigitsInComponent = this.RequiredLeadingDigits(isLatitude, enteredValue);

        const len = enteredValue.length;
        for (let i = 0; i < len; i++) {
            const c = enteredValue[i];

            if (c === '.') {
                if (component === 1) {
                    mask.push('.');
                    component++;
                    digitsInComponent = 0;
                    requiredDigitsInComponent = this._SettingsService.LatLonCoordinateMaxDecimalDigits;
                }
            } else if ((c >= '0') && (c <= '9')) {
                if (digitsInComponent >= requiredDigitsInComponent) {
                    //  Max digits already collected.  Auto add the separator and advance in to the next component.
                    //  If there is one...
                    switch (component) {
                        case 1:
                            mask.push('.');
                            requiredDigitsInComponent = this._SettingsService.LatLonCoordinateMaxDecimalDigits;
                            break;
                        case 2:
                            return;     //  Nothing more can be entered!
                    }
                    component++;
                    digitsInComponent = 0;
                }

                mask.push(/\d/);
                digitsInComponent++;
            }
        }
    }

    //  enteredValue should be stripped of the leading - if there is one
    private RequiredLeadingDigits(isLatitude: boolean, enteredValue: string): number {
        if (isLatitude)
            return 2;

        const maxDigits = this._SettingsService.LonCoordinateMaxWholeNumberDigits;
        if (maxDigits < 3)
            return maxDigits;

        //  For longitude, the only possible values in the US that can be up to 3 digits start with a "1".
        //  ...or '0' in case someone is entering a 3 digit value with a leading 0 for some reason...
        if ((enteredValue.length >= 0) && ((enteredValue[0] === '0') || (enteredValue[0] === '1')))
            return 3;
        return 2;
    }
}
