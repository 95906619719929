import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commaSeparatedFormat'    
})
export class CommaSeparatedFormatPipe implements PipeTransform {
    //childName is for if you have a collection and you have an object that you need to get the displayed value from.
    //  ie. if you have a colleciton of people and you want to display the excavtor contact name, the childName would be 'ExcavatorContact' and
    //  the propName would be 'Name'
    transform(value: any[], propName: string = "Name", childName: string = null, isArray = false): string {
        if (value == null || value.length === 0) {
            return null;
        }

        if (isArray)
            return value.join(", ");

        return value.map(m => {
            if (childName) {
                return m[childName][propName];
            }
            return m[propName];
        }).join(", ");
    }

}
