
import {takeUntil, take} from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, Optional, Self, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl, FormGroupDirective } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { IQ_MASK_FORMATS } from '@iqSharedUtils/MaskFormats.model';
import { FieldLengths } from 'Models/Configuration/FieldLengths.model';
import { AuthenticationService } from 'Services/AuthenticationService';

@Component({
    selector: 'iq-phone',
    templateUrl: './Phone.component.html',
    styleUrls: ['./Phone.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: PhoneComponent }],
    host: {
        '[class.floating]': 'shouldLabelFloat',
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
    }
})
export class PhoneComponent implements MatFormFieldControl<string>, OnDestroy, OnInit, ControlValueAccessor {//Since we want to use reactive forms with this control we need to implement ControlValueAccessor

    //This does not do validation or disabled if it doesn't have a formControlName control.  If we need to update to allow that then this will need to be changed.

    //#region ControlValueAccessor
    writeValue(obj: any): void {
        //Need to wait a tick incase this is happening because the form is re assigned
            setTimeout(() => {
                this.value = obj;
            });
    }
    propagateChange = (_: any) => { };
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    
    propagateTouched = (_: any) => { };
    registerOnTouched(fn: any): void {
        this.propagateTouched = (_: any) => {
            fn(_);
            this.errorState = this.ngControl.disabled ? false : !this.ngControl.valid;//Can't be in an error state if disabled because the user can't fix the error
        };
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //#endregion ControlValueAccessor

    //#region errorState
    //We don't do special errors in the control (the mask should enforce a phone format), and since we use this with the reactive forms stuff only we need to
    //  set all validators when creating the form control.  So just piggy back off of those and set the error state from there.
    private destroyed$: Subject<void> = new Subject();
    ngOnInit() {
        if (this.ngControl !== null) {
            this.ngControl.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
                //Tell material this control is in an error state so it will show errors correctly
                this.errorState = this.ngControl.disabled ? false : !this.ngControl.valid && this.ngControl.touched;//Can't be in an error state if disabled because the user can't fix the error
            });
            //Tell material this control is in an error state so it will show errors correctly
            this.errorState = this.ngControl.disabled ? false : this.value != null && !this.ngControl.valid;//Can't be in an error state if disabled because the user can't fix the error
        }

        //Update the error when the parent form is submitted
        if (this._parentFormGroup != null && this._parentFormGroup.ngSubmit != null) {
            this._parentFormGroup.ngSubmit.pipe(takeUntil(this.destroyed$)).subscribe(val => {
                this.stateChanges.next();//Tell material stuff changes happened
                this.errorState = this.ngControl.disabled ? false : !this.ngControl.valid;//Can't be in an error state if disabled because the user can't fix the error
            });
        }
    }
    //#endregion errorState
    

    //#region text mask
    @Input() 
    get AllowExtension() { return this._allowExtension; }
    set AllowExtension(val) {
        this._allowExtension = coerceBooleanProperty(val);
        this.stateChanges.next();//Tell material stuff changes happened
    }
    private _allowExtension = true;

    //  If true, allow the user to enter all 0's.  Which is not valid, but IN insists on doing that for the primary
    //  phone number on a large number of their tickets!!!  Among other problems that causes, it defeats even the
    //  small amount of 
    @Input()
    public AllowEmpty: boolean = false;

    private maskFunction = (rawValue: string) => {

        const val = this.getRawValue(rawValue);
        if (!this.AllowExtension || !val || val.length < 10)
            return this.AllowEmpty ? IQ_MASK_FORMATS.PhoneLineNumberAllowEmpty : IQ_MASK_FORMATS.PhoneLineNumber;
        else
            return this.AllowEmpty ? IQ_MASK_FORMATS.PhoneWithExtensionAllowEmpty : IQ_MASK_FORMATS.PhoneWithExtension;
    };

    public mask = {
        mask: this.maskFunction,
        guide: false
    }

    private getRawValue(val: string) {
        if (val === undefined || val === null || val === "")
            return null;

        return val.replace(/\D+/g, '');
    }
    //#endregion text mask

    //#region iqFocus - Our focus directive will find this as a matinput control, so we need to handle the focus event
    public focus(): void {
        this.elRef.nativeElement.querySelector('input').focus();
    }
    //#endregion iqFocus

    //#region Angular Material
    static nextId = 0;

    stateChanges = new Subject<void>();

    focused = false;

    errorState = false;

    controlType = 'iq-phone';

    get empty() {
        return this.value === undefined || this.value === null;
    }

    get shouldLabelFloat() { return this.focused || !this.empty; }

    id = `iq-phone-${PhoneComponent.nextId++}`;

    describedBy = '';

    @Input()
    get placeholder() { return this._placeholder; }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();//Tell material stuff changes happened
    }
    private _placeholder: string;

    @Input()//We only use reactive forms, so this should be set with those and not on the control directly
    get required() { return this._required; }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();//Tell material stuff changes happened
    }
    private _required = false;

    @Input()//We only use reactive forms, so this should be set with those and not on the control directly
    get disabled() { return this._disabled; }
    set disabled(dis) {
        this._disabled = coerceBooleanProperty(dis);
        this.stateChanges.next();//Tell material stuff changes happened
    }
    private _disabled = false;

    private _value: string;
    @Input()
    get value(): string | null {
        return this._value;
    }
    set value(val: string | null) {
        this._value = val === "" ? null : val;
        this.stateChanges.next();//Tell material stuff changes happened
        this.propagateChange(this.getRawValue(val));//Fire change event for angular forms
    }

    @Input()
    public FieldLengths: FieldLengths = new FieldLengths();

    constructor(private fm: FocusMonitor, private elRef: ElementRef, authenticationService: AuthenticationService,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() private _parentFormGroup: FormGroupDirective) {

        //Since this is a ControlValueAccessor we need to set the valueAccessor property of the ngControl to this directive
        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
        
        fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
            this.focused = !!origin;
            this.stateChanges.next();//Tell material stuff changes happened

            if (!this.focused)//If no longer focused then fire the onBlur event for angular forms
                this.propagateTouched(this.getRawValue(this.value));
        });

        authenticationService.CurrentUserObserver().pipe(take(1))
            .subscribe(user => {
                //  This component is used on self registration so will not have a user logged in.
                //  In that case, self registration sets the FieldLengths @Input based on which One Call it is registering for.
                if (user && user.FieldLengths)
                    this.FieldLengths = user.FieldLengths;
            });
    }
    
    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }
    //#endregion Angular Material
}
