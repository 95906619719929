import { Component, Input } from '@angular/core';
import { FeatureItemResponse } from 'Models/Maps/FeatureItemResponse.model';
import { SettingsService } from 'Services/SettingsService';

@Component({
    selector: 'iq-map-feature-autocomplete-option',
    templateUrl: './FeatureAutocompleteOption.component.html',
    styleUrls: ['./FeatureAutocompleteOption.component.scss']
})
export class FeatureAutocompleteOptionComponent {

    public FormattedStreet: string;

    private _Model: FeatureItemResponse;
    @Input("model")
    get Model(): FeatureItemResponse {
        return this._Model;
    }
    set Model(model: FeatureItemResponse) {
        this._Model = model;
        this.FormatModel(model);
    }

    public Icon: string;
    public Color: string;
    public AdditionalText: string;

    constructor(private _SettingsService: SettingsService) {
    }

    private FormatModel(model: FeatureItemResponse): void {
        switch (model.LayerName) {
            case "PointsOfInterest":
                this.Icon = "place";
                this.Color = this.IconColorForPointOfInterest(model.MapStyle);
                this.AdditionalText = ", " + model.PlaceName + this.FormatCountyState(model);
                break;
            case "Landmarks":
                this.Icon = "layers";
                this.Color = this.IconColorForLandmark(model.MapStyle);
                this.AdditionalText = ", " + model.PlaceName + this.FormatCountyState(model);
                break;
            case "Place":
            case "Places":
                this.Icon = "search";
                this.Color = "darkgray";
                this.AdditionalText = this.FormatCountyState(model);
                break;
            case "County":
            case "Counties":
                this.Icon = "search";
                this.Color = "darkgray";
                this.AdditionalText = ", " + model.State;
                break;
            case "Streets":
                this.Icon = "timeline";
                this.Color = "darkgray";
                this.AdditionalText = ", " + model.PlaceName + this.FormatCountyState(model);
                break;
            case "Intersections":
                this.Icon = "clear";
                this.Color = "darkgray";
                this.AdditionalText = ", " + model.PlaceName + this.FormatCountyState(model);
                break;
            default:
                this.Icon = "search";
                this.Color = "darkgray";
                this.AdditionalText = "";
        }
    }

    private FormatCountyState(model: FeatureItemResponse): string {
        if (this._SettingsService.UsesCountyInLocations)
            return " (" + model.CountyName + ", " + model.State + ")";
        else
            return ", " + model.State;
    }

    private IconColorForPointOfInterest(mapStyle: string): string {
        //  These are the same colors defined in the "Points of Interest" style in the GeoServer map configuration
        switch (mapStyle) {
            case "Entertainment":
                return "#13b5c7";
            case "Financial":
                return "#909ce1";
            case "Auto":
                return "#909ce1";
            case "Shopping":
                return "#4b96f3";
            case "Outdoor":
                return "#4db546";
            case "Transportation":
                return "#10bdff";
            case "Bus":
                return "#10bdff";
            case "Restaurant":
                return "#ff9e67";
            case "Medical":
                return "#f88181";
            case "Lodging":
                return "#ef74b6";
            case "HighwayExit":
                return "#53b181";
            case "MilePostMarker":
                return "#000000";
            default:
                return "#7b9eb0";
        }
    }

    private IconColorForLandmark(mapStyle: string): string {
        //  These are the same colors defined in the "Landmarks" style in the GeoServer map configuration
        switch (mapStyle) {
            case "Airport":
                return "#f3f3f4";
            case "AirportRoad":
                return "#eaeaea";
            case "Beach":
                return "#faf2c7";
            case "Building":
                return "#fddab1";        //  Darkened this one up a bit - this is what map uses "#fdf6ee";
            case "Cemetery":
                return "#d4f1c9";
            case "Park":
                return "#c0ecae";
            default:
                return "darkgray";
        }
    }
}
