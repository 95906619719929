export enum DigsiteEnteredTypeEnum {
    Street = 0,
    Intersection = 1,
    BetweenIntersections = 2,
    BoundedBy = 3,
    LatLon = 4,

    //MapNote = 5,      //  Point of Interest, No tickets ever created in AZ using this so not implementing this unless they insist
    //TRSQ = 6          //  No tickets created since 2015 so not implementing this unless they insist
}

export enum DigsiteEnteredTypeEnumDescriptions {
    Street = "Street",
    Intersection = "Intersection",
    BetweenIntersections = "Between Intersections",
    BoundedBy = "Bounded By",
    LatLon = "Lat/Lon",

    //MapNote = 5,      //  Point of Interest, No tickets ever created in AZ using this so not implementing this unless they insist
    //TRSQ = 6          //  No tickets created since 2015 so not implementing this unless they insist
}

