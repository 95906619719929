<div class="multipleTicketsContainer" style="display: flex; flex-direction: column; flex-basis: 50%; overflow: auto; padding-right: 10px">
    <div style="color: red; font-size: x-large; font-weight: bold; padding-bottom: 10px">The total length of the dig area on this locate request is {{TotalLengthFt | number}} ft.</div>
    <div *ngIf="Data.DigSiteRule.Message" class="gray-border-color" style="border-bottom:1px solid; padding-bottom:10px" [innerHTML]="Data.DigSiteRule.Message | trustHtml"></div>
    <div class="gray-border-color" style="border-bottom:1px solid; padding-bottom:10px; padding-top:10px">
        <ng-template dynamic-content></ng-template>
    </div>
    <div class="SegmentsList" style="padding-top: 10px; display: flex; flex-direction: column">
        <div style="display:flex">
            <div class="col header"><div class="cell-content">Segment</div></div>
            <div class="col header"><div class="cell-content">Footage</div></div>
            <div class="col header"><div class="cell-content">Direction</div></div>
            <div class="col header"><div class="cell-content"># Service Areas</div></div>
        </div>
        <div style="display:flex" *ngFor="let segment of Segments" [ngClass]="{'red': !segment.NumAffectedServiceAreas}">
            <div class="col"><div class="cell-content">{{segment.Number}}</div></div>
            <div class="col"><div class="cell-content">{{segment.LengthFt | number}}</div></div>
            <div class="col"><div class="cell-content">{{segment.Direction | EnumDescription : DirectionEnum : DirectionEnumDescription}}</div></div>
            <div class="col"><div class="cell-content">{{segment.NumAffectedServiceAreas}}</div></div>
        </div>
    </div>
</div>
