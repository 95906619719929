export class NameValueUpdateRequest {

    ID: string;

    Name: string;

    Value: any;

    ReturnUpdatedItem: boolean;

    constructor() {
    }
}

