import { Component } from '@angular/core';

import { Subject, combineLatest } from 'rxjs';
import { BaseFilterItem } from '../BaseFilterItem';
import { UntypedFormControl } from '@angular/forms';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { debounceTime } from 'rxjs/operators';
import { SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';


enum NumberFilterOptions {
    None,
    Equals,
    GreaterThan,
    GreaterThanOrEqual,
    LessThan,
    LessThanOrEqual,
    // Evaluated as value1 <= value < value2
    Between
}


@Component({
    selector: 'iq-list-number-filter',
    templateUrl: './NumberFilter.component.html',
    styleUrls: ['./NumberFilter.component.scss']
})
export class NumberFilterComponent extends BaseFilterItem {

    FilterOptions = NumberFilterOptions;

    selectedOption: NumberFilterOptions = NumberFilterOptions.None;
    selectedOptionChange() {

        this.searchOperator = SearchFilterOperatorEnum.Contains;
        
        if (this.selectedOption === NumberFilterOptions.None) {
            this.clearNonActiveInputs();
            this.values = [];
            this.fireChangeEvent();
        }
        else if (this.selectedOption === NumberFilterOptions.Equals)
            this.searchOperator = SearchFilterOperatorEnum.Equals;
        else if (this.selectedOption === NumberFilterOptions.GreaterThan)
            this.searchOperator = SearchFilterOperatorEnum.GreaterThan;
        else if (this.selectedOption === NumberFilterOptions.GreaterThanOrEqual)
            this.searchOperator = SearchFilterOperatorEnum.GreaterThanOrEqual;
        else if (this.selectedOption === NumberFilterOptions.LessThan)
            this.searchOperator = SearchFilterOperatorEnum.LessThan;
        else if (this.selectedOption === NumberFilterOptions.LessThanOrEqual)
            this.searchOperator = SearchFilterOperatorEnum.LessThanOrEqual;
        else if (this.selectedOption === NumberFilterOptions.Between)
            this.searchOperator = SearchFilterOperatorEnum.Between;

        this.enableNeededInputs();
    }

    equalsControl: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    greaterThanControl: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    greaterThanOrEqualControl: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    lessThanControl: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    lessThanOrEqualControl: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    betweenStartControl: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    betweenEndControl: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });

    equalsFocusEvent: Subject<boolean> = new Subject();
    greaterThanFocusEvent: Subject<boolean> = new Subject();
    greaterThanOrEqualFocusEvent: Subject<boolean> = new Subject();
    lessThanFocusEvent: Subject<boolean> = new Subject();
    lessThanOrEqualFocusEvent: Subject<boolean> = new Subject();
    betweenFocusEvent: Subject<boolean> = new Subject();

    constructor()
    {
        super();
    }

    private debounceTime: number = 500;
    ngOnInit() {
        super.ngOnInit();

        this.selectedOption = NumberFilterOptions.None;

        if (this.searchOperator === SearchFilterOperatorEnum.Equals) {
            this.selectedOption = NumberFilterOptions.Equals;
            this.equalsControl.reset(this.values[0].FilterValue, { onlySelf: true, emitEvent: false });
        }
        else if (this.searchOperator === SearchFilterOperatorEnum.GreaterThan) {
            this.selectedOption = NumberFilterOptions.GreaterThan;
            this.greaterThanControl.reset(this.values[0].FilterValue, { onlySelf: true, emitEvent: false });
        }
        else if (this.searchOperator === SearchFilterOperatorEnum.GreaterThanOrEqual) {
            this.selectedOption = NumberFilterOptions.GreaterThanOrEqual;
            this.greaterThanOrEqualControl.reset(this.values[0].FilterValue, { onlySelf: true, emitEvent: false });
        }
        else if (this.searchOperator === SearchFilterOperatorEnum.LessThan) {
            this.selectedOption = NumberFilterOptions.LessThan;
            this.lessThanControl.reset(this.values[0].FilterValue, { onlySelf: true, emitEvent: false });
        }
        else if (this.searchOperator === SearchFilterOperatorEnum.LessThanOrEqual) {
            this.selectedOption = NumberFilterOptions.LessThanOrEqual;
            this.lessThanOrEqualControl.reset(this.values[0].FilterValue, { onlySelf: true, emitEvent: false });
        }
        else if (this.searchOperator === SearchFilterOperatorEnum.Between) {
            this.selectedOption = NumberFilterOptions.Between;
            this.betweenStartControl.reset(this.values[0].FilterValue, { onlySelf: true, emitEvent: false });
            this.betweenEndControl.reset(this.values[1].FilterValue, { onlySelf: true, emitEvent: false });
        }

        this.enableNeededInputs();

        this.equalsControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(val => {
            if (this.selectedOption === NumberFilterOptions.Equals)
                this.inputControlValueChanged(val);
        });

        this.greaterThanControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(val => {
            if (this.selectedOption === NumberFilterOptions.GreaterThan)
                this.inputControlValueChanged(val);
        });

        this.greaterThanOrEqualControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(val => {
            if (this.selectedOption === NumberFilterOptions.GreaterThanOrEqual)
                this.inputControlValueChanged(val);
        });

        this.lessThanControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(val => {
            if (this.selectedOption === NumberFilterOptions.LessThan)
                this.inputControlValueChanged(val);
        });

        this.lessThanOrEqualControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(val => {
            if (this.selectedOption === NumberFilterOptions.LessThanOrEqual)
                this.inputControlValueChanged(val);
        });
        
        this.betweenStartControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(() => this.betweenChange());
        this.betweenEndControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(() => this.betweenChange());
    }
    private betweenChange() {
        if (this.selectedOption === NumberFilterOptions.Between) {
            let val = this.betweenStartControl.value;
            let val2 = this.betweenEndControl.value;

            if (val == null || val2 == null || val2 <= val)//If it's null then don't change anything.  If they clear out the value we will keep whatever the last one was
                return;

            this.clearNonActiveInputs();
            this.values = [];
            this.addValue([new SearchFilterValue(val.toString(), val.toString()), new SearchFilterValue(val2.toString(), val2.toString())]);
        }
    }
    private inputControlValueChanged(val: string) {
        if (val == null)//If it's null then don't change anything.  If they clear out the value we will keep whatever the last one was
            return;

        this.clearNonActiveInputs();
        this.values = [];
        this.addValue([new SearchFilterValue(val.toString(), val.toString())]);
    }

    private clearNonActiveInputs() {
        if (this.selectedOption === NumberFilterOptions.Equals) {
            this.greaterThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenStartControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenEndControl.reset(null, { onlySelf: true, emitEvent: false });
        }
        else if (this.selectedOption === NumberFilterOptions.GreaterThan) {
            this.equalsControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenStartControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenEndControl.reset(null, { onlySelf: true, emitEvent: false });
        }
        else if (this.selectedOption === NumberFilterOptions.GreaterThanOrEqual) {
            this.equalsControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenStartControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenEndControl.reset(null, { onlySelf: true, emitEvent: false });
        }
        else if (this.selectedOption === NumberFilterOptions.LessThan) {
            this.equalsControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenStartControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenEndControl.reset(null, { onlySelf: true, emitEvent: false });
        }
        else if (this.selectedOption === NumberFilterOptions.LessThanOrEqual) {
            this.equalsControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanControl.reset(null, { onlySelf: true, emitEvent: false })
            this.greaterThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenStartControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenEndControl.reset(null, { onlySelf: true, emitEvent: false });
        }
        else if (this.selectedOption === NumberFilterOptions.Between) {
            this.equalsControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
        }
        else {
            this.equalsControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.greaterThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanControl.reset(null, { onlySelf: true, emitEvent: false });
            this.lessThanOrEqualControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenStartControl.reset(null, { onlySelf: true, emitEvent: false });
            this.betweenEndControl.reset(null, { onlySelf: true, emitEvent: false });
        }
    }
    private enableNeededInputs() {
        if (this.selectedOption === NumberFilterOptions.Equals) {
            if (this.equalsControl.disabled) {
                this.equalsControl.enable({ onlySelf: true, emitEvent: false });
                this.equalsFocusEvent.next(true);
            }
        }
        else if (this.equalsControl.enabled)
            this.equalsControl.disable({ onlySelf: true, emitEvent: false });

        if (this.selectedOption === NumberFilterOptions.GreaterThan) {
            if (this.greaterThanControl.disabled) {
                this.greaterThanControl.enable({ onlySelf: true, emitEvent: false });
                this.greaterThanFocusEvent.next(true);
            }
        }
        else if (this.greaterThanControl.enabled)
            this.greaterThanControl.disable({ onlySelf: true, emitEvent: false });

        if (this.selectedOption === NumberFilterOptions.GreaterThanOrEqual) {
            if (this.greaterThanOrEqualControl.disabled) {
                this.greaterThanOrEqualControl.enable({ onlySelf: true, emitEvent: false });
                this.greaterThanOrEqualFocusEvent.next(true);
            }
        }
        else if (this.greaterThanOrEqualControl.enabled)
            this.greaterThanOrEqualControl.disable({ onlySelf: true, emitEvent: false });

        if (this.selectedOption === NumberFilterOptions.LessThan) {
            if (this.lessThanControl.disabled) {
                this.lessThanControl.enable({ onlySelf: true, emitEvent: false });
                this.lessThanFocusEvent.next(true);
            }
        }
        else if (this.lessThanControl.enabled)
            this.lessThanControl.disable({ onlySelf: true, emitEvent: false });

        if (this.selectedOption === NumberFilterOptions.LessThanOrEqual) {
            if (this.lessThanOrEqualControl.disabled) {
                this.lessThanOrEqualControl.enable({ onlySelf: true, emitEvent: false });
                this.lessThanOrEqualFocusEvent.next(true);
            }
        }
        else if (this.lessThanOrEqualControl.enabled)
            this.lessThanOrEqualControl.disable({ onlySelf: true, emitEvent: false });

        if (this.selectedOption === NumberFilterOptions.Between) {
            if (this.betweenStartControl.disabled) {
                this.betweenStartControl.enable({ onlySelf: true, emitEvent: false });
                this.betweenEndControl.enable({ onlySelf: true, emitEvent: false });
                this.betweenFocusEvent.next(true);
            }
        }
        else if (this.betweenStartControl.enabled) {
            this.betweenStartControl.disable({ onlySelf: true, emitEvent: false });
            this.betweenEndControl.disable({ onlySelf: true, emitEvent: false });
        }
    }

    inputClicked(selectOption: NumberFilterOptions) {
        if (this.isDisabled)
            return;

        if (this.selectedOption !== selectOption) {
            this.selectedOption = selectOption;

            this.selectedOptionChange();
        }
    }

    clear(): void {
        this.selectedOption = NumberFilterOptions.None;
        this.selectedOptionChange();
    }
}
