import { AfterContentInit, Directive, Input } from '@angular/core';
import { ExcavatorAutocompleteResponse } from 'Models/Excavators/ExcavatorAutocompleteResponse.model';
import { Address } from 'Models/Common/Address.model';

@Directive()
export abstract class ExcavatorContactAutocompleteOptionBase implements AfterContentInit {

    @Input("model")
    public Model: ExcavatorAutocompleteResponse;

    public Description: string;
    public FormattedAddress: string;

    public ngAfterContentInit(): void {
        //  These 3 properties exist on all responses.  But not on the "View all" action items.
        if ((this.Model.NumContacts !== null) && (this.Model.NumContacts !== undefined)) {
            if (this.Model.IsHomeowner)
                this.Description = "Homeowner";
            else if (this.Model.NumOffices !== 1)
                this.Description = this.Model.NumOffices.toString().concat(" offices...");

            this.FormattedAddress = this.Model.Address ? Address.FormatAddress(this.Model.Address) : '';
        }
    }
}
