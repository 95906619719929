import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UtilsModule } from 'Shared/Modules/Utils.module';
import { PhoneButtonComponent } from "./PhoneButton.component";

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatButtonModule,
        UtilsModule
    ],
    declarations: [
        PhoneButtonComponent,
    ],
    exports: [
        PhoneButtonComponent,
    ]

})
export class PhoneButtonsModule { }
