<mat-dialog-content style="display: flex; flex-direction: column;"><!--Set the display so that the content will scroll and keep the header at the top-->
    <div class="iq-section-header iq-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{data.Title}}</div>
    <div style="padding: 20px 2vw 0 2vw; overflow: auto;"><!--Set overflow to auto so that this sectino will scroll and keep the header at the top of the dialog-->
        <!-- this allows Message to contain html formatting -->
        <div [innerHTML]="data.Message | trustHtml"></div>

        <br *ngIf="data.Message && data.ConfirmationText" />
        <span *ngIf="data.ConfirmationText" style="float: right; padding-top: 3vh;">
            {{data.ConfirmationText}}
        </span>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button *ngIf="CancelText" button-class="Dialog" icon="Close" [mat-dialog-close]="null"><span style="text-decoration:underline">{{CancelKeyboardShortcut}}</span><span>{{CancelText}}</span></iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="Cancel" [mat-dialog-close]="false"><span style="text-decoration:underline">{{NoKeyboardShortcut}}</span><span>{{NoText}}</span></iq-icon-button>
    <iq-icon-button #yesButton button-class="Dialog" icon="OK" [mat-dialog-close]="true"><span style="text-decoration:underline">{{YesKeyboardShortcut}}</span><span>{{YesText}}</span></iq-icon-button>
</mat-dialog-actions>
