import { Component } from '@angular/core';

@Component({
    templateUrl: './PrintLayout.component.html',
    styleUrls: ['./PrintLayout.component.scss']
})
export class PrintLayoutComponent {

    constructor() {
    }
}
