import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { TicketEntryFormGroup } from 'Pages/Tickets/Details/Components/InputControls/TicketEntryFormGroup';
import { ManuallyAddServiceAreaComponent } from '../ManuallyAddServiceArea.component';

export class ManuallyAddServiceAreaData {
    public ExistingServiceAreaIDs: string[];
    public LimitToServiceAreaIDs?: string[];
    public TicketEntryForm: TicketEntryFormGroup;
    public AddButtonText: string = "Add to Ticket";
}

@Component({
    templateUrl: './ManuallyAddServiceAreaDialog.component.html',
    styleUrls: ['./ManuallyAddServiceAreaDialog.component.scss']
})
export class ManuallyAddServiceAreaDialogComponent {
    @ViewChild(ManuallyAddServiceAreaComponent)
    private _ManuallyAddServiceAreaComponent: ManuallyAddServiceAreaComponent;

    public AddButtonText: string;

    constructor(private _DialogRef: MatDialogRef<ManuallyAddServiceAreaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public ComponentData: ManuallyAddServiceAreaData) {
        this.AddButtonText = ComponentData.AddButtonText;
    }

    public AddToTicket(): void {
        this._DialogRef.close(this._ManuallyAddServiceAreaComponent.SelectedServiceAreas);
    }
}
