import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DiscussionTypeEnum } from 'Enums/DiscussionType.enum';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketEntryAllowedTicketActions } from 'Models/Tickets/TicketEntryAllowedTicketActions';
import { TicketResponseDiscussionListComponent } from 'Pages/Tickets/Responses/Views/TicketResponseDiscussionList/TicketResponseDiscussionList.component';
import { take } from 'rxjs/operators';
import { PermissionsService } from 'Services/PermissionsService';
import { SettingsService } from 'Services/SettingsService';
import { DeviceDetectorService } from '../../../../../Services/DeviceDetector.service';
import { AddPositiveResponseData } from '../../AddPositiveResponse/AddPositiveResponse.component';
import { AddPositiveResponseDialog } from '../../AddPositiveResponse/Dialog/AddPositiveResponseDialog.component';
import { ServiceAreaResponsesDialogComponent, ServiceAreaResponsesDialogData } from '../ServiceAreaResponsesDialog/ServiceAreaResponsesDialog.component';

export class DiscussionEvent {
    NeedsMarkingDelay: boolean;
    NeedsExcavationDate: boolean;
}

@Component({
    selector: 'ticket-response-service-area-list',
    templateUrl: './TicketResponseServiceAreaList.component.html',
    styleUrls: ['./TicketResponseServiceAreaList.component.scss']
})
export class TicketResponseServiceAreaListComponent {
    @Output() NeedsDiscussionValue: EventEmitter<DiscussionEvent> = new EventEmitter();

    //  If this is null, entering responses and changing/viewing the Work Complete will be disabled
    @Input()
    public AllowedActions: TicketEntryAllowedTicketActions;

    @Input()
    public Ticket: Ticket | any;        //  Ticket or a ticket list item

    private _ServiceAreas: TicketAffectedServiceAreaInfo[];
    @Input()
    get ServiceAreas(): TicketAffectedServiceAreaInfo[] { return this._ServiceAreas; }
    set ServiceAreas(value: TicketAffectedServiceAreaInfo[]) {
        this._ServiceAreas = value;
        this.SetupColumns();
    }

    private _IsExcavatorOfTicket: boolean;
    @Input()
    get IsExcavatorOfTicket(): boolean { return this._IsExcavatorOfTicket; }
    set IsExcavatorOfTicket(value: boolean) {
        this._IsExcavatorOfTicket = value;
        this.SetupColumns();
    }

    public DisplayedColumns: SearchColumn[] = [];

    public CanEnterResponse: boolean = false;
    public EnterResponsesPermission: PermissionsEnum = PermissionsEnum.TicketResponse_Create;

    constructor(public SettingsService: SettingsService, private _Dialog: MatDialog, private _PermissionsService: PermissionsService, public DeviceDetectorService: DeviceDetectorService) {
    }

    private SetupColumns(): void {
        this.DisplayedColumns = [];

        const serviceAreaCol = new SearchColumn("ServiceArea", "Service Area", null, null);
        serviceAreaCol.width = "25%"
        serviceAreaCol.canSearchAndFilter = false;
        this.DisplayedColumns.push(serviceAreaCol);

        const currResponseCol = new SearchColumn("CurrentResponses", "Response", null, null);
        currResponseCol.canSearchAndFilter = false;
        this.DisplayedColumns.push(currResponseCol);

        if (this._IsExcavatorOfTicket && this.SettingsService.CenterUsesExcavatorComments) {
            const excCommentsCol = new SearchColumn("ExcavatorComments", "Excavator Comments", null, null);
            excCommentsCol.width = "20%"
            excCommentsCol.canSearchAndFilter = false;
            this.DisplayedColumns.push(excCommentsCol);
        }

        if (TicketResponseDiscussionListComponent.HaveDiscussions(this.ServiceAreas)) {
            const discussionCol = new SearchColumn("Discussions", "Discussions", null, null);
            discussionCol.width = "20%"
            discussionCol.canSearchAndFilter = false;
            this.DisplayedColumns.push(discussionCol);
        }

        this.OnDiscussionChanged();

        if (this.AllowedActions && this.Ticket?.TicketTypeID && this.SettingsService.UsesPositiveResponse) {
            const serviceAreaIDs = this._ServiceAreas.map(sa => sa.ID);
            this._PermissionsService.CurrentUserHasPermission(PermissionsEnum.TicketResponse_Create, serviceAreaIDs).pipe(take(1))
                .subscribe(val => this.CanEnterResponse = coerceBooleanProperty(val));
        }
    }

    public AddNewResponse(serviceAreaInfo: TicketAffectedServiceAreaInfo = null): void {
        //  If clicked for a specific row, will add response for the service area/utility type that was clicked.
        //  Otherwise, we pass in our ServiceAreaList so that it is updated with the changes and the list in this component will update.
        const data = new AddPositiveResponseData([this.Ticket.TicketNumber], this.Ticket.TicketTypeID, true, serviceAreaInfo, !serviceAreaInfo ? this._ServiceAreas : null);

        this._Dialog.open(AddPositiveResponseDialog, {
            data: data,
            minWidth: '45%',
            width: '550px',
            maxWidth: '550px'
        });
    }

    public OnDiscussionChanged(): void {
        if (!this._ServiceAreas)
            return;

        const requiredDiscussionTypes = TicketResponseDiscussionListComponent.CheckForRequiredDiscussions(this._ServiceAreas);

        //  TODO: Change this event so it's more flexible...
        const needsMarkingDelay = (requiredDiscussionTypes.indexOf(DiscussionTypeEnum.MarkingDelayRequested) !== -1);
        const needsExcavationDate = (requiredDiscussionTypes.indexOf(DiscussionTypeEnum.RequestActualExcavationDate) !== -1);
        const event: DiscussionEvent = { NeedsExcavationDate: needsExcavationDate, NeedsMarkingDelay: needsMarkingDelay };
        this.NeedsDiscussionValue.emit(event);
    }

    public ViewServiceAreaResponses(sa: TicketAffectedServiceAreaInfo): void {
        this._Dialog.open(ServiceAreaResponsesDialogComponent, {
            data: new ServiceAreaResponsesDialogData(this.AllowedActions, this.Ticket, sa.ID),
            width: '90%', maxWidth: '90%', height: '90%'
        });
    }
}
