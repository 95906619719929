import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceFormFactorEnum } from 'Enums/DeviceFormFactor.enum';
import { DeviceDetectorService as NgxDeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { PrintingService } from './Printing.service';
import { PreventPhoneGuardService } from './RouteGuards/PreventPhoneGuard.service';
/**
 *  Uses the Angular BreakpointObserver to watch for changes to the browser size.
 *  Maintains the current device size and provides an event when it changes.
 *  ** There is an npm package called ngx-device-detector that also does a good job of identifying the device and
 *  also gives more information about the type of device.  But it does not provide any change notifications.
 */
@Injectable({
    providedIn: 'root'
})
export class DeviceDetectorService {

    //  These rely purely on the UserAgent - not the browser size.
    //  ** These are probably the properties you are looking for.
    public IsDesktop: boolean = false;
    public IsTablet: boolean = false;
    public IsPhone: boolean = false;

    public get DeviceFormFactor(): DeviceFormFactorEnum {
        if (this.IsPhone)
            return DeviceFormFactorEnum.Phone;
        else if (this.IsTablet)
            return DeviceFormFactorEnum.Tablet;
        else
            return DeviceFormFactorEnum.Desktop;
    }

    //  These rely purely on the browser size - not the UserAgent.
    //  ** They should *ONLY* be used if size matters and not the actual physical device.
    public IsDesktopSize: boolean = false;
    public IsTabletSize: boolean = false;
    public IsPhoneSize: boolean = false;

    public IsPortrait: boolean = false;
    public IsLandscape: boolean = false;

    public get IsPrinting(): boolean { return this._PrintingService.IsPrinting; }

    private _ChangedEvent: Subject<void> = new Subject();
    public get Changed(): Subject<void> { return this._ChangedEvent; }

    private _LastBreakpointState: BreakpointState;

    constructor(breakpointObserver: BreakpointObserver, private _Router: Router, private _NgxDeviceDetectorService: NgxDeviceDetectorService,
        private _PrintingService: PrintingService)
    {
        //  Angular's responsive BreakpointObserver: https://blog.angular-university.io/angular-responsive-design/
        //  Can also watch for Tablet or Web sizes and can also differentiate between portrait and landscape.
        //  If we need to do styling based on any of those, we can inject other css styles.
        breakpointObserver
            .observe([Breakpoints.Tablet, Breakpoints.Handset])//, Breakpoints.Web, Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
            .pipe()
            .subscribe(result => {
                //console.warn("DeviceDetector: Breakpoints changed", result);
                this.SetDeviceFlags(result, this._PrintingService.IsPrinting);
            });

        //  Need to monitor for changees on this so that we can force the flags to Desktop/Portrait.  The css classes
        //  are already forced by app.component.html.
        this._PrintingService.IsPrintingChanged.pipe().subscribe(isPrinting => {
            //console.warn("DeviceDetector: IsPrinting changed", isPrinting);
            this.SetDeviceFlags(this._LastBreakpointState, isPrinting);
        });
    }

    private SetDeviceFlags(state: BreakpointState, isPrinting: boolean): void {
        this._LastBreakpointState = state;

        //  This all works but it relies exclusively on the browser size and ignores the user agent.
        //  Which means using the "Force Desktop" function will not work and it can also cause issues when we are embedded
        //  in Coursettra - FL users have smaller screens and it gets detected a landscape phone device (which the hides the header).
        //  Changed this to use NgxDeviceDetector.  Doing that combined with these break point observer and the _ChangedEvent gives us
        //  better detection.
        //  ** If something needs to respond on purely the browser size (like the ticket details page does), it should use pure @media queries.
        /* old
        this.IsTablet = (result.breakpoints[Breakpoints.TabletLandscape] || result.breakpoints[Breakpoints.TabletPortrait]);
        this.IsPhone = (result.breakpoints[Breakpoints.HandsetLandscape] || result.breakpoints[Breakpoints.HandsetPortrait]);
        this.IsDesktop = !this.IsTablet && !this.IsPhone;
        this.IsPortrait = (result.breakpoints[Breakpoints.WebPortrait] || result.breakpoints[Breakpoints.TabletPortrait] || result.breakpoints[Breakpoints.HandsetPortrait]);
        this.IsLandscape = !this.IsPortrait;

        //  Special case.  For some reason, if you manually re-size the browser, there is a spot where Phone will be detected in between
        //  it (correctly) detected as a Tablet.  Which causes our UI to show (as you shrink the width of the browser): Tablet -> Phone (wrong!) -> Tablet -> Phone.
        //  This tries to handle that wrong Phone detection to force it to Tablet.
        if (this.IsPhone && (window.innerWidth > 700) && (window.innerHeight > 700)) {
            this.IsPhone = false;
            this.IsTablet = true;
            //console.warn("DeviceDetectorService: Switched from Phone -> Tablet");
        }
        */

        if (isPrinting)
            this.ForceDesktopPortrait();
        else {
            //  The NgxDeviceDetectorService has a property for orientation but it only seems to update it when the service is created!
            //  So must use the browser size/Breakpoints to determine this or we can't tell when the orientation changes.
            //this.IsPortrait = ngxDeviceDetectorService.orientation === "portrait";
            this.IsPortrait = (state.breakpoints[Breakpoints.WebPortrait] || state.breakpoints[Breakpoints.TabletPortrait] || state.breakpoints[Breakpoints.HandsetPortrait]);
            this.IsLandscape = !this.IsPortrait;

            //  Making these available as a helper for anything (like the MainMenuService) that needs browser size properties.
            this.IsTabletSize = (state.breakpoints[Breakpoints.TabletLandscape] || state.breakpoints[Breakpoints.TabletPortrait]);
            this.IsPhoneSize = (state.breakpoints[Breakpoints.HandsetLandscape] || state.breakpoints[Breakpoints.HandsetPortrait]);
            this.IsDesktopSize = !this.IsTabletSize && !this.IsPhoneSize;

            //  ** This also fires the ChangedEvent so do this last
            this.SetDeviceTypeFromUserAgent();

            if (this.IsPhone)
                this.EnsureRouteAllowedOnPhone(this._Router);
        }

        //console.warn("DeviceDetectorService: Device = ", this.IsDesktop ? "Desktop" : this.IsTablet ? "Tablet" : this.IsPhone ? "Phone" : "????");
        //console.warn("ngxDeviceDetectorService: ", ngxDeviceDetectorService.getDeviceInfo());

        //console.warn("DeviceDetectorService: Orientation = ", this.IsPortrait ? "Portrait" : "Landscape");
        //if (result.breakpoints[Breakpoints.XSmall])
        //    console.warn("DeviceDetectorService: size = XSmall");
        //if (result.breakpoints[Breakpoints.Small])
        //    console.warn("DeviceDetectorService: size = Small");
        //if (result.breakpoints[Breakpoints.Medium])
        //    console.warn("DeviceDetectorService: size = Medium");
        //console.warn("DeviceDetectorService: window size = ", window.innerWidth, window.innerHeight);
        //console.warn("DeviceDetectorService: breakpoints = ", result);
    }

    public SetDeviceTypeFromUserAgent(): void {
        //  The userAgent should never change but it does when we are testing w/Chrome and switch the device type.
        //  So must set it here.  But avoiding whatever ugly stuff happens inside by checking to see if it's different.
        if (navigator.userAgent !== this._NgxDeviceDetectorService.userAgent)
            this._NgxDeviceDetectorService.setDeviceInfo(navigator.userAgent);

        //  These settings come from NgxDeviceDetectorService which uses the User Agent to determine the device.
        //  This is more accurate than using only the browser size and it allows the user to use the "Request Desktop Site" function
        //  on their mobile device.  When using only the device size, we had issues when Exactix was embedded inside Coursettra (FL users have low res monitors).
        this.IsPhone = this._NgxDeviceDetectorService.isMobile();
        this.IsTablet = !this.IsPhone && this._NgxDeviceDetectorService.isTablet();
        this.IsDesktop = !this.IsTablet && !this.IsPhone;

        this._ChangedEvent.next();
    }

    private ForceDesktopPortrait(): void {
        this.IsDesktop = true;
        this.IsPhone = false;
        this.IsTablet = false;
        this.IsLandscape = false;
        this.IsPortrait = true;

        this._ChangedEvent.next();
    }

    /**
     * Traverse the routes to check to see if any do not allow phone.  If not, navigate to /home.
     * The route guards are not triggered when the device size changes.  So this ensures that phone is still not allowed if that happens.
     * Which should not be the case on an actual phone device but makes it consistent when testing browser resizing.
     */
    private EnsureRouteAllowedOnPhone(router: Router): void {
        //  The routes are stored as a tree where the root is the main app route.Have to traverse like this because the PreventPhoneGuardService
        //  could be placed on a parent route(i.e.the "ServiceArea" route) which covers all of the child routes.
        let currentRoute = router.routerState.root;
        do {
            if (currentRoute.routeConfig?.canActivate) {
                currentRoute.routeConfig.canActivate.forEach(canActivate => {
                    if (canActivate === PreventPhoneGuardService) {
                        console.warn("** Phone is not allowed on this page!");
                        router.navigate(["./home"]);
                    }
                })
            }
        } while (currentRoute = currentRoute.firstChild);
    }
}
