/*
    This is provide a layout for a select list (list with check boxes).  You need to provide an iq-list-select-item tag that will display the content of the individual item in the list
*/

import { Component, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'iq-select-list',
    templateUrl: './SelectList.component.html',
    styleUrls: ['./SelectList.component.scss'],
    encapsulation: ViewEncapsulation.None//If changed remove comment from the scss
})
export class SelectListComponent {
    
}


