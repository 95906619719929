export enum DiscussionTypeEnum {
    ExcavatorComment = 0,

    //  Custom Types:

    // Allows excavator to enter the date/time that they plan to begin the actual excavation.  Sends out a new version of the ticket with that information in the remarks.
    RequestActualExcavationDate = 100,

    // Allows excavator to agree or disagree to a service area requesting a marking delay.  No other action taken.
    MarkingDelayRequested = 101,
}
