import { AfterContentInit, Component, ContentChildren, Directive, QueryList, TemplateRef, ViewContainerRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { fadeInAnimation } from '../../../Utils/Animations';


@Directive({
    selector: '[carouselItem]'
})
export class CarouselItemDirective {
    constructor(public templateRef: TemplateRef<any>) {
    }
}


@Component({
    selector: 'iq-carousel',
    animations: [
        fadeInAnimation
    ],
    templateUrl: './Carousel.component.html',
    styleUrls: ['./Carousel.component.scss']
})
export class CarouselComponent implements AfterContentInit, OnDestroy {

    //Used to auto scroll through items.
    //Number is in milliseconds
    @Input() autoMoveNext: number;// = 15000;

    nextIcon = faChevronRight;
    previousIcon = faChevronLeft;

    @ContentChildren(CarouselItemDirective, { descendants: true }) _explicitContent: QueryList<CarouselItemDirective>;

    showArrows: boolean = false;

    currentSlide: TemplateRef<CarouselItemDirective>;
    index: number = 0;

    showAnimation: boolean = false;

    private intervalKey;

    ngOnDestroy() {
        if (this.intervalKey)
            clearInterval(this.intervalKey);
    }

    ngAfterContentInit() {
        this.showArrows = this._explicitContent.length > 1;

        if (this._explicitContent.length > 1) {
            this.currentSlide = this._explicitContent.first.templateRef;

            if (this.autoMoveNext != null)
                this.resetInterval();
        }

        this._explicitContent.changes.subscribe((val: QueryList<CarouselItemDirective>) => {
            this.currentSlide = this._explicitContent.first.templateRef;
            this.showArrows = this._explicitContent.length > 1;

            //If the content changes reset the slide interval
            if (this.autoMoveNext != null)
                this.resetInterval();
        });
    }

    constructor(private _viewContainerRef: ViewContainerRef) {

    }

    slide(right: boolean) {
        this.index = right ? this.index + 1 : this.index - 1;
        if (this.index < 0)
            this.index = this._explicitContent.length - 1;
        if (this.index === this._explicitContent.length)
            this.index = 0;

        this.showAnimation = true;
        this.currentSlide = this._explicitContent.toArray()[this.index].templateRef;
        setTimeout(() => {
            this.showAnimation = false;
            this.resetInterval();//reset the timer so that the user doesn't click next and it change right away
        }, 1000);
    }

    resetInterval() {
        if (this.intervalKey != null) {
            clearInterval(this.intervalKey);
        }

        if (this._explicitContent.length > 1)
            this.intervalKey = setInterval(() => this.slide(true), this.autoMoveNext);
    }

}


