import { Registration } from "./Registration.model";
import { RegistrationActionRequest } from "./RegistrationActionRequest.model";

export class RegistrationRollbackChangeRequest extends RegistrationActionRequest {
    //  The "Number" of the change record to create.  If this is not the next sequential Number
    //  then it indicates that another user has added or removed a change.
    public NextChangeNumber: number;

    constructor(registration: Registration,
        public RegistrationChangeID: string,
        public FromChangeNumber: number) {
        super(registration);
    }
}

