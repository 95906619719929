//TODO: make a common library of items like this and use it instead!!!

import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'busy-overlay',
  templateUrl: './busy-overlay.component.html',
  styleUrls: ['./busy-overlay.component.css']
})
export class BusyOverlayComponent implements OnInit {

  _show: boolean = false;
  @Input() set show(val: boolean) {
    this._show = coerceBooleanProperty(val);
  };
  
  @Input() diameter: number = 100;
  
  /**
   * This will allow overriding if we set the parent position to relative so that the overlay doesn't cover more than the control.  But
   * if you pass in false then that allows the app to control and put the style on a object further up the dom
   */
  @Input() setParentPosition: boolean = true;

  constructor(private elemRef: ElementRef, private renderer2: Renderer2) {
   
  }

  ngOnInit() {
    if (this.setParentPosition == true){
      //Make the parent item have a position of relative so that the busy overlay only shows up inside that element
      const parent = this.renderer2.parentNode(this.elemRef.nativeElement);
      this.renderer2.setStyle(parent, "position", "relative");
    }
  }

}
