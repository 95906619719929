<mat-dialog-content style="display: flex; flex-direction: column;">
    <div class="iq-section-header iq-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{data.Title}}</div>
    <div style="padding: 20px 4vw 0 4vw; overflow: auto;">
        <div [innerHTML]="data.Message | trustHtml"></div>
        <div class="flex-row">
            <mat-progress-bar style="margin-top:1em; margin-bottom:1em; height:6px" mode="determinate" [value]="PctComplete"></mat-progress-bar>
            <div style="padding-left:2em; display:flex; align-self:center">{{PctComplete}}%</div>
        </div>
        <div>{{NumLoaded}} found so far.</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button button-class="Dialog" icon="Cancel" (click)="OnComplete()">Stop</iq-icon-button>
</mat-dialog-actions>
