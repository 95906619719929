
export class RegistrationMergeFromRegistrationRequest {
    constructor(
        //  The Service Area of the registration we are merging into/creating
        public TargetServiceAreaID: string,
        //  If merging into an existing registration, this is the ID we are writing to.
        //  If null, a new Registration will be created (with a parent of the Active registration
        //  or most recently Approved registration - if either exists).
        public TargetRegistrationID: string,
        //  The RegistrationID (within SourceServiceAreaID) of the registration we are merging into the Target Service Area
        public SourceRegistrationID: string) {
    }
}

