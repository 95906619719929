import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

/**
 * Put this directive on the input used by a mat-autocomplete to cause it to automatically pick the active item when focus is lost.
 * Set the directive to the name of the property to (exact match) compare against.  If PropertyName is not set at all, will auto
 * select the first active item.  Use autoActiveFirstOption to auto set the first item to active.
 * If the autocomplete is against string values, set the property name to an empty string to cause it to exact match.
 */
@Directive({
    selector: '[iq-select-on-blur]'
})
export class AutocompleteSelectOnBlurDirective {
    /**
     *  Property value of the object which will be compared against the current string value of the control.
     *  null will cause us to auto select the first active item.
     *  If the autocomplete is against string values, set this to an empty string to cause it to exact match.
     */
    @Input('iq-select-on-blur')
    private _PropertyName: string = null;

    constructor(private _Control: NgControl, private _Trigger: MatAutocompleteTrigger) {
    }

    @HostListener("blur", ["$event"])
    public OnBlur(event: Event): void {
        let val = this._Control.control.value;
        if (!val)
            return;     //  Nothing picked so do nothing
        if (typeof val !== 'string' && !(val instanceof String))
            return;     //  Control is not a string so do nothing - must be a previously selected object

        const options = this._Trigger.autocomplete.options.toArray();//.filter(x => x.active);
        if (!options || (options.length === 0))
            return;     //  No options

        let pickOption: any = null;
        if (!this._PropertyName) {
            //  No property name.  So auto pick the first option (if any) that is active
            pickOption = options.find(o => o.active);
        }
        else {
            //  Have a property name - do exact match
            val = val.toUpperCase();
            if (typeof options[0] === 'string' || (options[0] instanceof String)) {
                //  Options are strings so look for exact match of val against the string
                pickOption = options.find(o => o.value === val);
            } else {
                //  Options are objects - do exact match against a property name
                pickOption = options.find(o => o.value[this._PropertyName] === val);
            }
        }

        if (pickOption)
            this._Control.control.setValue(pickOption.value);
    }
}
