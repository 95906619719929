import { Address } from "@iqModels/Common/Address.model";

export class PersonSelfRegisterRequest {
    OneCallCenterCode: string;

    //Use this if we found the Excavator Contact from the email search.
    ExcavatorContactID: string;

    FirstName: string;
    LastName: string;
    EmailAddress: string;
    PhoneNumber: string;

    Address: Address;

    ExcavatorCompanyID: string;
    ExcavatorOfficeID: string;

    //Set if creating a new Excavator
    ExcavatorCompanyName: string;
    //Set if creating a new Excavator
    ExcavatorCompanyTypeID: string;
    //Set if creating a new Excavator
    ExcavatorIndustryTypeID: string;
    //Set if creating a new Excavator
    ExcavatorAddress: Address;

    //Optional fields to use to try and match on to link the user if they are a homeowner
    PreviousTicketNumber: string;
    PreviousTicketPhoneNumber: string;

    //Project Role ID -- Will be set if the pick a project type that has a specific role (i.e. Political signs)
    ProjectRoleID: string;

    public CognitoAttributes: any;

    constructor() {

    }
}
