<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Add Alternate Login
</h2>
<mat-dialog-content style="overflow: auto; min-height: 150px; display: flex; flex-direction: column;">

    <p style="flex: 1 1 0%;" class="mat-body-2">
        You are about to add the {{ProviderName}} login with the username <b>{{Username}}</b> as an allowed login.  Is this correct?
    </p>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button [mat-dialog-close]="false">No, that's not my login</button>
     <button mat-button [mat-dialog-close]="true" color="primary">Yes</button>
</mat-dialog-actions>
