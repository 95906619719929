<div class="iq-list-header" style="overflow: hidden">
    <div style="display: flex; flex-grow: 1">
        <div class="column-header status-column">Status</div>
        <div class="column-header date-column">Date</div>
        <div class="column-header service-area-column">Service Area</div>
        <div *ngIf="HaveUtilityTypes" class="column-header utility-type-column">Utility Type</div>
        <div class="column-header response-column">Response</div>
        <div class="column-header entry-method-column">Entry Method</div>
        <div class="column-header comments-column">Comments</div>
    </div>
</div>
