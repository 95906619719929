import { ValidationErrors } from "@angular/forms";

export class CustomValidationError {

    constructor(public Error: string) {
    }

    /**
     * Use this to create a ValidationError in a custom validator.  The "error" will be displayed (by our iqValidationMessage directive)
     * when used inside a <mat-error>
     * @param error
     */
    public static Create(error: string): ValidationErrors {
        return { 'iqCustomValidationError': new CustomValidationError(error) };
    }
}
