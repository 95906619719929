import { UntypedFormGroup } from '@angular/forms';
import { OnDestroy, Input, OnInit, AfterContentInit, AfterViewInit, Directive } from '@angular/core';
import { IFocus } from 'Shared/Interfaces/IFocus.interface';
import { SelectOption } from '@iqModels/Configuration/SelectOption.model';
import { IEntity } from '../../../Models/Interfaces/IEntity.interface';

//A base class for anything we do that has Forms.
@Directive()
export class BaseComponent implements OnDestroy, IFocus, OnInit, AfterContentInit, AfterViewInit {

    formGroup: UntypedFormGroup = null;

    @Input()
    set group(val: UntypedFormGroup) {
        this.formGroup = val;
    }

    @Input() Edit: boolean = false;
    @Input() CanEdit: boolean;
    
    constructor() {
    }

    ngOnDestroy() { }

    ngOnInit() { }

    ngAfterContentInit() { }

    ngAfterViewInit() { }

    public focus(selector?: string | null): void { }

    //Use this if there is an element on the page that you don't want to cause the section to change when clicked.
    cancelMouseClick($event: MouseEvent) {
        //Stop it from bubbling up to the Section control.
        $event.stopPropagation();
        $event.preventDefault();
    }

    compareSelectOptions(ct1: SelectOption | null, ct2: SelectOption | null) {
        if (ct1 !== null && ct2 !== null)
            return ct1.Value === ct2.Value;

        return ct1 === null && ct2 === null;
    }

    compareEntity(ct1: IEntity | null, ct2: IEntity | null) {
        if (ct1 !== null && ct2 !== null)
            return ct1.ID === ct2.ID;

        return ct1 === null && ct2 === null;
    }
}
