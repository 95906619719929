import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

//  There are some more spots (emails) that should be converted to use this instead of UpperCase.
//  Search for "TODO: Use LowerCase" to find them
//      ** also search in .cs, there are changes needed to add StoreInLowercaseAttribute to entity models!
//  Left anything ticket entry related as upper case for the moment until get OK from one calls.
@Directive({
    selector: '[LowerCase]',
})
export class LowerCaseTextDirective implements OnInit {
    constructor(private ref: ElementRef, private renderer: Renderer2) {
    }

    //This will get called when loaded and will set the initial value to lowercase
    ngOnInit() {
        this.renderer.setStyle(this.ref.nativeElement, 'text-transform', 'lowercase');
    }
}

