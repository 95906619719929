import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'trustHtml',
    
})
export class TrustHtmlPipe implements PipeTransform {
    constructor(private domSanitier: DomSanitizer) { }

    transform(value) {
        //I don't like this, but the sanatizer strips out the style because of xss attacks...I don't like bypassing it, but it's needed...I would prefer to only bypass the styles, and keep all others..Maybe some day..
        return this.domSanitier.bypassSecurityTrustHtml(value);
    }

}
