import { ValidatorFn, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { ObjectUtils } from 'Shared/Utils/ObjectUtils';

/**
 *  Validates that the secondary FormControl has a value when the primary FormControl has a value.
 *  If invalid, adds a validation error to the secondary control with an error message.
 *  @param primaryFormControlName: The form control name of the primary FormControl - relative to the parent of this FormControl.
 *  @param secondaryFormControlName: The form control name of the secondary FormControl - relative to the parent of this FormControl.
 *  @param invalidMessage: The error message to show if the validation fails.
 * */
export function SecondaryRequiredWithPrimaryValidator(primaryFormControlName: string, secondaryFormControlName: string, invalidMessage: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): { [key: string]: any } => {

        let primaryFormControl = formGroup.controls[primaryFormControlName];
        let secondaryFormControl = formGroup.controls[secondaryFormControlName];
        let errors = secondaryFormControl.errors;

        //  Invalid is primary has a value and secondary does not
        const invalid = (!ObjectUtils.IsEmpty(primaryFormControl.value) && ObjectUtils.IsEmpty(secondaryFormControl.value));

        if (invalid) {
            if (!errors)
                errors = {};
            errors["message"] = invalidMessage;
            secondaryFormControl.setErrors(errors);
        } else if (errors) {
            //  Valid.  Check to see if the message from this validation rule is present in the existing errors.
            //  If so, we need to remove it.
            if (errors.message === invalidMessage) {
                delete errors["message"];
                if (_.isEmpty(errors))
                    errors = null;
                secondaryFormControl.setErrors(errors);
            }
        }

        //  Validation error is set on the secondary control if necessary.  This function always returns null.
        return null;
    }
}
