<mat-dialog-content>
    <div class="iq-section-header iq-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{data.Title}}</div>
    <div style="padding: 1vw 2vw 0 2vw; overflow: auto; max-height: 50vh;">
        <!-- this allows Message to contain html formatting -->
        <div *ngIf="!data.ShowInPre; else preContainer" [innerHTML]="data.Message | trustHtml"></div>

        <ng-template #preContainer>
            <pre style="margin: 0">{{data.Message}}</pre>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button iqAutoFocus button-class="Dialog" icon="OK" [mat-dialog-close]="true">{{data.ActionText}}</iq-icon-button>
</mat-dialog-actions>
