import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { CommonService } from 'Services/CommonService';

export class ConfirmVoidTicketDialogResponse {
    constructor(public Discard: boolean, public Reason: string, public SendVoidedNotification: boolean) { }
}

@Component({
    templateUrl: './ConfirmVoidTicketDialog.component.html',
    styleUrls: ['./ConfirmVoidTicketDialog.component.scss'],
    providers: [
        CommonService,
    ]
})
export class ConfirmVoidTicketDialogComponent {

    public PromptForReason: boolean;
    public Reason: string = null;
    public AllowSendVoidedNotification: boolean;
    public SendVoidedNotification: boolean = false;

    public Email: string;

    constructor(private dialogRef: MatDialogRef<ConfirmVoidTicketDialogComponent, ConfirmVoidTicketDialogResponse>,
        @Inject(MAT_DIALOG_DATA) data, commonService: CommonService, ticketService: TicketService) {

        //  CurrentUser should not be null - but think letting the browser sit for a while causes that to happen.
        //  If so, don't worry about the reason.
        this.PromptForReason = coerceBooleanProperty(commonService.CurrentUser && commonService.CurrentUser.OneCallCenterSettings && commonService.CurrentUser.OneCallCenterSettings.PromptForAbandonComments);

        this.Email = ticketService.Ticket.value?.Excavator?.ContactEmail?.toLowerCase();
        if (this.Email && (this.Email !== "")) {
            this.AllowSendVoidedNotification = ticketService.TicketConfiguration.AllowSendVoidedNotification;
            if (this.AllowSendVoidedNotification) {
                this.SendVoidedNotification = true;     //  When enabled, default to send email (per SC)
            }
        }
    }

    public OnDiscardTicket(): void {
        this.dialogRef.close(new ConfirmVoidTicketDialogResponse(true, this.Reason, this.SendVoidedNotification));
    }

    public CanDiscard(): boolean {
        //  Require a reason if we are prompting for one
        return !this.PromptForReason || (this.Reason && this.Reason.length > 0);
    }

    public OnCancelNavigation(): void {
        this.dialogRef.close(null);
    }
}
