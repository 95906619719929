<!-- This is the column header (with up/down arrows for sorting).  Clicking opens the pop-up contente defined in the template below.  -->
<mat-icon *ngIf="filtered" style="color: initial;">
    filter_list
</mat-icon>
<div style="overflow: hidden; text-overflow: ellipsis;">
    <ng-content></ng-content>
</div>
<mat-icon *ngIf="aToZChecked" style="color: initial;">
    arrow_upward
</mat-icon>
<mat-icon *ngIf="zToAChecked" style="color: initial;">
    arrow_downward
</mat-icon>

<!-- this template is the content shown in the pop-up after clicking on the column header name -->
<ng-template #actions>
    <div class="iq-column-header-options mat-elevation-z7" cdkDrag cdkDragRootElement=".cdk-overlay-pane" [@dialogContainer]="isOpen ? 'enter' : 'exit'">
        <!-- Extra div needed so that the outter div can set height to 100% properly.  Otherwise, the padding (added here) messes it up and scrollbars will not include the full content -->
        <div style="padding:10px; height: 100%; overflow:hidden; display:flex; flex-direction:column">
            <h2 *ngIf="Title != null" class="mat-mdc-dialog-title" cdkDragHandle>{{Title}}</h2>
            <div class="iq-column-header-options-body">
                <div *ngIf="SearchColumn.CanSort" class="flex-row">
                    <div class="flex-column" style="flex-grow:1">
                        <mat-checkbox [(ngModel)]="aToZChecked" (change)="ToggleSortAToZ($event)">Sort Ascending</mat-checkbox>
                        <mat-checkbox [(ngModel)]="zToAChecked" (change)="ToggleSortZToA($event)">Sort Descending</mat-checkbox>
                    </div>
                    <!-- not shown if "SelectItemSearch" because that list has it's own clear now -->
                    <div *ngIf="!SelectItemSearch && (filtered || aToZChecked || zToAChecked) && !isValueRequired" class="link"
                         (click)="ClearSortAndFiltersInternal()">
                        clear
                    </div>
                </div>

                <ng-container *ngIf="SearchColumn.CanFilter">
                    <label style="padding: 10px 0 5px">Limit by...</label>

                    <label *ngIf="SearchColumn.disabledOptionsMessage && isValueRequired" class="iq-caption red">*{{SearchColumn.disabledOptionsMessage}}</label>

                    <iq-list-filter-container [SearchFilter]="filter" [SearchColumn]="SearchColumn" [IsDisabled]="isDisabled" [IsValueRequired]="isValueRequired"
                                              [ClearFilter]="clearFilterEvent" (FilterChanged)="filterChanged($event)"></iq-list-filter-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
