<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Void Ticket?</h1>
<mat-dialog-content style="display:flex; flex-direction:column; overflow:hidden; padding-left:20px; padding-right:20px">
    <div style="text-align:center; font-size:x-large; padding-bottom:20px"><b>This will void the Ticket and prevent any further changes.</b></div>
    <div>Are you sure you want to continue and Void this ticket?</div>
    <div *ngIf="PromptForReason" style="padding-top:20px">
        <div>Please explain why you are Voiding this ticket:</div>
        <mat-form-field style="padding-top:20px; width:100%">
            <mat-label>Reason</mat-label>
            <textarea matInput [(ngModel)]="Reason" UpperCase cdkTextareaAutosize></textarea>
        </mat-form-field>
    </div>
    <div *ngIf="AllowSendVoidedNotification" style="padding-top:20px">
        <mat-checkbox color="primary" [(ngModel)]="SendVoidedNotification" labelPosition="after" class="iq-ticket-entry-label">Send email to Excavator: {{Email}}</mat-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="centered-dialog-buttons">
    <iq-icon-button button-class="Dialog" icon="Cancel" iqAutoFocus (click)="OnCancelNavigation()">Cancel</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" [disabled]="!CanDiscard()" (click)="OnDiscardTicket()">Void Ticket</iq-icon-button>
</mat-dialog-actions>
