import { Component } from '@angular/core';
import { AuthComponentBase } from '../../auth-component-base';
import { ViewStateEnum } from '../../models';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take, finalize } from 'rxjs/operators';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';

@Component({
  selector: 'iq-aws-cognito-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.css'],
	host: { '[class.iq-aws-cognito-confirm-user]': 'true' }
})
export class iQConfirmUserComponent extends AuthComponentBase {
  get username() { return this.group.get("Username"); }
  get code() { return this.group.get("Code"); }

  ShouldDisplay(): boolean {
    return this.authState.state === ViewStateEnum.confirmWithCode;
  }
  BuildForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      Username: new UntypedFormControl({value: null, disabled: true}, [Validators.required]),//Should always have the username because they just tried to sign in with it.
      Code: new UntypedFormControl(null, [Validators.required])
    })
  }

  constructor(protected awsCognitoService: IqAwsCognitoService, private snackbar: MatSnackBar) {
    super(awsCognitoService);    
  }
  
  ngOnInit() {
    super.ngOnInit();

    //Should be set by the time this is shown, so we can do a take(1)
    this.awsCognitoService.viewStateInfoChange$.pipe(take(1)).subscribe(val => {
      if (val.user != null)
        this.username.setValue(val.user.username);
      
      if (val.MessageData != null)
        this.SetActionMessage(val.MessageData);
    });
  }

  onConfirm() {
    this.isBusy = true;
    this.awsCognitoService.ConfirmUser(this.username.value, this.code.value).pipe(take(1), finalize(() => this.isBusy = false))
      .subscribe(null, err => this.SetError(err));
  }

  onResendCode() {
    this.isBusy = true;
    this.awsCognitoService.ResendSignupCode(this.username.value, this.ClientMetadata).pipe(take(1), finalize(() => this.isBusy = false))
      .subscribe(val => this.snackbar.open("Code Sent", null));
  }

  onReturnToLogin(){
    this.awsCognitoService.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
  }

}
