import { Component, OnInit } from '@angular/core';
import { SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { SearchRequest } from '@iqModels/Searching/SearchRequest.model';
import { ExcavatorAutocompleteResponse } from 'Models/Excavators/ExcavatorAutocompleteResponse.model';
import { SearchResponse } from 'Models/Searching/SearchResponse.model';
import { ExcavatorCompanyService } from 'Pages/Excavators/ExcavatorCompanies/Services/ExcavatorCompany.service';
import { map, take } from 'rxjs';
import { AuthenticationService } from 'Services/AuthenticationService';
import { AutoCompleteFormControl } from 'Shared/Components/Forms/FormControls/AutoCompleteFormControl';
import { BaseFilterItem } from '../BaseFilterItem';

@Component({
    selector: 'iq-list-excavator-company-filter',
    templateUrl: './ListExcavatorCompanyFilter.component.html',
    styleUrls: ['./ListExcavatorCompanyFilter.component.scss']
})
export class ListExcavatorCompanyFilterComponent extends BaseFilterItem implements OnInit {

    public AutoCompleteFormControl: AutoCompleteFormControl;

    public IsLocalUser: boolean;

    constructor(private _ExcavatorCompanyService: ExcavatorCompanyService, authenticationService: AuthenticationService) {
        super();

        this.AutoCompleteFormControl = new AutoCompleteFormControl(filter => this.CompanyAutoCompleteCall(filter));
        this.AutoCompleteFormControl.itemDisplayName = "ActionName";

        authenticationService.CurrentUserObserver().pipe(take(1)).subscribe(user => this.IsLocalUser = user.IsLocalUser);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (this.isDisabled)
            this.AutoCompleteFormControl.disable();
    }

    private CompanyAutoCompleteCall = (filter: SearchRequest) => {
        return this._ExcavatorCompanyService.TicketAutoComplete(filter.Filters[0].Values[0].FilterValue, "Name,CompanyID")
            .pipe(map((val: ExcavatorAutocompleteResponse[]) => {
                const response = new SearchResponse();
                response.Items = val;
                response.TotalCount = val.length
                return response;
            }));
    };

    public CompanyAutoCompleteDisplayFn(item?: ExcavatorAutocompleteResponse): string | undefined {
        return item ? item.CompanyName : undefined;
    }

    public AutoCompleteResultSelected(): void {
        const selectedItem: ExcavatorAutocompleteResponse = this.AutoCompleteFormControl.value;

        //  Don't do anything if they select the "No Results" option
        if (selectedItem?.ExcavatorCompanyID) {
            this.values.push(new SearchFilterValue(selectedItem.ExcavatorCompanyID, selectedItem.CompanyName));
            this.fireChangeEvent();
        }

        this.AutoCompleteFormControl.reset();
    }

    public clear(): void {
        this.values = [];
    }

    public RemoveAutoCompleteValue(value: SearchFilterValue): void {
        if (this.isDisabled || (!this.allowEmpty && this.values.length === 1))
            return;

        const index = this.values.findIndex(f => f.FilterValue === value.FilterValue);

        if (index >= 0) {
            this.values.splice(index, 1);
            this.fireChangeEvent();
        }
    }
}
