import { PhoneTypeEnum } from "Enums/PhoneType.enum";

export enum AdditionalContactTypeEnum {
    //  Same values as PhoneTypeEnum
    Phone = PhoneTypeEnum.Phone,
    Fax = PhoneTypeEnum.Fax,
    Cell = PhoneTypeEnum.Cell,
    Pager = PhoneTypeEnum.Pager,

    Email = 10,

    Home = 20,
    Office = 21
}

export enum AdditionalContactTypeEnumDescriptions {
    Phone = "Phone",
    Fax = "Fax",
    Cell = "Cell",
    Pager = "Pager",

    Email = "Email",

    Home = "Home",
    Office = "Office"
}
