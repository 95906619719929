import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentLookup } from 'Services/ComponentLookup.service';
import { ICustomResponseDataComponent } from "../ICustomResponseDataComponent";
import { IIN811ResponseCode3Cand3GSharedDataFormGroup, IN811_ResponseCode3Cand3GSharedComponent } from './IN811_ResponseCode3Cand3GShared.component';

interface IIN811ResponseCode3CDataFormGroup extends IIN811ResponseCode3Cand3GSharedDataFormGroup {
}

@ComponentLookup('IN811-Response-Code-3C')
@ComponentLookup('IN811-Response-Code-3F')
@Component({
    templateUrl: './IN811_ResponseCode3C.component.html',
    styleUrls: ['./IN811_ResponseCode3C.component.scss']
})
export class IN811_ResponseCode3CComponent implements ICustomResponseDataComponent {

    public FormGroup: FormGroup<IIN811ResponseCode3CDataFormGroup>;

    constructor() {
        this.BuildFormGroup();
    }

    private BuildFormGroup(): void {
        this.FormGroup = new FormGroup<IIN811ResponseCode3CDataFormGroup>({
            ...IN811_ResponseCode3Cand3GSharedComponent.CreateFormGroup()
        });
    }

    public IsValid(): boolean {
        return this.FormGroup?.valid ?? false;
    }

    public GetData(): object {
        const data = this.FormGroup.getRawValue();
        IN811_ResponseCode3Cand3GSharedComponent.FormatDates(data);     //  Needed to fix the date field format so it's not in UTC time
        return data;
    }
}
