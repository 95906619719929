<div *ngIf="showArrows" class="arrow-container" (click)="slide()">
    <button mat-icon-button color="primary">
        <fa-icon [icon]="previousIcon"></fa-icon>
    </button>

    <!--<fa-icon class="accent-color" style="cursor: pointer"
             [icon]="previousIcon"
             (click)="slide()"></fa-icon>-->
</div>

<div style="flex: 1 1 100%; overflow: auto;" [@enterTrigger]="showAnimation ? 'fadeIn' : 'void'">
    <ng-container *ngTemplateOutlet="currentSlide"></ng-container>
</div>

<div *ngIf="showArrows" class="arrow-container" (click)="slide(true)">
    <button mat-icon-button color="primary">
        <fa-icon [icon]="nextIcon"></fa-icon>
    </button>
</div>
