import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Ticket } from 'Models/Tickets/Ticket.model';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketResponseHistoryItem } from 'Models/Tickets/TicketResponseHistoryItem.model';
import { AddPositiveResponseData } from '../../AddPositiveResponse/AddPositiveResponse.component';

@Directive()
export abstract class TicketResponseRowViewBase {

    @Input()
    public ResponseItem: TicketResponseHistoryItem;

    @Input()
    public HaveUtilityTypes: boolean = false;

    @Input("Ticket")
    private _Ticket: Ticket | any;      //  Ticket or a ticket list item

    @Input()
    public CanEnterResponse: boolean;

    @Output()
    public Refresh = new EventEmitter<void>();

    constructor()
    {
    }

    public AddNewResponse(): void {
        if (!this.ResponseItem.Response)
            return;     //  Not a response!  User probably clicked on an Event row on phone

        //if (this.AllowedActions && this.Ticket?.TicketTypeID && this.CommonService.SettingsService.UsesPositiveResponse) {
        //    this._PermissionsService.CurrentUserHasPermission(PermissionsEnum.TicketResponse_Create, serviceAreaIDs).pipe(take(1))
        //        .subscribe(val => this.CanEnterResponse = coerceBooleanProperty(val));
        //}

        //  Create a dummy TicketAffectedServiceAreaInfo so that AddPositiveResponseDialog will limit to that service area/utility type.
        //  It doesn't need any more than enough to know how to create a new response.
        const serviceAreaInfo = new TicketAffectedServiceAreaInfo();
        serviceAreaInfo.ID = this.ResponseItem.Response.ServiceAreaID;
        serviceAreaInfo.Code = this.ResponseItem.Response.ServiceAreaCode;
        serviceAreaInfo.Name = this.ResponseItem.Response.ServiceAreaName;
        serviceAreaInfo.CurrentResponses = [this.ResponseItem.Response];

        const data = new AddPositiveResponseData([this._Ticket.TicketNumber], this._Ticket.TicketTypeID, true, serviceAreaInfo);
        this.ShowAddResponseDialog(data);
    }

    protected abstract ShowAddResponseDialog(data: AddPositiveResponseData): void;
}
