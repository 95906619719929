import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthComponentBase } from 'iqCognito/auth-component-base';
import { PasswordRequirements, ViewStateEnum } from 'iqCognito/models';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { controlsEqualValidator, IqAwsValidationClass, validateUsername } from 'iqCognito/validation';
import { finalize, take } from 'rxjs/operators';
import { CognitoAdminService } from 'Services/CognitoAdminService';
import { SettingsService } from 'Services/SettingsService';

@Component({
    selector: 'auth-forgot-password',
    templateUrl: './ForgotPasswordView.component.html',
    styleUrls: ['./ForgotPasswordView.component.scss']
})
export class ExactixResetPasswordComponent extends AuthComponentBase {

    private _passwordRequirements: PasswordRequirements;
    @Input() set PasswordRequirements(val: PasswordRequirements) {
        let defaultRequirements = new PasswordRequirements();
        this._passwordRequirements = { ...defaultRequirements, ...val };
    }
    get PasswordRequirements() {
        return this._passwordRequirements;
    }

    waitingForCode: boolean;
    fromLogin: boolean = false;
    forgotUsername: boolean = false;

    get username() { return this.group.get("Username"); }
    get password() { return this.group.get("Password"); }
    get confirmPassword() { return this.group.get("ConfirmPassword"); }
    get code() { return this.group.get("Code"); }

    get email() { return this.group.get("Email"); }

    ShouldDisplay(): boolean {
        return this.authState.state == ViewStateEnum.resetPassword;
    }
    BuildForm() {
        return new UntypedFormGroup({
            Username: new UntypedFormControl(null, [Validators.required, validateUsername]),
            Password: new UntypedFormControl(null, [Validators.required]),
            ConfirmPassword: new UntypedFormControl(null, [Validators.required]),
            Code: new UntypedFormControl(null, [Validators.required]),
            Email: new UntypedFormControl(null)
        }, [controlsEqualValidator('Password', 'ConfirmPassword')]);
    }

    constructor(protected awsCognitoService: IqAwsCognitoService, private snackBar: MatSnackBar, private cognitoAdminService: CognitoAdminService, private http: HttpClient, private settingsService: SettingsService) {
        super(awsCognitoService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.SetPasswordRequirements(this.password, this.PasswordRequirements);

        //Should be set by the time this is shown, so we can do a take(1)
        this.awsCognitoService.viewStateInfoChange$.pipe(take(1)).subscribe(val => {

            if (val.user != null)
                this.username.setValue(val.user.username);

            if (val.MessageData && val.MessageData.FromLogin) {
                this.username.disable();
                this.fromLogin = true;
                this.ActionMessage = "A password reset has been requested and not completed.  Please check your email for a code, or have it resent by clicking 'Resend code' below."
                this.waitingForCode = true;
            }
        });
    }

    onForgotUsername() {
        this.forgotUsername = true;
        this.email.setValidators([Validators.required, Validators.pattern(IqAwsValidationClass.emailPattern)]);
    }

    onReturnToLogin() {
        this.awsCognitoService.setViewState({
            state: ViewStateEnum.signedOut,
            user: this.username.value ? { username: this.username.value } : null,
            MessageData: null
        });
    }

    onSendEmail() {

        const usernameValue = this.username.value;
        if (!usernameValue) {
            this._errorMessage = 'Username cannot be empty';
            return;
        }

        //reset the error in case they changed the username and it's valid now
        this._errorMessage = null;
        this.username.disable();
        this.isBusy = true;

        //  We are using our own api method to trigger this because it will handle a user with an
        //  unverified email and cause the email to be sent anyway.  That is necessary to avoid an account
        //  getting stuck with no way to recover it.
        //this.awsCognitoService.InitForgotPassword(usernameValue, this.ClientMetadata)
        this.cognitoAdminService.ForgotPasswordByUsername(usernameValue)
            .pipe(finalize(() => this.isBusy = false))
            .subscribe(
                (data) => {
                    this.waitingForCode = true;
                    this.SetActionMessage(data);
                    this.snackBar.open("Code Sent", null);
                },
                (err) => {
                    this.username.enable();
                    this.SetError(err.error.Message);   //  Error formatted by Exactix api exception handler
                    this.waitingForCode = false;
                });
    }

    onSendUsernameEmail() {
        //reset the error in case they changed the username and it's valid now
        this._errorMessage = null;
        this.email.disable();
        this.isBusy = true;

        this.cognitoAdminService.ForgotPasswordByEmail(this.email.value)
            .pipe(finalize(() => this.isBusy = false))
            .subscribe(
                (data) => {
                    this.waitingForCode = true;
                    this.forgotUsername = false;
                    this.SetActionMessage(data);
                    this.snackBar.open("Code Sent", null);
                },
                (err) => {
                    this.email.enable();
                    this.SetError(err.error.Message);   //  Error formatted by Exactix api exception handler
                    this.waitingForCode = false;
                });
    }

    onSubmitPasswordChange() {
        if (this.group.valid) {
            this.isBusy = true;

            this.awsCognitoService.CompleteForgotPassword(this.username.value, this.password.value, this.code.value, this.ClientMetadata)
                .pipe(take(1), finalize(() => this.isBusy = false))
                .subscribe(null, (err) => this.SetError(err));
        }
        else
            this.SetError("Please provide required data.");
    }
}

