<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{Permanent ? 'Change' : 'Set Temporary'}} Password
</h2>
<mat-dialog-content style="display: flex; flex-direction: column; overflow: auto;" class="mat-typography">
    <!-- *ngIf="!Permanent"-->
    <p *ngIf="!Permanent" class="mat-body-2" style="color:red">
        You are about to set a temporary password.  The user will need to use this password to login and will then
        be prompted to change their password.
    </p>

    <iq-password-prompt [PasswordFormControl]="PasswordFormControl" [PasswordRequirements]="PasswordRequirements"></iq-password-prompt>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: flex-end">
    <iq-icon-button button-class="Dialog" icon="Cancel" mat-dialog-close>Cancel</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="Save" [disabled]="!PasswordFormControl.valid" [mat-dialog-close]="PasswordFormControl.value">Save</iq-icon-button>
</mat-dialog-actions>
