import { Injectable } from '@angular/core';
import { takeUntil, filter, map } from 'rxjs/operators';
import { timer, Subject, Observable } from 'rxjs';
import { SingleSignOnReadData, SingleSignOnStoreData, SingleSignOnService } from '../models';
import { CryptoService } from './crypto.service';

export class iFrameSingleSignOnServiceConfig {
    /** The id of the iFrame used to store the tokens */
    iFrameElementId: string;
    /** 
     * This should be the host of iFrame i.e. https://localhost 
     * 
     * This is used to verify the domain is allowed to post and receive message
    */
    iFrameOriginHost: string;
    /** 
     * The url in the iframe. Host has to match the iFrameOriginHost value
     * 
    */
    iFrameUrl: string;
    /** Turns on debug messages */
    debug: boolean = false;
}

function _window() : any {
    // return the global native browser window object
    return window;
 }
 
 @Injectable({
     providedIn: 'root'
 })
 export class WindowRef {
    get nativeWindow() : any {
       return _window();
    }
 }

@Injectable({
    providedIn: 'root'
})
export class iFrameSingleSignOnService implements SingleSignOnService {

    /** Update this if you make changes to how the data is stored on the html used for the iframe */
    private STORAGE_VERSION_NUMBER = "1";

    private get IsDebug(): boolean { return this.settings.debug; };

    private get iFrameElementId(): string { return this.settings.iFrameElementId; };
    private get iFrameUrl(): string { return this.settings.iFrameUrl; };
    private get iFrameOriginHost(): string { return this.settings.iFrameOriginHost; };


    private get crypto(): CryptoService { return new CryptoService(); };
    private get winRef(): WindowRef { return new WindowRef(); };
    
    /**
     * Even that is fired when we get an event from the post message listener
     * 
     * The first property is a key we got back to compare and make sure it's the right response.
     * The second is data needed to know what to return to the Read, Clear and Store data methods
     * 
     */
    private SsoListenerCallbackEvent = new Subject<[string, {username: string, token: string, tokenExpirationDate: string, success: boolean}]>();//May be able to remove the second property (boolean)

    constructor(private settings: iFrameSingleSignOnServiceConfig){
    }

    private listener;
    private setupListener(){
        if (this.listener == null){
            //listen for messages coming from the expected origin...
            this.listener = (event) => this.SsoConnectorPostListener(event);

            //attach a listener for when postMessage calls come in...
            if (this.winRef.nativeWindow.addEventListener) {
                this.winRef.nativeWindow.addEventListener("message", this.listener, false);
            } else {
                this.winRef.nativeWindow.attachEvent("onmessage", this.listener);
            }
        }
    }

    /**
     * Get the iFrame for the Connector
     */
    public getSsoIframe() {
        var frame = (<HTMLIFrameElement>document.getElementById(this.iFrameElementId));
        if (frame == null) {
            console.warn('SSO iframe config passed in, but not found! ID="' + this.iFrameElementId + '"');

            return null;
        }

        if (frame.src != this.iFrameUrl) {
            console.warn("SSO iframe config passed in, but the passed in url doesn't match the actual url!");

            return null;
        }

        return frame;
    }

    
    private newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /**
     * The code called from the post message back from the SSO connector (iFrame)
     */
    private SsoConnectorPostListener(event: any) {

        if (event.origin !== this.iFrameOriginHost) {
            if (this.IsDebug)
                console.log('iqAwsCognitoLog - listener got event on non trusted origin', event, this.iFrameOriginHost);

            //No point in firing the event because we don't have a key so the listening observer will ignore it anyway
            return;
        }

        if (this.IsDebug)
            console.log('iqAwsCognitoLog - listener got event', event);

        let data = null;

        //Maybe eat this error too?  Our code should always return something.
        if (!event.data)
            throw "No data returned in SSO event.data";

        try {
            data = JSON.parse(event.data);
        }
        catch {

            //Eat this error.  Apparently other extentions (Chrome Extentions) can fire postmessages from any document, so they can fire one from our iFrame
            //  https://developer.chrome.com/docs/extensions/mv3/messaging/

            if (this.IsDebug)
                console.log("**Couldn't parse the SSO return event: ", event, "\r\n**event.data ", event.data);

            //No point in firing the event because we don't have a key so the listening observer will ignore it anyway
            return;

            //Left in for a history of trying to figure out if there was a way to ignoe the extentions
            //
            //If it fails to parse this we need to know what the it's trying to parse so we can try to fix it...
            //  We can't complete the call if this isn't parsed because we need the key property.  The key property is what we listen for to know that we got the
            //  right response from our call to the sso connector (which could have multiple if for some reason the methods to interact with it are called more than
            //  once before succeding).  i.e. Calling ClearData and StoreData and not waiting for the first one to finish.
            //We seem to be getting events from other extentions...I"m not sure how since we check the origin, so logout the whole event here so we can maybe check how that url is sending post events we aren't doing...
            //throw "Couldn't parse the SSO return data: toString()=" + event.data.toString() + ", stringify()=" + JSON.stringify(event.data) + ", event = " + event.origin;
        }

        //This one may be a valid error we want to log, we should always have data at this point based on the checks above...
        if (!data)
            throw "SSO return data parsed to empty: " + JSON.stringify(event.data);


        if (!data.key) {
            //Eat this error.  Apparently other extentions (Chrome Extentions) can fire postmessages from any document, so they can fire one from our iFrame
            //  https://developer.chrome.com/docs/extensions/mv3/messaging/

            if (this.IsDebug)
                console.log("iqAwsCognitoLog - Got message parsed it correctly, but it does not have the key property");

            //No point in firing the event because we don't have a key so the listening observer will ignore it anyway
            return;
        }

        //This should really be an enum or a string of 'failed', 'stored' or 'read' because those are 3 states it really represents.
        if (data.success === true || data.success === false) {
            this.SsoListenerCallbackEvent.next([data.key, { username: null, token: null, tokenExpirationDate: null, success: data.success }]);
            return;
        }

        //We don't have the user data.  Something went wrong if we get here without this data...Fire the event so that it knows something went wrong.
        if (!data.clientId || !data.username || !data.token) {
            this.SsoListenerCallbackEvent.next([
                data.key,
                null
            ]);
            return;
        }

        //Reading data, so fire the event.
        this.SsoListenerCallbackEvent.next([data.key, {
            username: data.username, token: this.crypto.decode(data.token),
            tokenExpirationDate: data.tokenExpirationDate, success: true
        }]);
    }

  /**
   * Store the data to the iframe
   * 
   * The observable result is if it succeded to write the data or not.
   * @param data 
   */
    StoreData(data: SingleSignOnStoreData): Observable<boolean> {

        return new Observable<boolean>(observer =>{

            let frame = this.getSsoIframe();
            if (frame == null){
                observer.next(false);
                observer.complete();
                return;//Can't do anything.
            }

            let {clientId, username, token, tokenExpirationDate} = data;

            if (username == null || username == "" || token == null || token == "")
            {
                console.error("iqAwsCognitoLog - a username and token has to be passed to save the token.  If you were trying to clear the token call 'ClearSsoTokenConnector'")	
                observer.next(false);
                observer.complete();
                return;
            }

            let msg = {
                version: this.STORAGE_VERSION_NUMBER,
                key: this.newGuid(),
                clientId: clientId,
                username: username,
                token: this.crypto.encode(token),
                tokenExpirationDate: tokenExpirationDate
            }

            if (this.IsDebug)
                console.log('iqAwsCognitoLog - Post to set SSO token', msg, this.iFrameOriginHost);

      
            this.setupListener();
            
            const timerCanceled = new Subject<void>();
            this.SsoListenerCallbackEvent.pipe(filter(f => f[0] === msg.key), takeUntil(timerCanceled))
            .subscribe(val =>{
                let success = val != null && val[1] != null && val[1].success === true;

                observer.next(success);
                observer.complete();

                timerCanceled.next();
                timerCanceled.complete();
            });

            let win = frame.contentWindow;
            const maxAttempts = 3;
            timer(0, 2000).pipe(takeUntil(timerCanceled))
            .subscribe(val =>{

                if (val > maxAttempts){
                    timerCanceled.next();
                    timerCanceled.complete();
                    observer.next(false);
                    observer.complete();
                }

                win.postMessage(JSON.stringify(msg), this.iFrameOriginHost);
            });
            
        });
  }
  
  /**
   * Read data from the iframe for the clientId
   * 
   * The observable result is the data needed to create the login in this domain
   * @param clientId 
   */
    ReadData(clientId: string) {
        return new Observable<SingleSignOnReadData>(observer => {

            let frame = this.getSsoIframe();
            if (frame == null){

                observer.next(null);
                observer.complete();
                return;//Can't do anything.
            }

            let msg = {
                version: this.STORAGE_VERSION_NUMBER,
                key: this.newGuid(),
                clientId: clientId
            }

            if (this.IsDebug)
                console.log('iqAwsCognitoLog - Post to get SSO token', msg, this.iFrameOriginHost);

            
            const timerCanceled = new Subject<void>();

            this.SsoListenerCallbackEvent.pipe(filter(f => f[0] === msg.key), takeUntil(timerCanceled), map(val => {

                if (val != null){
                    //If we got a value back but it says it didn't succeed then we need to stop trying and return null.  This can happen if the request was
                    //	bad data or if it came from an unvalidated origin or even if the browser doesn't let us store to the storage used in the iframe
                    if (val[1] == null || val[1].success === false) {
                        observer.next(null);
                        observer.complete();
                    }
                    else{
                        observer.next(val[1]);
                        observer.complete();
                    }

                    timerCanceled.next();
                    timerCanceled.complete();
                }
                return val;
            }))
            .subscribe();

            this.setupListener();

            let win = frame.contentWindow;
            const maxAttempts = 3;
            timer(0, 2000).pipe(takeUntil(timerCanceled)).subscribe(val =>{
                
                if (val > maxAttempts){
                    timerCanceled.next();
                    timerCanceled.complete();

                    observer.next(null);
                    observer.complete();	
                }

                win.postMessage(JSON.stringify(msg), this.iFrameOriginHost);
            });

        });
  }
  
  /**
   * Clear data from the iframe for the clientId
   * 
   * The observable result is if it succeded to clear the data or not.
   * @param clientId 
   */
    ClearData(clientId: string): Observable<boolean> {

        return new Observable<boolean>(observer =>{
            let frame = this.getSsoIframe();
            if (frame == null)
                return;//Can't do anything.

            let msg = {
                version: this.STORAGE_VERSION_NUMBER,
                key: this.newGuid(),
                clientId: clientId,
                logout: true
            }

            if (this.IsDebug)
                console.log('iqAwsCognitoLog - Post to clear SSO token', msg, this.iFrameOriginHost);

            const timerCanceled = new Subject<void>();

            this.SsoListenerCallbackEvent.pipe(filter(f => f[0] === msg.key), takeUntil(timerCanceled), map(val =>{
                if (val != null){
                    let success = val != null && val[1] != null && val[1].success === true;

                    observer.next(success);
                    observer.complete();

                    timerCanceled.next();
                    timerCanceled.complete();
                }
                return val;
            }))
            .subscribe();

            this.setupListener();

            let win = frame.contentWindow;
            const maxAttempts = 3;
            timer(0, 2000).pipe(takeUntil(timerCanceled)).subscribe(val =>{
                
                if (val > maxAttempts){
                    timerCanceled.next();
                    timerCanceled.complete();

                    observer.next(false);
                    observer.complete();	
                }

                win.postMessage(JSON.stringify(msg), this.iFrameOriginHost);
            });
        });
    }

}
