import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListColumnGroup } from '@iqModels/Configuration/ConfiguredListColumnsAndFilters/ListColumnGroup.model';
import { EntityEnum } from 'Enums/EntityType.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from 'Services/SettingsService';

/*
 Used to keep track of what the user has picked to display, and what the options are.  All saving and stuff needs to be in the Crud service
 */
@Injectable({
    providedIn: 'root'
})
export class ListColumnService {
    protected apiPath: string = "/Config/ListColumnGroup";

    constructor(private http: HttpClient, private settingsService: SettingsService) {
    }

    //Add any configuration calls in here so that the cached values will get cleared when a person switches one calls
    //or logs out(may not be needed when they logout, because to login they get redirected to the IdSvr, but just to be safe we clear it out)
    public ClearCache() {
        this._userDisplayedColumns = {};
        this._userAvailableColumns = {};
        this.LastSavedListGroup = {};
        this._subs = {};
    }

    LastSavedListGroup: { [key: number]: ListColumnGroup } = {};//This is used if the user modifies the column list and doesn't save it. If the current one is the saved one, then this should be empty
    private _userDisplayedColumns: { [key: number]: ListColumnGroup } = {};
    public UpdateDisplayedColumns(item: ListColumnGroup) {
        if (item.Entity === EntityEnum.None) {
            console.warn('Need to provide an entity type if you want to save the columns');
            return;//if none then nothing to do
        }

        this._userDisplayedColumns[item.Entity] = item;

        if (this._subs[item.Entity])
            this._subs[item.Entity].next(item);
    }

    private _subs: { [key: number]: BehaviorSubject<ListColumnGroup> } = {};
    public GetUserCurrentDisplayedColumns(type: EntityEnum): BehaviorSubject<ListColumnGroup> {
        if (type === EntityEnum.None) {
            console.warn('Need to provide an entity type if you want to save the columns');
            return new BehaviorSubject(null);//if none then nothing to do
        }

        if (!this._subs[type])
            this._subs[type] = new BehaviorSubject(this._userDisplayedColumns[type]);

        return this._subs[type];
    }

    private _userAvailableColumns: { [key: number]: ListColumnGroup[] } = {};
    public GetUserAllDisplayedColumns(type: EntityEnum): Observable<ListColumnGroup[]> {
        return new Observable<ListColumnGroup[]>(observer => {
            if (this._userAvailableColumns[type] == null) {
                //  Don't have data cached yet - fetch it now
                return this.http.get<ListColumnGroup[]>(this.settingsService.ApiBaseUrl + this.apiPath + "/GetAllForType/" + type)
                    .subscribe(data => {
                        this._userAvailableColumns[type] = data;

                        //  Don't need to make a copy - these can't be modified and if we refresh, we set the cached property to null to force a new download
                        observer.next(data);
                        observer.complete();
                    }, err => {
                        observer.error(err);
                        observer.complete();
                    });
            }
            else {
                //  Already have data cached so return it immediately
                //  Don't need to make a copy - these can't be modified and if we refresh, we set the cached property to null to force a new download
                observer.next(this._userAvailableColumns[type]);
                observer.complete();
            }
        });
    }
}
