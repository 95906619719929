<mat-radio-group style="display:flex; flex-direction: column; overflow-y: auto; padding: 10px" [(ngModel)]="selectedOption" (change)="selectedOptionChange()" [disabled]="isDisabled">
    <mat-radio-button [value]="FilterOptions.None" color="primary" [disabled]="!allowEmpty">All</mat-radio-button>

    <div style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.Equals" color="primary">Equals</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" (click)="inputClicked(FilterOptions.Equals)">
            <mat-label>value</mat-label>
            <input autocomplete="off" type="number" min="0" [formControl]="equalsControl" matInput [iqFocus]="equalsFocusEvent" />
        </mat-form-field>
    </div>

    <div style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.GreaterThan" color="primary">Greater than</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" (click)="inputClicked(FilterOptions.GreaterThan)">
            <mat-label>value</mat-label>
            <input autocomplete="off" type="number" min="0" [formControl]="greaterThanControl" matInput [iqFocus]="greaterThanFocusEvent" />
        </mat-form-field>
    </div>

    <div style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.GreaterThanOrEqual" color="primary">Greater than or equal to</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" (click)="inputClicked(FilterOptions.GreaterThanOrEqual)">
            <mat-label>value</mat-label>
            <input autocomplete="off" type="number" min="0" [formControl]="greaterThanOrEqualControl" matInput [iqFocus]="greaterThanOrEqualFocusEvent" />
        </mat-form-field>
    </div>

    <div style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.LessThan" color="primary">Less Than</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" (click)="inputClicked(FilterOptions.LessThan)">
            <mat-label>value</mat-label>
            <input autocomplete="off" type="number" min="0" [formControl]="lessThanControl" matInput [iqFocus]="lessThanFocusEvent" />
        </mat-form-field>
    </div>

    <div style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.LessThanOrEqual" color="primary">Less than or equal to</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" (click)="inputClicked(FilterOptions.LessThanOrEqual)">
            <mat-label>value</mat-label>
            <input autocomplete="off" type="number" min="0" [formControl]="lessThanOrEqualControl" matInput [iqFocus]="lessThanOrEqualFocusEvent" />
        </mat-form-field>
    </div>

    <div style="display: flex; align-items: baseline">
        <mat-radio-button [value]="FilterOptions.Between" color="primary">Between</mat-radio-button>
        <mat-form-field subscriptSizing="dynamic" (click)="inputClicked(FilterOptions.Between)">
            <mat-label>min</mat-label>
            <input autocomplete="off" type="number" min="0" [formControl]="betweenStartControl" matInput [iqFocus]="betweenFocusEvent" />
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" (click)="inputClicked(FilterOptions.Between)">
            <mat-label>max</mat-label>
            <input autocomplete="off" type="number" min="0" [formControl]="betweenEndControl" matInput />
        </mat-form-field>
    </div>
</mat-radio-group>
