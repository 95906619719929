import { Directive, ViewContainerRef, Type } from '@angular/core';

/**
 *  Use this directive to dynamically inject a Component into a page.
 *  Put this directive on an ng-template element, use @ViewChild to get the instance from the page,
 *  then call LoadComponent with the component type you want to create.
 */
@Directive({
    selector: '[dynamic-content]'
})
export class DynamicContentDirective {
    constructor(private _ViewContainerRef: ViewContainerRef) { }

    /**
     * Load the component into the element that contains this directive.
     * Returns the instance of the newly created component.
     * @param componentType
     */
    public LoadComponent<T>(componentType: Type<T>) : T {
        this._ViewContainerRef.clear();
        return componentType ? this._ViewContainerRef.createComponent<T>(componentType).instance : null;
    }

    public ClearComponent(): void {
        this._ViewContainerRef.clear();
    }
}
