import { Component } from '@angular/core';
import { AddPositiveResponseData } from 'Pages/Tickets/Responses/AddPositiveResponse/AddPositiveResponse.component';
import { TicketResponseRowViewBase } from "../TicketResponseRowViewBase";

@Component({
    selector: 'desktop-ticket-response-row-header',
    templateUrl: './DesktopTicketResponseRowHeader.component.html',
    styleUrls: ['./DesktopTicketResponseRowHeader.component.scss']
})
export class DesktopTicketResponseRowHeaderComponent extends TicketResponseRowViewBase {
    constructor() {
        super();
    }

    protected ShowAddResponseDialog(data: AddPositiveResponseData): void { }
}
