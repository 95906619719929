import { Component, Inject } from '@angular/core';
import { CommonService } from 'Services/CommonService';
import { TicketFunctionBase } from "../TicketFunctionBase.component";
import { StartTicketFunctionData } from "../StartTicketFunctionData.model";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { TicketEntryOptionsService } from 'Pages/Tickets/Services/TicketEntryOptions.service';

@Component({
    templateUrl: './TicketFunctionDialog.component.html',
    styleUrls: ['./TicketFunctionDialog.component.scss'],
    providers: [
        CommonService,
        //  Scoped to this component.  All children (and directives) can add to their constructor to get THIS instance.
        //  *BUT* That does not apply to any other dialogs that may be opened because the MatDialog is scoped to the application root!
        //  Any child dialog that needs this instance of TicketService, will need to have it specifically passed to it instead
        //  of getting it from it's own constructor!
        //  https://stackoverflow.com/questions/58083775/propagating-service-instance-to-dialog-component-not-working-as-expected
        TicketService, TicketEntryOptionsService //  Once we move all of the state storage out of TicketService, should only need TicketEntryOptionsService
    ]
})
export class TicketFunctionDialogComponent extends TicketFunctionBase {

    constructor(ticketService: TicketService, @Inject(MAT_DIALOG_DATA) data: StartTicketFunctionData, private _DialogRef: MatDialogRef<any>) {
        super(ticketService, data);
    }

    public override OnSaveTicket(): void {
        this._DialogRef.disableClose = true;        //  Prevent ESC/outside click during save!
        super.OnSaveTicket();
    }

    protected override CloseForm(saved: boolean): void {
        this._DialogRef.close(saved);
    }
}
