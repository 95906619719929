import { Pipe, PipeTransform } from '@angular/core';
import { EntityEnum, EntityEnumDescription } from 'Enums/EntityType.enum';

@Pipe({
    name: 'LogLevelEnum'
})
export class EntityTypeEnumPipe implements PipeTransform {
    transform(value: EntityEnum): string {
        if (!value)
            return "";

        //  If there is a description defined, use it.  Otherwise will return the name.
        let description = EntityEnumDescription[EntityEnum[value]];
        return description ? description : EntityEnum[value];
    }
}
