import { Component, OnDestroy } from '@angular/core';
import { MainMenuService } from 'Services/MainMenuService';

@Component({
  selector: 'switch-one-call',
    templateUrl: './SwitchCenter.component.html',
    styleUrls: ['./SwitchCenter.component.scss']
})
export class SwitchCenterComponent implements OnDestroy {

    constructor(private mainMenuService: MainMenuService) {
        mainMenuService.forceClose = true;
    }

    ngOnDestroy() {
        this.mainMenuService.forceClose = false;
    }
}
