<div style="display:flex; flex-direction:column; column-gap: 20px;">
    <div class="column-if-phone-portrait" style="display:flex; flex-wrap:wrap; justify-content:space-between; column-gap:20px">
        <mat-form-field style="flex-grow:1">
            <mat-label>Service Area</mat-label>
            <mat-select #serviceAreaSelect [formControl]="ServiceAreaInfoFormControl" [compareWith]="CompareServiceAreaInfo">
                <mat-select-trigger>
                    {{ServiceAreaInfoFormControl.value ? ServiceAreaInfoFormControl.value.Code : ''}}
                </mat-select-trigger>

                <mat-option *ngFor="let sa of ServiceAreaInfoList" [value]="sa" style="height:auto; padding-top:10px; padding-bottom:10px">
                    <div style="display:flex; flex-direction: column; line-height: 15px">
                        <div>{{ sa.Name }}</div>
                        <div class="iq-caption gray-primary-text">{{ sa.Code }}</div>
                        <div class="iq-caption" style=" display: flex; flex-direction: column; place-content: center flex-start; justify-content: center;">
                            <ng-container *ngIf="sa.NeedsResponse; else currentResponse;">
                                <mat-icon class="red" style="margin-right: 5px; font-weight:600;">error_outline</mat-icon>
                                Response Needed
                            </ng-container>
                            <ng-template #currentResponse>
                                <ng-container *ngIf="sa.CurrentResponses?.length">
                                    <mat-icon class="green" style="margin-right: 5px; font-weight:600;">check</mat-icon>
                                    {{ sa.CurrentResponses[0].ResponseCode }} - {{ sa.CurrentResponses[0].ResponseDescription }}
                                </ng-container>
                            </ng-template>
                        </div>
                    </div>
                </mat-option>
            </mat-select>
            <mat-error iqValidationMessage [control]="ServiceAreaInfoFormControl"></mat-error>
            <mat-hint>{{ServiceAreaInfoFormControl.value?.Name}}</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="UtilityTypesForCurrentServiceArea" style="flex-grow:1">
            <mat-label>Utility Type</mat-label>
            <mat-select #utilityTypeSelect [formControl]="UtilityTypeFormControl">
                <mat-select-trigger>
                    {{UtilityTypeFormControl.value ? UtilityTypeFormControl.value.Name : ''}}
                </mat-select-trigger>

                <mat-option *ngFor="let type of UtilityTypesForCurrentServiceArea" [value]="type">
                    <div style="display:flex; flex-direction: column; line-height: 15px">
                        <div>{{type.Name}}</div>
                        <div class="iq-caption" style=" display: flex; flex-direction: column; place-content: center flex-start; justify-content: center;">
                            <ng-container *ngIf="!type.Response; else currentUtilityResponse;">
                                <mat-icon class="red" style="margin-right: 5px; font-weight:600;">error_outline</mat-icon>
                                Response Needed
                            </ng-container>
                            <ng-template #currentUtilityResponse>
                                <mat-icon class="green" style="margin-right: 5px; font-weight:600;">check</mat-icon>
                                {{ type.Response }}
                            </ng-template>
                        </div>
                    </div>
                </mat-option>
            </mat-select>
            <mat-error iqValidationMessage [control]="UtilityTypeFormControl"></mat-error>
        </mat-form-field>
    </div>
    <iq-positive-response-common [FormGroup]="FormGroup" [Data]="Data" [AffectedTicketNumbers]="affectedTicketNumbers" [UnaffectedTicketNumbers]="unaffectedTicketNumbers" #child></iq-positive-response-common>

</div>
