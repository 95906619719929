import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface VerifyAlternateLoginDialogComponentData {
  Username: string;
  ProviderName: string;
}

@Component({
  selector: 'lib-verify-alternate-login-dialog',
  templateUrl: './verify-alternate-login-dialog.component.html',
  styleUrls: ['./verify-alternate-login-dialog.component.css']
})
export class VerifyAlternateLoginDialogComponent {

  Username: string;
  ProviderName: string;

  constructor(private dialogRef: MatDialogRef<VerifyAlternateLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) dialogData: VerifyAlternateLoginDialogComponentData) {

      this.Username = dialogData.Username;
      this.ProviderName = dialogData.ProviderName;
  }

}
