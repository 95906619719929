import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CognitoUser } from '@aws-amplify/auth';
import { ViewStateEnum } from 'iqCognito/models';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { controlsEqualValidator, IqAwsValidationClass } from 'iqCognito/validation';
import { Subject } from 'rxjs';
import { finalize, mergeMap, take } from 'rxjs/operators';
import { SettingsService } from 'Services/SettingsService';

@Component({
    templateUrl: './ChangeVerifyEmailDialog.component.html',
    styleUrls: ['./ChangeVerifyEmailDialog.component.scss']
})
export class ExactixChangeVerifyEmailDialogComponent {

    isBusy: boolean = false;
    _errorMessage: string = null;

    private errorCount = 0;

    onEmailChanged: Subject<string> = new Subject();

    showVerifyEmail: boolean = false;

    loggedInUser: CognitoUser;
    isSignedIn: boolean;
    currentEmail: string;

    changeGroup: UntypedFormGroup = new UntypedFormGroup({
        NewEmail: new UntypedFormControl(null, [Validators.required, Validators.pattern(IqAwsValidationClass.emailPattern)]),
        ConfirmEmail: new UntypedFormControl(null, [Validators.required, Validators.pattern(IqAwsValidationClass.emailPattern)])
    }, [controlsEqualValidator('NewEmail', 'ConfirmEmail')]);

    get newEmail() { return this.changeGroup.get("NewEmail"); }
    get confirmEmail() { return this.changeGroup.get("ConfirmEmail"); }

    verifyGroup: UntypedFormGroup = new UntypedFormGroup({
        Email: new UntypedFormControl({ value: null, disabled: true }),
        Code: new UntypedFormControl(null, [Validators.required])
    });

    get email() { return this.verifyGroup.get("Email"); }
    get code() { return this.verifyGroup.get("Code"); }

    constructor(protected awsCognitoService: IqAwsCognitoService, public dialogRef: MatDialogRef<ExactixChangeVerifyEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private settingsService: SettingsService, private http: HttpClient) {
        //super();

        this.isBusy = true;

        if (data != null && data.ShowVerify)
            this.showVerifyEmail = true;

        awsCognitoService.signInEventChange$.pipe(take(1))
            .subscribe(val => {
                this.isSignedIn = val.SignedIn;

                if (val.SignedIn) {
                    this.loggedInUser = val.User;

                    this.loggedInUser.getUserAttributes((err, attrs) => {
                        if (err != null) {

                            if (this.errorCount === 1) {
                                this.awsCognitoService.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
                                this.dialogRef.close(null);
                                return;
                            }

                            this.errorCount++;

                            //try to refresh the session
                            this.awsCognitoService.GetCurrentAuthenticatedUser().pipe(take(1)).subscribe();
                        }
                        else {
                            if (attrs != null) {
                                let email = attrs.find(val => val.getName() == "email");
                                this.currentEmail = email.getValue();

                                this.email.reset(this.currentEmail);

                                this.isBusy = false;
                            }
                        }
                    });
                }
                else {
                    this.isBusy = false;
                }
            }, (err) => {
                this.isBusy = false;
                this.SetError(err);
            });

    }

    onChangeEmail() {
        this.isBusy = true;
        this.http.get<boolean>(this.settingsService.ApiBaseUrl + "/Administration/Person/EmailAvailableForLogin?email=" + this.newEmail.value).pipe(take(1), mergeMap(avail => {
            if (avail === true)
                return this.awsCognitoService.UpdateEmail(this.loggedInUser, this.newEmail.value).pipe(take(1));

            this.snackBar.open("Email already in use", null);
            throw "Email already in use";
        }), finalize(() => this.isBusy = false)).subscribe(val => {

            this.showVerifyEmail = true;
            this.email.reset(this.newEmail.value);

            this.onEmailChanged.next(this.newEmail.value);

        }, (err) => {
                const error = err.error || err;
                this.isBusy = false;
                this.SetError(error);
        });
    }

    onVerify() {
        this.isBusy = true;
        const code: string = this.code.value;
        this.loggedInUser.verifyAttribute("email", code.trim(), {
            onSuccess: (success) => {
                this.dialogRef.close(true);
            },
            onFailure: (err) => {
                this.isBusy = false;
                this.SetError(err);
            }
        });
    }

    ResendCode() {
        this.loggedInUser.getAttributeVerificationCode("email", {
            onSuccess: () => {
            },
            onFailure: (err) => {
            },
            inputVerificationCode: (data) => {
                this.snackBar.open("Code sent to " + this.email.value, null);
            }
        })
    }

    protected SetError(err) {
        //Can't be busy if we got an error
        this.isBusy = false;

        if (!err) {
            this._errorMessage = null;
            return;
        }
        let message: string = err.message || err.Message || err;
        let index = message.indexOf("iQError:");
        if (index > 0) {
            message = message.slice(index + 8, message.length - 2);
        }

        this._errorMessage = message;
    }
}
