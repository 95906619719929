//We use a 3rd party mask control.  Its npm page: https://www.npmjs.com/package/angular2-text-mask
//  Its github page https://github.com/text-mask/text-mask
//  Its angular2 page https://github.com/text-mask/text-mask/tree/master/angular2#readme
class MaskFormats {
    readonly PhoneAreaCode = ['(', /[1-9]/, /\d/, /\d/];
    readonly PhoneCentralOfficeCode = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/];
    readonly PhoneLineNumber = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    readonly PhoneWithExtension = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'x', /\d/, /\d/, /\d/, /\d/, /\d/];

    //  These are needed in order to display (not input) an empty phone.  Needed for IN/KY - they allow "unknown" on the primary phone number!
    readonly PhoneLineNumberAllowEmpty = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    readonly PhoneWithExtensionAllowEmpty = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'x', /\d/, /\d/, /\d/, /\d/, /\d/];

    /**
     * Builds a phone mask that handles a phone for the given length.  The length is digits only.
     * 10 digits is the phone number only.  > 10 allows an extension.
     * @param maxLength
     */
    public BuildPhoneMask(maxLength: number): (string | RegExp)[] {
        const mask = this.PhoneLineNumber.slice();

        //  Many fields do set this appropriately for a phone.  This gives it a have of a 5 digit extension which matches
        //  what we have defined up above in the PhoneWithExtension mask.
        if (maxLength > 15)
            maxLength = 15;

        if (maxLength > 10) {
            mask.push(' ');
            mask.push('x');

            for (let i = 11; i <= maxLength; i++)
                mask.push(/\d/);
        }

        return mask;
    }
}
export const IQ_MASK_FORMATS = new MaskFormats();

//  *** for email, we have this package included in project.
//  1) Add this import:
//          import emailMask from 'text-mask-addons/dist/emailMask';
//  2) use "emailMask" as the mask function

export const UIDateTimeFormat = "MM/dd/yyyy hh:mm a";
export const UIDateFormat = "MM/dd/yyyy";

export const APIDateFormat = 'MM/DD/YYYY'; //We don't want to send any time on these request because the user can't pick time, so we want it to always be the start of the date picked
export const APIDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSS';
export const TimeFormat = 'HH:mm:ss';
export const TimeDisplayFormat = 'h:mm A';
