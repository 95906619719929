
export class FindAvailableMeetingPeriodsRequest {

    constructor(
        public MeetingDate: Date,
        public MeetingDurationMinutes: number,
        public ServiceAreaIDs: string[],
        public State: string,
        public County: string,

        /**
         * If editing a ticket, set this to the ticket number we are editing to exclude an existing meeting time for that ticket.
         * Not doing this prevents being able to keep the same meeting time when editing or causes validation errors when saving.
         * already scheduled for that ticket.
         */
        public ForUpdateOfTicket: string) {
    }
}
