
/*
 *  Options for displaying the SiteInfo components on the Ticket Entry Form.
 *  This is customized per One Call in SettingsService.SiteInfoOptions().
 *  Saves us from having to add tons of different @Inputs() and pass them around between the components.
 */
export class SiteInfoOptions {

    //  Not currently used by anyone: Adds a caller place field to the form.
    //  We search by all names in the place search and show alt names in ()'s in the results.  This will show that alt name on the form.
    public ShowCallerPlace: boolean = false;

    /**
     *  If true, the State and County are placed after the Street.  So address is entered as street, place, then county.
     *  If false, the State and County are placed before the Street.  So county is entered before the street.
     */
    public PromptForStreetBeforeStateCounty: boolean = false;

    /*
     *  If true and if the county and place are displayed next to each other, the place field is first.
     */
    public CountyFollowsPlace: boolean = false;

    /**
     *  When true (default), the place name appears on the same line as the Street.
     *  When false, the place name appears on the same line as the State.
     */
    public PlaceFollowsStreet: boolean = true;

    public ShowMainStreetOn2ndIntersection: boolean = true;

    public CrossStreetLabel: string = "Cross Street";

    public UseSideOfStreet: boolean = false;

    public UseCorner: boolean = false;

    public UseWithinQtrMileOnNearSt: boolean = false;

    public UseUserEnteredDistanceOnNearSt: boolean = false;

    public MainStreetPlaceholder: string = "Street/Address";

    constructor(public ShowCounty: boolean) {}
}

