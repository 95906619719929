import { Component, Input } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { SearchRequest } from '@iqModels/Searching/SearchRequest.model';
import { Observable } from 'rxjs';
import { AutoCompleteFormControl } from 'Shared/Components/Forms/FormControls/AutoCompleteFormControl';
import { BaseFilterItem } from '../BaseFilterItem';

@Component({
    selector: 'iq-list-autocomplete-filter',
    templateUrl: './AutocompleteFilter.component.html',
    styleUrls: ['./AutocompleteFilter.component.scss']
})
export class ListAutocompleteFilterComponent extends BaseFilterItem {
    autoCompleteControl: AutoCompleteFormControl;

    //Used to call the server for the filter
    @Input() ResultFilterValue: string = "ID";

    //Used to display in the list of filtered items.  i.e. Members would show the Code here
    @Input() ResultDisplayValue: string = "Name";

    @Input() minChars: number;

    /**
     *  For some reason, the Equals operator was built to allow picking multiple options!  If multiple are allowed, the operator
     *  should be "IsOneOf" not "Equals"!  The Report Parameters need to be able to restrict some parameters to only allow picking
     *  one option.  So the only way to accomplish that without breaking existing filters was to add this flag.
     */
    @Input("RestrictListFilterToSingleSelection")
    public RestrictListFilterToSingleSelection: boolean;

    /**
     *  If true, will search on an empty value (and show an initial search result as soon as displayed).
     */
    @Input()
    public SearchOnEmpty: boolean;

    @Input() set AutoCompleteSearchFunction(func: (filter: SearchRequest) => Observable<any>) {
        if (func != null) {
            //The passed in func needs to return a value with a 'Name" property.  It's what is used to display the value (maybe we can pass in the template to use on the values, but for now do this)
            this.autoCompleteControl = new AutoCompleteFormControl(func, { value: null, disabled: this.isDisabled });
        }
    }

    private _AutocompleteSelected: boolean;

    constructor()
    {
        super();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (this.SearchOnEmpty) {
            this.autoCompleteControl.SearchOnEmpty = this.SearchOnEmpty;
            setTimeout(() => this.autoCompleteControl.setValue(""));
        }
    }

    public AutoCompleteResultSelected($event: MatAutocompleteSelectedEvent): void {
        this._AutocompleteSelected = true;

        const autoCompleteResult = $event.option;

        this.addValue([new SearchFilterValue(autoCompleteResult.value[this.ResultFilterValue], autoCompleteResult.value[this.ResultDisplayValue])]);

        this.autoCompleteControl.reset(null);
    }

    public Add(event: MatChipInputEvent): void {
        if (this._AutocompleteSelected || this.ResultFilterValue !== "Name") {
            this._AutocompleteSelected = false;
            return;
        }

        if (this.minChars && event.value.length < this.minChars)
            return;

        const input = event.input;
        
        // Reset the input value...Needed on auto complete for some reason too, even though we are clearing the value on the control, it's not updating the UI unless we clear it here too...
        if (input)
            input.value = '';

        this.addValue([new SearchFilterValue(event.value, event.value)]);
    }

    protected addValue(values: SearchFilterValue[]) {
        if (!values || (values.length === 0))
            return;

        if (this.RestrictListFilterToSingleSelection)
            this.values = [];

        super.addValue(values);
    }

    protected clear(): void {
        //  Do not clear items with HideInUI - they are required filter values that the user cannot configure
        if (this.values)
            this.values = this.values.filter(v => v.HideInUI);
    }
}
