<div class="page-header">
    <div class="header-content">
        <div *ngIf="Ticket" class="header-title-xx">Ticket {{Ticket.TicketNumber}}-{{Ticket.Version}}</div>
        <ng-container *ngIf="!Ticket">
            <div *ngIf="!NotFound" class="header-title-xx">Retrieving Ticket...</div>
            <div *ngIf="NotFound" class="header-title-xx">Ticket not found</div>
        </ng-container>
    </div>

    <div>
        <!-- extra div to prevent the button from expanding to fill the parent -->
        <div class="iq-header-button-container">
            <iq-icon-button button-class="Header" icon="Login" title="Go to Login page" routerLink="/login">Login</iq-icon-button>
        </div>
    </div>
</div>
<div *ngIf="Ticket" class="page-content" style="display: flex; flex-direction: column;">
    <ticket-anon-simple-view [Ticket]="Ticket"></ticket-anon-simple-view>
</div>
