import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';

export function UnallowedCharactersValidator(unallowedCharacters: string[] = ["@"]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const thisValue: string = control.value;

        if (thisValue !== null && thisValue !== undefined) {
            for (var i = 0; i < unallowedCharacters.length; i++) {
                const character = unallowedCharacters[i];
                if (thisValue.includes(character)) {
                    return { customMessage: 'Character not allowed: ' + character, UnallowedCharacters: true };
                }
            }
        }

        return null;
    };
}
