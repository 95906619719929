import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '@iqModels/People/Person.model';

@Pipe({
    name: 'personName'
})
export class PersonNameFormatPipe implements PipeTransform {

    //FL = First Last
    //LF = Last, First
    transform(value: Person, format: 'FL' | 'LF' | null): string {
        if (!value)
            return "";

        const first = value.FirstName ?? "";
        const last = value.LastName ?? "";
        switch (format) {
            case 'LF':
                return last + ', ' + first;
            default:        //  FL is default
                return first + ' ' + last;
        }
    }
}
