<div *ngIf="FocusOnTab" tabindex="{{Edit ? -1 : 0}}" (focus)="Focus()" style="height:0px; width:0px;"></div>
<div *ngIf="Visible">
    <ng-container *ngIf="_edit; then editTemp; else viewTemp">
        <!--Outlet for the templates-->
    </ng-container>
</div>

<!-- Don't add the iq-body-1 style to the "view" and "edit" templates!  It can alter the fields from what is intended.
     And it causes issues with printing because it doesn't scale the fonts the same way as it does on the rest of the form.  -->
<ng-template #viewTemp>
    <div class="iq-section-item-view" (click)="Clicked($event)">
        <ng-content select=".view"></ng-content>
    </div>
</ng-template>

<ng-template #editTemp>
    <div class="iq-section-item-edit" (click)="Clicked($event)">
        <ng-content select=".edit"></ng-content>
    </div>
</ng-template>
