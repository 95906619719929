
export enum MapLayerTypeEnum {
    BaseMap = 1,

    UserMapFeatures = 20,

    Overlay = 30
}

export enum MapLayerTypeEnumDescriptions {
    BaseMap = "Base Map",

    UserMapFeatures = "User Map Features",

    Overlay = "Overlay"
}

