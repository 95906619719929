<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{Action}} Registration</h1>
<mat-dialog-content style="display:flex; flex-direction:column; overflow:initial; padding-right:20px; padding-left:20px">
    <div *ngIf="Action === 'Complete'">This will submit the registration for review by the One Call Center.</div>
    <div *ngIf="Action === 'Approve'">This will Approve the registration for use by the Service Area.</div>
    <div class="row" [ngClass]="{'red':RequireConfirmation}">
        <ng-container *ngIf="NoChanges">
            <div>There are no changes detected in this Registration Version.</div>
        </ng-container>
        <ng-container *ngIf="!NoChanges">
            <div>
                <span>These changes will</span>
                <span *ngIf="Registration.SqMilesAdded"> add {{Registration.SqMilesAdded | number: '1.2-2' }} sq miles</span>
                <span *ngIf="Registration.PctAdded"> ({{Registration.PctAdded | number: '1.2-2'}}%)</span>
                <span *ngIf="Registration.SqMilesAdded && Registration.SqMilesRemoved"> and</span>
                <span *ngIf="Registration.SqMilesRemoved"> remove {{Registration.SqMilesRemoved | number: '1.2-2' }}</span>
                <span *ngIf="Registration.PctRemoved"> sq miles ({{Registration.PctRemoved | number: '1.2-2'}}%)</span>
                <span>.</span>
            </div>
            <div *ngIf="Registration.PctOverall">
                This will result in an overall <span *ngIf="Registration.PctOverall > 0">increase</span><span *ngIf="Registration.PctOverall < 0">decrease</span> of {{Registration.PctOverall | number: '1.2-2'}}% compared to Version {{Registration.ParentRegistrationVersion}}.
            </div>
        </ng-container>
        <mat-checkbox *ngIf="RequireConfirmation" color="primary" [formControl]="ConfirmedFormControl" [labelPosition]="'after'">I confirm that this is correct</mat-checkbox>
    </div>
    <div *ngIf="Action === 'Complete'" class="row">Are you sure you are finished making changes and ready to Complete the registration?</div>
    <div *ngIf="Action === 'Approve'" class="row">Are you sure you want to Approve the registration?</div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-evenly">
    <iq-icon-button button-class="Dialog" icon="Cancel" [mat-dialog-close]="false">Cancel</iq-icon-button>
    <iq-icon-button button-class="Dialog" icon="OK" [mat-dialog-close]="true" [disabled]="RequireConfirmation && !ConfirmedFormControl.value">{{Action}}</iq-icon-button>
</mat-dialog-actions>
