import { Directive, ViewChild } from '@angular/core';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { DynamicContentDirective } from 'Shared/Directives/DynamicContent/DynamicContent.directive';
import { ITicketFunctionContentView } from './ITicketFunctionContentView.interface';
import { StartTicketFunctionData } from './StartTicketFunctionData.model';

/**
 *  Base class for a dialog/flyout that shows a ticket function UI (to edit a ticket).
 */
@Directive()
export abstract class TicketFunctionBase {

    public IsSaving: boolean = false;

    public get IsValid(): boolean { return this._ContentViewComponentInstance?.IsValid; }

    public get Title(): string { return this._ContentViewComponentInstance?.Title ?? this.Data?.TicketEntryResponse?.TicketFunction?.Name ?? "Edit" }

    public get SaveButtonText(): string {
        return this._ContentViewComponentInstance?.SaveButtonText ? this._ContentViewComponentInstance?.SaveButtonText() : "Continue";
    }

    @ViewChild(DynamicContentDirective, { static: true })
    private _ContentContainer: DynamicContentDirective;

    private _ContentViewComponentInstance: ITicketFunctionContentView;

    constructor(protected TicketService: TicketService, public Data: StartTicketFunctionData)
    {
        this.TicketService.OnTicketEntryResponseReceived(Data.TicketEntryResponse);
    }

    public ngOnInit(): void {
        //  Create the content view.  If not specified in Data.ContentViewComponent, will use the default view.
        //  If we should display a custom component, specify that in an override of TicketEntryFormBase.CreateConfirmAffectedServiceAreasData()
        const viewComponentClassRef = this.Data.ContentViewComponent;
        this._ContentViewComponentInstance = this._ContentContainer.LoadComponent<ITicketFunctionContentView>(viewComponentClassRef);

        this._ContentViewComponentInstance.CreateTicketEntryFormForTicket(this.Data.TicketEntryResponse.Ticket);

        //  If this (optional) method is defined in the component, it needs the Data that was given to use when starting the function.
        if (this._ContentViewComponentInstance.SetData)
            this._ContentViewComponentInstance.SetData(this.Data);
    }

    /**
     *  Close the Dialog/Flyout
     */
    protected abstract CloseForm(saved: boolean): void;

    public OnSaveTicket(): void {
        if (this.IsSaving || !this.IsValid)
            return;     //  Already saving or not valid!
        this.IsSaving = true;

        this._ContentViewComponentInstance.VerifyAndSaveTicket()
            .subscribe({
                next: (saved) => {
                    if (saved)
                        this.CloseForm(saved);
                    else
                        this.IsSaving = false;
                },
                error: () => this.IsSaving = false
            });
    }
}
