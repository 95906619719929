import { Component, forwardRef, HostBinding, Inject, ViewChild } from '@angular/core';
import { IQ_FLYOUT_DATA, IQ_FLYOUT_REF } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { SideSlideoutRef } from 'Shared/PhoneDevice/SideSlideout/SideSlideoutRef';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { TicketEntrySectionEventService } from 'Pages/Tickets/Services/TicketEntrySectionEvent.service';
import { CommonService } from 'Services/CommonService';
import { MapRouteSegment } from 'Models/Maps/MapRouteSegment.model';
import { TicketEntryDialogBase } from '../../../Base/TicketEntryDialogBase.component';
import { TicketEntrySectionBase } from '../../../Base/TicketEntrySectionBase.component';
import { DigsiteMapComponent } from '../../DigsiteMap/DigsiteMap.component';
import { CreateMultipleTicketsComponent, CreateMultipleTicketsData } from '../CreateMultipleTickets.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { faMapMarkedAlt, faReceipt } from '@fortawesome/pro-light-svg-icons';



@Component({
    templateUrl: './CreateMultipleTicketsFlyout.component.html',
    styleUrls: ['./CreateMultipleTicketsFlyout.component.scss'],
    animations: [slideInFromRight],
    providers: [
        CommonService,
        TicketEntrySectionEventService.Provider,
        { provide: TicketEntrySectionBase, useExisting: forwardRef(() => CreateMultipleTicketsFlyoutComponent) }      //  so we can use QueryList<EntrySectionBase> to find
    ]
})

export class CreateMultipleTicketsFlyoutComponent extends TicketEntryDialogBase {
    //  Defining the animation on the HostBinding like this is cleaner and causes it to do the close animation automatically.
    @HostBinding('@slideInFromRight') animation = 'enter';

    @ViewChild(DigsiteMapComponent, { static: false })
    private _DigsiteMap: DigsiteMapComponent;

    @ViewChild(CreateMultipleTicketsComponent)
    private _CreateMultipleTicketsComponent: CreateMultipleTicketsComponent;

    private _routeList: MapRouteSegment[] = [];

    public TicketIcon = faReceipt;
    public MapIcon = faMapMarkedAlt;

    constructor(commonService: CommonService, sectionEventService: TicketEntrySectionEventService,
                @Inject(IQ_FLYOUT_DATA) public Data: CreateMultipleTicketsData, @Inject(IQ_FLYOUT_REF) private _SideSlideoutRef: SideSlideoutRef) {
        super(commonService, sectionEventService, Data.TicketEntryForm);

    }

    public Close(response: any = null) {
        this._SideSlideoutRef.close(response);
    }

    public RouteListChanged(routeList: MapRouteSegment[]): void {
        this._routeList = routeList;
    }

    public OnSelectedTabChange(evt: MatTabChangeEvent): void {
        if (evt.index === 1 && this._DigsiteMap) {
            this._DigsiteMap.ShowRoute(this._routeList);
        }
    }

    public OnSaveMultipleTickets(): void {
        this._CreateMultipleTicketsComponent.OnSaveMultipleTickets();

        this.TicketEntryForm.VerifyTicketBeforeSave().subscribe(verifyResponse => {
            //  Need to manually remove any other Digsite Rules for Length since they have been resolved by this dialog.
            //  This allows configuring limits (errors/warnings) that will only be applied when NOT splitting up the digsite.
            //  TODO: Would be nice if these dig site rules would be calculated based off the segments instead of the
            //  full digsite geometry (since we are re-calling VerifyTicketBeforeSave anyway).  But right now at least,
            //  didn't have any need other than to remove them here.
            if (verifyResponse.InvalidDigSiteRules)
                verifyResponse.InvalidDigSiteRules = verifyResponse.InvalidDigSiteRules.filter(r => !this._CreateMultipleTicketsComponent.IgnoreDigsiteRule(r));

            this._SideSlideoutRef.close(verifyResponse);
        }, () => this._CreateMultipleTicketsComponent.IsBusy = false);
    }

}
