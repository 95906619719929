
export class FeatureItemResponse {
    public ID: string;

    public PlaceName: string;
    public CountyName: string;
    public State: string;

    public FeatureTypeName: string;
    public MapStyle: string;
    public Icon: string;
    public IconSizeRatio: number;

    // Contains the full text to geocode and display if the user picks this item.
    // Will contain the address #'s entered by the user and the full street name of the item.
    public EnteredStreetAddress: string;
    public EnteredCrossStreet: string;

    constructor(public LayerName: string, public FeatureName: string, public GeometryJson: object) { }
}
