import { Directive } from "@angular/core";
import { TicketEntryFormGroup } from 'Pages/Tickets/Details/Components/InputControls/TicketEntryFormGroup';
import { TicketEntrySectionEventService } from 'Pages/Tickets/Services/TicketEntrySectionEvent.service';
import { CommonService } from 'Services/CommonService';
import { TicketEntrySectionBase } from "./TicketEntrySectionBase.component";

/**
 *  Base class for a dialog that uses the TicketEntryFormGroup to display or prompt for ticket fields.
 */
@Directive()
export abstract class TicketEntryDialogBase extends TicketEntrySectionBase {

    constructor(commonService: CommonService, sectionEventService: TicketEntrySectionEventService, ticketEntryForm: TicketEntryFormGroup) {
        super(commonService, sectionEventService)

        this.TicketEntryForm = ticketEntryForm;
    }
}
