export enum TicketListItemViewEnum {
    LocalUser_Default,
    LocalUser_Incomplete,
    LocalUser_Suspended,

    ExcavatorUser_Default,
    ExcavatorUser_Expiring,
    ExcavatorUser_ResponseStatus,

    ServiceAreaUser_Default,
    ServiceAreaUser_NoPosResp,
    ServiceAreaUser_Response,

    RegulatorUser_Default
}
