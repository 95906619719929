<div *ngIf="ShowBrandingImage" style="display: flex; justify-content: center; min-height: 43px;">
    <img *ngIf="siginImage" style="height: 33px; padding-bottom: 10px; padding-top: 10px; align-self: center;" [src]="siginImage" />
</div>
<ng-template #viewContainer></ng-template>

<ng-template #defaultSigninTemplate>
    <iq-aws-cognito-sign-in [AllowCreateLogin]="AllowCreateLogin" [(SignInImage)]="siginImage" [ClientMetadata]="ClientMetadata" [FederatedLoginReturnState]="FederatedLoginReturnState"></iq-aws-cognito-sign-in>
</ng-template>

<ng-template #defaultSignoutTemplate>
    <iq-aws-cognito-logout></iq-aws-cognito-logout>
</ng-template>

<ng-template #defaultResetPasswordTemplate>
    <iq-aws-cognito-reset-password [PasswordRequirements]="PasswordRequirements" [ClientMetadata]="ClientMetadata"></iq-aws-cognito-reset-password>
</ng-template>

<ng-template #defaultCreateNewUserTemplate>
    <iq-aws-cognito-sign-up [PasswordRequirements]="PasswordRequirements" [ClientMetadata]="ClientMetadata"></iq-aws-cognito-sign-up>
</ng-template>

<ng-template #defaultConfirmUserTemplate>
    <iq-aws-cognito-confirm-user></iq-aws-cognito-confirm-user>
</ng-template>

<ng-template #defaultNewPasswordTemplate>
    <iq-aws-cognito-new-password [PasswordRequirements]="PasswordRequirements" [ClientMetadata]="ClientMetadata"></iq-aws-cognito-new-password>
</ng-template>
