<div>
    Extraordinary circumstances means circumstances which make it impractical or impossible for the operator to comply with the provisions of this chapter.
    Extraordinary circumstances may include <span style="color:red">hurricanes, tornadoes, floods, ice, snow, and acts of God.</span>
</div>
<!-- flex-shrink's are needed to help with the phone view sizing -->
<div class="row">
    <div class="gray-primary-text">Date and time when the location will be marked:</div>
    <iq-date-with-picker #markedOnDatePicker style="flex-shrink:0" [formControl]="MarkedOnDateFormControl" [Min]="MinDate" [Max]="MaxDate"></iq-date-with-picker>
</div>
<div class="row">
    <div class="gray-primary-text">Circumstance:</div>
    <mat-form-field style="flex-basis:12em; flex-shrink: 0">
        <mat-select [formControl]="CircumstanceFormControl">
            <mat-option value="Hurricane">Hurricane</mat-option>
            <mat-option value="Tornado">Tornado</mat-option>
            <mat-option value="Flood">Flood</mat-option>
            <mat-option value="Ice">Ice</mat-option>
            <mat-option value="Snow">Snow</mat-option>
            <mat-option value="Act of God">Act of God</mat-option>
        </mat-select>
        <mat-error iqValidationMessage [control]="CircumstanceFormControl"></mat-error>
    </mat-form-field>
</div>
