import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { TicketDiscussionService } from '../../Services/TicketDiscussion.service';

export class AddExcavationDateDialogData {
    TicketDiscussionList: TicketDiscussionChildResponse[];
}

@Component({
    templateUrl: './AddExcavationDateDialog.component.html',
    styleUrls: ['./AddExcavationDateDialog.component.scss']
})
export class AddExcavationDateDialogComponent {
    public MinDate: Date;
    public MaxDate: Date;
    public IsSaving: boolean = false;

    public DateTimeFormControl: UntypedFormControl;

    constructor(private _DialogRef: MatDialogRef<AddExcavationDateDialogComponent>, @Inject(MAT_DIALOG_DATA) private _Data: AddExcavationDateDialogData,
        private _DiscussionService: TicketDiscussionService) {

        //  Would probably be better to default to the work start date on the ticket.  But don't have easy access to that when
        //  this is launched from the ticket list.
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(8, 0, 0, 0);

        this.DateTimeFormControl = new UntypedFormControl(tomorrow, [Validators.required]);

        //  These will probably need to be constrained differently...
        this.MinDate = new Date();
        this.MinDate.setDate(this.MinDate.getDate() - 5);
        this.MinDate.setHours(0, 0, 0);

        this.MaxDate = new Date();
        this.MaxDate.setDate(this.MaxDate.getDate() + 10);
        this.MaxDate.setHours(0, 0, 0);
    }

    public OnSave(): void {
        this.IsSaving = true;

        //  If there are multiple, just need to pass the first ID and the server will handle updating all of the others.
        this._DiscussionService.SaveResponse(this._Data.TicketDiscussionList[0].ID, this.DateTimeFormControl.value)
            .subscribe(updatedDiscussionList => {
                //  Could be multiple for this type of discussion
                if (updatedDiscussionList && updatedDiscussionList.length > 0)
                    this._DialogRef.close(updatedDiscussionList);
                else
                    this.IsSaving = false;      //  Nothing updated?  Should not happen...
            }, err => {
                this.IsSaving = false;
            });
    }
}
