
export enum MapLayerSourceEnum {
    Geoserver = 1,
    MapFeatures = 2,

    HERETileService = 20,
    BingTileService = 21,
    ESRIArcGISTileService = 22,

    ESRIArcGISWFSService = 30,

    /**
     *  Download from an external site and store in MapFeatures table.
     */
    Download = 31
}

export enum MapLayerSourceEnumDescriptions {
    Geoserver = "Geoserver",
    MapFeatures = "Map Features created by Exactix",

    HERETileService = "HERE Tile Service",
    BingTileService = "Bing Tile Service",
    ESRIArcGISTileService = "ESRI ArcGIS Online Tile Service",

    ESRIArcGISWFSService = "ESRI ArcGIS WFS Service",

    Download = "Download from External Site"
}
