import { Component, Input } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { BaseFilterItem } from '../BaseFilterItem';

@Component({
    selector: 'iq-list-free-text-filter',
    templateUrl: './ListFreeTextFilter.component.html',
    styleUrls: ['./ListFreeTextFilter.component.scss']
})
export class ListFreeTextFilterComponent extends BaseFilterItem {

    @Input() minChars: number;

    constructor()
    {
        super();
    }

    add(event: MatChipInputEvent): void {
        if (this.minChars && event.value.length < this.minChars)
            return;

        const input = event.chipInput.inputElement;
        input.value = '';
        
        this.addValue([new SearchFilterValue(event.value, event.value)]);
    }

    clear(): void {
        //  Do not clear items with HideInUI - they are required filter values that the user cannot configure
        if (this.values)
            this.values = this.values.filter(v => v.HideInUI);
    }
}
