import { Injectable } from '@angular/core';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { Member } from '@iqModels/Members/Member.model';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { SearchColumn } from 'Models/Searching/SearchColumn.model';
import { Observable, of } from 'rxjs';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { UIDateTimeFormat } from 'Shared/Utils/MaskFormats.model';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { BillingTypeService } from '../../System/Services/BillingType.service';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class MemberService extends CRUDBaseService<Member> {
    protected apiPath: string = "Administration/Member";

    ViewPermission: PermissionsEnum = PermissionsEnum.Member_View;//Needs to be overriden and set to the proper permission
    EditPermission: PermissionsEnum = PermissionsEnum.Member_Edit;//Needs to be overriden and set to the proper permission
    CreatePermission: PermissionsEnum = PermissionsEnum.Member_Create;//Needs to be overriden and set to the proper permission
    DeletePermission: PermissionsEnum = PermissionsEnum.Member_Delete;//Needs to be overriden and set to the proper permission
    CopyPermission: PermissionsEnum = PermissionsEnum.Memeber_Copy;//Needs to be overriden and set to the proper permission

    constructor(protected services: CRUDServices, private billingTypeService: BillingTypeService) {
        super(services);
    }

    public GetAvailableSearchColumnsAndFilters(): Observable<{ columns: SearchColumn[], filters: SearchColumn[] }> {

        let columns = [new SearchColumn("Name", "Name", "Name", "Name"), new SearchColumn("Code", "Code", "Code", "Code")];

        const isActive = new SearchColumn("IsActive", "Active", "IsActive", "IsActive");
        isActive.filterOptions = of([new SelectOption(true, "Yes"), new SelectOption(false, "No")]);
        isActive.DynamicCssClass = SearchColumn.BoolToRedGreen;
        isActive.width = "10%";
        columns.push(isActive);

        const modifyDate = new SearchColumn("LastModifyDateTime", "Last Modified", "LastModifyDateTime", "LastModifyDateTime");
        modifyDate.useDateSearch = true;
        modifyDate.ShowFutureDateOptions = false;
        modifyDate.formatType = 'date';
        modifyDate.width = "15%";
        modifyDate.format = UIDateTimeFormat;
        columns.push(modifyDate);

        const serviceAreaNames = new SearchColumn("ServiceAreaNames", "Service Area Names", "ServiceAreaNames", "ServiceAreaNames");        
        serviceAreaNames.filterOperator = SearchFilterOperatorEnum.CustomOr;
        columns.push(serviceAreaNames);

        const serviceAreaCodes = new SearchColumn("ServiceAreaCodes", "Service Area Codes", "ServiceAreaCodes", "ServiceAreaCodes");
        serviceAreaCodes.filterOperator = SearchFilterOperatorEnum.CustomOr;
        columns.push(serviceAreaCodes);

        const billingType = new SearchColumn("BillingType_Name", "Billing Type", "BillingType.Name", "BillingTypeID");
        billingType.filterOptions = this.billingTypeService.GetAll().pipe(map(vals => vals.map(m => new SelectOption(m.ID, m.Name))));
        columns.push(billingType);

        const contractNumberCol = new SearchColumn("ContractNumber", "Contract Number", "ContractNumber", "ContractNumber");
        columns.push(contractNumberCol);

        columns = columns.sort((a, b) => a.name.localeCompare(b.name));
        return of({ columns: columns, filters: columns });
    }
}
