import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { IqAwsCognitoService } from 'iqCognito/Services/iq-aws-cognito.service';
import { UnallowedCharactersValidator } from '@iqValidation/Validators/UnAllowedCharacters.validator';
import { Address } from 'Models/Common/Address.model';
import { ExcavatorCompanyType } from 'Models/Configuration/ExcavatorCompanyType.model';
import { FieldLengths } from 'Models/Configuration/FieldLengths.model';
import { IndustryType } from 'Models/Configuration/IndustryType.model';
import { ProjectType } from 'Models/Configuration/ProjectType.model';
import { SelectOption } from 'Models/Configuration/SelectOption.model';
import { ExcavatorCompany } from 'Models/Excavators/ExcavatorCompany.model';
import { ExcavatorContact } from 'Models/Excavators/ExcavatorContact.model';
import { ExcavatorOffice } from 'Models/Excavators/ExcavatorOffice.model';
import { ExcavatorSelfRegistrationSearchRequest } from 'Models/Excavators/ExcavatorSelfRegistrationSearchRequest.model';
import { OneCallCenter } from 'Models/OneCallCenter/OneCallCenter.model';
import { PersonSelfRegisterRequest } from 'Models/People/PersonSelfRegisterRequest.model';
import { AppUser } from 'Models/Security/AppUser.model';
import { SelfRegistrationConfigurationResponse } from 'Models/SelfRegistration/SelfRegistrationConfigurationResponse.model';
import { ExcavatorCompanyService } from 'Pages/Excavators/ExcavatorCompanies/Services/ExcavatorCompany.service';
import { ExcavatorContactService } from 'Pages/Excavators/ExcavatorContacts/Services/ExcavatorContact.service';
import { ExcavatorOfficeService } from 'Pages/Excavators/ExcavatorOffices/Services/ExcavatorOffice.service';
import { PersonService } from 'Pages/People/Services/Person.service';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { BusyService } from 'Services/BusyService';
import { CommonService } from 'Services/CommonService';
import { SettingsService } from 'Services/SettingsService';
import { BaseComponent } from 'Shared/Components/Forms/BaseComponent';
import { IQ_VALIDATOR_PATTERNS } from 'Shared/Components/Forms/Validation/ValidationPatterns.model';
import { EntityHasIDValidator } from 'Shared/Components/Forms/Validation/Validators/EntityHasID.validator';
import { IsNumeric } from 'Shared/Components/Forms/Validation/Validators/IsNumeric.validator';
import { Guid } from 'Shared/Utils/Guid';
import { UserRegistrationService } from '../Services/UserRegistration.service';

@Component({
    selector: 'iq-new-user-registration-user-details',
    templateUrl: './RegistrationUserDetailsSteps.component.html',
    styleUrls: ['./RegistrationUserDetailsSteps.component.scss'],
    providers: [CommonService]
})
export class RegistrationUserDetailsStepsComponent extends BaseComponent implements OnInit, OnDestroy {

    public AccountTypeIsExcavator: boolean = null;          //  null means not picked yet

    private _Destroyed: Subject<void> = new Subject();

    private _SelfRegistrationConfigurations: SelfRegistrationConfigurationResponse[];
    public FieldLengths: FieldLengths = new FieldLengths();

    public SelectedProjectType: ProjectType;

    public HaveMultipleOneCalls = false;
    public OneCallCenters: OneCallCenter[];
    public ProjectTypes: ProjectType[];
    public AllowSearchByCompanyName: boolean;

    public IsSaving: boolean = false;
    public SearchReturnedNotFound = false;

    public BusyKey: string = this._BusyService.createNew();

    @ViewChild('stepper', { static: false })
    private _Stepper: MatStepper;

    public CreateNewAccount: boolean = null;        //  null means not determined yet

    public PersonFormGroup: UntypedFormGroup;
    public FirstNameFormControl: UntypedFormControl;
    public LastNameFormControl: UntypedFormControl;
    public PhoneNumberFormControl: UntypedFormControl;
    public EmailAddressFormControl: UntypedFormControl;
    public OneCallCenterFormControl: UntypedFormControl;

    public CompanyTypes: Observable<ExcavatorCompanyType[]> = null;
    public IndustryTypes: Observable<IndustryType[]> = null;

    //  Existing contact found via "FindForSelfRegistration" call.
    public ExistingContact: ExcavatorContact = null;
    public IsExistingContactWrong: boolean = null;      //  null  means not decided yet

    public FIND_METHOD_COMPANY_ID = 1;
    public FIND_METHOD_EXCAVATOR_ID = 2;
    public FIND_METHOD_COMPANY_NAME = 3;
    public FIND_METHOD_TICKET_NUMBER = 4;
    public FIND_METHOD_CREATE_NEW = 10;
    public FindMethod: number = null;           //  null means nothing picked

    public AttachToExcavatorCompanyFormControl: UntypedFormControl = null;
    public AttachToExcavatorOfficeFormControl: UntypedFormControl = null;
    public AttachToExcavatorContact: ExcavatorContact = null;
    public AvailableOffices: ExcavatorOffice[] = [];

    public CompanyIDFormControl: UntypedFormControl;
    public ExcavatorIDFormControl: UntypedFormControl;
    public CompanyNameFormControl: UntypedFormControl;

    public TicketNumberMaskOptions: any;
    public PreviousTicketNumberFormControl: UntypedFormControl;
    public PreviousTicketPhoneNumberFormControl: UntypedFormControl;
    public PreviousTicketVerified = false;

    public NewExcavatorFormGroup: UntypedFormGroup;
    public NewExcavatorCompanyNameFormControl: UntypedFormControl;
    public NewExcavatorCompanyTypeFormControl: UntypedFormControl;
    public NewExcavatorIndustryTypeFormControl: UntypedFormControl;
    public NewExcavatorOfficeAddressFormControl: UntypedFormGroup;

    public NewHomeownerAddressFormGroup: UntypedFormGroup;

    //  True if we have collected all information needed to create the login (and either attach to an existing company/office/homeowner or create new).
    public get CanCreateAccount(): boolean {
        if (!this.FindMethod)
            return false;

        switch (this.FindMethod) {
            case this.FIND_METHOD_COMPANY_ID:
            case this.FIND_METHOD_COMPANY_NAME:
                return this.AttachToExcavatorCompanyFormControl.valid && this.AttachToExcavatorOfficeFormControl.valid;

            case this.FIND_METHOD_EXCAVATOR_ID:
                //  This is not used to create a new account.  If we find a match, the user will be prompted about linking to it.
                //  If they say "not me", they get put back to the page to pick the FindMethod.
                return false;

            case this.FIND_METHOD_TICKET_NUMBER:
                if (!this.AttachToExcavatorCompanyFormControl.valid && this.AttachToExcavatorOfficeFormControl.valid)
                    return false;
                //  Found company & office, but if AttachToExcavatorContact is set, the user is still deciding if should be linked directly
                //  to the ExcavatorContact on the ticket.  Don't allow until they figure that out...
                return !this.AttachToExcavatorContact;

            case this.FIND_METHOD_CREATE_NEW:
                if (this.AccountTypeIsExcavator === true)
                    return this.NewExcavatorFormGroup.valid;
                else if (this.AccountTypeIsExcavator === false)
                    return this.NewHomeownerAddressFormGroup.valid;
                break;

            default:
                console.error("Unhandled value for FindMethod in RegistrationUserDetailsSteps", this.FindMethod);
                break;
        }

        return false;
    }

    constructor(private _CommonService: CommonService, private _Router: Router, private _BusyService: BusyService,
        private _UserRegService: UserRegistrationService, private _PersonService: PersonService,
        private _ExcavatorCompanyService: ExcavatorCompanyService, private _ExcavatorOfficeService: ExcavatorOfficeService,
        private _ExcavatorContactService: ExcavatorContactService, private _CognitoService: IqAwsCognitoService)
    {
        super();
        this._BusyService.showByKey(this.BusyKey);
    }

    public ngOnInit(): void {
        this.FirstNameFormControl = new UntypedFormControl(null, [Validators.required, UnallowedCharactersValidator()]);
        this.LastNameFormControl = new UntypedFormControl(null, [Validators.required, UnallowedCharactersValidator()]);
        this.EmailAddressFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(IQ_VALIDATOR_PATTERNS.emailPattern)]);
        this.PhoneNumberFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(IQ_VALIDATOR_PATTERNS.phonePattern)]);
        this.OneCallCenterFormControl = new UntypedFormControl(null, [Validators.required, EntityHasIDValidator]);

        this.PersonFormGroup = this._CommonService.FormBuilder.group({
            FirstName: this.FirstNameFormControl,
            LastName: this.LastNameFormControl,
            EmailAddress: this.EmailAddressFormControl,
            PhoneNumber: this.PhoneNumberFormControl,
            OneCallCenter: this.OneCallCenterFormControl
        });

        //Anytime the UserIsSignedIn value changes we want to do this logic.
        this._CommonService.AuthenticationService.UserIsSignedIn.pipe(takeUntil(this._Destroyed), mergeMap(isLoggedIn => {
                //If logged in we need to get the user info and need to use the observer so that we can wait for the api call to get it to finish.
                //  Can't do it as part of a combineLatest, etc, because if they logged in then logged out it won't fire the event when the page loads to
                //  tell us the user, so do it only if we know they are signed in.
                if (isLoggedIn)
                    //Have to get all because when they sign in and aren't attached to anyone they won't have an ID but we still need to get the user data
                    return this._CommonService.AuthenticationService.CurrentUserObserver(true).pipe(filter(val => val && val.Username !== null && val.Username !== undefined), take(1), mergeMap(user => {

                        //HAVE to do any calls to the server AFTER the call to get the user info has finished, or we get race conditions because of
                        //  how we set the one call center in the user session
                        return this.GetSelfRegistrationCenters(isLoggedIn, user);
                    }));
                else
                    return this.GetSelfRegistrationCenters(isLoggedIn, null);
            }))
            .subscribe(val => {
                const isLoggedIn = val[0];
                const centers = val[1];
                const appUser = val[2];

                if (isLoggedIn) {
                    if (appUser.ID && appUser.ID !== Guid.empty) {
                        //  User is already linked!  Don't show a dialog about this - the user won't understand what
                        //  it means.  Just re-direct.  They won't understand how that happened either but the result
                        //  will be that they are logged in.  They should not have been trying to create
                        //  a new user in the first place.
                        this._Router.navigateByUrl(this._CommonService.AuthenticationService.CurrentUser.ConfiguredLandingPage);
                    }
                    else {
                        this.PersonFormGroup.patchValue({
                            FirstName: appUser.FirstName,
                            LastName: appUser.LastName,
                            EmailAddress: appUser.EmailAddress
                        });
                    }
                }

                if (!this.OneCallCenters) {
                    //Need to build the forms first so we can set the center automatically if there is only 1.
                    //Need to do this like this so we can get the count and the items without having to call the server multiple times by doing async in the HTML
                    this.OneCallCenters = centers;

                    if (centers.length === 1) {
                        this.OneCallCenterFormControl.setValue(centers[0]);
                        this.OnOneCallCenterSet();
                    }
                    else {
                        this.HaveMultipleOneCalls = true;

                        //  If we have more than one center then we need to watch the value change to try and find an excavator with the login ID
                        let lastOneCallCenterCode = null;
                        this.OneCallCenterFormControl.valueChanges.pipe(takeUntil(this._Destroyed)).subscribe(val => {
                            if (!val)
                                return;     //  For some reason, clicking on Next is setting this (to null if nothing is picked).
                            //  NextStep() has to call updateValueAndValidity() which (for some stupid reason) triggers a change event!
                            //  So we have to track the last occ.Code to avoid calling this again.
                            const occ = val as OneCallCenter;
                            if (!this.ExistingContact || (occ.Code !== lastOneCallCenterCode)) {
                                this._ExcavatorContactService.FindForSelfRegistration(occ.Code, this._CommonService.AuthenticationService.CurrentUser.EmailAddress)
                                    .pipe(take(1))
                                    .subscribe(excavatorContact => this.ExistingContact = excavatorContact);
                            }
                            lastOneCallCenterCode = occ.Code;

                            this.OnOneCallCenterSet();
                        });
                    }
                }

                this._BusyService.hideByKey(this.BusyKey);
            });
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();

        this._BusyService.removeByKey(this.BusyKey);

        super.ngOnDestroy();
    }

    public NextStep(): void {
        //  The matStepperNext directive (on a button) seems to do this automatically.  But calling _Stepper.next() does not.
        //  Not doing this still prevents going to the next step (because the control is not valid) but the invalid control
        //  is not highlighted in red like it should be.
        if (this._Stepper.selected.stepControl) {
            if (this._Stepper.selected.stepControl instanceof UntypedFormGroup) {
                const formGroup = this._Stepper.selected.stepControl as UntypedFormGroup;
                for (const name in formGroup.controls) {
                    const control = formGroup.controls[name];
                    control.markAsTouched();
                    control.updateValueAndValidity();
                }
            }

            this._Stepper.selected.stepControl.markAsTouched();
            this._Stepper.selected.stepControl.updateValueAndValidity();
        }

        this._Stepper.next();
    }

    public PreviousStep(): void {
        this._Stepper.previous();
    }

    private OnOneCallCenterSet(): void {
        const oneCallCenter = this.OneCallCenterFormControl.value as OneCallCenter;

        this.TicketNumberMaskOptions = SettingsService.TicketNumberMaskOptionsForOneCall(oneCallCenter.Code);

        this.LoadCompanyTypes(oneCallCenter);
        this.LoadIndustryTypes(oneCallCenter);
        this.SetProjectTypes(oneCallCenter);

        this.CreateForms(oneCallCenter);
    }

    private CreateForms(oneCallCenter: OneCallCenter): void {
        //  Matching by Code (not ID) because when Idaho was configured, it was done by copying the DigSafe database and then forgot to generate a new OneCallCenter.ID
        const config = this._SelfRegistrationConfigurations.find(r => r.OneCallCenter.Code === oneCallCenter.Code);
        this.FieldLengths = config.FieldLengths;
        this.AllowSearchByCompanyName = config.AllowSearchByCompanyName;

        this.NewHomeownerAddressFormGroup = this.CreateAddressForm();

        this.NewExcavatorCompanyNameFormControl = new UntypedFormControl(null, [Validators.required]);
        this.NewExcavatorCompanyTypeFormControl = new UntypedFormControl(null, [Validators.required]);
        this.NewExcavatorOfficeAddressFormControl = this.CreateAddressForm();
        this.NewExcavatorFormGroup = this._CommonService.FormBuilder.group({
            ExcavatorCompanyName: this.NewExcavatorCompanyNameFormControl,
            CompanyType: this.NewExcavatorCompanyTypeFormControl,
            OfficeAddress: this.NewExcavatorOfficeAddressFormControl
        });

        if (!this.FieldLengths.ExcavatorCompany_IndustryTypeID_Hidden) {
            //  FL does not use this field at all so it's hidden.  AZ uses it but it's not required.
            //  Other centers (NY and DigSafe) required it.
            this.NewExcavatorIndustryTypeFormControl = new UntypedFormControl();
            if (this.FieldLengths.ExcavatorCompany_IndustryTypeID_Required)
                this.NewExcavatorIndustryTypeFormControl.setValidators(Validators.required);
            this.NewExcavatorFormGroup.addControl("IndustryType", this.NewExcavatorIndustryTypeFormControl);
        }
        else
            this.NewExcavatorIndustryTypeFormControl = null;        //  Set to null in case user switched One Calls and previous used industry type.

        this.CompanyIDFormControl = new UntypedFormControl(null, [IsNumeric, Validators.maxLength(9)]);     // 9 is max # of digits for int32 - higher will overflow
        this.ExcavatorIDFormControl = new UntypedFormControl(null, [IsNumeric, Validators.maxLength(9)]);     // 9 is max # of digits for int32 - higher will overflow
        this.CompanyNameFormControl = new UntypedFormControl(null, [Validators.required]);

        const validators = [Validators.required];
        if (this.TicketNumberMaskOptions?.mask) {
            validators.push(Validators.minLength(this.TicketNumberMaskOptions.mask.length));
            validators.push(Validators.maxLength(this.TicketNumberMaskOptions.mask.length));
        }
        this.PreviousTicketNumberFormControl = new UntypedFormControl(null, validators);
        this.PreviousTicketPhoneNumberFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(IQ_VALIDATOR_PATTERNS.phonePattern)]);

        this.AttachToExcavatorCompanyFormControl = new UntypedFormControl(null, [Validators.required, EntityHasIDValidator]);
        this.AttachToExcavatorOfficeFormControl = new UntypedFormControl(null, [Validators.required, EntityHasIDValidator]);

        this.ClearFindMethodInfo();
    }

    private CreateAddressForm(): UntypedFormGroup {
        const group = this._CommonService.FormBuilder.group({
            Address1: new UntypedFormControl(null, [Validators.required, Validators.maxLength(80)]),
            Address2: new UntypedFormControl(null, [Validators.maxLength(80)]),
            City: new UntypedFormControl(null, [Validators.required, Validators.maxLength(80)]),
            State: new UntypedFormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
            Zip: new UntypedFormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
        });

        if (this.FieldLengths.Address_County > 0) {
            //  Only prompt for this if it's required by the form used by the Self Registration form.
            //  Not adding the FormControl will cause the Address component to hide it.
            group.addControl("County", new UntypedFormControl(null, [Validators.required, Validators.maxLength(80)]));
        }

        return group;
    }

    private SetProjectTypes(center: OneCallCenter): void {
        this.ProjectTypes = this._SelfRegistrationConfigurations.find(f => f.OneCallCenter.ID === center.ID).ProjectTypes;
    }

    public ProjectTypeSelected(projectType: ProjectType): void {
        //  As of 3/29/2022: Political Signs (for AZ) is the only Project Type that allows self registration to begin with.  And it requires an Excavator.
        if (projectType.Name === "Political Signs")
            this.SetAccountType(true);

        this.SelectedProjectType = projectType;
    }

    private LoadCompanyTypes(center: OneCallCenter): void {
        this.CompanyTypes = this._CommonService.HttpClient.get<ExcavatorCompanyType[]>(this._CommonService.SettingsService.ApiBaseUrl + "/Config/ExcavatorCompanyTypes/GetForCenter/" + center.Code);
    }

    private LoadIndustryTypes(center: OneCallCenter): void {
        this.IndustryTypes = this._CommonService.HttpClient.get<IndustryType[]>(this._CommonService.SettingsService.ApiBaseUrl + "/Config/IndustryTypes/GetForCenter/" + center.Code);
    }

    private GetSelfRegistrationCenters(isLoggedIn: boolean, user: AppUser): Observable<[boolean, OneCallCenter[], AppUser]> {
        return this._UserRegService.SelfRegistrationConfiguration().pipe(take(1), mergeMap((data: SelfRegistrationConfigurationResponse[]) => {
            this._SelfRegistrationConfigurations = data;

            //Only 1 center so see if we have an excavator already.  If more than one then we have to wait until the user pick the center
            // Also if we don't have a user then they aren't logged in so we can't search for a contact
            if (data.length === 1 && user)
                return this._ExcavatorContactService.FindForSelfRegistration(data[0].OneCallCenter.Code, user.EmailAddress)
                    .pipe(take(1), map(contact => {
                        this.ExistingContact = contact;

                        const retValue: [boolean, OneCallCenter[], AppUser] = [isLoggedIn, data.map(m => m.OneCallCenter), user];
                        return retValue;
                    }));
            else
                return of<[boolean, OneCallCenter[], AppUser]>([isLoggedIn, data.map(m => m.OneCallCenter), user]);
        }));
    }

    public CompareSelectOptions(i1: SelectOption | null, i2: SelectOption | null): boolean {
        if (i1 && i2)
            return i1.Value === i2.Value;

        return i1 === null && i2 === null;
    }

    public CompareOfficeSelectOptions(office1: ExcavatorOffice, office2: ExcavatorOffice): boolean {
        return office1?.ID === office2?.ID;
    }

    public ExistingContactIsNotUser(): void {
        this.IsExistingContactWrong = true;
        this.MoveToNextStepAfterAddingSteps();
    }

    private MoveToNextStepAfterAddingSteps(): void {
        setTimeout(() => this._Stepper.next());
    }

    public SetAccountType(isExcavator: boolean): void {
        this.AccountTypeIsExcavator = isExcavator;
        this.SelectedProjectType = null;

        this.MoveToNextStepAfterAddingSteps();
    }

    public SetFindMethod(method: number): void {
        this.FindMethod = method;

        this.ClearFindMethodInfo();

        this.MoveToNextStepAfterAddingSteps();

        switch (method) {
            case this.FIND_METHOD_COMPANY_ID:
                this.ClearCreateNewExcavator();
                break;
            case this.FIND_METHOD_EXCAVATOR_ID:
                break;
            case this.FIND_METHOD_COMPANY_ID:
                break;
            case this.FIND_METHOD_TICKET_NUMBER:
                this.ClearCreateNewExcavator();
                break;
            case this.FIND_METHOD_CREATE_NEW:
                break;
        }
    }

    private ClearFindMethodInfo(resetSearchFormControls: boolean = true): void {
        this.AttachToExcavatorCompanyFormControl.reset();
        this.AttachToExcavatorOfficeFormControl.reset();
        this.AvailableOffices = [];
        this.AttachToExcavatorContact = null;

        this.PreviousTicketVerified = false;
        this.SearchReturnedNotFound = false;

        if (resetSearchFormControls) {
            this.CompanyIDFormControl.reset();
            this.CompanyNameFormControl.reset();
            this.ExcavatorIDFormControl.reset();
            this.PreviousTicketNumberFormControl.reset();
            this.PreviousTicketPhoneNumberFormControl.reset();
        }
    }

    private ClearCreateNewExcavator(): void {
        this.NewExcavatorFormGroup.reset();
    }

    public SearchByCompanyID() {
        if (this.CompanyIDFormControl.invalid)
            return;

        const request: ExcavatorSelfRegistrationSearchRequest = {
            CenterCode: this.OneCallCenterFormControl.value.Code,
            CompanyID: this.CompanyIDFormControl.value
        };
        this._ExcavatorCompanyService.SearchForSelfRegistration(request).pipe(take(1))
            .subscribe(response => {
                if (response?.Company) {
                    this.SearchReturnedNotFound = false;
                    this.AttachToExcavatorCompanyFormControl.setValue(response.Company);

                    this.SetAvailableOffices(response.Offices);
                } else {
                    this.ClearFindMethodInfo(false);
                    this.SearchReturnedNotFound = true;
                }
            });
    }

    public CompanyNameSelected(excavatorCompany: ExcavatorCompany): void {
        this.AttachToExcavatorCompanyFormControl.setValue(excavatorCompany);

        //  iq-find-company component automatically fetches all offices for the company if there are multiple.
        //  So ExcavatorOffices should ALWAYS be populated for us.
        this.SetAvailableOffices(excavatorCompany.ExcavatorOffices);
    }

    private SetAvailableOffices(officeList: ExcavatorOffice[]): void {
        if (!officeList)
            return;

        officeList.forEach(office => {
            //  Stuff the formatted address here for display in the dropdown and review page
            (office as any).FormattedAddress = office.Address ? Address.FormatAddress(office.Address) : '';
        });

        if (officeList.length === 1) {
            this.AttachToExcavatorOfficeFormControl.setValue(officeList[0]);
            this.AvailableOffices = [];
        } else {
            //  Should not be possible for there to be 0 offices.  If so, this still works - at least enough that it will prevent
            //  someone from picking it (since office is required and there won't be any to select).
            this.AttachToExcavatorOfficeFormControl.reset();
            this.AvailableOffices = officeList;
        }
    }

    public SearchByExcavatorID() {
        if (this.ExcavatorIDFormControl.invalid)
            return;

        const request: ExcavatorSelfRegistrationSearchRequest = {
            CenterCode: this.OneCallCenterFormControl.value.Code,
            ExcavatorID: this.ExcavatorIDFormControl.value
        };
        this._ExcavatorCompanyService.SearchForSelfRegistration(request).pipe(take(1))
            .subscribe(response => {
                if (response?.Contact) {
                    this.SearchReturnedNotFound = false;
                    this.AttachToExcavatorContact = response.Contact;
                } else {
                    this.ClearFindMethodInfo(false);
                    this.SearchReturnedNotFound = true;
                }
            });
    }

    public SearchByPreviousTicket(): void {
        const request: ExcavatorSelfRegistrationSearchRequest = {
            CenterCode: this.OneCallCenterFormControl.value.Code,
            TicketNumber: this.PreviousTicketNumberFormControl.value,
            PhoneNumber: this.PreviousTicketPhoneNumberFormControl.value
        }
        this._ExcavatorCompanyService.SearchForSelfRegistration(request).pipe(take(1))
            .subscribe(response => {
                this.SearchReturnedNotFound = false;

                //  If Homeowner created the ticket, this will be set and Company/Office will be null.
                //  Contact will always be null if the contact on the ticket already has a login.  So anytime this is set,
                //  we offer to link the login to it.
                this.AttachToExcavatorContact = response?.Contact;

                this.AttachToExcavatorCompanyFormControl.reset();
                this.AttachToExcavatorOfficeFormControl.reset();

                //  Searching by a ticket number can only return a single company/office/contact.
                //  If the ticket was created by a homeowner, then company & office will be null.
                if (response?.Company && response?.Offices && (response.Offices.length === 1)) {
                    //  Company ticket - there can only be exactly 1 office.
                    this.AttachToExcavatorCompanyFormControl.setValue(response.Company);
                    this.AttachToExcavatorOfficeFormControl.setValue(response.Offices[0]);

                    //  This is only set to true if we have a Company.  If we have a Homeowner, we will offer to directly link
                    //  the login to that ExcavatorContact (which will skip the rest of the steps - like we do if we found an existing
                    //  contact using the email).  This flag is just used to know that we can advance to the final confirmation step
                    //  to confirm creating a new ExcavatorContact.
                    this.PreviousTicketVerified = true;
                }

                if (!this.AttachToExcavatorContact && !this.AttachToExcavatorCompanyFormControl.value) {
                    this.ClearFindMethodInfo(false);
                    this.SearchReturnedNotFound = true;
                }
            });
    }

    public CreateAccount(linkToExcavatorContact: ExcavatorContact = null): void {
        this._BusyService.showByKey(this.BusyKey);
        this.IsSaving = true;

        const personInsert = new PersonSelfRegisterRequest();
        personInsert.OneCallCenterCode = this.OneCallCenterFormControl.value.Code;
        personInsert.ProjectRoleID = this.SelectedProjectType ? this.SelectedProjectType.SelfRegistrationRoleID : null;

        personInsert.FirstName = this.FirstNameFormControl.value;
        personInsert.LastName = this.LastNameFormControl.value;
        personInsert.EmailAddress = this.EmailAddressFormControl.value;
        personInsert.PhoneNumber = this.PhoneNumberFormControl.value;

        if (linkToExcavatorContact) {
            //  When linking to a specific ExcavatorContact, this is all we need to specify.
            //  This is always done any time we find a specific ExcavatorContact (including if searching by Ticket Number and the ExcavatorContact
            //  on the ticket does not have a login).
            personInsert.ExcavatorContactID = linkToExcavatorContact.ID;
        } else {
            if (this.FindMethod === this.FIND_METHOD_CREATE_NEW) {
                //  Creating a brand new Excavator/Homeowner
                if (this.AccountTypeIsExcavator === true) {
                    personInsert.ExcavatorCompanyName = this.NewExcavatorCompanyNameFormControl.value;
                    personInsert.ExcavatorAddress = this.NewExcavatorOfficeAddressFormControl.value;
                    personInsert.ExcavatorCompanyTypeID = this.NewExcavatorCompanyTypeFormControl.value.ID;
                    if (this.NewExcavatorIndustryTypeFormControl && this.NewExcavatorIndustryTypeFormControl.value)
                        personInsert.ExcavatorIndustryTypeID = this.NewExcavatorIndustryTypeFormControl.value.ID;
                } else if (this.AccountTypeIsExcavator === false)
                    personInsert.Address = this.NewHomeownerAddressFormGroup.value;
                else
                    throw new Error("SelfRegistration: Unhandled conditions for FindMethod = CREATE_NEW");
            } else {
                //  Creating a new Excavator Contact but linking to an Existing Company/Office (found via one of the search options).
                if (this.AttachToExcavatorCompanyFormControl.valid) {
                    //  Both of these will be valid or CanCreateAccount will not have been true
                    personInsert.ExcavatorCompanyID = this.AttachToExcavatorCompanyFormControl.value.ID;
                    personInsert.ExcavatorOfficeID = this.AttachToExcavatorOfficeFormControl.value.ID;
                } else
                    throw new Error("SelfRegistration: Unhandled conditions for FindMethod != CREATE_NEW");
            }
        }

        //  Need to pass the cognito user attributes - they are needed when creating the login
        this._CognitoService.GetCurrentAuthenticatedUser().pipe(take(1)).subscribe(user => {
            user.getUserAttributes((err, atts) => {
                personInsert.CognitoAttributes = atts;

                this._PersonService.SelfRegisterUser(personInsert).pipe(take(1), mergeMap(appUser => this._CommonService.AuthenticationService.AcceptTermsAndConditions(appUser)))
                    .subscribe(val => {
                        //If we get a null here then they didn't accept the terms and conditions, so log them out.
                        if (!val) {
                            this._CommonService.AuthenticationService.logout(true);
                        }
                        else {
                            this._BusyService.hideByKey(this.BusyKey);
                            this._CommonService.AuthenticationService.setAppUserAndRedirect(val);
                        }
                    }, () => {
                        this.IsSaving = false;
                        this._BusyService.hideByKey(this.BusyKey);
                    });
            });
        });
    }
}
