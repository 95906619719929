//  Contains components that should be included everywhere
//  See: https://angular.io/guide/ngmodule-faq#sharedmodule

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsLayoutComponent } from '@iqSharedComponentControls/DetailLayout/DetailsLayout.component';
import { DetailsLayoutNavigationComponent } from '@iqSharedComponentControls/DetailLayout/Navigation/DetailsLayoutNavigation.component';


@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        DetailsLayoutComponent, DetailsLayoutNavigationComponent
    ],
    declarations: [
        DetailsLayoutComponent, DetailsLayoutNavigationComponent
    ]
})

export class DetailLayoutModule { }
