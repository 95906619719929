
import {delay, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'Services/SettingsService';
import { PermissionsService } from 'Services/PermissionsService';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { Email } from '@iqModels/Common/Email.model';
import { NameValueUpdateRequest } from '@iqModels/Base/NameValueUpdateRequest.model';
import { AuthenticationService } from 'Services/AuthenticationService';

//Update this to use the base CRUDService if we need to.  This is a child item so there isn't any real permissions for it, they have to come from the parent it belongs to.
//  So with that and only doing add, and delete I didn't want to extend CRUDService and make the permissions confusing and possibly call one of the other methods that aren't implemented
//  to handle permissions correctly.
@Injectable({
    providedIn: 'root'
})
export class EmailService {
    protected apiPath: string = 'Administration/Email';

    constructor(public http: HttpClient, public settingsService: SettingsService, public permissionService: PermissionsService) { }

    CanPerformAction(action: 'View' | 'Create' | 'Edit' | 'Delete', item: Email): Observable<boolean> {
        let permissions: PermissionsEnum;
        let editIDs = null;
        let hasAnywhere = false;//Need to check for it on a member and then let the server handle it

        if (item.ExcavatorCompanyID) {
            permissions = PermissionsEnum.ExcavatorCompany_Edit;
            editIDs = [item.ExcavatorCompanyID];
        }
        else if (item.ExcavatorOfficeID) {
            permissions = PermissionsEnum.ExcavatorOffice_Edit;
            editIDs = [item.ExcavatorOfficeID];
            //hasAnywhere = true;//Let the server figure this out because we don't know it here
        }
        else if (item.MemberID) {
            permissions = PermissionsEnum.Member_Edit;
            editIDs = [item.MemberID];
        }
        else if (item.PersonID) {
            permissions = PermissionsEnum.Person_Edit;
            editIDs = [item.PersonID];
        }
        else if (item.ServiceAreaID) {
            permissions = PermissionsEnum.ServiceArea_Edit;
            hasAnywhere = true;
        }

        switch (action) {
            case 'View':
                if (item.ExcavatorCompanyID)
                    return this.permissionService.CurrentUserHasPermission(PermissionsEnum.ExcavatorCompany_View, editIDs, hasAnywhere);
                else if (item.ExcavatorOfficeID)
                    return this.permissionService.CurrentUserHasPermission(PermissionsEnum.ExcavatorOffice_View, editIDs, hasAnywhere);
                else if (item.MemberID)
                    return this.permissionService.CurrentUserHasPermission(PermissionsEnum.Member_View, editIDs, hasAnywhere);
                else if (item.PersonID)
                    return this.permissionService.CurrentUserHasPermission(PermissionsEnum.Person_View, editIDs, hasAnywhere);
                else if (item.ServiceAreaID)
                    return this.permissionService.CurrentUserHasPermission(PermissionsEnum.ServiceArea_View, editIDs, hasAnywhere);

                return new BehaviorSubject<boolean>(false);
                
            case 'Edit':
                return this.permissionService.CurrentUserHasPermission(permissions, editIDs, hasAnywhere);
            case 'Create':
                return this.permissionService.CurrentUserHasPermission(permissions, editIDs, hasAnywhere);
            case 'Delete':
                return this.permissionService.CurrentUserHasPermission(permissions, editIDs, hasAnywhere);
            default:
                return new BehaviorSubject<boolean>(false);
        }
    }

    Delete(item: Email): Observable<Email> {
        return this.CanPerformAction('Delete', item).pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<Email>({} as Email)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.http.delete<Email>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/" + item.ID);
        }));
    }

    //The caller needs to supply the subscribe so that it can do any logic that's needed
    Insert(model: Email): Observable<Email> {
        //Always an Edit since it's a child property
        return this.CanPerformAction('Edit', model).pipe(mergeMap(allowed => {
            if (!allowed)
                return new BehaviorSubject<Email>({} as Email)
                    .pipe((data) => {//Do something to inform the user??
                        console.log('invalid permission');
                        return data;
                    }).pipe(delay(500));//Need to do a delay so that angular forms have a chance to bind before it gets a response

            return this.http.post<Email>(this.settingsService.ApiBaseUrl + "/" + this.apiPath, model);
        }));
    }

    SendVerificationEmail(model: Email): Observable<void> {
        let request: NameValueUpdateRequest = new NameValueUpdateRequest();
        request.ID = model.ID;
        request.Value = model.EmailAddress;

        //No permissions...If they get access to something that calls this then they are allowed
        return this.http.post<void>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/SendVerificationEmail", request);
    }

    SetVerified(id: string): Observable<void> {
        let request: NameValueUpdateRequest = new NameValueUpdateRequest();
        request.ID = id;

        //No permissions...If they get access to something that calls this then they are allowed
        return this.http.post<void>(this.settingsService.ApiBaseUrl + "/" + this.apiPath + "/VerifyEmail", request);
    }
}
