import { Registration } from "./Registration.model";
import { RegistrationActionRequest } from "./RegistrationActionRequest.model";

export class RegistrationDeleteChangeRequest extends RegistrationActionRequest {

    constructor(registration: Registration,
        public RegistrationChangeID: string) {
        super(registration);
    }
}

