<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Reset password
</h2>
<mat-dialog-content class="mat-typography" style="overflow: auto; display: flex; flex-direction: column;">
  <div style="flex: 1 1 0%;"  class="mat-body-2">
    <p>You are about to initiate a password reset for the username <b>{{Username}}</b>.</p>
    <p>The user will be prompted to change their password the next time they log in.</p>
    <p>To complete that process, the user must enter a code that will be emailed to them now.</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button (click)="onChange()" color="primary">
        Confirm
    </button>
</mat-dialog-actions>
