import { Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { IQ_FLYOUT_DATA, IQ_FLYOUT_REF } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { SideSlideoutRef } from 'Shared/PhoneDevice/SideSlideout/SideSlideoutRef';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { TicketCancelComponent, TicketCancelData } from "../TicketCancel.component";

@Component({
    templateUrl: './TicketCancelFlyout.component.html',
    styleUrls: ['./TicketCancelFlyout.component.scss'],
    animations: [slideInFromRight]
})

export class TicketCancelFlyoutComponent {
    //  Defining the animation on the HostBinding like this is cleaner and causes it to do the close animation automatically.
    @HostBinding('@slideInFromRight') animation = 'enter';

    @ViewChild(TicketCancelComponent)
    private _TicketCancelComponent: TicketCancelComponent;

    constructor(@Inject(IQ_FLYOUT_DATA) public Data: TicketCancelData, @Inject(IQ_FLYOUT_REF) private _SideSlideoutRef: SideSlideoutRef) {
    }

    public Close(returnData: TicketCancelData = null) {
        this._SideSlideoutRef.close(returnData);
    }

    public Save(): void {
        var response = this._TicketCancelComponent.OnSave();
        this.Close(response);
    }
}
