import { CryptoService } from '../Services/crypto.service';
import { MemoryStorage } from './memory-storage';

/** 
 * This class will encode the refres token so that it isn't stored in the localhost as the plain key.
 * Can do other data, but it could be taxing to constatly decode the idToken since we would be doing that on every api call.  Also, the other tokens will expire every hour, but the 
 * refresh key will last much longer (a day +).
 */
export abstract class LocalEncodedStorage {

    private static ENCRYPT_TOKEN_KEY = "refreshToken";

    /**
	 * This will set a listener to know to logout of the tab if the user logs out on a different tab
	 */
	// private setStorageWatches() {
	// 	this.winRef.nativeWindow.onstorage = (e) => {
	// 		if (e.key.startsWith("CognitoIdentityServiceProvider") && e.key.endsWith("accessToken") && e.newValue == null) {
	// 			this.SignOut().pipe(take(1)).subscribe();
	// 			this.setSignedIn({ SignedIn: false, User: null });
	// 		}
	// 		else if (e.key.startsWith("CognitoIdentityServiceProvider") && e.key.endsWith("accessToken") && e.oldValue == null) {
	// 			this.GetCurrentUserAppStartUp().pipe(take(1))//Have to to the app startup here so we can check all configured userpools (main and sso)
	// 				.subscribe(null,
	// 					//Catch the error but don't do anything so that there isn't anything logged by default
	// 					() => { });
	// 		}
	// 	}
	// }




    /**
     * This is just a failsafe.  If they don't allow localstorage then if the user refreshes then the values won't persist and it will try to get them
     * from SSO, which may or may not be set up or find the login.
     */
    private static _Storage = null;
    private static getStorage(){
        if (this._Storage == null){
            try {
                this._Storage = window.localStorage;
                this._Storage.setItem('aws.amplify.test-ls', 1);
                this._Storage.removeItem('aws.amplify.test-ls');
            } catch (exception) {
                this._Storage = MemoryStorage;
            }
        }

        return this._Storage;
    }

    /**
     * This is just the crypto service.  Not for external consumption.
     */
	private static _CryptoService = null;
	private static getCrypto(): CryptoService{
		if (this._CryptoService == null)
			this._CryptoService = new CryptoService();

		return this._CryptoService;
	}

    static syncPromise = null;
    /**
     * This is used to set a specific item in storage
     */
    static setItem(key: string, value) {
        if (key.endsWith(this.ENCRYPT_TOKEN_KEY))
            value = this.getCrypto().encode(value);

		this.getStorage().setItem(key, value);
		return value;
    }

    /**
     * This is used to get a specific key from storage
     */
    static getItem(key) {
		let val = this.getStorage().getItem(key);
		if (val != null){
            if (key.endsWith(this.ENCRYPT_TOKEN_KEY))
                val = this.getCrypto().decode(val);

            return val;
        }
		
		return null;
    }

    /**
     * This is used to remove an item from storage
     */
    static removeItem(key) {
        this.getStorage().removeItem(key);
    }

    /**
     * This is used to clear the storage
     */
    static clear() {
		this.getStorage().clear();
    }

    /**
     * 
    */
   static sync() {
		if (!this.syncPromise) {
            this.syncPromise = new Promise<void>((res, rej) => {
				res();
			});
        }
        return this.syncPromise;
    }
}
