/*
    This is the template for item in the view list component.  It just displays the html they want for each item with some default styling
*/

import { Component } from '@angular/core';
import { BaseListItem } from '../Base/BaseListItem.class';

@Component({
    selector: 'iq-list-item',
    templateUrl: './ListItem.component.html',
    styleUrls: ['./ListItem.component.scss']
})
export class ListItemComponent extends BaseListItem {
}
