import * as _ from 'lodash';

export class StringUtils {

    public static AppendToString(cur: string, value: any, prefix: string): string {
        if (value && (value !== '')) {
            if (prefix && (prefix !== '') && cur && (cur !== ''))
                cur += prefix;

            cur += value;
        }

        return cur;
    }

    //  This handles checking objects that are potentially null/undefined against another string that is an empty string.
    //  So: null is equal to ''
    public static IsEqual(str1: string, str2: string, caseInsensitive: boolean = false): boolean {
        const str1Empty = _.isEmpty(str1);
        const str2Empty = _.isEmpty(str2);

        if (str1Empty && str2Empty)
            return true;        //  Both are null/undefined/empty so equal
        if (str1Empty || str2Empty)
            return false;       //  One is null/undefined and the other is not (b/c of check above) so not equal

        if ((typeof str1 === "string") && (typeof str2 === "string")) {
            if (caseInsensitive) {
                str1 = str1.toLowerCase();
                str2 = str2.toLowerCase();
            }

            str1 = str1.trim();
            str2 = str2.trim();
        }

        return str1 === str2;
    }
}




//Make global so we don't have to import everywhere
declare global {
    interface String {
        iqToUppercase(): string;
        iqToLowercase(): string;
        toBoolean(): boolean;
    }
}

/**Checks for null/undefined, if not null/undefined uses .toLocaleUpperCase() to uppercase the string*/
String.prototype.iqToUppercase = function (): string {
    return !this ? null : (this as string).toLocaleUpperCase();
}

String.prototype.iqToLowercase = function (): string {
    return !this ? null : (this as string).toLocaleLowerCase();
}
String.prototype.toBoolean = function (): boolean {
    switch ((this as string).iqToLowercase()) {
        case 'true':
        case '1':
        case 'on':
        case 'yes':
            return true
        default:
            return false
    }
}
