<!-- flex-shrink's are needed to help with the phone view sizing -->
<div class="row">
    <div class="gray-primary-text">Arranged meeting date and time:</div>
    <iq-date-with-picker #meetingDatePicker style="flex-shrink:0" [formControl]="MeetingDateFormControl" [Min]="MinDate" [Max]="MaxDate"></iq-date-with-picker>
</div>
<div class="row">
    <div class="gray-primary-text">Name of Person Locator spoke to:</div>
    <mat-form-field style="flex-grow: 1; flex-shrink: 0">
        <input iqDisableAutofill matInput UpperCase [formControl]="PersonSpokeToFormControl" />
        <mat-error iqValidationMessage [control]="PersonSpokeToFormControl"></mat-error>
    </mat-form-field>
</div>
<div class="row">
    <div class="gray-primary-text">Phone number of Person Locator spoke to:</div>
    <mat-form-field style="flex-shrink: 0">
        <iq-phone [formControl]="PhoneNumberFormControl"></iq-phone>
        <mat-error iqValidationMessage [control]="PhoneNumberFormControl"></mat-error>
    </mat-form-field>
</div>
<div class="row">
    <div class="gray-primary-text">Locators name:</div>
    <mat-form-field style="flex-grow: 1; flex-shrink: 0">
        <input iqDisableAutofill matInput UpperCase [formControl]="LocatorNameFormControl" />
        <mat-error iqValidationMessage [control]="LocatorNameFormControl"></mat-error>
    </mat-form-field>
</div>
