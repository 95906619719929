import { AutocompleteRequest } from "@iqModels/Base/AutocompleteRequest.model";

export class PlaceAutocompleteRequest extends AutocompleteRequest {

    public State: string;

    /**
      * If known, this can be used to limit the search to a specific county.
     */
    public CountyName: string;

    public EnteredStreet: string;

    constructor(propertyName: string, searchValue: string, public IncludeCounty: boolean, public IncludeCallerPlace: boolean) {
        super(propertyName, searchValue);
    }
}
