import { MatDialog } from '@angular/material/dialog';
import { VerifyTicketBeforeSaveResponse } from 'Models/Tickets/VerifyTicketBeforeSaveResponse.model';
import { DigSiteRuleActionEnum } from 'Enums/DigSiteRules/DigSiteRuleAction.enum';
import { DigSiteRuleValidationErrorResponse } from 'Models/Errors/DigSiteRuleValidationErrorResponse.model';
import { IResponseWithDigSiteRules } from 'Models/Tickets/IResponseWithDigSiteRules.interface';
import { map, Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'Shared/Components/Controls/Dialog/Confirmation/ConfirmationDialog.component';
import { InformationDialogComponent } from 'Shared/Components/Controls/Dialog/Information/InformationDialog.component';
import { StringUtils } from 'Shared/Utils/StringUtils';
import { DialogModel } from 'Shared/Components/Controls/Dialog/Models/Dialog.model';
import { GenericFlyoutComponent } from 'Shared/Components/Controls/Flyout/Generic/GenericFlyout.component';
import { SideSlideoutService } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { CreateMultipleTicketsDialogComponent } from '../Details/Components/CreateMultipleTickets/Dialog/CreateMultipleTicketsDialog.component';
import { TicketEntryFormGroup } from '../Details/Components/InputControls/TicketEntryFormGroup';
import { CreateMultipleTicketsData } from '../Details/Components/CreateMultipleTickets/CreateMultipleTickets.component';
import { CreateMultipleTicketsFlyoutComponent } from '../Details/Components/CreateMultipleTickets/Flyout/CreateMultipleTicketsFlyout.component';

export class TicketDigSiteRuleService {

    public static ShowInvalidDigSiteRules<T extends IResponseWithDigSiteRules>(response: T, sideSlideoutService: SideSlideoutService, dialog: MatDialog, isPhone: boolean): Observable<T> {
        if (response?.InvalidDigSiteRules) {
            //  If we have errors, we show the error dialog.
            //  ** When finished, the observable outputs null so the save process is aborted
            if (response.InvalidDigSiteRules.some(r => r.DigSiteRuleAction === DigSiteRuleActionEnum.ShowError))
                return TicketDigSiteRuleService.ShowDigSiteRuleErrors(response.InvalidDigSiteRules, sideSlideoutService, dialog, isPhone).pipe(map(() => null));

            //  Otherwise, show warnings.  That dialog allows the user to continue or cancel
            if (response.InvalidDigSiteRules.some(r => r.DigSiteRuleAction === DigSiteRuleActionEnum.ShowMessage))
                return TicketDigSiteRuleService.ShowDigSiteRuleWarnings(response.InvalidDigSiteRules, null, sideSlideoutService, dialog, isPhone).pipe(map(val => val ? response : null));
        }

        return of(response);        //  No invalid dig site rules;
    }

    private static ShowDigSiteRuleErrors(invalidDigSiteRules: DigSiteRuleValidationErrorResponse[], sideSlideoutService: SideSlideoutService, dialog: MatDialog, isPhone: boolean): Observable<any> {

        var message = TicketDigSiteRuleService.DigSiteRuleMessage(DigSiteRuleActionEnum.ShowError, invalidDigSiteRules);

        var componentData: DialogModel = new DialogModel();
        componentData.Title = "Invalid Dig Site!";
        componentData.Message = message;
        componentData.ActionText = "OK";
        componentData.IsConfirm = false;

        return TicketDigSiteRuleService.OpenDialogOrFlyout(componentData, isPhone, sideSlideoutService, dialog);
    }

    private static ShowDigSiteRuleWarnings(invalidDigSiteRules: DigSiteRuleValidationErrorResponse[], afterAction: () => void, sideSlideoutService: SideSlideoutService, dialog: MatDialog, isPhone: boolean): Observable<any> {

        var message = TicketDigSiteRuleService.DigSiteRuleMessage(DigSiteRuleActionEnum.ShowMessage, invalidDigSiteRules);

        var componentData: DialogModel = new DialogModel();
        componentData.Title = "Warning!";
        componentData.Message = message;
        componentData.ConfirmationText = "Continue anyway?";
        componentData.ActionText = isPhone ? "Continue" : "Yes";
        componentData.CancelText = isPhone ? "Cancel" : "No";
        componentData.IsConfirm = true;

        return TicketDigSiteRuleService.OpenDialogOrFlyout(componentData, isPhone, sideSlideoutService, dialog);
    }

    private static OpenDialogOrFlyout(componentData: DialogModel, isPhone: boolean, sideSlideoutService: SideSlideoutService, dialog: MatDialog): Observable<any> {
        if (isPhone) 
            return sideSlideoutService.AttachComponent("Right", GenericFlyoutComponent, componentData).OnClose;
        else if (componentData.IsConfirm) 
            return dialog.open(ConfirmationDialogComponent, { data: componentData }).afterClosed();
        else
            return dialog.open(InformationDialogComponent, { panelClass: 'iq-warn', data: componentData }).afterClosed();
    }

    private static DigSiteRuleMessage(ruleType: DigSiteRuleActionEnum, invalidDigSiteRules: DigSiteRuleValidationErrorResponse[]): string {
        let message: string = "";
        invalidDigSiteRules.forEach(r => {
            if (r.DigSiteRuleAction === ruleType)
                message = StringUtils.AppendToString(message, r.Message, "</br></br>");
        });

        return message;
    }

    public static ShowCreateMultipleTicketsDialog(verifyResponse: VerifyTicketBeforeSaveResponse,
                                                    ticketEntryFormGroup: TicketEntryFormGroup, dialog: MatDialog,
                                                    sideSlideoutService: SideSlideoutService, isPhone: boolean)
        : Observable<VerifyTicketBeforeSaveResponse>
    {
        if (!verifyResponse?.InvalidDigSiteRules) 
            return of(verifyResponse);

        const showMultipleTicketDigSiteRule = verifyResponse.InvalidDigSiteRules.find(r => r.DigSiteRuleAction === DigSiteRuleActionEnum.CreateMultipleTickets);

        if (!showMultipleTicketDigSiteRule)
            return of(verifyResponse);

        const componentData: CreateMultipleTicketsData = new CreateMultipleTicketsData(ticketEntryFormGroup, verifyResponse, showMultipleTicketDigSiteRule);

        //  Returns the verifyResponse if creating a ticket (when creating multiple, will return an UPDATED
        //  verify response) or null to abort saving.
        if (isPhone) {
            return sideSlideoutService.AttachComponent("Right", CreateMultipleTicketsFlyoutComponent, componentData).OnClose;
        }
        else {
            return dialog
                .open(CreateMultipleTicketsDialogComponent, {
                    minWidth: "95%",
                    height: "90%",
                    data: componentData,
                    autoFocus: false        //  To prevent the dialog from focusing on the first input control - it interfers with dialog setting focus itself!
                })
                .afterClosed();
        }
    }
}

