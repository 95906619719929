import { AbstractControl } from '@angular/forms';


export function IsNumeric(control: AbstractControl) {
    if (control.value !== null && control.value !== undefined) {
        const numeric = control.value.replace(/\D/g, '').substr(0, 9);
        if (numeric.length !== control.value.length)
            return { customMessage: 'Value must be numeric' };
    }

    return null;
}
