<ng-container *ngIf="ServiceArea?.Discussions" >
    <ng-container *ngFor="let discussion of ServiceArea.Discussions; last as isLast">
        <div style="padding: 5px 0; display:flex; flex-direction:column">
            <ng-container [ngSwitch]="discussion.DiscussionType">
                <!-- Note that these descriptions are also built in the TicketDiscussionCollectionToDiscussionStatusConverter.cs in the api! -->
                <ng-container *ngSwitchCase="DiscussionTypeEnum.RequestActualExcavationDate">
                    <div>
                        <ng-container *ngIf="discussion.Response">
                            <span class="iq-caption gray-primary-text">Date of Excavation: </span>
                            <span>{{discussion.Response | date: SettingsService.DateFormat}}</span>
                        </ng-container>
                        <span *ngIf="!discussion.Response" style="color:red">Date of Excavation not provided yet.</span>
                    </div>
                    <div *ngIf="!ReadOnly && discussion.CurrentUserCanSaveResponse" class="link" (click)="AddExcavationDate($event)">+ add excavation date</div>
                </ng-container>
                <ng-container *ngSwitchCase="DiscussionTypeEnum.MarkingDelayRequested">
                    <div>
                        <span *ngIf="discussion.Response">Excavator {{discussion.Response}}s with marking delay</span>
                        <span *ngIf="!discussion.Response" style="color:red">Waiting for Excavator to review Marking Delay request.</span>
                    </div>
                    <div *ngIf="!ReadOnly && discussion.CurrentUserCanSaveResponse" class="link" (click)="AddMarkingDelayResponse($event)">+ add marking delay response</div>
                </ng-container>
            </ng-container>
        </div>

        <mat-divider style="margin-left: 25px;" *ngIf="!isLast"></mat-divider>
    </ng-container>
</ng-container>
