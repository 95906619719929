import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelfRegistrationConfigurationResponse } from 'Models/SelfRegistration/SelfRegistrationConfigurationResponse.model';
import { combineLatest, of, Subject } from 'rxjs';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { AuthenticationService, ExactixFederatedLoginState } from 'Services/AuthenticationService';
import { BusyService } from 'Services/BusyService';
import { passwordRequirements } from 'Services/CognitoAdminService';
import { MainMenuService } from 'Services/MainMenuService';
import { UserRegistrationService } from '../Services/UserRegistration.service';

@Component({
    selector: 'iq-new-user-registration',
    templateUrl: './UserRegistration.component.html',
    styleUrls: ['./UserRegistration.component.scss']
})
export class UserRegistrationComponent implements OnInit, OnDestroy {

    public FederatedLoginReturnState: ExactixFederatedLoginState = { redirectBeforeFetchingUser: false, returnUrl: this._Router.url };
    public PasswordRequirements = passwordRequirements;

    private _Destroyed: Subject<void> = new Subject();

    public BusyKey: string = this._BusyService.createNew();

    private _RedirectingToLogin = false;

    public IsLoggedIn: boolean = false;

    public IsInitialized: boolean = false;
    public DisclaimerHtml: string;
    public ShowDisclaimer: boolean = false;

    constructor(private _MainMenuService: MainMenuService, private _AuthService: AuthenticationService,
        private _BusyService: BusyService, private _Router: Router, private _UserRegistrationService: UserRegistrationService)
    {
        this._MainMenuService.forceClose = true;
    }

    public ngOnInit(): void {
        combineLatest(
            this._AuthService.UserIsSignedIn.pipe(takeUntil(this._Destroyed)),
            this._AuthService.FetchedUserInfo.pipe(takeUntil(this._Destroyed), mergeMap((val) => {
                if (val)
                    return this._AuthService.CurrentUserObserver().pipe(take(1), map((user) => val));
                else
                    return of<boolean>(val);
            })),
            this._UserRegistrationService.SelfRegistrationConfiguration().pipe(take(1))
        ).subscribe(val => {
            if (this._RedirectingToLogin)
                return;

            if (val[0] && !val[1])
                this._BusyService.showByKey(this.BusyKey);
            else
                this._BusyService.hideByKey(this.BusyKey);

            this.IsLoggedIn = val[0] && val[1];

            if (this.IsLoggedIn) {
                //  If already logged in we (should!) be returning to this page after the user has created their login and entered the verification code.
                //  So don't show the disclaimer again.  And for some reason, these events fire multiple times so when logged in, the FetchedUserInfo
                //  shows as false initially...
                this.ShowDisclaimer = false;
            } else {
                //  Should not have multiple One Calls on a prod system.  So if there is a disclaimer anywhere, just pick the first one we find
                //  since we haven't picked a One Call yet.
                const selfRegConfigs = val[2] as SelfRegistrationConfigurationResponse[];
                if (selfRegConfigs) {
                    const withDisclaimers = selfRegConfigs.filter(c => c.DisclaimerHtml && c.DisclaimerHtml !== "");
                    if (withDisclaimers.length > 0) {
                        this.DisclaimerHtml = withDisclaimers[0].DisclaimerHtml;
                        this.ShowDisclaimer = true;
                    }
                }
            }

            //  Should really fetch the SelfRegistrationConfiguration() as part of the route navigation.  But didn't have
            //  time to do that properly.  This works just as well for now at least.
            this.IsInitialized = true;

            //  Don't do anything here if isLoggedIn!  RegistrationUserDetails watches for the same events
            //  and redirects/handles it.
        });
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();

        this._BusyService.removeByKey(this.BusyKey);

        if (!this._RedirectingToLogin)
            this._MainMenuService.forceClose = false;
    }

    public Logout(): void {
        this._BusyService.showByKey(this.BusyKey);
        this._RedirectingToLogin = true;
        this._AuthService.logout();
    }
}
