import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Announcement } from 'Models/Announcements/Announcement.model';
import { AnnouncementService } from 'Pages/Administration/Services/Announcement.service';

@Component({
    selector: 'iq-announcements-carousel',
    templateUrl: './AnnouncementsCarousel.component.html',
    styleUrls: ['./AnnouncementsCarousel.component.scss']
})
export class AnnouncementsCarouselComponent implements OnInit {

    items = [];

    private _forLogin: boolean = false;
    @Input() set forLogin(val) {
        this._forLogin = coerceBooleanProperty(val);
    }
    get forLogin() {
        return this._forLogin;
    }

    ngOnInit() {
        this.announcementService.GetActive(this.forLogin).pipe(take(1)).subscribe((val: Announcement[]) => {
            this.items = val;
        });
    }

    constructor(private announcementService: AnnouncementService) {
    }
}
