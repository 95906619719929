import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TicketDiscussion } from 'Models/Tickets/TicketDiscussion.model';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { TicketDiscussionSaveResponse } from "Models/Tickets/TicketDiscussionSaveResponse.model";

@Injectable({
    providedIn: 'root'
})
export class TicketDiscussionService {
    protected apiPath: string = "Ticket/Discussion";

    constructor(private _Services: CRUDServices) { }

    /**
     *  Save a response to a TicketDiscussion.  Note that if the response could affect multiple TicketDiscussion records
     *  so all affected will be returned here (i.e. FL's AddExcavationDate)
     */
    public SaveResponse(ticketDiscussionID: string, response: string): Observable<TicketDiscussionChildResponse[]> {
        const request = new TicketDiscussionSaveResponse(ticketDiscussionID, response);
        const url = this._Services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/SaveResponse";
        return this._Services.http.post<TicketDiscussionChildResponse[]>(url, request);
    }

    /**
     *  Create TicketDiscussion records to store a comment from the excavator for a list of service areas.
     */
    public CreateExcavatorComment(discussionList: TicketDiscussion[]): Observable<TicketDiscussionChildResponse[]> {
        const url = this._Services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/CreateExcavatorComment";
        return this._Services.http.post<TicketDiscussionChildResponse[]>(url, discussionList);
    }
}
