import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SearchFilterOperatorEnum } from "Enums/SearchFilterOperator.enum";
import { SearchColumn } from "./SearchColumn.model";

export class SearchFilterValue {
    constructor(
        public FilterValue: any,
        public DisplayValue: string,
        public HideInUI?: boolean)          //  Default to undefined so that it is omitted from the request payload
    {
    }
}

export class SearchFilter {

    //If you add a property here make sure you update the 'Copy' function in the ListFilter.service so it will get set properly when read out

    //  These boolean's need to use ? to default to undefined.  It reduces the payload sent to the server - there is no need to send
    //  the server a false value, it will default to that automatically.  By defaulting to "undefined", the entire property will be
    //  omitted from the payload in the request.
    constructor(public PropertyName: string, public Operator: SearchFilterOperatorEnum, public Values: SearchFilterValue[],
        public IgnoreFilter?: boolean,
        public QuickTextSearch?: boolean,   //  If true, then it should be the value in the quick text search at the top of the search pages.  i.e. the quick ticket number search on tickets search page
        public IsOrFilter?: boolean)        //  If true it will be put in an OR filter that is AND'd to the other (normal) AND filters
    {
    }

    IsEqual(comp: SearchFilter): boolean {
        //  coerceBooleanProperty needed due to the properties defaulting to undefined - see notes in constructor.
        if (coerceBooleanProperty(comp.IgnoreFilter) !== coerceBooleanProperty(this.IgnoreFilter))
            return false;
        if (coerceBooleanProperty(comp.IsOrFilter) !== coerceBooleanProperty(this.IsOrFilter))
            return false;
        if (comp.Operator !== this.Operator)
            return false;
        if (comp.PropertyName !== this.PropertyName)
            return false;
        if (coerceBooleanProperty(comp.QuickTextSearch) !== coerceBooleanProperty(this.QuickTextSearch))
            return false;

        if (comp.Values && !this.Values)
            return false;

        if (!comp.Values && this.Values)
            return false;

        if (comp.Values && this.Values) {
            if (comp.Values.length !== this.Values.length)
                return false;

            for (let index = 0; index < comp.Values.length; index++) {
                const compValue = comp.Values[index];
                if (!this.Values.find(f => f.DisplayValue === compValue.DisplayValue && f.FilterValue === compValue.FilterValue && coerceBooleanProperty(f.HideInUI) === coerceBooleanProperty(compValue.HideInUI)))
                    return false;
            }
        }

        return true;
    }

    public IsValid(column: SearchColumn): boolean {
        //  I don't understand why we need to have some of the values needed for the filter set in the column...  Does not make any sense...
        //  Because of that, we need both in order to check to see if a filter is valid!

        if (!column.IsRequired)
            return true;        //  If not required, does not matter what is set

        //  Make sure we have Values where they are needed.
        //  Assuming that if they have values at all, the values are valid.
        //  And the list of operators that do NOT require a value is much less than those that do.
        switch (this.Operator) {
            case SearchFilterOperatorEnum.IsNotNull:
            case SearchFilterOperatorEnum.IsNull:
            case SearchFilterOperatorEnum.Today:
            case SearchFilterOperatorEnum.Yesterday:
            case SearchFilterOperatorEnum.LastWeek:
            case SearchFilterOperatorEnum.LastMonth:
            case SearchFilterOperatorEnum.Tomorrow:
            case SearchFilterOperatorEnum.CurrentUser:
                //  No value required so filters on these operators are always valid
                return true;
        }

        //  Value required
        if (!this.Values || this.Values.length === 0)
            return false;

        return true;
    }
}
