

import {
    Component
} from '@angular/core';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { AutoCompleteFormControl } from 'Shared/Components/Forms/FormControls/AutoCompleteFormControl';
import { SearchRequest } from '@iqModels/Searching/SearchRequest.model';
import { BaseFilterItem } from '../BaseFilterItem';
import { PersonService } from 'Pages/People/Services/Person.service';
import { UntypedFormControl } from '@angular/forms';
import { SearchFilter, SearchFilterValue } from '@iqModels/Searching/SearchFilter.model';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { Guid } from '@iqSharedUtils/Guid';


enum PersonFilterOptions {
    None,
    Self,
    SpecificPeople,
    NameContains
}


@Component({
    selector: 'iq-list-agent-filter',
    templateUrl: './AgentFilter.component.html',
    styleUrls: ['./AgentFilter.component.scss']
})
export class AgentFilterComponent extends BaseFilterItem {

    FilterOptions = PersonFilterOptions;

    selectedOption: PersonFilterOptions = PersonFilterOptions.None;
    selectedOptionChange($event) {

        if($event)
            this.selectedOption = $event.value;

        this.searchOperator = SearchFilterOperatorEnum.Contains;
        
        if (this.selectedOption === PersonFilterOptions.None) {
            this.values = [];
            this.autoCompleteValues = [];
            this.fireChangeEvent();
        }
        else if (this.selectedOption === PersonFilterOptions.Self) {
            this.values = [];
            this.autoCompleteValues = [];
            this.searchOperator = SearchFilterOperatorEnum.CurrentUser;
            this.fireChangeEvent();
        }

        if (this.selectedOption !== PersonFilterOptions.SpecificPeople && this.autoCompleteControl.enabled)
            this.autoCompleteControl.reset();

        if (this.selectedOption !== PersonFilterOptions.NameContains && this.containsControl.enabled)
            this.containsControl.reset();

        this.enableNeededInputs();
    }



    private autoCompleteSearch = (filter: SearchRequest) => {
        filter.Filters.push(new SearchFilter("HasLogin", SearchFilterOperatorEnum.Equals, [new SearchFilterValue(true, "Yes")]));
        return this.personService.Autocomplete(filter);
    };
    autoCompleteControl: AutoCompleteFormControl = new AutoCompleteFormControl(this.autoCompleteSearch, { value: null, disabled: true });

    containsControl = new UntypedFormControl({ value: null, disabled: false})
    containsFocusEvent: Subject<boolean> = new Subject();
    
    autoCompleteValues: SearchFilterValue[] = [];
    autocompleteFocusEvent: Subject<boolean> = new Subject();

    constructor(private personService: PersonService)
    {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.selectedOption = PersonFilterOptions.None;

        if (!this.values)
            this.values = [];

        if (this.searchOperator === SearchFilterOperatorEnum.CurrentUser) {
            this.values = [];
            this.autoCompleteValues = [];
            this.selectedOption = PersonFilterOptions.Self;
        }
        else if (this.values.length > 0) {
            if (Guid.isGuid(this.values[0].FilterValue)) {
                this.autoCompleteValues = this.values;
                this.values = [];
                this.selectedOption = PersonFilterOptions.SpecificPeople;
            }
            else {
                this.autoCompleteValues = [];
                this.selectedOption = PersonFilterOptions.NameContains;
            }
        }

        this.enableNeededInputs();
    }

    private enableNeededInputs() {
        if (this.selectedOption === PersonFilterOptions.SpecificPeople) {
            if (this.autoCompleteControl.disabled) {
                this.autoCompleteControl.enable();
                this.autocompleteFocusEvent.next(true);
            }
        }
        else if (this.autoCompleteControl.enabled)
            this.autoCompleteControl.disable();

        if(this.selectedOption === PersonFilterOptions.NameContains) {
            if (this.containsControl.disabled) {
                this.containsControl.enable();
                this.containsFocusEvent.next(true);
            }
        }
        else if (this.containsControl.enabled)
            this.containsControl.disable();
    }

    inputClicked(selectOption: PersonFilterOptions) {
        if (this.isDisabled)
            return;

        if (this.selectedOption !== selectOption) {
            this.selectedOption = selectOption;

            this.selectedOptionChange(null);
        }
    }
    
    AutoCompleteResultSelected($event: MatAutocompleteSelectedEvent) {
        //Don't do anything if they select the "No Results" option
        if ($event.option.value.ID != null) {

            this.values = [];

            this.autoCompleteValues.push(new SearchFilterValue($event.option.value.ID, $event.option.value.Name));
            
            this.fireChangeEvent();
        }

        this.autoCompleteControl.reset();
    }
    add(): void {
        this.autoCompleteValues = [];

        this.addValue([new SearchFilterValue(this.containsControl.value, this.containsControl.value)]);
        this.containsControl.reset();

    }

    clear(): void {
        this.values = [];
        this.autoCompleteValues = [];
        this.selectedOption = PersonFilterOptions.None;
    }


    //Overridden because of the two set of chips
    protected fireChangeEvent() {

        const filterName = this.filter.PropertyName;
        let values = this.values;
        if (this.selectedOption === PersonFilterOptions.SpecificPeople)
            values = this.autoCompleteValues;
        else if (this.selectedOption === PersonFilterOptions.Self)
            values = [new SearchFilterValue("0", "Myself")];//Need to have some value so this doesn't get disreguared as empty before being sent to the server

        this.valueChange.emit(new SearchFilter(filterName, this.searchOperator, values));
    }

    removeAutoComplete(value: SearchFilterValue): void {
        if (this.isDisabled || (!this.allowEmpty && this.autoCompleteValues.length === 1))
            return;

        let values = this.autoCompleteValues;
        const index = values.findIndex(f => f.FilterValue === value.FilterValue);

        if (index >= 0) {
            values.splice(index, 1);

            //Only call if removed
            this.fireChangeEvent();
        }
    }
}
