import { Component, Input } from '@angular/core';

import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { BaseComponent } from 'Shared/Components/Forms/BaseComponent';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AuthenticationService } from 'Services/AuthenticationService';
import { take } from 'rxjs/operators';
import { FieldLengths } from 'Models/Configuration/FieldLengths.model';

@Component({
    selector: 'address',
    templateUrl: './Address.component.html',
    styleUrls: ['./Address.component.scss']
})
export class AddressComponent extends BaseComponent {

    @Input()
    public FieldLengths: FieldLengths = new FieldLengths();

    constructor(authenticationService: AuthenticationService) {
        super();

        authenticationService.CurrentUserObserver().pipe(take(1))
            .subscribe(user => {
                //  This component is used on self registration so will not have a user logged in.
                //  In that case, self registration sets the FieldLengths @Input based on which One Call it is registering for.
                if (user && user.FieldLengths)
                    this.FieldLengths = user.FieldLengths;
            });
    }

    @Input() set group(val: UntypedFormGroup) {
        this.formGroup = val;
    }

    private _focusOnGroupFocusNext: boolean = false;
    @Input('GroupAutoFocusNext') set FocusOnGroupFocusNext(val: boolean) {
        this._focusOnGroupFocusNext = coerceBooleanProperty(val);
    }
    get FocusOnGroupFocusNext(){
        return this._focusOnGroupFocusNext;
    }
    private _focusOnGroupFocusPrevious: boolean = false;
    @Input('GroupAutoFocusPrevious') set FocusOnGroupFocusPrevious(val: boolean) {
        this._focusOnGroupFocusPrevious = coerceBooleanProperty(val);
    }
    get FocusOnGroupFocusPrevious() {
        return this._focusOnGroupFocusPrevious;
    }

    private _LastControlInContainer: boolean = false;
    @Input() set IsLastControlInContainer(val: boolean) {
        this._LastControlInContainer = coerceBooleanProperty(val);
    }
    get IsLastControlInContainer() {
        return this._LastControlInContainer;
    }

    //Only set this if the first control in the container is a component we created because angular can't auto find it that deep!
    private _FirstControlInContainer: boolean = false;
    @Input() set IsFirstControlInContainer(val: boolean) {
        this._FirstControlInContainer = coerceBooleanProperty(val);
    }
    get IsFirstControlInContainer() {
        return this._FirstControlInContainer;
    }

    get attention() { return this.formGroup.get("Attention"); }
    get attentionIsRequired() { return this.isRequiredControl(this.attention); }

    get address1() { return this.formGroup.get("Address1"); }
    get address1IsRequired() { return this.isRequiredControl(this.address1); }

    get address2() { return this.formGroup.get("Address2"); }
    get address2IsRequired() { return this.isRequiredControl(this.address2); }

    get city() { return this.formGroup.get("City"); }
    get cityIsRequired() { return this.isRequiredControl(this.city); }

    get state() { return this.formGroup.get("State"); }
    get stateIsRequired() { return this.isRequiredControl(this.state); }

    get zip() { return this.formGroup.get("Zip"); }
    get zipIsRequired() { return this.isRequiredControl(this.zip); }
    
    get county() { return this.formGroup.get("County"); }
    get countyIsRequired() { return this.FieldLengths.Address_County > 0; }

    get reference() { return this.formGroup.get("Reference"); }
    get referenceIsRequired() { return this.isRequiredControl(this.reference); }

    //  Setting [required] on the input makes it show an "*" in the placeholder.
    //  Why not make a directive that does this that we can just pop up the element?
    //  If do that, make it handle the FieldIsRequiredAsync validator used by the county field.
    private isRequiredControl(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }

        return false;
    }
}
