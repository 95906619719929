import { NgModule } from '@angular/core';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { EmergencyContactsComponent } from "./EmergencyContacts/EmergencyContacts.component";

@NgModule({
    imports: [
        GlobalSharedModule
    ],
    exports: [
        EmergencyContactsComponent
    ],
    declarations: [
        EmergencyContactsComponent
    ]
})
export class ServiceAreaAnonymousModule { }
