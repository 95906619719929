export enum ServiceAreaExtraordinaryCircumstancesTypeEnum {
        None = 0,
        AllCounties = 1,
        SpecificCounties = 2,
}

export enum ServiceAreaExtraordinaryCircumstancesTypeEnumDescriptions {
    None = "Not Declared",
    AllCounties = "All Counties",
    SpecificCounties = "Specific Counties",
}
