import { Component, HostBinding, Inject } from '@angular/core';
import { IQ_FLYOUT_DATA, IQ_FLYOUT_REF } from 'Shared/PhoneDevice/SideSlideout/SideSlideout.service';
import { SideSlideoutRef } from 'Shared/PhoneDevice/SideSlideout/SideSlideoutRef';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { DialogModel } from '../../Dialog/Models/Dialog.model';

@Component({
    templateUrl: './GenericFlyout.component.html',
    styleUrls: ['./GenericFlyout.component.scss'],
    animations: [slideInFromRight]
})

export class GenericFlyoutComponent {
    //  Defining the animation on the HostBinding like this is cleaner and causes it to do the close animation automatically.
    @HostBinding('@slideInFromRight') animation = 'enter';

    constructor(@Inject(IQ_FLYOUT_DATA) public data: DialogModel, @Inject(IQ_FLYOUT_REF) private _SideSlideoutRef: SideSlideoutRef) {
    }

    public Close(confirmed: boolean) {
        this._SideSlideoutRef.close(confirmed);
    }
}
