import { Injectable, NgZone, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoRefreshToken, CognitoUser, CognitoUserSession, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { Auth, AuthClass, Hub } from 'aws-amplify';
import { from, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, takeWhile, tap } from 'rxjs/operators';
import { VerifyAlternateLoginDialogComponent } from '../LinkAccount/verify-alternate-login-dialog/verify-alternate-login-dialog.component';
import {
    FederatedLoginState,
    IqAwsCognitoAllowedCognitoConfiguration, IqAwsCognitoAmplifyConfig, IqAwsCognitoConfig, NewUser,
    REDIRECT_KEY, SignInState, SingleSignOnService, ViewState, ViewStateEnum
} from '../models';
import { LocalEncodedStorage } from '../Storage/local-encoded-storage';
import { IsUsernameValid } from '../validation';
import { CryptoService } from './crypto.service';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class IqAwsCognitoService {

    /** used to try and auto log in the user after they have confirmed their account */
    private _password: string;

    /**
     * Storage used to store stuff in this class.
     */
    private get storage() {
        let storage = this.CurrentConfig.storage;
        if (storage != null)
            return storage;

        //Don't like doing this, but it's not a public property.  Maybe ignore it and just use local storage?
        storage = (<any>Auth)._storage;

        if (storage != null)
            return storage;

        //Should never get here unless the structure above changes
        //This should default to memory storage if local storage isn't allowed.
        return LocalEncodedStorage;
    }

    private _loggedInwithExternalUserStoragKey = "iqAwsCognito.LoggedInWithExternal";
    /**
     * Get if the user is returning from doing an external login from Cognito.  i.e. they picked a Google login
     */
    private get LoggedInWithExternal() {
        let val = this.storage.getItem(this._loggedInwithExternalUserStoragKey);
        if (val == null)
            return null;

        return JSON.parse(val);
    }
    /**
     * Set if the user is starting a to do an external login from Cognito.  i.e. the picked a google login
     */
    private set LoggedInWithExternal(val: boolean) {
        if (val == null)
            this.storage.removeItem(this._loggedInwithExternalUserStoragKey);
        else
            this.storage.setItem(this._loggedInwithExternalUserStoragKey, JSON.stringify(val));
    }

    private _externalLoginSignoutCalledStoragKey = "iqAwsCognito.IsExternalSignoutCalled";
    /**
     * Get if the user is returning from doing an external login from Cognito.  i.e. they picked a Google login
     */
    private get IsExternalSignoutCalled() {
        let val = this.storage.getItem(this._externalLoginSignoutCalledStoragKey);
        if (val == null)
            return null;

        return JSON.parse(val);
    }
    /**
     * Set if the user is starting a to do an external login from Cognito.  i.e. the picked a google login
     */
    private set IsExternalSignoutCalled(val: boolean) {
        if (val == null)
            this.storage.removeItem(this._externalLoginSignoutCalledStoragKey);
        else
            this.storage.setItem(this._externalLoginSignoutCalledStoragKey, JSON.stringify(val));
    }


    private _doingExternalGoogleLoginStoragKey = "iqAwsCognito.RedirectedGoogleLogin";
    private wasDoingExternalLogin = false;
    /**
     * Get if the user is returning from doing an external login from Cognito.  i.e. they picked a Google login
     */
    private get DoingGoogleLogin() {
        let val = this.storage.getItem(this._doingExternalGoogleLoginStoragKey);
        if (val == null)
            return null;

        return JSON.parse(val);
    }
    /**
     * Set if the user is starting a to do an external login from Cognito.  i.e. the picked a google login
     */
    private set DoingGoogleLogin(val: boolean) {
        if (val == null)
            this.storage.removeItem(this._doingExternalGoogleLoginStoragKey);
        else
            this.storage.setItem(this._doingExternalGoogleLoginStoragKey, JSON.stringify(val));
    }

    private _refreshTokenCreatedTime = "iqAwsCognito.RefreshTokenCreatedTime";

    private getStoredRefreshTokenExiprationValue(clientId: string) {
        return this.storage.getItem(this._refreshTokenCreatedTime + clientId);
    }

    /**
     * Get when the token was created so we can know if we need to force them to re-enter the creds
     */
    private doesRefreshTokenExpireSoon(clientId: string): boolean {

        let usesRefreshExpiration = this.UsesRefreshTokenExpiration(clientId);
        if (usesRefreshExpiration == false)
            return false;

        let storedVal: string = this.getStoredRefreshTokenExiprationValue(clientId);

        return this.checkStoredRefreshTokenExpireSoon(clientId, storedVal);
    }

    //  This tracks the date when we last called checkStoredRefreshTokenExpireSoon().
    //  That happens on initial app load (including browser refresh) and on every page navigation.
    //  If this value is null, we have never checked the expiration.  This causes us to use half of the token
    //  expiration minutes so that the initial load will be more aggressive at forcing the user to log in - which is
    //  better to do at a time when the user is not in the middle of something.
    //  If this value is more than 4 hours old, we also use half the refresh token value.  Because if the user has
    //  let the browser sit for that long, they are not in the middle of something and more than likely just left their
    //  browser open when they should have closed it.
    //  The effect is a bit of a sliding token expiration and should make sure that at these points (initial app
    //  load or after 4 hours of inactivity), if the token is still valid, it will be valid for at least half of
    //  the configured token expiration minutes (so if configured for 20 hours, they are good for at least another
    //  10 hours).
    private _DateLastCheckedRefreshTokenExpiration: Date = null;

    /**
     * Checks if the client uses the feature and if the storedVal is an expired date
     * @param clientId
     * @param storedVal
     */
    private checkStoredRefreshTokenExpireSoon(clientId: string, storedVal: string = null): boolean {

        const usesFeature = this.UsesRefreshTokenExpiration(clientId);
        if (!usesFeature)
            return false;

        if (storedVal == null || storedVal == "")
            storedVal = this.getStoredRefreshTokenExiprationValue(clientId);

        //Still empty then it's expired
        if (storedVal == null || storedVal == "")
            return true;

        const storedDate = new Date(storedVal);

        //  TODO: May want to configure some of these parameters instead of hardcoding to 4 hours and using half
        //  of the expiration minutes.  Obviously, if the token expiration is ever configured lower than
        //  (or near) 4 hours, this will cause it to not work as expected.
        let minutesValid = this.RefreshTokenExpirationMinutes(clientId);
        if (!this._DateLastCheckedRefreshTokenExpiration) {
            //  When this is null, we have never checked - so it's the initial app load or the browser was refreshed.
            //  Use half the token expiration.  This will make sure they have a valid token (one way or the other)
            //  for at least half the expiration time.
            minutesValid = minutesValid / 2;
        } else {
            //  If we last checked this 4+ hours ago, the user left their browser sitting for a very long time
            //  (and chances are, overnight).  So use half the expiration value since we know the user can't
            //  be in the middle of something.  This will make sure they have a valid token (one way or the other)
            //  for at least half the expiration time.
            const d = new Date(this._DateLastCheckedRefreshTokenExpiration);
            d.setHours(d.getHours() + 4);
            if (d < new Date())
                minutesValid = minutesValid / 2;
        }

        storedDate.setMinutes(storedDate.getMinutes() + minutesValid);

        this._DateLastCheckedRefreshTokenExpiration = new Date();
        return storedDate <= new Date();
    }

    /**
     * Set when the refresh token was created s0 we can figure out when to force them to re-enter the creds
     * @param clientId
     * @param setToDate OPTIONAL - if the client uses the feature it will store the value passed in here if it is not null or empty.  Used when setting up the with
     * a value from single sign on
     */
    private setRefreshTokenCreatedTime(clientId: string, setToDate: string = null): string {
        if (!this.UsesRefreshTokenExpiration(clientId))
            return null;

        if (setToDate != null) {
            this.storage.setItem(this._refreshTokenCreatedTime + clientId, setToDate);
            return setToDate;
        }

        let now = new Date();

        let storedDate = now.toString();
        this.storage.setItem(this._refreshTokenCreatedTime + clientId, storedDate);

        return storedDate;
    }

    private removeRefreshTokenCreatedTime(clientId: string) {
        this.storage.removeItem(this._refreshTokenCreatedTime + clientId);
    }

    private RefreshTokenExpirationMinutes(clientId: string) {
        if (!this.UsesRefreshTokenExpiration(clientId))
            throw "Client doesn't use the RefreshTokenExipration feature";

        if (this.DefaultConfig.userPoolWebClientId === clientId)
            return this.DefaultConfig.RefreshTokenExiprationMinutes;
        else if (this.AlternateConfigs != null && this.AlternateConfigs.length > 0) {
            let config = this.AlternateConfigs.find(f => f.Configuration.userPoolWebClientId == clientId);
            if (config != null)
                return config.Configuration.RefreshTokenExiprationMinutes;
        }

        throw "Client RefreshTokenExipration value not found";
    }

    private UsesRefreshTokenExpiration(clientId: string) {
        if (this.DefaultConfig.userPoolWebClientId === clientId)
            return this.DefaultConfig.RefreshTokenExiprationMinutes != null && this.DefaultConfig.RefreshTokenExiprationMinutes > 0;
        else if (this.AlternateConfigs != null && this.AlternateConfigs.length > 0) {
            let config = this.AlternateConfigs.find(f => f.Configuration.userPoolWebClientId == clientId);
            if (config != null)
                return config.Configuration.RefreshTokenExiprationMinutes != null && config.Configuration.RefreshTokenExiprationMinutes > 0;
        }

        return false;
    }


    public get DefaultConfig() {
        return this.config.DefaultCognitoSettings;
    }

    public get AlternateConfigs() {
        return this.config.AlternateCognitoSettings;
    }

    public get CurrentConfig() {
        return Auth.configure(null);
    }

    /**
     * Was the config passed in set to log debug messages
     */
    private get IsDebug() {
        return this.config.Debug;
    }


    private _doingAltRedirectStorageKey = "iqAwsCognito.AltRedirectedLogin"
    private set RedirectForLogin(clientId: string) {
        this.storage.setItem(this._doingAltRedirectStorageKey, clientId);
    }
    private get RedirectForLogin() {
        let val = this.storage.getItem(this._doingAltRedirectStorageKey);
        this.storage.removeItem(this._doingAltRedirectStorageKey);
        return val;
    }

    private currentViewState: ViewState;
    /**
     * Set the authState so that the controls know to move to the next step
     * @param state The state that should now be shown
     */
    setViewState(state: ViewState) {
        //If it's not a valid username then don't carry it over.
        if (state.user != null && state.user.username != null && IsUsernameValid(state.user.username) != null)
            state.user.username = null;

        this.currentViewState = state;
        this._viewStateInfo.next(state);
    }

    /**
     * Used to determine the viewState to show.  Don't set directly, call setViewState
     */
    private _viewStateInfo = new ReplaySubject<ViewState>(1);
    /**
     * An event that has the value of what view should be shown
     */
    viewStateInfoChange$ = this._viewStateInfo.asObservable();


    private setSignedIn(val: SignInState) {
        this._signedInEvent.next(val);
    }
    /**
     * Used to determine if the user is signed in.  Don't set directly, call setSignedIn
     */
    private _signedInEvent = new ReplaySubject<SignInState>(1);
    /**
     * Attach to this to know if the user is signed in or not
     */
    signInEventChange$ = this._signedInEvent.asObservable();

    private _federatedLoginCustomState = new Subject<FederatedLoginState>();

    constructor(//private winRef: WindowRef,
        private ngZone: NgZone,
        private config: IqAwsCognitoConfig,
        @Optional() private SingleSignOnService: SingleSignOnService,
        private matDialog: MatDialog, private encryptionService: CryptoService
    ) {

        if (this.DoingGoogleLogin) {
            const conf = this.AlternateConfigs.find(f => f.IsGoogle == true);
            if (conf != null)
                Auth.configure(conf.Configuration);
            else
                Auth.configure(this.DefaultConfig);//Safety net so something gets set
        }
        else
            Auth.configure(this.DefaultConfig);

        if (this.IsDebug) {
            console.log("iqAwsCognitoLog - " + (this.AlternateConfigs != null && this.AlternateConfigs.length > 0 ? "configured to use a multiple userpool configs" : "not configured to use a multiple userpool configs"));
        }

        Hub.listen(
            'auth', ({ payload: { event, data } }) => {
                if (event === "customOAuthState") {
                    if (this.IsDebug)
                        console.log("iqAwsCognitoLog - Federated Custom State: " + data, "\r\ndecodeURIComponent State: " + decodeURIComponent(data), "\r\nFully Decoded string: " + this.encryptionService.decode(decodeURIComponent(data)));

                    //Have to use our encode process because the aws amplify doesn't encode some string characters right (i.e. a - will completely break there encoding and cut off the object when we get it back.  So { returnUrl: '/virtual-scroll' } will get back { returnUrl: '/virtual (missing the "-scroll' }"))
                    //  Digging through their code it looks like they use a '-' to signify a custom state exists....Since our routes have '-' in them, we have to translate them to something else...Don't want to do random charactors, and then figuring it out, so just
                    //  use our encoding to get rid of them so we have an easy way to transform it back
                    //
                    //I'm not sure why this isn't URI decoded at this point, but it isn't, so we have to do it ourselves
                    this._federatedLoginCustomState.next(JSON.parse(this.encryptionService.decode(decodeURIComponent(data))));
                }

                //Only care if it's being called because of an external login i.e. Google
                else if (event === "cognitoHostedUI"){
                    //For some reason amplify fires this message before the customOAuthState one.  So we need to always pass a custom state to the federated login stuff (at minimum send the provider used i.e. Google or Facebook, etc.) so we can make
                    //  sure we have that value so we can pass it in the SignedIn event.
                    this._federatedLoginCustomState.pipe(take(1)).subscribe(federatedState => {
                        this.ngZone.run(() => {
                            const user: CognitoUser = data;

                            //Always clear this out because we don't need it now
                            this.DoingGoogleLogin = null;

                            let clientId = this.CurrentConfig.userPoolWebClientId;
                            //Need to wait for the session to set the SSO token stuff
                            Auth.currentSession().then(session => {

                                this.LoggedInWithExternal = true;

                                let refreshDate = this.setRefreshTokenCreatedTime(clientId);
                                //Don't really care if it saves..
                                if (this.SingleSignOnService != null) {
                                    this.SingleSignOnService.StoreData({
                                        clientId: clientId,
                                        username: user.getUsername(),
                                        token: user.getSignInUserSession().getRefreshToken().getToken(),
                                        tokenExpirationDate: refreshDate
                                    })
                                        .subscribe();
                                }

                                this.setSignedIn({ SignedIn: true, User: user, UsedSso: false, FederatedLoginReturnState: federatedState })
                                this.setViewState({
                                    state: ViewStateEnum.signedIn,
                                    user: user,
                                    MessageData: null
                                });
                            });

                        });
                    });
                }
            }
        );

        let redirectClientId = this.RedirectForLogin;
        if (redirectClientId != null) {
            let config = this.AlternateConfigs.find(f => f.Configuration.userPoolWebClientId === redirectClientId);
            if (config != null) {
                this.TrySiginFromSingleSignOn(config.Configuration).pipe(take(1)).subscribe()
            }
            else {//Should never really happen, but just to be safe..
                //Just need to call to check the status.  The method handles setting the value
                this.GetCurrentUserAppStartUp().pipe(take(1))
                    .subscribe(null,
                        //Catch the error but don't do anything so that there isn't anything logged by default
                        () => { });
            }
        }
        else if (!this.DoingGoogleLogin && !this.IsExternalSignoutCalled) {
            //Just need to call to check the status.  The method handles setting the value
            this.GetCurrentUserAppStartUp().pipe(take(1))
                .subscribe(() => {
                    //If we get here then we logged in with a token
                    this.IsExternalSignoutCalled = null;
                    this.LoggedInWithExternal = null;
                },
                    //Catch the error but don't do anything so that there isn't anything logged by default
                    () => { });
        }
        else if (this.IsExternalSignoutCalled) {
            this.IsExternalSignoutCalled = null;
            this.LoggedInWithExternal = null;

            //Have to set these because this will happen from a redirect.
            //  We have to handle this like this because we may have something stored in the SSO Connecter
            //  still (i.e. for a different app), but we don't want to auto log them in again.  It will log them in if they refresh the page, but
            //  there is no way around that with how this needs to function.
            this.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
            this.setSignedIn({ SignedIn: false, User: null, UsedSso: false, FederatedLoginReturnState: null });
        }
        else {
            this.DoingGoogleLogin = null;
            this.wasDoingExternalLogin = true;
        }
    }

    /**
     * Run this from the contructor only!  It will check for a current logged in user and set the view state properly
     */
    private GetCurrentUserAppStartUp(): Observable<any> {

        return from(Auth.currentAuthenticatedUser())
            .pipe(mergeMap(user => {
                if (this.doesRefreshTokenExpireSoon(this.CurrentConfig.userPoolWebClientId)) {
                    if (this.IsDebug) {
                        console.log("IqAwsCognitoLog - Constructor token expires soon, logging out.");
                    }

                    //Do this to clear out the locally stored data.  Do not call the Signout method in this service or it will clear out the SSO tokens too,
                    //  which we don't want yet, because we need to check if that has been updated on a different url/site..
                    return from(Auth.signOut({ global: false })).pipe(take(1), map(() => {
                        this.removeRefreshTokenCreatedTime(this.CurrentConfig.userPoolWebClientId);

                        throw "Session Expired";
                    }));
                }

                return of(user);
            }), tap(user => {

                //Don't care if it succeds
                if (this.SingleSignOnService != null)
                    this.SingleSignOnService.StoreData({
                        clientId: this.CurrentConfig.userPoolWebClientId,
                        username: user.getUsername(),
                        token: user.getSignInUserSession().getRefreshToken().getToken(),
                        tokenExpirationDate: this.getStoredRefreshTokenExiprationValue(this.CurrentConfig.userPoolWebClientId)
                    }).subscribe();

                this.setViewState({ state: ViewStateEnum.signedIn, user, MessageData: null });
                this.setSignedIn({ SignedIn: true, User: user, UsedSso: false, FederatedLoginReturnState: null });
            },
                err => {

                    if (this.AlternateConfigs != null && this.AlternateConfigs.length > 0) {
                        //Check for any of the alternate configs stored locally (this will check the non local ones, SSO Connector, after dong the local check)
                        this.CheckForAlternateConfigLogins();
                    }
                    else
                        this.LoginNotFoundLocally();

                }));
    }

    /**
     * Get the authenticated user
     */
    public GetCurrentAuthenticatedUser(): Observable<any> {

        return from(Auth.currentAuthenticatedUser());
    }


    /**
     * Get the current session that holds the tokens
     */
    public GetTokens(): Observable<CognitoUserSession> {
        return from(Auth.currentSession());
    }

    /**
     * Check if the session is valid or if it needs to be refreshed
     */
    public IsSessionValid(): Observable<boolean> {
        return from(Auth.currentSession()).pipe(map(val => {
            if (this.doesRefreshTokenExpireSoon(this.CurrentConfig.userPoolWebClientId))
                return false;

            return val.isValid();
        }), catchError(err => of(false)));
    }

    private SetParamsInClientMetadata(clientMetadata: { [key: string]: string }, password: string): { [key: string]: string } {
        //  Sending the MigrationUrl and Password so that we can capture the hashed password and stash it in a DynamoDB table
        //  during the PreAuthorization/PostAuthorization lambdas in the cognito login flow.
        //  PostAuthorization does not get that data, so it's hashed and stored by the PreAuthorization lambda (as "last password hash").
        //  The PostAuthorization is called after the user is successfully authenticated.  It will swap the "last password hash"
        //  in to a "password hash" property.
        //  If we ever need to switch to a new user pool (due to Amazon region being down, for example), the UserMigration
        //  will then use the DynamoDB record to automatically migrate the user with their password in to the new user pool.
        //  The MigrationUrl is used as a fallback (if there is no DynamoDB record) to ask the host system (Exactix) if
        //  a username exists.  If so, the user can be migrated with no password and forced in to the forgot password flow.
        //  Sending the Password like this is not ideal, but it's already sent in this signon request to Amazon anyway so it's not
        //  exposing anything.
        //  All of the above is being done in the Exactix Cognito Lambdas.  The extra data sent here will not affect any other systems
        //  that don't use it.
        if (!clientMetadata)
            clientMetadata = {};
        if (this.config.FindExistingUserUrl)
            clientMetadata.FindExistingUserUrl = this.config.FindExistingUserUrl;
        clientMetadata.Password = password;

        return clientMetadata;
    }

    /**
     * Sign in the user
     * @param username username
     * @param password password
     * @param clientMetadata Optional data to send to Cognito
     */
    public SignIn(username: string, password: string, clientMetadata: { [key: string]: string } = null): Observable<any> {
        clientMetadata = this.SetParamsInClientMetadata(clientMetadata, password);

        return from(Auth.signIn(username, password, clientMetadata)).pipe(tap(user => {

            //Clear this out so that it's not kept around
            this._password = null;

            let clientId = this.CurrentConfig.userPoolWebClientId;
            let tokenExpireDate = null;
            if (this.UsesRefreshTokenExpiration(clientId))
                tokenExpireDate = this.setRefreshTokenCreatedTime(clientId);

            if (!user.challengeName) {
                //Don't really care if it succeeds or not
                if (this.SingleSignOnService != null)
                    this.SingleSignOnService.StoreData({
                        clientId: clientId,
                        username: user.getUsername(),
                        token: user.getSignInUserSession().getRefreshToken().getToken(),
                        tokenExpirationDate: tokenExpireDate
                    }).subscribe();

                this.setViewState({ state: ViewStateEnum.signedIn, user, MessageData: null });
                this.setSignedIn({ SignedIn: true, User: user, UsedSso: false, FederatedLoginReturnState: null });
                return user;
            }

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.setViewState({ state: ViewStateEnum.newPasswordRequired, user, MessageData: password });
            } else if (user.ChallengeName === 'MFA_SETUP') {
                this.setViewState({ state: ViewStateEnum.setupMFA, user, MessageData: null });
            } else if (
                user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA'
            ) {
                this.setViewState({ state: ViewStateEnum.confirmWithCode, user, MessageData: null });
            } else {
                //Custom challenge, don't know how to handle these quite yet
            }
            return user;
        }, err => {
            if (err.code) {
                if (err.code === "UserNotConfirmedException")
                    this.setViewState({ state: ViewStateEnum.confirmWithCode, user: { username: username }, MessageData: null });
                else if (err.code === "PasswordResetRequiredException")
                    this.setViewState({ state: ViewStateEnum.resetPassword, user: { username: username }, MessageData: { FromLogin: true } });
            }
        }));
    }

    /**
     * Complete the new password chalenge if the user had to change the password because an admin set a temp one for them
     * @param user The user that is completing the new password. Should be a CognitoUser
     * @param newPassword The new password to set
     */
    public CompleteNewPassword(user: any, newPassword: string, clientMetadata: { [key: string]: string } = null) {
        return from(Auth.completeNewPassword(user, newPassword, user.challengeParam.requiredAttributes, clientMetadata)).pipe(tap(() => {

            //Don't really care if it succeeds or not
            if (this.SingleSignOnService != null)
                this.SingleSignOnService.StoreData({
                    clientId: this.CurrentConfig.userPoolWebClientId,
                    username: user.getUsername(),
                    token: user.getSignInUserSession().getRefreshToken().getToken(),
                    tokenExpirationDate: this.getStoredRefreshTokenExiprationValue(this.CurrentConfig.userPoolWebClientId)
                }).subscribe();

            this.setViewState({ state: ViewStateEnum.signedIn, user, MessageData: null });
            this.setSignedIn({ SignedIn: true, User: user, UsedSso: false, FederatedLoginReturnState: null });
        }));
    }

    /**
     * Log the user out
     *
     * Clears out the Single Sign On if logged in using the default config.  To clear out if not the default then  make global = true
     * @param global if true then it will do a global signout on Cognito including the Single Sign On if currently logged in with an alternate configuration
     */
    public SignOut(global: boolean = false): Observable<any> {

        if (this.IsDebug) {
            console.log("IqAwsCognitoLog - Sign out");
        }

        //Maybe make sure to clear out the default one too?
        if (this.SingleSignOnService != null) {
            //Only clear out the default unless they are signing out globally from the login
            if (this.CurrentConfig.userPoolWebClientId == this.config.DefaultCognitoSettings.userPoolWebClientId || global == true)
                this.SingleSignOnService.ClearData(this.CurrentConfig.userPoolWebClientId).subscribe();
        }

        //Maybe make sure to clear out the default one too?
        this.removeRefreshTokenCreatedTime(this.CurrentConfig.userPoolWebClientId);

        if (this.LoggedInWithExternal === true)
            this.IsExternalSignoutCalled = true;

        return from(Auth.signOut({ global: global })).pipe(tap(data => {

            //Always return to the main pool
            this.ChangeAuthConfig();

            this.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
            this.setSignedIn({ SignedIn: false, User: null, UsedSso: false, FederatedLoginReturnState: null });


            return data;
        }));
    }

    /**
     * Initiates the forgot password for the user
     * @param username the username to change password
     * @param clientMetadata Optional data to send to Cognito
     */
    public InitForgotPassword(username: string, clientMetadata: { [key: string]: string } = null): Observable<any> {
        if (!username) {
            return throwError('Username cannot be empty');
        }

        clientMetadata = this.SetParamsInClientMetadata(clientMetadata, undefined);

        return from(Auth.forgotPassword(username, clientMetadata));
    }

    /**
     * Completes the forgot password flow.
     * @param username — The username
     * @param code — The confirmation code
     * @param password — The new password
     */
    public CompleteForgotPassword(username: string, password: string, code: string, clientMetadata: { [key: string]: string } = null): Observable<void> {
        if (!username)
            return throwError('Username cannot be empty');

        return from(Auth.forgotPasswordSubmit(username, code.trim(), password, clientMetadata)).pipe(mergeMap(data => {

            //This should log them in, if not then something bad is going on and then need to enter the password again.
            return this.SignIn(username, password, clientMetadata).pipe(map(() => data));

            //this.setViewState({ state: ViewStateEnum.signedOut, user: { username }, MessageData: null });
        }));
    }

    /**
     * Call Cognito to create a new user
     * @param userAdd data to send to populate the user record
     */
    public CreateNewUser(userAdd: NewUser, clientMetadata: { [key: string]: string } = null): Observable<any> {

        //I don't like trimming this automatically here because they may think that it has the spaces there.  The UI should prevent it/show an error
        if (IsUsernameValid(userAdd.username) != null)
            throw "Username is not valid";

        let newUserMapped = { username: userAdd.username, password: userAdd.password, attributes: null, clientMetadata };

        let attrKeys = Object.keys(userAdd);
        attrKeys = attrKeys.filter(val => { return val != "username" && val != "password" && val != "custom" });
        newUserMapped.attributes = attrKeys.length > 0 ? {} : null;
        for (var i = 0; i < attrKeys.length; i++) {
            //Don't add anything if it's null or we handle it in a different place.  The model is flattened out for ease, but if we
            //	absolutely need to we can make the attributes a collection..
            //	Should never set an attribute to null when first creating a user.
            //	If you want it to be null then we shouldn't pass a value for it
            if (attrKeys[i] == "username" || attrKeys[i] == "password" || attrKeys[i] == "custom" || userAdd[attrKeys[i]] == null)
                continue;

            newUserMapped.attributes[attrKeys[i]] = userAdd[attrKeys[i]];
        }
        if (userAdd.custom != null) {
            let customKeys = Object.keys(userAdd.custom);
            if (newUserMapped.attributes == null && customKeys.length > 0)
                newUserMapped.attributes = {};

            for (var i = 0; i < customKeys.length; i++) {
                //Don't add anything if it's null.  Should never set an attribute to null when first creating a user.
                //	If you want it to be null then we shouldn't pass a value for it
                if (userAdd.custom[customKeys[i]] == null)
                    return;

                let key = 'custom:' + customKeys[i];
                newUserMapped.attributes[key] = userAdd.custom[customKeys[i]];
            }
        }

        return from(Auth.signUp(newUserMapped)).pipe(mergeMap(data => {

            if (data != null && data.userConfirmed == true) {

                //This should log them in, if not then something bad is going on and then need to enter the password again.
                return this.SignIn(userAdd.username, userAdd.password, clientMetadata).pipe(map(() => data));

                // this.setViewState({
                // 	state: ViewStateEnum.signedOut,
                // 	user: { username: newUserMapped.username },
                // 	MessageData: data
                // });
            }
            else {

                //Store the password so that we can try to auto log them in after they confirm the login.
                this._password = userAdd.password;

                this.setViewState({
                    state: ViewStateEnum.confirmWithCode,
                    user: { username: newUserMapped.username },
                    MessageData: data
                });

                return of(data);
            }
        }));
    }

    /**
     * Call Cognito to confirm the user
     * @param username username to confirm
     * @param code code to confirm the username
     */
    public ConfirmUser(username: string, code: string): Observable<any> {
        return from(Auth.confirmSignUp(username, code.trim()))
            .pipe(mergeMap((data) => {
                if (this._password != null) {
                    //Try to log them in.
                    return this.SignIn(username, this._password).pipe(map(() => data));
                }

                this.setViewState({ state: ViewStateEnum.signedOut, user: { username }, MessageData: null })
                return of(data);
            }));
    }

    /**
     * Resend the code that the user got when they first signed.  It is needed to confirm the user
     * @param username username to send the code for
     */
    public ResendSignupCode(username: string, clientMetadata: { [key: string]: string } = null): Observable<string> {
        return from(Auth.resendSignUp(username, clientMetadata));
    }

    /**
     * Start the process of logging in with Google
     */
    public LoginWithGoogle(customState: any = null) {
        this.DoingGoogleLogin = true;
        var state: FederatedLoginState = { Provider: CognitoHostedUIIdentityProvider.Google, CustomState: customState };

        //Have to use our encode process because the aws amplify doesn't encode some string characters right (i.e. a - will completely break there encoding and cut off the object when we get it back.  So { returnUrl: '/virtual-scroll' } will get back { returnUrl: '/virtual (missing the "-scroll' }"))
        //  Digging through their code it looks like they use a '-' to signify a custom state exists....Since our routes have '-' in them, we have to translate them to something else...Don't want to do random charactors, and then figuring it out, so just
        //  use our encoding to get rid of them so we have an easy way to transform it back
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: this.encryptionService.encode(JSON.stringify(state)) });
    }

    //#region Self Manage Account

    /**
     * Allow the logged in user to update the email associated with the login
     * @param user the logged in user.  This should probably be a CognitoUser type
     * @param newEmail new email to use
     */
    public UpdateEmail(user: any, newEmail: string, clientMetadata: { [key: string]: string } = null): Observable<string> {
        return from(Auth.updateUserAttributes(user, { email: newEmail }, clientMetadata));
    }

    /**
     * Allow the logged in user to update the preferred username associated with the login.  Does not change the Username property in Cognito, but sets an alternate login that
     * the user can use
     * @param user the logged in user.  This should probably be a CognitoUser type
     * @param preferredUsername The value to set as the preferred username
     */
    public UpdatePreferredUsername(user: any, preferredUsername: string, clientMetadata: { [key: string]: string } = null) {

        //I don't like trimming this automatically here because they may think that it has the spaces there.  The UI should prevent it/show an error
        if (IsUsernameValid(preferredUsername) != null)
            throw "Username is not valid";

        return from(Auth.updateUserAttributes(user, { preferred_username: preferredUsername }, clientMetadata));
    }

    /**
     * Allow the logged in user to change their password
     * @param user the logged in user.  This should probably be a CognitoUser type
     * @param oldPassword the old password
     * @param newPassword the new password
     */
    public ChangePassword(user: CognitoUser, oldPassword: string, newPassword: string): Observable<boolean> {
        return new Observable<boolean>(observer => {
            user.changePassword(oldPassword, newPassword, (err, success) => {
                if (err != null) {
                    observer.error(err);
                    observer.complete();
                    return;
                }

                observer.next(true);
                observer.complete();
            });
        });
    }
    //#endregion



    //Maybe move these to a base class for orginazation.  But we don't want them to be accessable outside of this class, and def not from a app using the library


    /**
     * Sets the current Auth Config to the Region, UserPoolID, and UserPoolWebClientID values for the config
     * with the name passed in. If no name is passed in it sets it to the main one
     * @param configName — Name in the configuration to change to
     */
    public ChangeAuthConfig(configClientId?: string) {
        let config = this.AlternateConfigs != null ? this.AlternateConfigs.find(f => f.Configuration.userPoolWebClientId == configClientId) : null;
        if (config != null)
            Auth.configure(config.Configuration);
        else
            Auth.configure(this.DefaultConfig);
    }

    /**
     * This will try to login the user to the provided configuration to get the subid.  Used to link a login from an alternate configuration
     * @param configuration the configuration to use
     * @param username the username
     * @param password the password
     */
    public GetAlternateConfigAttributes(configuration: IqAwsCognitoAmplifyConfig, username: string, password: string) {
        let auth = new AuthClass(configuration);
        return from(auth.signIn(username, password))
            .pipe(take(1), mergeMap((user: CognitoUser) => {

                return new Observable<ICognitoUserAttributeData[]>(observer => {
                    //Use this instead of get Attributes because this will return the username and the preferred_username
                    user.getUserData((err, data) => {
                        if (err != null) {
                            observer.error(err);
                            observer.complete();
                        }
                        else {
                            //sign them out because the amplify stuff will store the data and we don't want it stored anywhere for this.
                            //  If it gets stored then if they logout and refresh the browser it will automatically log them back in
                            //  with the login they entered here, which we don't want.
                            auth.signOut().then();

                            //Add in the username as an attribute...This is just so I don't have to create a return value...Hahah
                            data.UserAttributes.push({ Name: "username", Value: data.Username });

                            observer.next(data.UserAttributes);
                            observer.complete();
                        }
                    });
                });

            }));
    }

    public GetAlternateConfigAttributesFromToken(configuration: IqAwsCognitoAmplifyConfig, username: string, token: string) {
        return new Observable<ICognitoUserAttributeData[]>(observer => {
            let auth = new AuthClass(configuration);
            //I don't understand why this is private on the Auth object, but we need to create a user so that we can refresh
            //	it with the refresh token..
            let user = (<any>auth).createCognitoUser(username);

            var RefreshToken = new CognitoRefreshToken({
                RefreshToken: token
            });

            let that = this;
            var refreshCallback = function (err, session) {
                if (that.IsDebug)
                    console.log('iqAwsCognitoLog - tried to refresh token', (err != null ? '--Got error, set to sign in view' : '--Session returned, set to signed in view'), (err != null ? err : session));

                if (err != null) {
                    observer.next(null);
                    observer.complete();
                }
                else {
                    user.getUserData((err, data) => {

                        if (err != null) {
                            observer.error(err);
                            observer.complete();
                        }
                        else {
                            //sign them out because the amplify stuff will store the data and we don't want it stored anywhere for this.
                            //  If it gets stored then if they logout and refresh the browser it will automatically log them back in
                            //  with the login they entered here, which we don't want.
                            auth.signOut().then();

                            //Add in the username as an attribute...This is just so I don't have to create a return value...Hahah
                            data.UserAttributes.push({ Name: "username", Value: data.Username });

                            observer.next(data.UserAttributes);
                            observer.complete();
                        }
                    });
                }
            };

            user.refreshSession(RefreshToken, refreshCallback);
        });
    }

    public RedirectForAlternateLogin(altConfig: IqAwsCognitoAllowedCognitoConfiguration) {
        this.RedirectForLogin = altConfig.Configuration.userPoolWebClientId;

        window.location.href = altConfig.RedirectUrl + '?' + REDIRECT_KEY + '=' + window.location.href;
    }



    private _doingAltRedirectLinkStorageKey = "iqAwsCognito.AltRedirectedLinkLogin";
    /** Sets a value in storage so we know to do something with this when redirected from the alternate app */
    set RedirectForLinkLogin(clientId: string) {
        this.storage.setItem(this._doingAltRedirectLinkStorageKey, clientId);
    }
    /** Get the value from storage (and removes it!) so we know to do something with this when redirected from the alternate app */
    get RedirectForLinkLogin() {
        let val = this.storage.getItem(this._doingAltRedirectLinkStorageKey);
        this.storage.removeItem(this._doingAltRedirectLinkStorageKey);
        return val;
    }

    /**
     * used to pass the attributes when linking a login to the client app so it can save...
     */
    private _linkLoginValue = new Subject<ICognitoUserAttributeData[]>();
    /**
     * An event that has the value of the attributes the user is trying to link to a person
     */
    linkLoginValueChange$ = this._linkLoginValue.asObservable();

    /**
     * Try to link the alternate login.  Pops up a dialog verifing they want to link the login, and will either redirect to the alt login
     * to have the user login, or it will fire the linkLoginValueChange$ change event with the attributes of the login.
     * @param altConfig
     * @param redirect
     */
    public LinkAlternateLogin(altConfig: IqAwsCognitoAllowedCognitoConfiguration, redirect: boolean) {
        let config = altConfig.Configuration
        if (this.SingleSignOnService != null) {
            //Only read for the default config here.  If a child app wants to read for a different configuration then they have to manage that.
            this.SingleSignOnService.ReadData(config.userPoolWebClientId).pipe(take(1))
                .subscribe(ssoVal => {
                    if (ssoVal == null) {
                        if (this.IsDebug) {
                            console.log("iqAwsCognitoLog - Tried to get data from Single Sign On for client id: " + config.userPoolWebClientId + " but didn't get a value");
                        }

                        if (altConfig.RedirectUrl != null && redirect) {
                            this.RedirectForLinkLogin = config.userPoolWebClientId;
                            window.location.href = altConfig.RedirectUrl + '?' + REDIRECT_KEY + '=' + window.location.href;
                        }

                        //If we get here then it's probably because the user hit the back button from the alternate login.

                        return;
                    }

                    this.matDialog.open(VerifyAlternateLoginDialogComponent, {
                        width: "500px",
                        data: { ProviderName: altConfig.Name, Username: ssoVal.username }
                    })
                        .afterClosed()
                        .pipe(take(1)).subscribe(link => {

                            if (link == true) {
                                this.GetAlternateConfigAttributesFromToken(altConfig.Configuration, ssoVal.username, ssoVal.token)
                                    .pipe(take(1)).subscribe(attrs => {
                                        this._linkLoginValue.next(attrs);
                                    });
                            }
                            else if (link == false) {
                                this.RedirectForLinkLogin = config.userPoolWebClientId;
                                window.location.href = altConfig.RedirectUrl + '?' + REDIRECT_KEY + '=' + window.location.href;
                            }

                        });

                    return;

                });
        }
        else {
            return;
        }
    }

    /**
     * Tries to get a login from the configured SSO service.  Returns an Observable just to let the caller know when it is done and if it found anything.
     *
     * This will take care of setting the view state and the signed in state if found or not found.
     * @param config
     */
    public TrySiginFromSingleSignOn(config: IqAwsCognitoAmplifyConfig) {
        return new Observable<boolean>(observer => {
            if (this.SingleSignOnService != null) {
                //Only read for the default config here.  If a child app wants to read for a different configuration then they have to manage that.
                this.SingleSignOnService.ReadData(config.userPoolWebClientId).pipe(take(1))
                    .subscribe(val => {

                        if (val == null || this.checkStoredRefreshTokenExpireSoon(config.userPoolWebClientId, val.tokenExpirationDate)) {
                            if (this.IsDebug) {
                                if (val == null)
                                    console.log("iqAwsCognitoLog - Tried to get data from Single Sign On for client id: " + config.userPoolWebClientId + " but didn't get a value");
                                else
                                    console.log("iqAwsCognitoLog - Tried to get data from Single Sign On for client id: " + config.userPoolWebClientId + " but the token expiration has passed.");
                            }

                            //Don't care to much if this succeeds, just trying to cleanup the data if we can.
                            if (val && this.checkStoredRefreshTokenExpireSoon(config.userPoolWebClientId, val.tokenExpirationDate))
                                this.SingleSignOnService.ClearData(config.userPoolWebClientId).pipe(take(1)).subscribe();

                            //Only switch if the current view isn't the signed out view.  This way that view can call this to try and login with the config, but not get
                            //	refreshed if nothing is found.
                            if (this.currentViewState == null || this.currentViewState.state != ViewStateEnum.signedOut) {
                                this.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
                                this.setSignedIn({ SignedIn: false, User: null, UsedSso: false, FederatedLoginReturnState: null });
                            }

                            observer.next(false);
                            observer.complete();
                            return;
                        }

                        //Have to set this so that everything is set right to refresh the user
                        Auth.configure(config);

                        //I don't understand why this is private on the Auth object, but we need to create a user so that we can refresh
                        //	it with the refresh token..
                        let user = (<any>Auth).createCognitoUser(val.username);

                        let RefreshToken = new CognitoRefreshToken({
                            RefreshToken: val.token
                        });

                        let that = this;
                        let refreshCallback = function (err, session) {
                            if (that.IsDebug)
                                console.log('iqAwsCognitoLog - tried to refresh token', (err != null ? '--Got error, set to sign in view' : '--Session returned, set to signed in view'), (err != null ? err : session));

                            if (err != null) {
                                that.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
                                that.setSignedIn({ SignedIn: false, User: null, UsedSso: false, FederatedLoginReturnState: null });

                                observer.next(false);
                                observer.complete();
                            }
                            else {
                                that.setRefreshTokenCreatedTime(config.userPoolWebClientId, val.tokenExpirationDate);
                                that.setViewState({ state: ViewStateEnum.signedIn, user, MessageData: null });
                                that.setSignedIn({ SignedIn: true, User: user, UsedSso: true, FederatedLoginReturnState: null });

                                observer.next(true);
                                observer.complete();
                            }
                        };

                        user.refreshSession(RefreshToken, refreshCallback);
                    });
            }
            else {
                //Only switch if the current view isn't the signed out view.  This way that view can call this to try and login with the config, but not get
                //	refreshed if nothing is found.
                if (this.currentViewState == null || this.currentViewState.state != ViewStateEnum.signedOut) {
                    this.setViewState({ state: ViewStateEnum.signedOut, user: null, MessageData: null });
                    this.setSignedIn({ SignedIn: false, User: null, UsedSso: false, FederatedLoginReturnState: null });
                }

                observer.next(false);
                observer.complete();
                return;
            }
        });
    }

    /**
     * This is called when we don't find a login stored in the local session.  This will call the SingleSignOnService to check for a login for the default congig
     */
    private LoginNotFoundLocally() {

        //If there are alternate configs then make sure we set the active config to the default.
        if (this.AlternateConfigs && this.AlternateConfigs.length > 0)
            Auth.configure(this.DefaultConfig);

        let observable = this.TrySiginFromSingleSignOn(this.DefaultConfig);

        //  If there are other clients configured as "alternates", then add them to the mix.
        if (this.config.AlternateCognitoSettings) {
            this.config.AlternateCognitoSettings.forEach(configOption => {
                if (configOption.IsAlternate) {
                    observable = observable.pipe(takeWhile(success => !success), mergeMap(success => {
                        if (success === true)
                            return of(true);
                        else
                            return this.TrySiginFromSingleSignOn(configOption.Configuration);
                    }));
                }
            });
        }

        //Don't care if it is found or not in this method.  The TrySiginFromSingleSignOn will handle everything we need
        observable.subscribe();
    }

    /**
     * Check for any alternate logins in storage for the current domain.  Should only be called from GetCurrentUserAppStartUp
     */
    private CheckForAlternateConfigLogins() {
        if (this.AlternateConfigs == null || this.AlternateConfigs.length == 0)
            return;

        let maxConfigIndices = this.AlternateConfigs.length - 1;

        this.CheckAlternateConfig(0, maxConfigIndices);
    }

    /**
     * Check an alternate config to see if there is a value in storage for it.  This is called recursively until all configs are checked
     * @param configIndex alternate config index from config.SingleSignOnSettings.AllowedAlternateCognitoConfigurations
     * @param maxConfigs the max number of configs in config.SingleSignOnSettings.AllowedAlternateCognitoConfigurations to check
     */
    private CheckAlternateConfig(configIndex: number, maxConfigs: number) {
        let config = this.AlternateConfigs[configIndex];
        if (config == null) {
            this.LoginNotFoundLocally();
            return;
        }

        //Don't check this as it should be redundent to the intial check.
        if (config.Configuration.userPoolWebClientId == this.DefaultConfig.userPoolWebClientId) {
            this.CheckAlternateConfig(configIndex + 1, maxConfigs);
            return;
        }

        let clientId = config.Configuration.userPoolWebClientId;

        if (this.IsDebug)
            console.log("IqAwsCognitoLog - Checking altenate config " + clientId);


        this.ChangeAuthConfig(clientId);

        from(Auth.currentAuthenticatedUser()).pipe(take(1)).subscribe(user => {

            if (this.doesRefreshTokenExpireSoon(clientId) === true) {
                if (this.IsDebug)
                    console.log("IqAwsCognitoLog - Checking altenate config token expires soon, logging out");

                this.SignOut().pipe(take(1)).subscribe();
                return;
            }

            //Don't care if it saves, so no logic in the subscribe.
            if (this.SingleSignOnService != null)
                this.SingleSignOnService.StoreData({
                    clientId: clientId,
                    username: user.getUsername(),
                    token: user.getSignInUserSession().getRefreshToken().getToken(),
                    tokenExpirationDate: this.getStoredRefreshTokenExiprationValue(clientId)//should be stored already
                }).subscribe();

            this.setViewState({ state: ViewStateEnum.signedIn, user, MessageData: null });
            this.setSignedIn({ SignedIn: true, User: user, UsedSso: false, FederatedLoginReturnState: null });
        }, err2 => {

            if (configIndex == maxConfigs) {
                this.LoginNotFoundLocally();
            }
            else {
                this.CheckAlternateConfig(configIndex + 1, maxConfigs);
            }
        });
    }
}
