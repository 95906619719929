<div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
    <mat-card appearance="outlined" class="reactive-width">
        <!-- div needed here in order to set the padding.  Setting it on the mat-card does not work right.  -->
        <div style="padding-left: 10px; right: 10px">
            <busy-indicator [busyKey]="BusyKey"></busy-indicator>
            <mat-card-title>
                <div class="primary-color" style="display:flex; justify-content:space-evenly">
                    <div>New User Registration</div>
                    <button *ngIf="!ShowDisclaimer" mat-button type="button" [color]="'primary'" (click)="Logout()" class="link">Back to Login</button>
                </div>
            </mat-card-title>
            <!--<mat-card-subtitle>This is used to create a new user for an excavator or a homeowner to enter a ticket.</mat-card-subtitle>-->

            <ng-container *ngIf="IsInitialized">
                <div *ngIf="ShowDisclaimer" style="display: flex; flex-direction: column; overflow: hidden; flex-grow: 1">
                    <div style="display: flex; flex-direction: column; overflow: auto; padding: 20px; flex-grow: 1" [innerHTML]="DisclaimerHtml | trustHtml"></div>
                    <div style="display:flex; justify-content:center; padding-bottom:20px; padding-top:1em">
                        <iq-icon-button button-class="Button" icon="OK" (click)="ShowDisclaimer = false">Continue</iq-icon-button>
                        <iq-icon-button button-class="Button" icon="Cancel" (click)="Logout()" style="margin-left:50px">Back to Login</iq-icon-button>
                    </div>
                </div>
                <ng-container *ngIf="!ShowDisclaimer">
                    <ng-container *ngIf="!IsLoggedIn; else personInfoTemplate">
                        <div style="display: flex; flex-direction: column; place-content: center; align-items: center;">
                            Please follow the steps to create a login.
                            <iq-aws-cognito [StartingStep]="6" [PasswordRequirements]="PasswordRequirements" [AllowCreateLogin]="false" [ShowBrandingImage]="false"
                                            [FederatedLoginReturnState]="FederatedLoginReturnState"
                                            style="width: 100%; overflow: auto; max-width: 375px;">

                                <ng-template iqAwsResetPasswordTemplate>
                                    <auth-forgot-password [PasswordRequirements]="PasswordRequirements"></auth-forgot-password>
                                </ng-template>
                            </iq-aws-cognito>
                        </div>
                    </ng-container>

                    <ng-template #personInfoTemplate>
                        <iq-new-user-registration-user-details></iq-new-user-registration-user-details>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
    </mat-card>
</div>
