import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from '../../Interceptors/Api.interceptor';


@NgModule({
    providers: [
        {
            //  HttpIntercepts are SUPPOSED to be global but (despite everything I could find in searches), they
            //  seem to be scoped to the current module.  So need to specify it here in the shared module so that
            //  it gets applied to all modules.
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        }
    ]
})
export class ApiInterceptorModule { }
