import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteTemplatesModule } from 'Shared/Components/Autocomplete/AutocompleteTemplates.module';
import { IconButtonModule } from 'Shared/Components/Controls/IconButton/IconButton.module';
import { FindCompanyDialogComponent } from './Dialog/FindCompanyDialog.component';
import { FindCompanyComponent } from './FindCompany.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatInputModule,
        DragDropModule,
        IconButtonModule,
        AutocompleteTemplatesModule
    ],
    declarations: [
        FindCompanyDialogComponent,
        FindCompanyComponent
    ],
    exports: [
        FindCompanyDialogComponent,
        FindCompanyComponent
    ]
})
export class FindCompanyModule { }
