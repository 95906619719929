import { EntryPageEventServiceBase } from "./EntryPageEventBase.service";

/**
 * This service should be added *IN THE PROVIDER SECTION* of each EntrySectionBase derived ticket entry section.
 * Then any children (including directives) of that section can add this service into it's constructor and it will
 * get a shared instance of it.  Therefore each section will have it's own instance.
 * And anything inside it will have a reference to that instance.
 */
export abstract class EntrySectionEventServiceBase<TPageEventService extends EntryPageEventServiceBase> {

    constructor(public PageEventService: TPageEventService) {
    }
}
