import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'Services/AuthenticationService';
import { MainMenuService } from 'Services/MainMenuService';

@Component({
    selector: 'phone-root',
    templateUrl: './PhoneRoot.component.html',
    styleUrls: ['./PhoneRoot.component.scss'],
    //animations: [
    //    phoneRouteAnimations
    //]
})
export class PhoneRootComponent {
    private _Destroyed: Subject<void> = new Subject();

    public IsSignedIn: Observable<boolean>;

    // If we aren't showing the login page, and they aren't signed in then we want to show a back button to bring them to the loging page
    public ShowingLoginPage: boolean = true;

    constructor(router: Router, authService: AuthenticationService, public MainMenuService: MainMenuService) {
        this.IsSignedIn = authService.UserIsSignedIn;

        this.ShowingLoginPage = router.url === '/login';

        //  Used to determine which header to use (can deep link to non-phone optimized links like an error page).
        //  And also to show a busy spinner if the page is taking a long time to load on the phone(it needs to kind of act like an app)
        router.events.pipe(takeUntil(this._Destroyed)).subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.ShowingLoginPage = router.url === '/login';

                //  This is only so that if they go to a page that isn't optimized for a phone they will be able to navigate.
                //  If we want to block all pages on a phone that aren't optimized then this can be removed.
                const parts = val.url.split("/");
                parts.shift();      // Remove out the first one because it's always empty
            }
        });
    }

    //  This is not working - see Shared\Animations.ts
    //  Animations for route navigation
    //public PrepareRouteAnimation(outlet: RouterOutlet): any {
    //    //console.warn("PrepareRouteAnimation", outlet);
    //    //return outlet && outlet.activatedRouteData && PhoneAnimationTriggers.DefaultAnimation;// outlet.activatedRouteData.animation;
    //    return PhoneAnimationTriggers.SlideInFromRight;
    //}
}
