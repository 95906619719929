<div style="display: flex; flex-direction: column;">
    <mat-chip-listbox #autoChipList>
        <mat-chip-option *ngFor="let value of values" [selectable]="true"
                         [removable]="!isDisabled && (allowEmpty || values.length > 1)"
                         [disabled]="isDisabled"
                         (removed)="RemoveAutoCompleteValue(value)" (keydown.delete)="RemoveAutoCompleteValue(value)">
            {{value.DisplayValue}}
            <mat-icon matChipRemove *ngIf="!isDisabled && (allowEmpty || values.length > 1)">cancel</mat-icon>
        </mat-chip-option>
    </mat-chip-listbox>

    <mat-form-field style="min-width: 300px">
        <mat-label>Search for Company Name or ID</mat-label>
        <input iqDisableAutofill iqAutoFocus type="search" matInput [formControl]="AutoCompleteFormControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="CompanyAutoCompleteDisplayFn" panelWidth="auto"
                          class="excavator-contact-autocomplete-option iq-ticket-entry" (optionSelected)="AutoCompleteResultSelected()">
            <mat-option *ngFor="let option of AutoCompleteFormControl.Results | async" [value]="option">
                <!-- external users not allowed to see all info - it would include names, phones, etc -->
                <iq-excavator-company-autocomplete-option *ngIf="IsLocalUser" [model]="option"></iq-excavator-company-autocomplete-option>
                <div *ngIf="!IsLocalUser" class="item" style="display:flex; white-space:normal">
                    <div class="gray-primary-text" style="min-width:6ch">{{option.CompanyID}}</div>
                    <div style="display: flex; flex-direction: column; flex-grow: 1; padding-left: 10px">{{option.CompanyName}}</div>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
