import { DigSiteIntersection } from "./DigSiteIntersection.model";
import { DigsiteEnteredTypeEnum } from "Enums/DigsiteEnteredType.enum";
import { DistanceUnitsEnum } from "Enums/DistanceUnits.enum";

export class DigSite {

    public DigsiteEnteredType: DigsiteEnteredTypeEnum;

    public GeometryJson?: object;
    public BufferFt?: number;
    public UnbufferedGeometryJson?: object;

    public BothSidesOfStreet?: string;
    public NearEdgeOfRoad?: string;

    public Latitude?: string;
    public Longitude?: string;

    /*
     *  True if the buffered geometry intersects a railroad.
     *  Only set if ICenterBiz.CalculateNearRailroad is set to true.
     */
    public NearRailroad?: boolean;

    public Intersections?: { [key: string]: DigSiteIntersection; };

    public FootprintAmount?: number;
    public FootprintUnits?: DistanceUnitsEnum;

    //  ** If anything is added here that affects geocoding, need to add it to GeocodeService.DigSitesAreEqual()!
}
