import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface iQAdminForcePasswordResetDialogComponentData{
  Username: string;
}

@Component({
  templateUrl: './admin-force-password-reset-dialog.component.html',
  styleUrls: ['./admin-force-password-reset-dialog.component.css']
})
export class iQAdminForcePasswordResetDialogComponent implements OnInit {

  Username: string;

  constructor(private dialogRef: MatDialogRef<iQAdminForcePasswordResetDialogComponent>,
                @Inject(MAT_DIALOG_DATA) dialogData: iQAdminForcePasswordResetDialogComponentData)
  {
    this.Username = dialogData.Username;
  }

  ngOnInit() {
    this.dialogRef.updateSize("500px");
  }

  onChange() {
    this.dialogRef.close(this.Username);
  }
}
