import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PasswordRequirements } from 'iqCognito/models';

export class AdminSetPasswordDialogComponentData {
    PasswordRequirements: PasswordRequirements;
    Permanent: boolean;
}

@Component({
    templateUrl: './AdminSetPasswordDialog.component.html',
    styleUrls: ['./AdminSetPasswordDialog.component.scss']
})
export class AdminSetPasswordDialogComponent {
    public PasswordRequirements: PasswordRequirements;
    public Permanent: boolean;

    public PasswordFormControl: UntypedFormControl = new UntypedFormControl(null);

    constructor(@Inject(MAT_DIALOG_DATA) data: AdminSetPasswordDialogComponentData) {

        this.PasswordRequirements = { ...new PasswordRequirements(), ...data.PasswordRequirements };
        this.Permanent = data.Permanent;
    }
}
