import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Guid } from 'Shared/Utils/Guid';

/* How to disable Chrome autofill:
        Stack Overflow discussion about disabling autofill in Chrome: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
        Latest Chrome bug report about this: https://bugs.chromium.org/p/chromium/issues/detail?id=370363#c7
        12/2018: Chrome changed again...  The way to (currently) prevent Chrome's autofill is to use a Form with autocomplete="off"
            then on <input> elements that should NOT use autofill, set type="search" (or put both of those on the input).
            See: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill/35639702#35639702
            and https://www.w3.org/wiki/HTML/Elements/input/search
        9/12/2020: Chrome is at it again.  Now - in some situations!!! - the above does not work.  Chrome seems to be scanning
            for input elements and looking for patterns.  What (now) seems to disable everything completely is
            adding both of these attributes to the input element: role="presentation" autocomplete = "off"
            See here: https://stackoverflow.com/a/31596296/916949
        1/8/2021: Created this directive.  Angular seems to set the same attributes we need to set.  This seems to allow us to get
            our values set after Angular has done it's thing.
*/

//  *** Make this the first directive in an element.  I don't know why, but it makes a difference...

/**
 * Put this directive on an html element (input) to use whatever method is working today to prevent the browser's built-in autofill.
 */
@Directive({
    selector: '[iqDisableAutofill]'
})
export class DisableAutofillDirective implements OnInit {

    constructor(private _ElementRef: ElementRef<HTMLElement>, private _Renderer: Renderer2) {
    }

    ngOnInit(): void {
        //  Must do all of this in a timeout because Angular seems to be setting some of these values too.
        //  I think that's why sometimes putting these attributes directly on the html attributes sometimes does not work.
        setTimeout(() => {
            if (this._ElementRef.nativeElement instanceof HTMLInputElement) {
                if (this._ElementRef.nativeElement.getAttribute("type") !== "number")
                    this._Renderer.setAttribute(this._ElementRef.nativeElement, "type", "search");
                this._Renderer.setAttribute(this._ElementRef.nativeElement, "role", "presentation");

                //  2021/07/05: Here we go again...  Now adding a name attribute with a random value is necessary too!
                //  Found here: https://stackoverflow.com/questions/60689757/how-to-disable-chrome-autofill-after-2020
                if (!this._ElementRef.nativeElement.getAttribute("name"))
                    this._Renderer.setAttribute(this._ElementRef.nativeElement, "name", Guid.newGuid());
            }

            //  On 1/8/2021, I thought this needed to be set to "nofill" (because of this: https://stackoverflow.com/a/34327924/916949).
            //  But then when I added this directive to other controls, they all started not working.  I think if the
            //  value is not "off", it becomes the name of the autofill field.  Or maybe if it's duplicated, it jacks it up...
            //  Ended up finding that keeping it set to "off" and making sure the 2 above attributes are set - IN A TIMEOUT - seems
            //  to be the magic solution of the day.  If may just be that Angular is overwriting these things so the timeout
            //  gets our values in after Angular has done it's thing.  I noticed on the Angular autocomplete controls, it was
            //  setting "role" to "combo" - so that could explain what I was seeing.
            this._Renderer.setAttribute(this._ElementRef.nativeElement, "autocomplete", "off");
        });
    }
}
