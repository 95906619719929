import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselComponent, CarouselItemDirective } from './Carousel.component';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        FontAwesomeModule
    ],
    declarations: [
        CarouselComponent,
        CarouselItemDirective
    ],
    exports: [
        CarouselComponent,
        CarouselItemDirective
    ]

})
export class CarouselModule { }
