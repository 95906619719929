import { NgModule } from '@angular/core';
import { AddressListSectionComponent } from '@iqSharedComponentControls/Address/ListSection/AddressListSection.component';
import { AddressAddComponent } from 'Shared/AddNewForms/Address/AddressAdd.component';
import { AddressNewDialog } from 'Shared/AddNewForms/Address/NewDialog/AddressNewDialog.component';
import { AddressComponent } from '../Components/Controls/Address/Address.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './Material.module';
import { UtilsModule } from './Utils.module';
import { IQSectionModule } from '../Components/Controls/Section/Modules/Section.module';
import { HomeownerAddressDialog } from '../Components/Controls/Address/HomeownerAddressDialog/HomeownerAddressDialog.component';
import { IconButtonModule } from 'Shared/Components/Controls/IconButton/IconButton.module';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        UtilsModule,
        IQSectionModule,
        IconButtonModule
    ],
    declarations: [
        AddressAddComponent,
        AddressListSectionComponent,
        AddressNewDialog,
        AddressComponent,
        HomeownerAddressDialog
    ],
    exports: [
        AddressAddComponent,
        AddressListSectionComponent,
        AddressComponent,
        HomeownerAddressDialog
    ]
})
export class AddressModule { }
