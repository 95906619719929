import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DiscussionTypeEnum } from 'Enums/DiscussionType.enum';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { TicketServiceArea } from 'Models/Tickets/TicketServiceArea.model';
import { concat, Observable, of, reduce, take } from 'rxjs';
import { SettingsService } from 'Services/SettingsService';
import { AddExcavationDateDialogComponent, AddExcavationDateDialogData } from '../../AddExcavationDate/AddExcavationDateDialog.component';
import { AddMarkingDelayResponseDialogComponent, AddMarkingDelayResponseDialogData } from '../../AddMarkingDelayResponse/AddMarkingDelayResponseDialog.component';

/*
 *  Shows a list of TicketResponseDiscussions for a specific TicketServiceArea.  Meant to be shown in a ticket response list.
 *  Also handles any necessary prompting for responses.
 */
@Component({
    selector: 'iq-ticket-response-discussion-list',
    templateUrl: './TicketResponseDiscussionList.component.html',
    styleUrls: ['./TicketResponseDiscussionList.component.scss']
})
export class TicketResponseDiscussionListComponent {
    private _ServiceArea: TicketAffectedServiceAreaInfo;
    @Input()
    public set ServiceArea(val: TicketAffectedServiceAreaInfo | TicketServiceArea) {
        this._ServiceArea = TicketResponseDiscussionListComponent.ToServiceAreaInfo(val);
    }
    public get ServiceArea(): TicketAffectedServiceAreaInfo {
        return this._ServiceArea;
    }

    /*
     *  Set this to the full list of all service areas in order to handle discussions that affect all service areas.
     */
    @Input()
    public ServiceAreaList: TicketAffectedServiceAreaInfo[] | TicketServiceArea[];

    @Input()
    public ReadOnly: boolean = false;

    /*
     *  Notifies the owner that a response to a discussion has been entered.
     *  Owner can then use the CheckForRequiredDiscussions() method to re-check for required discussions.
     */
    @Output()
    Changed: EventEmitter<void> = new EventEmitter();

    public DiscussionTypeEnum = DiscussionTypeEnum;

    constructor(private _Dialog: MatDialog, public SettingsService: SettingsService) {
    }

    private static ToServiceAreaInfo(val: TicketAffectedServiceAreaInfo | TicketServiceArea): TicketAffectedServiceAreaInfo {
        //  Can't use instanceof on this - probably because we are dealing with objects created from api responses
        //  which are not actually instances of the classes.  They are just objects created from json that are typed
        //  to the class.
        if ((val as any).ServiceAreaInfo)
            return (val as any).ServiceAreaInfo;
        return val as TicketAffectedServiceAreaInfo;
    }

    /**
     * Returns true if any service areas have discussions.
     * @param serviceAreaList
     */
    public static HaveDiscussions(serviceAreaList: TicketAffectedServiceAreaInfo[]): boolean {
        if (!serviceAreaList)
            return false;

        return serviceAreaList.some(sa => sa.Discussions && sa.Discussions.length > 0);
    }

    /**
     * Returns DiscussionTypeEnum values of any required Discussion Types that do not have a response and that the current user
     * is allowed to provide a response.
     * This is static and evaluates discussions for all service areas because some discussions affect all service
     * areas at once while others are individual.  So this allows the component owner to only evaluate this when
     * necessary and to be able to see when all requirements have been met.
     * @param serviceAreaList
     */
    public static CheckForRequiredDiscussions(serviceAreaList: TicketAffectedServiceAreaInfo[]): DiscussionTypeEnum[] {
        if (!serviceAreaList)
            return [];     //  No service areas!

        const requiredDiscussionTypes: DiscussionTypeEnum[] = [];

        serviceAreaList.forEach(sa => {
            if (sa.Discussions) {
                sa.Discussions.forEach(d => {
                    switch (d.DiscussionType) {
                        case DiscussionTypeEnum.MarkingDelayRequested:
                        case DiscussionTypeEnum.RequestActualExcavationDate:
                            //  These require the Excavator to enter a reply.  If we don't have one, notify the container so they
                            //  can display something about it or prevent leaving a dialog or whatever.
                            if (!d.Response && d.CurrentUserCanSaveResponse && requiredDiscussionTypes.indexOf(d.DiscussionType) === -1)
                                requiredDiscussionTypes.push(d.DiscussionType);
                    }
                });
            }
        });

        return requiredDiscussionTypes;
    }

    /**
     *  If any discussions are pending a response from the excavator, bring up the dialog(s) for them now.
     */
    public static PromptForRequiredDiscussionResponses(serviceAreaList: TicketAffectedServiceAreaInfo[] | TicketServiceArea[], dialog: MatDialog): Observable<boolean> {
        const serviceAreaInfoList = serviceAreaList.map(tsa => TicketResponseDiscussionListComponent.ToServiceAreaInfo(tsa));

        //  This is done once and can apply to multiple service areas.  The method already looks at the serviceAreaList and does nothing if there are none
        const excavatorDateObs = TicketResponseDiscussionListComponent.ShowAddExcavationDateDialog(serviceAreaInfoList, dialog);

        //  These are 1 per service area.  So make an array of each of these.
        const markingDelayObsList: Observable<any>[] = [];
        serviceAreaInfoList.forEach(sa => {
            if (sa.Discussions) {
                if (sa.Discussions.some(d => (d.DiscussionType === DiscussionTypeEnum.MarkingDelayRequested) && !d.Response && d.CurrentUserCanSaveResponse))
                    markingDelayObsList.push(TicketResponseDiscussionListComponent.ShowAddMarkingDelayResponse(sa, dialog));
            }
        });

        //  Merge/pipe them all together so they execute in sequence.
        //  This reduce will cause us to return true if any of the dialogs were saved or false if there were none shown or user canceled all of them.
        //  i.e. If true, caller should refresh the view.  If false, nothing changed so refresh not necessary.
        return concat(excavatorDateObs, ...markingDelayObsList).pipe(reduce((acc, value) => coerceBooleanProperty(acc || value), false));
    }

    public AddExcavationDate(event: Event): void {
        //  Needed to prevent click from being handled by the phone view (which opens up the response history)
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        const serviceAreaInfoList = this.ServiceAreaList.map(tsa => TicketResponseDiscussionListComponent.ToServiceAreaInfo(tsa));
        TicketResponseDiscussionListComponent.ShowAddExcavationDateDialog(serviceAreaInfoList, this._Dialog).pipe(take(1)).subscribe(result => {
            if (result)
                this.Changed.emit();
        });
    }

    /**
     *  Show the AddExcavationDate dialog/flyout.  This prompt can apply to multiple service areas.
     *  Does nothing if there are no RequestActualExcavationDate discussion types in the list.
     */
    private static ShowAddExcavationDateDialog(serviceAreaList: TicketAffectedServiceAreaInfo[], dialog: MatDialog): Observable<any> {
        const dialogData = new AddExcavationDateDialogData();

        //  This date is entered once and multiple service areas may have requested it.  Find all of the discussions from any service area
        //  and send in to the dialog.  They will all be handled at once (we prompt once, send the date to the server, it will update all records,
        //  and the dialog will set the date in to all of these items so the UI updates on all of them)
        dialogData.TicketDiscussionList = [];
        serviceAreaList.forEach(sa => {
            if (sa.Discussions) {
                sa.Discussions.forEach(d => {
                    if ((d.DiscussionType === DiscussionTypeEnum.RequestActualExcavationDate) && !d.Response && d.CurrentUserCanSaveResponse)
                        dialogData.TicketDiscussionList.push(d);
                })
            }
        });

        if (dialogData.TicketDiscussionList.length === 0)
            return of(null);

        //  Must wrap this in an Observable because .open() will show the dialog immediately.  When we are showing the prompts
        //  via PromptForRequiredDiscussionResponses(), we need these to be shown sequentially as each previous dialog is dismissed.
        return new Observable<any>(observer => {
            dialog.open(AddExcavationDateDialogComponent, {
                data: dialogData,
                minWidth: '35%',
                width: '500px',//Smaller than min on large screen, so it will use min on large screen.  But larger than max on small screen, so it will use max on small screen 
                maxWidth: '80%'
            }).afterClosed().subscribe(updatedDiscussionList => {
                TicketResponseDiscussionListComponent.UpdateModifiedDiscussions(updatedDiscussionList, serviceAreaList)
                observer.next(updatedDiscussionList);
                observer.complete();
            });
        });
    }

    public AddMarkingDelayResponse(event: Event) {
        //  Needed to prevent click from being handled by the phone view (which opens up the response history)
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        TicketResponseDiscussionListComponent.ShowAddMarkingDelayResponse(this._ServiceArea, this._Dialog).pipe(take(1)).subscribe(result => {
            if (result)
                this.Changed.emit();
        });
    }

    private static ShowAddMarkingDelayResponse(serviceArea: TicketAffectedServiceAreaInfo, dialog: MatDialog): Observable<TicketDiscussionChildResponse[]> {
        const dialogData = new AddMarkingDelayResponseDialogData();
        dialogData.TicketDiscussion = serviceArea.Discussions.find(d => (d.DiscussionType === DiscussionTypeEnum.MarkingDelayRequested) && !d.Response && d.CurrentUserCanSaveResponse);
        if (!dialogData.TicketDiscussion)
            return of(null);

        //  Must wrap this in an Observable because .open() will show the dialog immediately.  When we are showing the prompts
        //  via PromptForRequiredDiscussionResponses(), we need these to be shown sequentially as each previous dialog is dismissed.
        return new Observable<any>(observer => {
            dialog.open(AddMarkingDelayResponseDialogComponent, {
                data: dialogData,
                minWidth: '35%',
                width: '500px',//Smaller than min on large screen, so it will use min on large screen.  But larger than max on small screen, so it will use max on small screen 
                maxWidth: '80%'
            }).afterClosed().subscribe(updatedDiscussionList => {
                TicketResponseDiscussionListComponent.UpdateModifiedDiscussions(updatedDiscussionList, [serviceArea])
                observer.next(updatedDiscussionList);
                observer.complete();
            });
        });
    }

    private static UpdateModifiedDiscussions(updatedDiscussionList: TicketDiscussionChildResponse[], serviceAreaInfoList: TicketAffectedServiceAreaInfo[]): void {
        if (!updatedDiscussionList)
            return;

        serviceAreaInfoList.forEach(sa => {
            if (sa.Discussions) {
                sa.Discussions.forEach(d => {
                    const updatedDiscussion = updatedDiscussionList.find(u => u.ID === d.ID);
                    if (updatedDiscussion) {
                        d.Response = updatedDiscussion.Response;
                        d.ResponseDate = updatedDiscussion.ResponseDate;
                        d.ResponsePerson = updatedDiscussion.ResponsePerson;
                        d.ResponsePersonID = updatedDiscussion.ResponsePersonID;
                        d.CurrentUserCanSaveResponse = updatedDiscussion.CurrentUserCanSaveResponse;
                    }
                });
            }
        });
    }
}
