import { NgModule } from '@angular/core';
import { IqAwsCognitoModule } from 'iqCognito/iq-aws-cognito.module';
import { FindCompanyModule } from 'Pages/Excavators/ExcavatorCompanies/FindCompany/FindCompany.module';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { UserRegistrationComponent } from './NewUserRegistration/UserRegistration.component';
import { RegistrationUserDetailsStepsComponent } from './UserDetailsSteps/RegistrationUserDetailsSteps.component';

@NgModule({
    imports: [
        GlobalSharedModule,
        IqAwsCognitoModule.forRoot(),
        FindCompanyModule
    ],
    declarations: [
        UserRegistrationComponent,
        RegistrationUserDetailsStepsComponent
    ],
    exports: [
        RegistrationUserDetailsStepsComponent       //  Exported so it can be used on the Authentication/NoPersonForLogin page
    ]
})
export class UserRegistrationModule { }
