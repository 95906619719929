<div class="page-header" *ngIf="!IsPhoneView">
    <div class="header-content">
        <div class="header-title-xx">Emergency Contacts</div>
    </div>

    <div *ngIf="IsAnonymous">
        <!-- extra div to prevent the button from expanding to fill the parent -->
        <div class="iq-header-button-container">
            <iq-icon-button button-class="Header" icon="Login" title="Go to Login page" routerLink="/login">Login</iq-icon-button>
        </div>
    </div>
</div>
<div class="page-content" style="display: flex; flex-direction: column; padding: 0px 20px 20px 20px">
    <div style="display:flex; flex-wrap:wrap">
        <mat-form-field *ngIf="OccCodeList.length > 1" style="width: 10em; padding-right:4em">
            <mat-label>One Call</mat-label>
            <mat-select [formControl]="SelectedOccCodeFormControl">
                <mat-option *ngFor="let code of OccCodeList" [value]="code">{{code}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="StateList.length > 1" style="width: 4em; padding-right: 2em ">
            <mat-label>State</mat-label>
            <mat-select [formControl]="SelectedStateFormControl">
                <mat-option *ngFor="let state of StateList" [value]="state">{{state}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="CountyList.length > 1" style="width: 10em; padding-right: 2em">
            <mat-label>County</mat-label>
            <mat-select [formControl]="SelectedCountyFormControl">
                <!-- Value is Name not ID because server needs the Name for the query against RegistrationPlaces -->
                <mat-option *ngFor="let county of CountyList" [value]="county.Name">{{county.Name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="ContactTypeList.length > 1" style="width: 12em; padding-right: 2em ">
            <mat-label>Contact Type</mat-label>
            <mat-select [formControl]="SelectedContactTypeFormControl">
                <mat-option *ngFor="let contactType of ContactTypeList" [value]="contactType.ID">{{contactType.Name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="ContactList?.length > 0" style="display:flex; flex-direction: column; overflow:auto">
        <div class="header-row">
            <div>
                <div class="service-area-col column-header">Service Area</div>
                <div class="contact-name-col column-header">Contact</div>
                <div class="phone-number-col column-header">Phone</div>
                <div class="email-address-col column-header">Email</div>
            </div>
        </div>
        <div *ngFor="let contact of ContactList" class="row">
            <div class="service-area-col">{{contact.ServiceAreaCode}}: {{contact.ServiceAreaName}}</div>
            <div class="contact-name-col">{{contact.ContactName}}</div>
            <div class="phone-number-col">{{contact.PhoneNumber | phone}}</div>
            <div class="email-address-col">{{contact.EmailAddress}}</div>
        </div>
    </div>
</div>
