import { Component, ElementRef, Input, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { SectionGroupContainerService } from '@iqSharedComponentControls/Section/SectionGroupContainer.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';


/**
 * A Container for a list of SectionGroups.  Basically just exists to have a service all groups can access
 */
@Component({
    selector: 'iq-section-group-container',
    templateUrl: './SectionGroupContainer.component.html',
    styleUrls: ['./SectionGroupContainer.component.scss'],
    providers: [SectionGroupContainerService]
})
export class SectionGroupContainerComponent implements AfterViewInit, OnDestroy {

    private _Destroyed: Subject<void> = new Subject();

    //  If true, auto closes all groups when a click is detected outside the group container
    private closeonBlur: boolean = false;
    @Input() set AutoCloseOnBlur(val: boolean) {
        let boolVal = coerceBooleanProperty(val);
        if (this.closeonBlur !== boolVal)
            this.closeonBlur = boolVal;
    }

    @Output() LastControlTabbedOut = new EventEmitter<boolean>();

    constructor(private el: ElementRef, public sectionGroupContainerService: SectionGroupContainerService) {
        this.sectionGroupContainerService.id = 1;
        this.sectionGroupContainerService.lastGroupTabbedOut
            .pipe(takeUntil(this._Destroyed))
            .subscribe(() => this.LastControlTabbedOut.emit(true));
    }

    public ngOnDestroy(): void {
        this._Destroyed.next();
        this._Destroyed.complete();

        this.sectionGroupContainerService.OnDestroy();
    }

    ngAfterViewInit(): void {
        //  This happens AFTER all of the groups inside the container have registered themselves with the
        //  sectionGroupContainerService.  So can now tell the first/last groups that they are
        //  first/last so they can setup their section items accordingly.

        this.sectionGroupContainerService.SetFirstLastGroupsInContainer();
    }
}
