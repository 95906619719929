<div style="width: 100vw; height: 100%; background-color: white; display:flex">
    <div style="display: flex; flex-direction: column; flex-grow:1; width:100%">
        <iq-phone-header>
            <ng-template iqPhoneHeaderLeft>
                <iq-phone-button icon="Cancel" [disabled]="IsSaving" (click)="CloseForm(false)">Cancel</iq-phone-button>
            </ng-template>
            <ng-template iqPhoneHeaderCenter>{{Title}}</ng-template>
            <ng-template iqPhoneHeaderRight>
                <iq-phone-button icon="Save" [disabled]="!IsValid || IsSaving" (click)="OnSaveTicket()">{{SaveButtonText}}</iq-phone-button>
            </ng-template>
        </iq-phone-header>

        <div style="padding:10px; overflow: hidden; display: flex; flex-grow:1;">
            <ng-template dynamic-content></ng-template>
        </div>
    </div>
</div>
