import { Component, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, Validators, UntypedFormGroup } from "@angular/forms";
import { ViewStateEnum, IqAwsCognitoAllowedCognitoConfiguration, IqAwsCognitoConfig, REDIRECT_KEY } from '../../models';
import { AuthComponentBase } from '../../auth-component-base';
import { take, finalize } from 'rxjs/operators';
import { IqAwsCognitoService } from '../../Services/iq-aws-cognito.service';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { validateUsername } from '../../validation';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'iq-aws-cognito-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.css'],
    host: { '[class.iq-aws-cognito-sign-in]': 'true' },
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

        // the "in" style determines the "resting" state of the element when it is visible.
        state('in', style({opacity: 1})),

        // fade in when created. this could also be written as transition('void => *')
        transition(':enter', [
            style({opacity: 0}),
            animate(400)
        ]),

        // fade out when destroyed. this could also be written as transition('void => *')
        transition(':leave',
            animate(400, style({opacity: 0})))
        ])
    ]
})
export class iQSignInComponent extends AuthComponentBase implements AfterViewInit {
    @Input() AllowCreateLogin = true;

    @Input() FederatedLoginReturnState: any = null;

    //@Input() SignInImage: Subject<string>;
    @Input() SignInImage: string;
    @Output() SignInImageChange = new EventEmitter();


    @ViewChild('usernameInput', { read: ElementRef }) usernameInput: ElementRef<HTMLElement>;
    @ViewChild('passwordInput', { read: ElementRef }) passwordInput: ElementRef<HTMLElement>;

    defaultAppName: string;
    defaultAppImage: string;

    get username() { return this.group.get("Username"); }
    get password() { return this.group.get("Password"); }

    isUsernameAutoFilled = false;
    isPasswordAutoFilled = false;
    get isAutoFilled() {
        return this.isUsernameAutoFilled && this.isPasswordAutoFilled;
    }

    AlternateCognitoConfigs: IqAwsCognitoAllowedCognitoConfiguration[];
    usingAlternateConfig: IqAwsCognitoAllowedCognitoConfiguration;

    constructor(protected awsCognitoService: IqAwsCognitoService,
        private config: IqAwsCognitoConfig,
        private autofillMonitor: AutofillMonitor) {

        super(awsCognitoService);

        this.AlternateCognitoConfigs = this.awsCognitoService.AlternateConfigs == null ? null : this.awsCognitoService.AlternateConfigs.filter(f=>f.ShowOnLogin);

        this.defaultAppName = config.DefaultAppName;

        this.defaultAppImage = config.DefaultSignInImage;

    }

    ngOnInit() {
        super.ngOnInit();

        //Should be set by the time this is shown, so we can do a take(1)
        this.awsCognitoService.viewStateInfoChange$.pipe(take(1)).subscribe(val => {
            if (val != null && val.user != null)
                this.username.setValue(val.user.username);
        });
    }
    ngAfterViewInit() {
        this.autofillMonitor.monitor(this.usernameInput).subscribe(e => this.isUsernameAutoFilled = e.isAutofilled);
        this.autofillMonitor.monitor(this.passwordInput).subscribe(e => this.isPasswordAutoFilled = e.isAutofilled);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.autofillMonitor.stopMonitoring(this.usernameInput);
        this.autofillMonitor.stopMonitoring(this.passwordInput);
    }

    ShouldDisplay() {
        return ViewStateEnum.signedOut == this.authState.state;
    }

    BuildForm() {
        return new UntypedFormGroup({
            Username: new UntypedFormControl(null, [Validators.required, validateUsername]),
            Password: new UntypedFormControl(null, [Validators.required])
        });
    }


    private setSignInImage(image: string){
        if (this.SignInImage != null){
            this.SignInImageChange.emit(image);
        }
    }

    trySignIn() {
        if (this.group.valid)
            this.onSignIn();
    }

    private onSignIn() {
        this.isBusy = true;
        this.awsCognitoService.SignIn(this.username.value, this.password.value, this.ClientMetadata)
            .pipe(take(1), finalize(() => this.isBusy = false))
            .subscribe(null,
                err => {
                    if (err.toString().includes("QuotaExceededError") || err.toString().includes("The quota has been exceeded")) {
                        //  Note that the "err" that is returned in this case is some kind of weird object.  It's not a string and, when output using the console,
                        //  doesn't show any properties!  Maybe that's just a FireFox thing though.  So had to .toString() it in order to be able to test for the "quota exceeded" error.
                        //  The full .toString() looks like this: "QuotaExceededError: The quota has been exceeded."
                        //  ** This can be reproduced using FireFox:
                        //  1) Open up the site (Exactix) to the login page
                        //  2) In another tab, put this in the url: "about:config"
                        //  3) Search for setting: dom.storage.default_quota
                        //  4) Change the value to 0
                        //  5) Go back to the Exactix login tab and try to log in.
                        this.SetError("The 'Local Storage' in your browser is full.  Please clear it and try again.  Refreshing your browser may also help.");
                    } else
                        this.SetError(err);
                });
    }

    // onSignInWithGoogle() {
    //     this.isBusy = true;
    //     console.log('in', this.FederatedLoginReturnState);
    //     this.awsCognitoService.LoginWithGoogle(this.FederatedLoginReturnState);
    // }

    returnToDefaultConfig(){

        this.usingAlternateConfig = null;
        this.awsCognitoService.ChangeAuthConfig();

        this.switchViews();
    }

    signInWithAltConfig(alt: IqAwsCognitoAllowedCognitoConfiguration) {
        if (!alt) {
            this.awsCognitoService.ChangeAuthConfig();
            return;
        }

        if (alt.IsGoogle) {
            //  Test to see if LocalStorage is available.  If not, Amplify blows up.  And it's not because we store
            //  our own value into storage, it's inside Amplify somewhere.
            //  The issue only seems to be affecting Mac and iOS when using Safari.
            //  Possible bug in Safari 15, see: https://github.com/aws-amplify/amplify-js/discussions/8986 & https://github.com/aws-amplify/amplify-js/issues/9140
            //  And using a normal Cognito accounts works fine even though it also uses Local Storage (it must detect that and do something else).
            //  Can test this situation using FireFox:
            //  1) Open FireFox and put this in the url: about:config
            //  2) Search for setting: dom.storage.default_quota
            //  3) Change the value to 0
            try {
                window.localStorage.setItem("iqStorageTest", "test");
                window.localStorage.removeItem("iqStorageTest");
            } catch (error) {
                this.SetError("The 'Local Storage' in your browser is full.  Please clear it and try again.  Refreshing your browser may also help.");
                return;
            }

            this.isBusy = true;
            this.awsCognitoService.ChangeAuthConfig(alt.Configuration.userPoolWebClientId);
            this.awsCognitoService.LoginWithGoogle(this.FederatedLoginReturnState);
        }
        else if (alt.RedirectUrl) {
            this.awsCognitoService.TrySiginFromSingleSignOn(alt.Configuration)
                .pipe(take(1))
                .subscribe(loggedIn => {
                    if (!loggedIn)
                        this.awsCognitoService.RedirectForAlternateLogin(alt);
                });
        }
        else {
            this.usingAlternateConfig = alt;

            this.awsCognitoService.TrySiginFromSingleSignOn(alt.Configuration)
                .pipe(take(1))
                .subscribe(loggedIn => {
                    if (!loggedIn) {
                        this.awsCognitoService.ChangeAuthConfig(alt.Configuration.userPoolWebClientId);
                        this.switchViews();
                    }
                });
        }
    }

    switchingViews: boolean = false;
    private switchViews(){
        this.switchingViews = true;
        setTimeout(()=> {
            if (this.usingAlternateConfig != null)
                this.setSignInImage(this.usingAlternateConfig.SignInImage);
            else
                this.setSignInImage(this.config.DefaultSignInImage);

            this.switchingViews = false;
        }, 500);
    }

    getUserObj() {
        return this.username.value ? { username: this.username.value } : null;
    }

    onForgotPassword() {
        const user = this.getUserObj();
        this.awsCognitoService.setViewState({ state: ViewStateEnum.resetPassword, user, MessageData: null });
    }

    onSignUp() {
        const user = this.getUserObj();
        this.awsCognitoService.setViewState({ state: ViewStateEnum.createNewUser, user, MessageData: null });
    }
}
