<div class="dialog-fill-height">
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Responses for Ticket {{Data.Ticket.TicketNumber}}</h1>
    <mat-dialog-content style="display:flex; flex-direction:column; overflow:hidden">
        <ticket-response-service-area [ServiceAreaListData]="Data" #child style="overflow:hidden"></ticket-response-service-area>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: space-between;">
        <div style="display:flex">
            <div style="display:flex">
                <div style="height:20px; width:20px; margin-right:10px" class="no-response-background"></div>
                <div>No Response Posted - Excavator must check job site for marks</div>
            </div>
            <div *ngIf="child.ViewOption === 2" style="display:flex; margin-left:40px">
                <div style="height:20px; width:20px; margin-right:10px" class="event-background"></div>
                <div>Ticket Entry Event</div>
            </div>
        </div>
        <div style="display:flex">
            <iq-icon-button *ngIf="child.IsExcavatorOfTicket && child.CenterUsesExcavatorComments" button-class="Dialog" icon="New" (click)="child.AddExcavatorComment()" style="margin-right:50px">Add Comments</iq-icon-button>

            <mat-checkbox *ngIf="child.NeedsExcavationDate || child.NeedsMarkingDelayResponse" color="primary" (change)="child.IgnoreRequired($event)"
                          style="margin-right:50px; align-self:center">I choose not to provide required answers right now</mat-checkbox>

            <iq-icon-button button-class="Dialog" icon="Close" [disabled]="!child.IsValid && !child.IgnoreRequiredDiscussionResponses" (click)="DialogRef.close()" iqAutoFocus>Done</iq-icon-button>
        </div>
    </mat-dialog-actions>
</div>
