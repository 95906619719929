
export enum MapToolsEnum {
    Buffer = 10,
    CurrentPosition = 25,
    DrawCircles = 45,
    DrawLines = 95,
    DrawPolygons = 105,
    DrawRectangles = 125,
    PushPins = 200
}

export enum MapToolsEnumDescriptions {
    Buffer = "Buffer",
    CurrentPosition = "Current Position",
    DrawCircles = "Draw Circles",
    DrawLines = "Draw Lines",
    DrawPolygons = "Draw Polygons",
    DrawRectangles = "Draw Rectangles",
    PushPins = "Push Pins"
}
