import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { DrawingToolBase } from "Shared/Components/Maps/Tools/DrawingToolBase";
import { MapToolService } from "Shared/Components/Maps/MapToolService";
import { MapConstants } from "Shared/Components/Maps/MapConstants";
import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import Map from 'ol/Map';
import { Draw, Interaction } from "ol/interaction";
import { Collection, MapBrowserEvent } from "ol";
import { Polygon } from "ol/geom";
import { DigSiteSizeControl } from "../Controls/DigSiteSizeControl";

export class DrawPolygonTool extends DrawingToolBase {

    private _NumPoints: number = 0;

    constructor(map: Map, mapToolService: MapToolService, private _VectorLayer: VectorLayerBase) {
        super(map, mapToolService);

        this.Init();
    }

    public OnDestroy(): any {
        this._VectorLayer = null;
        return super.OnDestroy();
    }

    protected CreateInteraction(): Interaction {
        const sizeControl = DigSiteSizeControl.FindSelfInMap(this.Map);

        //  ol.interaction.Draw: https://openlayers.org/en/latest/apidoc/module-ol_interaction_Draw.html
        const interaction = new Draw({
            features: new Collection(),      //  We draw into a collection so that we can pre-process when drawing is finished before adding to the _VectorLayer.
            type: "Polygon",
            style: (feature, resolution) => {
                if (sizeControl && (feature.getGeometry().getType() === "Polygon"))
                    sizeControl.ShowSizeOfFeatureBeingDrawn(feature);
                return this._VectorLayer.BuildStyleForFeature(feature, true, MapFeatureStyleEnum.Polygon, resolution);
            },
            finishCondition: () => this._NumPoints > 2,
            geometryFunction: (coordinates, geometry) => {
                if (geometry)
                    geometry.setCoordinates(coordinates);
                else
                    geometry = new Polygon(coordinates as any);

                const polygon = geometry as Polygon;
                this._NumPoints = polygon.getLinearRing(0).getCoordinates().length;

                return geometry;
            },
            condition: (evt: MapBrowserEvent<PointerEvent>) => {
                if (evt.type !== "pointerdown")
                    return false;       //  ???

                const pointerEvent = evt.originalEvent;
                if (pointerEvent.button === 2) {
                    //  Right click: If empty, turn off the tool.  If not empty, finish the drawing and leave active.
                    if (this._NumPoints === 0)
                        interaction.setActive(false);
                    else
                        interaction.finishDrawing();
                }

                return pointerEvent.button === 0;       //  Only process Left mouse click
            },
        });

        this.AddListener(interaction.on("drawend", (evt: any /* DrawEvent is not exported! */) => {
            this._NumPoints = 0;

            //  Clean the drawing and then add to the source.  This fixes self-intersecting polygons and breaks
            //  them up into multiple features if necessary.  Also applies any extra validation needed (minimum size?).
            this._VectorLayer.CleanAndAddFeature(evt.feature, false, !this.MapToolService.AllowMultipleShapes);

            setTimeout(() => this.MapToolService.ActivateTool.next(MapConstants.TOOL_EDIT_GEOMETRY));
        }));

        return interaction;
    }

    protected OnPointerMove(evt: any): void {
        if (evt.dragging) {
            return;
        }

        let helpMsg = 'Click to start drawing';
        if (this._NumPoints > 0) {
            helpMsg = 'Click to add vertex';
            if (this._NumPoints > 2)
                helpMsg += ',</br>Double-click to finish drawing';
        }

        this.SetHelpMessage(helpMsg, evt.coordinate);
    }
}
