import { MapFeatureStyleEnum } from "Enums/MapFeatureStyle.enum";
import { Feature } from "ol";
import { LineString, Point } from "ol/geom";
import { Icon, Stroke, Style } from "ol/style";
import { VectorLayerBase } from "Shared/Components/Maps/Layers/VectorLayerBase";
import { MapToolService } from "Shared/Components/Maps/MapToolService";

export class RouteVectorLayer extends VectorLayerBase {

    constructor(layerName: string, mapToolService: MapToolService) {
        super(mapToolService, layerName, .5);
    }

    public BuildStyleForFeature(feature: Feature<any>, isDrawing: boolean, featureStyle: MapFeatureStyleEnum, resolution: number): Style | Style[] {
        const styles = [
            new Style({
                stroke: new Stroke({
                    color: "red",
                    width: 3
                })
            })
        ];

        const geom = feature.getGeometry();
        if (geom.getType() !== "LineString")
            return styles;      //  Not a LineString!

        const line = geom as LineString;
        const coords = line.getCoordinates();
        if (coords.length < 2)
            return styles;      //  ???

        const lastSegStart = coords[coords.length - 2];
        const lastSegEnd = coords[coords.length - 1];
        styles.push(this.CreateArrowStyle(lastSegStart, lastSegEnd));

        return styles;
    }

    private CreateArrowStyle(start: number[], end: number[]): Style {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);

        // Create an arrow using an image: https://openlayers.org/en/latest/examples/line-arrows.html
        return new Style({
            geometry: new Point(end),
            image: new Icon({
                src: 'assets/Images/arrow-red.png',
                anchor: [0.75, 0.5],
                rotateWithView: true,
                rotation: -rotation,
            }),
        });

        //  Another way that creates an arrow using a RegularShape: https://stackoverflow.com/a/58237497/916949
        //return new Style({
        //    geometry: new Point(end),
        //    image: new RegularShape({
        //        fill: new Fill({ color: '#ffcc33' }),
        //        points: 3,
        //        radius: 20,
        //        rotation: -rotation,
        //        angle: Math.PI / 2 // rotate 90°
        //    })
        //});
    }
}
