import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router } from '@angular/router';
import { NavigationTypes } from 'Enums/NavigationTypes.enum';
import { Ticket } from 'Models/Tickets/Ticket.model';


@Injectable({
    providedIn: 'root'
})
export class RoutingService {

    constructor(private router: Router) { }

    public getRouteTitle(): string {
        return this.getRouteData();
    }

    private getRouteData(): any {
        const root = this.router.routerState.snapshot.root;

        const event = this.lastChild(root).data;
        switch (event.type) {
            case NavigationTypes.Name:
                return event.item.Name;
            case NavigationTypes.Code:
                return event.item.Code;
            case NavigationTypes.FirstLastName:
                return event.item.FirstName + " " + event.item.LastName;
            case NavigationTypes.Static:
                if (event.title)
                    return event.title;
                break;
            case NavigationTypes.Property:
                return this.GetTitleFromProperty(event);
            case NavigationTypes.TicketNumber:
                //  Special case for showing the TicketNumber.  Can't use Property like we had been doing because
                //  if the Ticket.Status is Incomplete or Void, we are not supposed to show the ticket number!
                const ticket = event.item?.Ticket as Ticket;
                if (ticket?.HideTicketNumber)
                    return event.title;
                //  Otherwise, fallback to handling like "Property" does.  Needed because copying showing a different
                //  property than the other routes.
                return this.GetTitleFromProperty(event);
        }

        return null;
    }

    private GetTitleFromProperty(event: Data): string {
        if (event.titleProperty) {
            //const propValue = event.item[event.titleProperty]?.toString();
            const propValue = this.GetValue(event.item, event.titleProperty)?.toString();
            if (propValue)
                return (event.title ? event.title + " " : "") + propValue;
        }
        if (event.title)
            return event.title;
    }

    //  Adapted from: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
    private GetValue(obj: any, path: string[]): any {
        const [parent, key] = resolve(obj, path);
        return parent[key];

        function resolve(obj, path) {
            const root = obj = [obj];
            path = [0, ...path];
            while (path.length > 1)
                obj = obj[path.shift()];
            return [obj, path[0], root];
        }
    }

    private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
        if (route.firstChild) {
            return this.lastChild(route.firstChild);
        } else {
            return route;
        }
    }

}
