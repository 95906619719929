/*
    This is the template for item in the view list component.  It just displays the html they want for each item with some default styling and a checkbox.
    It will also emit an event when the checkbox is checked
*/

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { BaseListItem } from '../Base/BaseListItem.class';
import { SelectOption } from 'Models/Configuration/SelectOption.model';

@Component({
    selector: 'iq-list-select-item',
    templateUrl: './ListSelectItem.component.html',
    styleUrls: ['./ListSelectItem.component.scss'],
    providers: [//Use this so that we can control the checkbox being checked or not by clicking the area around it and not having to click the actual checkbox
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions }//Don't do anything when the checkbox is clicked
    ]
})
export class ListSelectItemComponent extends BaseListItem {

    //  If this is set to an empty list (not left as undefined), the menu will not be displayed at all.
    //  So can set ShowSelect=false and actions=[] to prevent showing any select check boxes or action menu.
    //  Useful if the list can be edited or needs to be readonly due to user permissions.
    @Input() actions: SelectOption[];

    private _showSelect: boolean;
    @Input() set ShowSelect(val: boolean) {
        this._showSelect = val;
    }
    get ShowSelect() {
        return this._showSelect;
    }

    @Output() ShowSelectChange: EventEmitter<boolean> = new EventEmitter();

    @Input() checked: boolean = false;

    @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() ActionSelected = new EventEmitter<SelectOption>();

    //Called from the clicking the area aroung the check box an not actually the checkbox
    ToggleCheck() {
        this.checked = !this.checked;
        this.changed.next(this.checked)
    }

    ChangeToSelect() {
        this.ToggleCheck();
        this.ShowSelectChange.next(!this._showSelect);
    }

    ActionClicked(action: SelectOption) {
        this.ActionSelected.emit(action);
    }
}
