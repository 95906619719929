import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityBase } from 'Models/Base/EntityBase.model';
import { Form } from 'Models/Configuration/Form.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'Services/SettingsService';

export enum TicketHelpUserType {
    LocalUser = 1,
    SelfRegistrationRole = 2,
    WebUser = 4
}

export class TicketHelpSectionData extends EntityBase {

    FormSectionID: string;
    OneCallCenterID: string;

    UserType: TicketHelpUserType;

    Html: string = null;

    LastModifyDateTime: Date;

    UpdatedBy: string;
}

@Injectable({
    providedIn: 'root'
})
export class TicketHelpService {

    private _CachedForms: Form[];

    constructor(private http: HttpClient, private settingsService: SettingsService) {
    }

    public ClearCache(): void {
        this._CachedForms = null;
    }

    public InsertOrUpdate(item: TicketHelpSectionData): Observable<TicketHelpSectionData> {
        return this.http.post<TicketHelpSectionData>(this.settingsService.ApiBaseUrl + "/Config/FormSectionHelp", item);
    }

    public Delete(id: string): Observable<TicketHelpSectionData> {
        return this.http.delete<TicketHelpSectionData>(this.settingsService.ApiBaseUrl + "/Config/FormSectionHelp/" + id);
    }

    public GetFormSections(getCached = false): Observable<Form[]> {
        if (getCached && this._CachedForms)
            return of(this._CachedForms);

        return this.http.get<Form[]>(this.settingsService.ApiBaseUrl + "/Config/Forms/GetHelp")
            .pipe(map(val => {
                if (val && val.length > 0)
                    this._CachedForms = val;
                return val;
            }));
    }
}
