import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogModel } from "../Models/Dialog.model";


@Component({
    templateUrl: './InformationDialog.component.html',
    styleUrls: ['./InformationDialog.component.scss']
})
export class InformationDialogComponent {

    constructor(public dialogRef: MatDialogRef<InformationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogModel) {
    }
}
