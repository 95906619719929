import { NgModule } from '@angular/core';
import { GlobalSharedModule } from 'Shared/Modules/GlobalShared.module';
import { PrintTicketTextAndServiceAreasModule } from '../Details/Printing/PrintTicketTextAndServiceAreas.module';
import { TicketSimpleViewComponent } from './Components/TicketSimpleView.component';
import { TicketSearchByNumberAndPhoneComponent } from './Pages/SearchByNumberAndPhone/TicketSearchByNumberAndPhone.component';
import { ViewTicketIDComponent } from './Pages/ViewTicketID/ViewTicketID.component';

@NgModule({
    imports: [
        GlobalSharedModule,
        PrintTicketTextAndServiceAreasModule
    ],
    exports: [
        ViewTicketIDComponent, TicketSearchByNumberAndPhoneComponent
    ],
    declarations: [
        ViewTicketIDComponent,
        TicketSimpleViewComponent, TicketSearchByNumberAndPhoneComponent
    ]
})
export class TicketAnonymousModule { }
