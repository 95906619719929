import { Component, ViewChild } from '@angular/core';
import { SearchFilterOperatorEnum } from 'Enums/SearchFilterOperator.enum';
import { TicketService } from 'Pages/Tickets/Services/TicketService';
import { SettingsService } from 'Services/SettingsService';
import { slideInFromRight } from 'Shared/Utils/Animations';
import { AuthenticationService } from '../../../../../Services/AuthenticationService';
import { TicketActionsService } from '../../../Services/TicketActions.service';
import { TicketListItemViewComponent } from '../../ListItemViews/TicketListItemView.component';
import { PhoneTicketListBase } from '../PhoneTicketListBase';

@Component({
    templateUrl: './PhoneTicketSearch.component.html',
    styleUrls: ['./PhoneTicketSearch.component.scss'],
    animations: [slideInFromRight]
})
export class PhoneTicketSearchComponent extends PhoneTicketListBase {
    @ViewChild(TicketListItemViewComponent)
    public ticketList: TicketListItemViewComponent;

    public LimitToTicketsWithinXFtOfCurrentLocation?: number;

    constructor(protected ticketService: TicketService, protected settingsService: SettingsService,
        protected ticketActionsService: TicketActionsService, authenticationService: AuthenticationService) {
        super(ticketService, settingsService, ticketActionsService, authenticationService);
    }

    protected override LoadView() {
        super.LoadView();

        //  If there is a filter limiting to the current location, set this to force zooming to current location and hide the "Tickets" link on the .html page.
        const withinFeetValues = this.Config.DefaultFilters?.find(f => f.Operator === SearchFilterOperatorEnum.WithinXFeetOfLocation)?.Values;
        if (withinFeetValues && withinFeetValues.length === 3)
            this.LimitToTicketsWithinXFtOfCurrentLocation = withinFeetValues[2].FilterValue;
    }
}
