import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DiscussionTypeEnum } from 'Enums/DiscussionType.enum';
import { TicketAffectedServiceAreaInfo } from 'Models/Tickets/TicketAffectedServiceAreaInfo.model';
import { TicketDiscussion } from 'Models/Tickets/TicketDiscussion.model';
import { TicketDiscussionChildResponse } from 'Models/Tickets/TicketDiscussionChildResponse.model';
import { finalize, map, Observable, of } from 'rxjs';
import { TicketDiscussionService } from '../../Services/TicketDiscussion.service';

export class AddExcavatorCommentData {
    constructor(public TicketNumber: string, public ServiceAreas: TicketAffectedServiceAreaInfo[]) { }
}

@Component({
    selector: 'iq-ticket-add-excavator-comment',
    templateUrl: './AddExcavatorComment.component.html',
    styleUrls: ['./AddExcavatorComment.component.scss']
})
export class AddExcavatorCommentComponent {

    @Input()
    public Data: AddExcavatorCommentData;

    public FormGroup: UntypedFormGroup;
    public ServiceAreasFormControl: UntypedFormControl;
    public CommentFormControl: UntypedFormControl;

    private _IsSaving: boolean = false;

    //  Check this in the parent component to see if a Save button can be enabled.
    //  Check to make sure the form is valid and that we are not in the process of saving.
    public get CanSave(): boolean {
        return this.FormGroup && this.FormGroup.valid && !this._IsSaving;
    }

    constructor(private _DiscussionService: TicketDiscussionService) {
        this.ServiceAreasFormControl = new UntypedFormControl(null, Validators.required);
        this.CommentFormControl = new UntypedFormControl(null, Validators.required);

        this.FormGroup = new UntypedFormGroup({
            ServiceAreas: this.ServiceAreasFormControl,
            Comment: this.CommentFormControl
        });
    }

    public CompareServiceAreas(sa1: TicketAffectedServiceAreaInfo, sa2: TicketAffectedServiceAreaInfo) {
        if (sa1 != null && sa2 != null)
            return sa1.ID === sa2.ID;

        return sa1 === null && sa2 === null;
    }

    /**
     * Observable returns a TicketResponseItem if the response was saved.  Otherwise returns null if nothing was saved
     */
    public Save(): Observable<TicketDiscussionChildResponse[]> {
        if (!this.CanSave)
            return of(null);

        const comments: TicketDiscussion[] = [];
        const serviceAreaIDs = this.ServiceAreasFormControl.value as TicketAffectedServiceAreaInfo[];

        for (let i = 0; i < serviceAreaIDs.length; i++) {
            let model = new TicketDiscussion();

            //  CreateDate and CreatePersonID are set by server.  *NEVER* set a date using the client workstation - we have no idea
            //  if their date is set correctly!
            model.IsPublic = false;
            model.DiscussionType = DiscussionTypeEnum.ExcavatorComment;
            model.ServiceAreaID = serviceAreaIDs[i].ID;
            model.TicketNumber = this.Data.TicketNumber;
            model.Message = this.CommentFormControl.value;

            comments.push(model);
        }

        this._IsSaving = true;
        return this._DiscussionService.CreateExcavatorComment(comments).pipe(
            map(response => response),
            finalize(() => this._IsSaving = false)
        );
    }
}
