//  This package is a fork of the Angular MatDatepicker that supports prompting for a date *AND* time.
//  The default Angular DatePicker is imported in the MaterialModule and can be used when only prompting for a Date.
import { NgModule } from '@angular/core';

//  Becuase it is a fork, it is nearly identical except for adding support for the time.
//  Choosing the date format: https://github.com/ng-matero/extensions/issues/141
//  For this one, we are using the Moment adapter since this was done new so decided to do it right.
//  We can swap to this control instead of the default angular control if we want to and it will then be using moment.
import { MtxMomentDatetimeModule } from '@ng-matero/extensions-moment-adapter';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';

const modules: any[] = [MtxMomentDatetimeModule, MtxDatetimepickerModule];

@NgModule({
    imports: modules,
    exports: modules,
    providers: [
        {
            //  Configure the date format to be used by the @ng-matero/extensions date picker using "moment" dates
            //  Format codes: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
            provide: MTX_DATETIME_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM/DD/YYYY',
                    monthInput: 'MMMM',
                    yearInput: 'YYYY',
                    timeInput: 'HH:mm',
                    datetimeInput: 'MM/DD/YYYY HH:mm',
                },
                display: {
                    dateInput: 'MM/DD/YYYY',
                    monthInput: 'MMMM',
                    yearInput: 'YYYY',
                    timeInput: 'hh:mm a',
                    datetimeInput: 'M/DD/YYYY hh:mm a',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                    popupHeaderDateLabel: 'MMM DD, ddd',
                }
            }
        }
    ]
})
export class DateTimePickerModule { }
