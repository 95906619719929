import { Injectable } from '@angular/core';
import { PermissionsEnum } from 'Enums/RolesAndPermissions/Permissions.enum';
import { CRUDBaseService, CRUDServices } from 'Shared/BaseServices/CRUDBase.service';
import { SearchResponse } from 'Models/Searching/SearchResponse.model';
import { Observable, of } from 'rxjs';
import { SearchRequest } from 'Models/Searching/SearchRequest.model';
import { mergeMap } from 'rxjs/operators';
import { BillingType } from '../../../Models/Configuration/BillingType.model';


@Injectable({
    providedIn: 'root'
})
export class BillingTypeService extends CRUDBaseService<BillingType> {

    ViewPermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_View;//This isn't used for the GetAll function.  We need to allow them to get a list of them to add/modify/view a member.
    EditPermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_Edit;
    CreatePermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_Create;
    DeletePermission: PermissionsEnum = PermissionsEnum.SystemConfiguration_Delete;

    protected apiPath: string = 'Config/BillingTypes';

    constructor(protected services: CRUDServices) {
        super(services)
    }

    GetList(searchRequest: SearchRequest, keepListOfIDsForNextPreviousFunctionality: boolean = true): Observable<SearchResponse> {
        throw "Not Used";
    }

    GetAll() {
        //Don't put permissions around this because they are needed to add, edit, and view a member.
        return this.services.http.get<BillingType[]>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath);
    }

    ForceDelete(billingTypeId: string, newBillingTypeId): Observable<BillingType> {
        return this.CanPerformAction('Edit').pipe(mergeMap(allowed => {
            return new Observable<BillingType>(observer => {

                if (!allowed)//If they don't have permission then return an empty list, else do the search
                {
                    observer.next();
                    observer.complete();
                }

                return this.services.http.post<BillingType>(this.services.settingsService.ApiBaseUrl + "/" + this.apiPath + "/ForceDelete", { BillingTypeID: billingTypeId, ReplaceWithID: newBillingTypeId })
                    .subscribe(val => {
                        observer.next(val);
                        observer.complete();
                    }, err => {//Need the errors here so that our navigation will work for redirecting
                        observer.error(err);
                        observer.complete();
                            return of<BillingType>();
                    });
            });

        }));
    }
}
