import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionItemComponent } from '../Item/SectionItem.component';
import { SectionGroupComponent } from '../Group/SectionGroup.component';
import { SectionGroupContainerComponent } from '../Container/SectionGroupContainer.component';
import { SectionItemLastControlDirective } from '../Item/SectionItemLastControl.directive';
import { SectionGroupContainerService } from '../SectionGroupContainer.service';
import { SectionGroupService } from '../SectionGroup.service';
import { SectionItemService } from '../SectionItem.service';


@NgModule({
    imports: [
        //MaterialModule,
        CommonModule,
    ],
    declarations: [
        SectionItemComponent, SectionGroupComponent, SectionGroupContainerComponent, SectionItemLastControlDirective,
    ],
    exports: [
        SectionItemComponent, SectionGroupComponent, SectionGroupContainerComponent, SectionItemLastControlDirective
    ],
    providers: [
        SectionGroupContainerService,
        SectionGroupService,
        SectionItemService
    ]

})
export class IQSectionModule { }
